import type { Product } from '@/features/products/entities';

function getPriority(product: Product): number {
  let priority = 0;

  if (product.barcodes.length > 1) {
    //Priority is at least 2
    priority = product.barcodes.length;
  }

  if (product.barcodes.length === 1 && product.barcodes[0] !== product.sku) {
    priority = 1;
  }

  return priority;
}

export function sortByBarcodesPriority(products: Product[]): Product[] {
  return products.sort(
    (product1, product2) => getPriority(product2) - getPriority(product1),
  );
}
