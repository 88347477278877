import { readonly, ref } from 'vue';
import { deviceIdServicePlugin } from '../plugins';
import type { useDeviceInfoClass } from '../types';

const deviceId = ref<string | undefined>();

export function useDeviceInfo(): useDeviceInfoClass {
  const getDeviceId = async () => {
    if (deviceId.value) return;
    deviceId.value = await deviceIdServicePlugin.get().getDeviceId();
  };

  void getDeviceId();

  return {
    deviceId: readonly(deviceId),
  };
}
