
import { computed, defineComponent } from 'vue';
import Button from './Button.vue';
import { useOauthLogout } from '@/features/login/composables';

export default defineComponent({
  name: 'LogoutButton',
  components: { Button },
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isLogoutCanBeDisabled, isLogoutCanBeHide, handleLogout } =
      useOauthLogout();

    const isLogoutDisabled = computed(
      () => props.disabled && isLogoutCanBeDisabled.value,
    );

    const isLogoutHide = computed(() => props.hide && isLogoutCanBeHide.value);

    return {
      handleLogout,
      isLogoutDisabled,
      isLogoutHide,
    };
  },
});
