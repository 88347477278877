import type { Product } from '@/features/products';
import type { OrderItem } from '@/features/orders';
import { isItemEntity } from './isItemEntity';
import type { BarcodeWithEmbeddedValue } from '../types';
import { roundInputWithExponentToDigits } from './roundInputWithExponentToDigits';

export function calculateWeight<T extends OrderItem | Product>(
  itemOrProduct: T,
  baseData: BarcodeWithEmbeddedValue,
): number | null;

export function calculateWeight(
  itemOrProduct: OrderItem | Product,
  baseData: BarcodeWithEmbeddedValue,
): number | null {
  if (baseData.barcodeType === 'weightEmbedded') {
    return baseData.embeddedValue;
  }

  const unitPriceInCents = isItemEntity(itemOrProduct)
    ? itemOrProduct.product.price
    : itemOrProduct.price;

  if (!unitPriceInCents || !baseData.embeddedValue) return null;

  return roundInputWithExponentToDigits(
    baseData.embeddedValue / (unitPriceInCents / 100),
    2,
  );
}
