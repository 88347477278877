import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bar" }
const _hoisted_2 = { class: "bar__content" }
const _hoisted_3 = { class: "bar__content__navigation" }
const _hoisted_4 = { class: "bar__content-right-side" }
const _hoisted_5 = { class: "bar__icons" }
const _hoisted_6 = {
  key: 0,
  class: "bar__icons__icon"
}
const _hoisted_7 = { class: "bar__icons__icon" }

import { ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { Popup } from '@/features/ui/components';
import CustomerNotePopup from '@/features/ui/components/CustomerNotePopup.vue';
import { ArrowLeftIcon, InfoIcon, ProfileIcon } from '@/features/ui/icons';
import { Profile } from '@/features/login/views';
import type { Order } from '@/features/orders/entities/order';
import { useCheckInOrders } from '@/features/orders';


export default /*@__PURE__*/_defineComponent({
  __name: 'StatusBar',
  props: {
    order: {},
    to: { default: '/' },
    title: { default: '' },
    hasBackButton: { type: Boolean, default: true },
    hasInfoIcon: { type: Boolean, default: true },
    customerNote: { default: undefined },
    customerFullName: { default: undefined }
  },
  setup(__props: any) {



const visibleProfile = ref(false);
const showProfile = () => {
  visibleProfile.value = !visibleProfile.value;
};

const { checkInBanner } = useCheckInOrders();
const visibleNote = ref(false);
const toggleNote = () => {
  visibleNote.value = !visibleNote.value;
  checkInBanner.value = !visibleNote.value;
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.hasBackButton)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: _ctx.to,
              class: "ut-d-flex",
              "data-e2e": "header-back-link"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(ArrowLeftIcon), { width: "24" })
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true),
        _createElementVNode("h3", {
          class: _normalizeClass([
            'bar__content__title ut-font_fix',
            { 'pl-16': _ctx.hasBackButton },
          ]),
          "data-e2e": "header-order-label"
        }, _toDisplayString(_ctx.title || _ctx.order.orderReference), 3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "right", {}, () => [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.hasInfoIcon)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_ctx.customerNote)
                    ? (_openBlock(), _createBlock(_unref(InfoIcon), {
                        key: 0,
                        "data-e2e": "show-note-button",
                        onClick: toggleNote
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_unref(ProfileIcon), {
                "data-e2e": "show-account-button",
                onClick: showProfile
              })
            ])
          ]),
          _createVNode(CustomerNotePopup, {
            closeButtonText: 
              _ctx.$t('components.customer-note-popup.button-close.text')
            ,
            customerFullName: _ctx.customerFullName,
            customerNote: _ctx.customerNote,
            isVisible: visibleNote.value,
            order: _ctx.order,
            onConfirm: toggleNote
          }, null, 8, ["closeButtonText", "customerFullName", "customerNote", "isVisible", "order"])
        ])
      ])
    ]),
    _createVNode(_unref(Popup), {
      visible: visibleProfile.value,
      fullWidth: "",
      isSpaceToContentExist: "",
      onClose: showProfile
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Profile))
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})