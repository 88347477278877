import { $t } from '@/i18n';
import type { Ref } from 'vue';
import { ref } from 'vue';
import { loggerServicePlugin } from '@/features/core/logger';
import { errorPlugin } from '@/features/core/errors';
import type { Order, UseCancelOrder } from '@/features/orders';
import { OrderLocalStatus, ordersServicePlugin } from '@/features/orders';
import { appCrashServicePlugin } from '@/features/app-crash';
import { updateOrderTimestamp } from '@/utils/helpers/updateOrderTimestamp';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import router from '@/features/core/router';

export function useCancelOrder(): UseCancelOrder {
  const cancellationInProcess: Ref<boolean> = ref(false);

  const cancelOrder = async (order: Order) => {
    const isOrderCancellable = [
      OrderLocalStatus.PickingInProgress,
      OrderLocalStatus.Paused,
      OrderLocalStatus.HandoverInProgress,
    ].includes(order.localStatus);

    const isOrderInHandoverStage =
      order.localStatus === OrderLocalStatus.HandoverInProgress;

    try {
      cancellationInProcess.value = true;

      if (!isOrderCancellable) {
        throw new Error($t('common.cancel-order-error.message'));
      }

      order.localStatus = isOrderInHandoverStage
        ? OrderLocalStatus.HandoverCancelled
        : OrderLocalStatus.PickingCancelled;

      updateOrderTimestamp(order);

      await ordersServicePlugin.get().saveOrder(order, true, false, false);

      loggerServicePlugin
        .get()
        .info(`Order ${order.id} cancelled (no items found)`, {
          orderId: order.id,
        });

      notificationPlugin.get().show({
        text: $t('common.cancel-order-toast.message', {
          orderId: order.orderReference,
        }),
        type: NotificationType.Info,
      });

      await appCrashServicePlugin.get().updateProcessedOrderData(order);
    } catch (error) {
      errorPlugin.get().handle(error);
    } finally {
      cancellationInProcess.value = false;
      await router.get().push({
        name: 'default',
      });
    }
  };

  return {
    cancelOrder,
    cancellationInProcess,
  };
}
