import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = ["data-e2e"]
const _hoisted_3 = { class: "list" }

interface Props {
  title: string;
  e2e: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ItemsGroup',
  props: {
    title: {},
    e2e: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", {
      class: "title",
      "data-e2e": _ctx.e2e
    }, _toDisplayString(_ctx.title), 9, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})