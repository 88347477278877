import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "barcode-validate" }
const _hoisted_2 = {
  class: "barcode-validate__title",
  "data-e2e": "barcode-validate-title"
}
const _hoisted_3 = {
  class: "barcode-validate__note",
  "data-e2e": "barcode-validate-note"
}
const _hoisted_4 = { class: "barcode-validate__input" }
const _hoisted_5 = { class: "barcode-validate__footer" }

import type { Ref } from 'vue';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { Button, Popup } from '@/features/ui/components';
import MultipleInputsCode from '@/features/ui/components/MultipleInputsCode.vue';
import { debounce } from '@/utils/helpers/debounce';


export default /*@__PURE__*/_defineComponent({
  __name: 'ValidateItemBarcodePopup',
  props: {
    isVisible: { type: Boolean, default: false },
    title: {}
  },
  emits: ["validate", "focus", "blur", "cancel"],
  setup(__props: any, { expose: __expose, emit: __emit }) {



const emit = __emit;

onMounted(() => {
  window.addEventListener('resize', debounce(handleWindowSizeChange, 100));
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debounce(handleWindowSizeChange, 100));
});

const barcodeInput: Ref<typeof MultipleInputsCode | null> = ref(null);
const dialogWithKeyboardPadding = ref('');

const handleWindowSizeChange = () => {
  dialogWithKeyboardPadding.value = `${
    window.innerHeight - window.visualViewport.height
  }px`;
};

const indicateValidation = (isValid: boolean) => {
  if (barcodeInput.value) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    barcodeInput.value.indicateValidation(isValid ? 'success' : 'error');
  }
};

__expose({ indicateValidation, barcodeInput });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Popup), {
    style: _normalizeStyle({ 'padding-bottom': dialogWithKeyboardPadding.value }),
    visible: _ctx.isVisible,
    isCloseButtonExist: false,
    isContentCentered: "",
    isSpaceToContentExist: "",
    withoutHeader: "",
    fullWidth: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t(
              'components.picking-validate-item-barcode-popup.barcode-validation-note.text',
            )), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(MultipleInputsCode, {
            ref_key: "barcodeInput",
            ref: barcodeInput,
            onValidate: _cache[0] || (_cache[0] = ($event: any) => (emit('validate', $event))),
            onFocus: _cache[1] || (_cache[1] = ($event: any) => (emit('focus'))),
            onBlur: _cache[2] || (_cache[2] = ($event: any) => (emit('blur')))
          }, null, 512)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(Button), {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (emit('cancel'))),
            btnWhite: "",
            "data-e2e": "cancel-barcode-validate"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(
              'components.picking-validate-item-barcode-popup.button-cancel.text',
            )), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["style", "visible"]))
}
}

})