import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  for: "input",
  class: "label"
}
const _hoisted_2 = { class: "input-container" }
const _hoisted_3 = { class: "input-wrapper" }
const _hoisted_4 = ["inputmode"]
const _hoisted_5 = { class: "hint" }

import { computed, watch } from 'vue';
import { InfoIcon, ClearIcon, AlertIcon } from '../icons';
import Button from './Button.vue';

enum Events {
  Update = 'update:modelValue',
  Focus = 'focus',
  Blur = 'blur',
}
interface Emits {
  (e: Events, value?: string): void;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CustomInput',
  props: {
  modelValue: {
    type: String,
    required: true,
  },
  isValid: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: true,
  },
  hint: {
    type: String,
  },
  type: {
    type: String,
    default: 'string',
  },
  inputmode: {
    type: String,
    default: 'text',
  },
},
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const inputValue = computed({
  get() {
    return props.modelValue;
  },
  set(value: string) {
    emit(Events.Update, value);
  },
});

if (props.type === 'number') {
  watch(inputValue, () => {
    inputValue.value = inputValue.value.replace(/\D/g, '');
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    class: _normalizeClass(['container', { invalid: !__props.isValid }])
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("label", _hoisted_1, _toDisplayString(__props.label), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          id: "input",
          name: "barcode",
          class: "input",
          type: "text",
          inputmode: __props.inputmode,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
          required: "",
          "data-e2e": `barcode-input`,
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (emit(Events.Focus))),
          onBlur: _cache[2] || (_cache[2] = ($event: any) => (emit(Events.Blur)))
        }, null, 40, _hoisted_4), [
          [_vModelText, inputValue.value]
        ])
      ]),
      _createVNode(Button, {
        icon: "",
        btnOutline: "",
        plain: "",
        class: "reset",
        type: "reset",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (emit(Events.Update, ''))),
        "data-e2e": `barcode-reset`
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(ClearIcon), {
            fill: 
            __props.isValid
              ? 'rgba(var(--aldi-mid-blue-100), 1)'
              : 'rgba(var(--aldi-red-alert), 1)'
          
          }, null, 8, ["fill"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("p", _hoisted_5, [
      _withDirectives(_createVNode(_unref(InfoIcon), {
        width: "16",
        fill: "rgba(var(--aldi-gray-mid), 1)"
      }, null, 512), [
        [_vShow, __props.isValid]
      ]),
      _withDirectives(_createVNode(_unref(AlertIcon), {
        width: "16",
        fill: "rgba(var(--aldi-red-alert), 1)"
      }, null, 512), [
        [_vShow, !__props.isValid]
      ]),
      _createElementVNode("span", null, _toDisplayString(__props.hint), 1)
    ])
  ], 2))
}
}

})