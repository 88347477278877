import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/complete-picking@2x.png'


const _hoisted_1 = { class: "picking" }
const _hoisted_2 = { class: "picking__content" }
const _hoisted_3 = { class: "picking__content-note" }
const _hoisted_4 = { class: "picking__content-note__title" }
const _hoisted_5 = { class: "picking__content-note__subtitle" }
const _hoisted_6 = { class: "picking__footer" }

import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { AppHeader } from '@/features/ui';
// #region resolve issue
// NOTE: The following components failed to resolve, when importing via barrel
import BaseButton from '@/features/ui/components/Button.vue';
import StatusBar from '@/features/ui/components/StatusBar.vue';
import ScreenLoading from '@/features/ui/components/ScreenLoading.vue';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';
// #endregion
import { useOrder } from '../composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'PickingCompleteNote',
  props: {
  id: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { order, loadOrder, redirectIfOrderNotPicking, loading } = useOrder();
const { startTracking } = usePerformanceTracker();
const processing = ref(false);

const router = useRouter();
const goNext = () => {
  processing.value = true;
  startTracking(
    `picking-order-to-review-order-${props.id}`,
    PerformanceThresholdEnum.ROUTE_CHANGE,
  );
  void router.push({
    name: 'review-order',
    params: {
      id: props.id,
    },
  });
};
onMounted(async () => {
  await loadOrder(props.id);
  await redirectIfOrderNotPicking(order.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(order))
      ? (_openBlock(), _createBlock(_unref(AppHeader), { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(StatusBar, {
              customerNote: _unref(order)?.cartNote,
              order: _unref(order),
              to: { name: 'picking-order', params: { id: __props.id } }
            }, null, 8, ["customerNote", "order", "to"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(loading) || processing.value)
      ? (_openBlock(), _createBlock(ScreenLoading, {
          key: 1,
          title: _ctx.$t('pages.picking-complete-note.order-saved.title'),
          blur: ""
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "picking__content-image" }, [
          _createElementVNode("img", {
            alt: "Customer Note",
            class: "ut-img-contain",
            "data-e2e": "picking-complete-img",
            height: "344",
            src: _imports_0,
            width: "180"
          })
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('pages.picking-complete-note.picking-note-title.text')), 1),
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('pages.picking-complete-note.picking-note-subtitle.text')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(BaseButton, {
          btnOutline: "",
          "data-e2e": "picking-complete-button",
          onClick: goNext
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('pages.picking-complete-note.review-picking-button.text')), 1)
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}
}

})