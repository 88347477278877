import type { ScannableDeliveryUnit } from '../types';
import type { ObjectComparator } from '@/utils/types';

export const scannableDeliveryUnitsComparator: ObjectComparator<
  ScannableDeliveryUnit
> = (scannableDeliveryUnit1, scannableDeliveryUnit2) => {
  return (
    scannableDeliveryUnit1.code === scannableDeliveryUnit2.code &&
    scannableDeliveryUnit1.position === scannableDeliveryUnit2.position
  );
};
