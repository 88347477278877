import type { Storage } from '@/features/core/storage';
import { User } from '../entities';
import type { MerchantApiClient } from '@/features/merchant/api';
import type { AuthService } from '@/features/core/auth';
import {
  HandoverAbortedEvent,
  HandoverCompletedEvent,
  HandoverStartedEvent,
  PickingCompletedEvent,
  PickingContinuedEvent,
  PickingStartedEvent,
} from '@/features/orders/events';
import type { EventBus } from '@/features/core/event-bus';
import { calculateTimePassedSinceTimestamp } from '@/utils/helpers/calculateTimePassed';
import { MAX_INACTIVITY_HOURS_BEFORE_LOGOUT } from '@/features/oauth/types';

export class UserService {
  constructor(
    private storage: Storage,
    private authService: AuthService,
    private merchantApiClient: MerchantApiClient,
    private eventBus: EventBus,
  ) {}

  async setUserByMerchant(): Promise<User> {
    const merchantReference = await this.authService.getMerchantReference();
    const merchantEmail = await this.authService.getUserEmail();
    const merchantRoles = await this.authService.getUserRoles();
    if (!merchantReference) {
      throw new Error('UserService: User is not logged in!');
    }

    const merchant = await this.merchantApiClient.getByMerchantReference(
      merchantReference,
    );

    const user = User.from({
      id: merchantReference,
      merchant: merchant,
      email: merchantEmail ?? '',
      roles: merchantRoles,
    });

    return await this.storage.save(user);
  }

  async clearUser(): Promise<void> {
    await this.storage.removeAll(User);
  }

  async getUser(): Promise<User | undefined> {
    const merchantReference = await this.authService.getMerchantReference();

    if (!merchantReference) {
      throw new Error('UserService: User is not logged in!');
    }

    return await this.storage.getById(User, {
      id: merchantReference,
    });
  }

  async setLastActivity(): Promise<User | undefined> {
    const user = await this.getUser();
    if (user) {
      // wasn't able to find better way to mock Date in order to cover it with unit tests
      user.lastActivity = new Date(Date.now()).toISOString();
      await this.storage.save(user);
    }
    return user;
  }

  async getLastActivity(): Promise<string | null> {
    const isLoggedIn = await this.isUserLoggedIn();
    if (!isLoggedIn) {
      return null;
    }
    const user = await this.getUser();
    return user?.lastActivity ? user.lastActivity : null;
  }

  async isForceLogoutRequired(): Promise<boolean> {
    const userLastActivity = await this.getLastActivity();
    if (!userLastActivity) {
      return false;
    }
    return (
      calculateTimePassedSinceTimestamp(userLastActivity, 'hours', false) >
      MAX_INACTIVITY_HOURS_BEFORE_LOGOUT
    );
  }

  listenToActivity(): void {
    const setLastActivity = () => void this.setLastActivity();

    this.eventBus.on(PickingStartedEvent, setLastActivity);
    this.eventBus.on(PickingContinuedEvent, setLastActivity);
    this.eventBus.on(PickingCompletedEvent, setLastActivity);
    this.eventBus.on(HandoverStartedEvent, setLastActivity);
    this.eventBus.on(HandoverCompletedEvent, setLastActivity);
    this.eventBus.on(HandoverAbortedEvent, setLastActivity);
  }

  private async isUserLoggedIn(): Promise<boolean> {
    const merchantReference = await this.authService.getMerchantReference();
    return Boolean(merchantReference);
  }
}
