import { HandledError } from '@/features/core/errors/error-classes';
import type { InternalAxiosRequestConfig } from 'axios';
import { deepClone } from '@/utils/helpers/deepClone';
import type { ApiClient } from '@/features/core/api';
import { transformErrorToObject } from '@/utils/helpers/transformErrorToObject';
import type {
  Logger,
  loggerServiceOptions,
  LogLevel,
  MessageTransferObject,
  Transporter,
} from '../types';
import { getOrderFromWindow } from '@/utils/helpers/getOrderFromWindow';
import type { ApiClientError } from '@/features/core/api/types';
import { isApiError } from '@/features/core/api/helper/is-api-client-error';

export class LoggerService implements Logger {
  redactedKeys: string[];
  redactionString = '***MASKED***';

  constructor(
    options: loggerServiceOptions,
    private transporters: Transporter[] = [],
  ) {
    this.redactedKeys = options.redactedKeys || [];
  }

  debug<T>(msg: string | Error, additionalData?: T | Error): void {
    const logLevel: LogLevel = 20;
    this.informTransporters(msg, additionalData, logLevel);
  }

  info<T>(msg: string | Error, additionalData?: T | Error): void {
    const logLevel: LogLevel = 30;
    this.informTransporters(msg, additionalData, logLevel);
  }

  warn<T>(msg: string | Error, additionalData?: T | Error): void {
    const logLevel: LogLevel = 40;
    this.informTransporters(msg, additionalData, logLevel);
  }

  error<T>(msg: string | Error, additionalData?: T | Error): void {
    const logLevel: LogLevel = 50;
    this.informTransporters(msg, additionalData, logLevel);
  }

  fatal<T>(msg: string | Error, additionalData?: T | Error): void {
    const logLevel: LogLevel = 60;
    this.informTransporters(msg, additionalData, logLevel);
  }

  log<T>(
    logLevel: LogLevel,
    msg: string | Error,
    additionalData?: T | Error,
  ): void {
    this.informTransporters(msg, additionalData, logLevel);
  }

  addTransporters(transporters: Transporter[]): void {
    this.transporters = [...this.transporters, ...transporters];
  }

  setLoggerInterceptors(apiClient: ApiClient): void {
    apiClient.client.interceptors.request.use(
      this.beforeResponse.bind(this),
      undefined,
    );
    apiClient.client.interceptors.response.use(
      undefined,
      this.onResponseError.bind(this),
    );
  }

  private getOrderId(): string | null {
    if (typeof window === 'undefined') {
      return null;
    }

    return getOrderFromWindow(window.location);
  }

  private informTransporters<T>(
    logMsg: string | Error,
    additionalData: T | Error | undefined,
    logLevel: LogLevel,
  ) {
    const message = this.getFormatedMessage(logMsg, additionalData);

    const orderId = this.getOrderId();
    if (orderId) {
      message.msg = `[${orderId}]: ${message.msg}`;
    }

    this.transporters.forEach((transporter) => {
      try {
        transporter.write(logLevel, message);
      } catch (e) {
        console.error(e as Error); // To avoid a loop, console.error will be used
      }
    });
  }

  private setAllSensitiveInfoToMaskedByKey<T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj: { [key: string]: any },
    key: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    processedObjects = new WeakMap<any, boolean>(),
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const maskProperty = (obj: { [key: string]: any }, key: string) => {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        obj[key] = this.redactionString;
      }
    };

    if (processedObjects.has(obj)) {
      return;
    }

    processedObjects.set(obj, true);
    maskProperty(obj, key);

    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        const value = obj[prop] as T;

        if (typeof value === 'object' && value !== null) {
          obj[prop] = processedObjects.has(value)
            ? '[Recursive]'
            : this.setAllSensitiveInfoToMaskedByKey(
                value,
                key,
                processedObjects,
              );
        }
      }
    }
  }

  private redactKeySensitiveInformation<T extends Record<string, unknown>>(
    data: T,
  ): T {
    // clone object to avoid have side effects on the original object,
    const sensitiveData = deepClone(data, false);

    this.redactedKeys.forEach((key) => {
      this.setAllSensitiveInfoToMaskedByKey(sensitiveData, key);
    });
    return sensitiveData;
  }

  private getFormatedMessage<T extends Error | Record<string, unknown>>(
    logMsg: string | Error,
    additionalData: T | Error | unknown,
  ): MessageTransferObject {
    const message: MessageTransferObject = {
      msg: logMsg instanceof Error ? logMsg.message : logMsg,
    };
    if (additionalData) {
      const additionalDataObj =
        additionalData instanceof Error
          ? this.redactKeySensitiveInformation(
              transformErrorToObject(additionalData),
            )
          : additionalData;

      message.additionalData =
        typeof additionalDataObj === 'object'
          ? this.redactKeySensitiveInformation(
              additionalDataObj as Record<string, unknown>,
            )
          : additionalDataObj;
    }
    if (logMsg instanceof Error) {
      message.err = this.redactKeySensitiveInformation(
        transformErrorToObject(logMsg),
      );
    }
    return message;
  }

  private beforeResponse<T>(
    config: InternalAxiosRequestConfig,
  ): InternalAxiosRequestConfig {
    if (!config.skipRequestLog) {
      this.info(
        `Request: ${String(config.method?.toUpperCase())} ${String(
          config.url,
        )}`,
        {
          url: config.url,
          data: config.data as T,
        },
      );
    }

    return config;
  }

  private onResponseError<T>(
    error: ApiClientError | HandledError,
  ): Promise<ApiClientError | void> {
    if (!error) {
      return Promise.reject(error);
    }

    const err =
      error instanceof HandledError &&
      error.originalError &&
      isApiError(error.originalError)
        ? error.originalError
        : error;

    if (isApiError(err) && err.config) {
      const responseStatus =
        err.response?.status ?? `No Response (${err.message})`;
      this.error(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `Failed Request: ${err.config?.method?.toUpperCase()} ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          err.config?.url
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        } ${responseStatus}`,
        {
          url: err.config?.url,
          data: err.response?.data as T | undefined,
          errorCode: err.code,
          errorMessage: err.message,
        },
      );
    } else {
      this.error(`Failed Request: Not found`, error);
    }

    return Promise.reject(error);
  }
}
