
import { defineComponent } from 'vue';
import { oauthServicePlugin } from '@/features/oauth';
import Button from './Button.vue';
import LogoutButton from './LogoutButton.vue';

export default defineComponent({
  name: 'VerificationDialog',
  components: { LogoutButton, Button },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const handleVerify = async () => {
      await oauthServicePlugin.get().handleReLogin('empowerId');
    };

    return {
      handleVerify,
    };
  },
});
