import type { ErrorContext } from '@/features/core/errors/error-classes';
import { BaseError } from '@/features/core/errors/error-classes';
import { $t } from '@/i18n';
import type { LogLevel } from '@/features/core/logger';

interface BarcodeNotFoundContext extends ErrorContext {
  barcodeValue: string;
}

interface BarcodeLimitContext extends ErrorContext {
  limit: number;
}

declare module '@/features/core/errors/error-code' {
  interface ErrorCodes {
    [BarcodeNotFoundInOrderError.Code]: 'BarcodeNotFoundInOrderError';
    [ProductPickingCompletedError.Code]: 'ProductPickingCompletedError';
  }
}

export class BarcodeNotFoundInOrderError extends BaseError<BarcodeNotFoundContext> {
  static Code = 10 as const;
  static Message = $t('errors.barcode.barcode-not-found-in-order.message');
}

export class ProductPickingCompletedError extends BaseError {
  static Code = 11 as const;
  static Message = $t('errors.barcode.product-picking-completed.message');
  static LogLevel: LogLevel = 30;
}

export class BarcodeNotFoundError extends BaseError<BarcodeNotFoundContext> {
  static Code = 14 as const;
  static Message = $t('errors.barcode.barcode-not-found.message');
}

export class ValidatePickingError extends BaseError {
  static Code = 15 as const;
  static Message = $t('errors.barcode.validate-picking.message');
}

export class BarcodeHasLimit extends BaseError<BarcodeLimitContext> {
  static Code = 16 as const;
  static Message = $t('errors.barcode.barcode-has-limit.message');
}
