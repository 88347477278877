import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "dateInput__input-container" }
const _hoisted_2 = ["data-e2e"]
const _hoisted_3 = ["maxlength", "name", "placeholder", "id", "onUpdate:modelValue", "data-e2e", "onFocus", "onInput", "onKeydown"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["data-e2e"]
const _hoisted_6 = {
  key: 0,
  class: "dateInput__verification"
}
const _hoisted_7 = ["data-e2e"]
const _hoisted_8 = ["data-e2e"]

import { computed, onMounted, ref, toRef } from 'vue';
import { BaseIcon, CheckmarkIcon, ErrorIcon } from '@/features/ui/icons';
import { $t } from '@/i18n';
import { useTemplateRefsList } from '@vueuse/core';


export default /*@__PURE__*/_defineComponent({
  __name: 'DateInput',
  props: {
    label: { default: '' },
    placeholder: {},
    format: {},
    errorValidationText: {},
    errorValidationInstruction: {},
    isValid: { type: Boolean, default: false },
    prefilledDate: {},
    e2eDescription: { default: 'dateInput' },
    minYear: { default: 1900 },
    required: { type: Boolean, default: true }
  },
  emits: ["onFocus", "onBlur", "update:date"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const dateInputState = ref<'incomplete' | 'incorrect' | 'correct'>(
  'incomplete',
);
const format = toRef(props, 'format');
const prefilledDate = toRef(props, 'prefilledDate');

const delimiter = /[^a-zA-Z0-9]/.exec(format.value)?.[0] ?? '-';
const normalizedFormat = format.value
  .toLowerCase()
  .replace(/[^a-zA-Z0-9]/g, '-');
const formatItems = normalizedFormat.split('-');
const monthIndex = formatItems.indexOf('mm');
const dayIndex = formatItems.indexOf('dd');
const yearIndex = formatItems.indexOf('yyyy');

const inputValues = ref(formatItems.map(() => ''));
const sizeCheckElements = useTemplateRefsList<HTMLSpanElement>();
const inputElements = useTemplateRefsList<HTMLInputElement>();
const placeholderParts = computed(() => props.placeholder.split(delimiter));

const onInput = (): boolean => {
  dateInputState.value = 'incomplete';

  const disallowedCharRegex = /\D/;
  inputValues.value = inputValues.value.map((value, i) => {
    if (disallowedCharRegex.exec(value) === null) {
      return value;
    }

    if (value.length === 1) {
      return '';
    }

    return value
      .replace(disallowedCharRegex, '')
      .padStart(formatItems[i].length, '0');
  });

  if (
    (dayIndex !== -1 && inputValues.value[dayIndex].length !== 2) ||
    (monthIndex !== -1 && inputValues.value[monthIndex].length !== 2) ||
    (yearIndex !== -1 && inputValues.value[yearIndex].length !== 4)
  ) {
    return false;
  }

  const year = parseInt(inputValues.value[yearIndex] ?? '1');
  const month = parseInt(inputValues.value[monthIndex] ?? '1') - 1;
  const day = parseInt(inputValues.value[dayIndex] ?? '1');
  const date = new Date(year, month, day);
  if (
    (date.getFullYear() === year || yearIndex === -1) &&
    (date.getMonth() === month || monthIndex === -1) &&
    (date.getDate() === day || dayIndex === -1) &&
    date.getFullYear() >= props.minYear &&
    date < new Date()
  ) {
    emit('update:date', date);
    dateInputState.value = 'correct';
    return true;
  } else {
    emit('update:date', null);
    dateInputState.value = 'incorrect';
    return false;
  }
};

if (prefilledDate.value) {
  inputValues.value[dayIndex] = prefilledDate.value
    .getDate()
    .toString()
    .padStart(2, '0');
  inputValues.value[monthIndex] = (prefilledDate.value.getMonth() + 1)
    .toString()
    .padStart(2, '0');
  inputValues.value[yearIndex] = prefilledDate.value.getFullYear().toString();

  onInput();
}

const inputPlaceholderWidths = ref<number[] | undefined>();

onMounted(() => {
  inputPlaceholderWidths.value = sizeCheckElements.value.map(
    (element) => element.getBoundingClientRect().width,
  );
});

const handleFocus = (elementIndex?: number, forceFocus = false) => {
  const firstEmpty = inputValues.value.findIndex((value) => value.length === 0);

  if (
    elementIndex === undefined ||
    (firstEmpty !== -1 && firstEmpty < elementIndex)
  ) {
    inputElements.value[
      firstEmpty === -1 ? formatItems.length - 1 : firstEmpty
    ].focus();
  } else if (forceFocus) {
    inputElements.value[elementIndex].focus();
  }

  emit('onFocus');
};

const handleInput = (elementIndex: number) => {
  const inputRef = inputElements.value[elementIndex];

  if (inputRef.value.length !== formatItems[elementIndex].length) {
    onInput();
    return;
  }

  if (elementIndex === formatItems.length - 1) {
    if (onInput()) {
      inputRef.blur();
    }
    return;
  }

  const nextElement = inputElements.value[elementIndex + 1];
  nextElement.focus();
  if (nextElement.value.length > 0) {
    nextElement.select();
  }
  onInput();
};

const handleKeyDown = (elementIndex: number, e: Event) => {
  const keyEvent = e as KeyboardEvent;

  if (keyEvent.key !== 'Backspace') {
    return;
  }

  const inputRef = inputElements.value[elementIndex];

  if (inputRef.selectionStart !== 0 || inputRef.selectionEnd !== 0) {
    return;
  }

  if (elementIndex === 0) {
    return;
  }

  const previousElement = inputElements.value[elementIndex - 1];
  previousElement.focus();
  previousElement.selectionEnd = previousElement.value.length;
  previousElement.selectionStart = previousElement.value.length;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass({
        dateInput: true,
        'dateInput--required': _ctx.required,
        'dateInput--filled': inputValues.value.some((value) => value.length > 0),
        'dateInput--invalid': dateInputState.value === 'incorrect',
      })
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              "data-e2e": _ctx.e2eDescription + '-label',
              class: "dateInput__label",
              for: "date-input-first"
            }, _toDisplayString(_ctx.label), 9, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "dateInput__input-group",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (handleFocus()))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formatItems), (formatItem, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: formatItem }, [
              _withDirectives(_createElementVNode("input", {
                inputmode: "numeric",
                maxlength: formatItem.length,
                name: `date-${formatItem}`,
                placeholder: placeholderParts.value[index],
                id: index === 0 ? 'date-input-first' : undefined,
                class: "dateInput__input-part",
                "onUpdate:modelValue": ($event: any) => ((inputValues.value[index]) = $event),
                style: _normalizeStyle(
                inputValues.value[index].length > 0
                  ? `width: ${formatItem.length}ch;`
                  : `width: ${(inputPlaceholderWidths.value?.[index] ?? 0) + 2}px;`
              ),
                autocomplete: "off",
                ref_for: true,
                ref: _unref(inputElements).set,
                "data-e2e": `${_ctx.e2eDescription}-input-${formatItem}`,
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                onFocus: _withModifiers(($event: any) => (handleFocus(index)), ["stop"]),
                onInput: _withModifiers(($event: any) => (handleInput(index)), ["stop"]),
                onKeydown: (e) => handleKeyDown(index, e)
              }, null, 44, _hoisted_3), [
                [_vModelText, inputValues.value[index]]
              ]),
              _createElementVNode("span", {
                class: "dateInput__width-placeholder",
                ref_for: true,
                ref: _unref(sizeCheckElements).set
              }, _toDisplayString(placeholderParts.value[index]), 513),
              (index !== _unref(formatItems).length - 1)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "dateInput__separator",
                    onClick: _withModifiers(($event: any) => (handleFocus(index, true)), ["stop"])
                  }, _toDisplayString(_unref(delimiter)), 9, _hoisted_4))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        (dateInputState.value === 'correct')
          ? (_openBlock(), _createBlock(_unref(BaseIcon), {
              key: 1,
              class: "dateInput__input-icon",
              iconFill: "#0D3A93",
              width: "24",
              height: "24",
              "stroke-width": "0"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(CheckmarkIcon))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (dateInputState.value === 'incorrect')
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              "data-e2e": _ctx.e2eDescription + '-error',
              class: "invalid-date-error"
            }, [
              _createVNode(_unref(ErrorIcon), {
                iconFill: "#e00016",
                iconStroke: "#e00016",
                width: "16",
                height: "18"
              }),
              _createElementVNode("span", null, _toDisplayString(_unref($t)('components.date-input.invalid-date.text')), 1)
            ], 8, _hoisted_5))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.isValid && _ctx.errorValidationText && dateInputState.value === 'correct')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", {
              class: "error-message",
              "data-e2e": _ctx.e2eDescription + '-error-title'
            }, _toDisplayString(_ctx.errorValidationText), 9, _hoisted_7),
            _createElementVNode("div", {
              class: "error-instruction",
              "data-e2e": _ctx.e2eDescription + '-error-title-info'
            }, _toDisplayString(_ctx.errorValidationInstruction), 9, _hoisted_8)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}
}

})