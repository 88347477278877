import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Dialog } from '@/features/ui/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'CancelPickingConfirmationPopup',
  props: {
  isVisible: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    visible: __props.isVisible,
    "content-text": 
      _ctx.$t('components.cancel-picking-confirmation-popup.content.text')
    ,
    "title-text": _ctx.$t('components.cancel-picking-confirmation-popup.title.text'),
    "confirm-text": 
      _ctx.$t('components.cancel-picking-confirmation-popup.confirm.text')
    ,
    "cancel-text": 
      _ctx.$t('components.cancel-picking-confirmation-popup.cancel.text')
    ,
    onConfirmed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('confirm'))),
    onCanceled: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel'))),
    isSpaceToContentExist: "",
    "full-width": "",
    isTitleCentered: false
  }, null, 8, ["visible", "content-text", "title-text", "confirm-text", "cancel-text"]))
}
}

})