import type {
  PerformanceData,
  PerformanceTrackerServiceInterface,
} from '../types';

export class PerformanceTrackerService
  implements PerformanceTrackerServiceInterface
{
  private readonly performanceData: Map<string, PerformanceData>;

  constructor() {
    this.performanceData = new Map<string, PerformanceData>();
  }

  startTracking(key: string, threshold = 0): void {
    if (this.performanceData.has(key)) {
      this.performanceData.delete(key);
    }

    this.performanceData.set(key, {
      start: performance.now(),
      threshold,
    });
  }

  stopTracking(key: string): void {
    const data = this.performanceData.get(key);
    if (!data) {
      throw new Error(`No tracking found for key "${key}".`);
    }

    data.end = performance.now();
  }

  getTrackingData(key: string): PerformanceData | undefined {
    return this.performanceData.get(key);
  }

  getAllTrackingData(): Map<string, PerformanceData> {
    return this.performanceData;
  }

  clearTrackingData(key: string): void {
    if (!this.performanceData.has(key)) {
      return;
    }

    this.performanceData.delete(key);
  }

  clearAllTrackingData(): void {
    this.performanceData.clear();
  }

  getRunTime(key: string): number | undefined {
    const data = this.performanceData.get(key);
    if (!data) {
      return undefined;
    }

    if (data.end === undefined) {
      const now = performance.now();

      return now - data.start;
    }

    return data.end - data.start;
  }
}
