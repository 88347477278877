import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-e2e"]
const _hoisted_2 = ["src", "data-e2e"]
const _hoisted_3 = { class: "handover-item-frame__header" }
const _hoisted_4 = ["data-e2e"]
const _hoisted_5 = {
  key: 0,
  class: "handover-item-frame__temperature-class"
}
const _hoisted_6 = { class: "handover-item-frame__footer" }

import 'vue';
import { CloseButton } from '@/features/handover';
import TemperatureClasses from '@/features/ui/components/TemperatureClasses.vue';
import HandoverFixedWeightFooter from '@/features/handover/components/HandoverFixedWeightFooter.vue';
import type { Ref } from 'vue';
import { computed, ref, toRefs, watch } from 'vue';
import BaseButton from '@/features/ui/components/BaseButton.vue';
import { $t } from '@/i18n';
import HandoverLooseRandomWeightFooter from '@/features/handover/components/HandoverLooseRandomWeightFooter.vue';
import HandoverPackagedRandomWeightFooter from '@/features/handover/components/HandoverPackagedRandomWeightFooter.vue';
import HandoverPackagedRandomWeightHeader from '@/features/handover/components/HandoverPackagedRandomWeightHeader.vue';
import placeholderImage from '@/assets/images/placeholder-image.svg';
import { imageCachingQueueServicePlugin } from '@/features/imageCachingQueue';
import type { Product } from '@/features/products';
import { loggerServicePlugin } from '@/features/core/logger';


export default /*@__PURE__*/_defineComponent({
  __name: 'HandoverItemFrame',
  props: {
    totalQuantity: {},
    totalAmount: {},
    product: {},
    showAsRejected: { type: Boolean, default: false },
    isReplacement: { type: Boolean, default: false },
    e2eTag: {},
    e2eValue: {},
    disabled: { type: Boolean, default: false },
    imageDisabled: { type: Boolean, default: false },
    rejectable: { type: Boolean, default: false },
    restoreable: { type: Boolean, default: false },
    stagedQuantity: {},
    availableQuantity: {},
    stagedAmount: { default: 0 },
    availableAmount: { default: 0 },
    showRejectedChip: { type: Boolean, default: false }
  },
  emits: ["apply", "reject", "restoreAll", "packagedClicked"],
  setup(__props: any) {

const props = __props;

const { totalQuantity, product, e2eTag, e2eValue } = toRefs(props);



const getE2eId = (tag: string) =>
  `${e2eTag.value ?? 'handover-item-frame'}-${tag}-${
    e2eValue.value ?? props.product.sku
  }`;

const imagePath = computed(() =>
  product.value.noImage || !product.value.image || imageLoadingFailed.value
    ? placeholderImage
    : imageCachingQueueServicePlugin.get().replaceUrlWidth(product.value.image),
);

const imageLoadingFailed: Ref<boolean> = ref(false);

const handleBrokenImage = (e: ErrorEvent) => {
  // Log images that failed to load
  // Because image should be null if no valid URL is found this will
  // only fire when an actual image we expect to exist could not load
  loggerServicePlugin.get().warn(e.error);
  imageLoadingFailed.value = true;
};

watch(
  () => product.value.image,
  () => {
    imageLoadingFailed.value = false;
  },
  { immediate: true },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "handover-item-frame",
    "data-e2e": getE2eId('img-wrapper')
  }, [
    _createElementVNode("img", {
      src: imagePath.value,
      title: "",
      alt: "",
      "data-e2e": getE2eId('img'),
      onError: handleBrokenImage,
      class: _normalizeClass({
        'handover-item-frame__image': true,
        'handover-item-frame__image--disabled': _ctx.imageDisabled,
      })
    }, null, 42, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "handover-item-frame__title",
        "data-e2e": getE2eId('name-label')
      }, _toDisplayString(_unref(product).productName), 9, _hoisted_4),
      (_unref(product).rwpType === 1)
        ? (_openBlock(), _createBlock(HandoverPackagedRandomWeightHeader, {
            key: 0,
            unit: _unref(product).unit ?? '',
            "total-amount": _ctx.totalAmount ?? 0,
            "show-total-amount": !_ctx.isReplacement,
            amount: _ctx.showAsRejected ? _ctx.availableAmount - _ctx.stagedAmount : _ctx.stagedAmount
          }, null, 8, ["unit", "total-amount", "show-total-amount", "amount"]))
        : _createCommentVNode("", true)
    ]),
    (_unref(product).temperatureClass !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (!_ctx.imageDisabled)
            ? (_openBlock(), _createBlock(TemperatureClasses, {
                key: 0,
                classes: [_unref(product).temperatureClass]
              }, null, 8, ["classes"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.rejectable)
      ? (_openBlock(), _createBlock(_unref(CloseButton), {
          key: 1,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reject'))),
          "data-e2e": getE2eId('delete'),
          class: "handover-item-frame__reject-button"
        }, null, 8, ["data-e2e"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      (_unref(product).rwpType === undefined || _unref(product).rwpType === 0)
        ? (_openBlock(), _createBlock(HandoverFixedWeightFooter, {
            key: 0,
            staged: _ctx.stagedQuantity,
            rejected: _ctx.availableQuantity - _ctx.stagedQuantity,
            total: _ctx.isReplacement ? undefined : _unref(totalQuantity),
            disabled: _ctx.disabled,
            "is-rejected-category": _ctx.showAsRejected,
            "show-rejected-chip": _ctx.showRejectedChip,
            onUpdate: _cache[1] || (_cache[1] = (value) => _ctx.$emit('apply', value))
          }, null, 8, ["staged", "rejected", "total", "disabled", "is-rejected-category", "show-rejected-chip"]))
        : (_unref(product).rwpType === 1)
          ? (_openBlock(), _createBlock(HandoverPackagedRandomWeightFooter, {
              key: 1,
              staged: _ctx.stagedQuantity,
              rejected: _ctx.availableQuantity - _ctx.stagedQuantity,
              total: _ctx.isReplacement ? undefined : _unref(totalQuantity),
              available: _ctx.availableQuantity,
              disabled: _ctx.disabled,
              "show-rejected-chip": _ctx.showRejectedChip,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('packagedClicked')))
            }, null, 8, ["staged", "rejected", "total", "available", "disabled", "show-rejected-chip"]))
          : (_openBlock(), _createBlock(HandoverLooseRandomWeightFooter, {
              key: 2,
              amount: _ctx.showAsRejected ? _ctx.availableAmount - _ctx.stagedAmount : _ctx.stagedAmount,
              "total-amount": _ctx.isReplacement ? undefined : _ctx.totalAmount,
              unit: _unref(product).unit ?? '',
              "show-rejected-chip": _ctx.showRejectedChip
            }, null, 8, ["amount", "total-amount", "unit", "show-rejected-chip"]))
    ]),
    (_ctx.restoreable)
      ? (_openBlock(), _createBlock(BaseButton, {
          key: 2,
          class: "handover-item-frame__restore-button",
          label: _unref($t)('components.handover-item.restore-items.text'),
          type: "secondary",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('restoreAll')))
        }, null, 8, ["label"]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}
}

})