import type { Ref } from 'vue';
import { computed } from 'vue';
import type { OrderItem } from '@/features/orders/types';
import type { UseProduct } from '../types';

export function useProduct(product: Ref<OrderItem>): UseProduct {
  const canDecrease = computed(() => {
    return product.value.quantity > 0;
  });

  const canIncrease = computed(() => {
    return product.value.quantity < product.value.quantityOriginal;
  });

  const isFilled = computed(() => {
    return product.value.quantity == product.value.quantityOriginal;
  });

  return {
    canDecrease,
    canIncrease,
    isFilled,
  };
}
