import type {
  AdoptedServiceWorker,
  AppServiceWorker,
  EntityUpdate,
} from './types';
import { ServiceWorkerEventTypes } from './types';
import type { EntityType } from '@/features/core/entity-repository/entity';
import {
  EntityUpdatedEvent,
  OrderUpdateEvent,
} from '@/features/core/entity-repository/events';
import type { EventBus, EventBusEvent } from '@/features/core/event-bus/types';
import type { Sync } from '@/features/sync-scheduler/entities/sync';
import {
  ENTITY_SYNC_TAG,
  ErrorTriggerEvent,
  SyncFailureEvent,
  SyncStatusChangedEvent,
  SyncTableUpdateEvent,
} from '@/features/sync-scheduler';
import { LogoutEvent } from '@/features/oauth/events/index';
import { CheckinNotifyEvent } from '@/features/orders/checkin/events';
import { getEntityByPushType } from '@/utils/helpers/getEntityByPushType';
import { AppServiceWorkerNotifySyncEvent } from './events';
import { BackgroundSyncTags } from '@/features/data-background-sync/types';
import { VerificationDialogShow } from '@/features/login/events';
import { ChangeUrlEvent } from '@/features/route-leave-guard/events';
import { loggerServicePlugin } from '@/features/core/logger';
import { getOrderFromWindow } from '@/utils/helpers/getOrderFromWindow';
import type {
  PushEventData,
  SWNotificationEvent,
} from '@/features/push-notification';
import { Order } from '@/features/orders/entities/order';

export class AppServiceWorkerService implements AppServiceWorker {
  constructor(
    private eventBus: EventBus,
    private serviceWorker: AdoptedServiceWorker = window.navigator
      .serviceWorker,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private navigator = window.navigator,
    private notification = Notification,
  ) {
    this.eventBus.on(AppServiceWorkerNotifySyncEvent, (event): void => {
      let syncTag = ENTITY_SYNC_TAG;
      if (event.customTag) {
        syncTag += `_${event.customTag}`;
      } else if (event.entityType && event.entityId) {
        syncTag += `_${event.entityType}_${event.entityId}`;
      }
      void this.notifySync(syncTag);
    });
    this.serviceWorker?.addEventListener('message', (event: MessageEvent) => {
      const eventData = event.data as SWNotificationEvent;
      switch (eventData.type) {
        case ServiceWorkerEventTypes.Push: {
          this.handlePush(event);
          break;
        }

        case ServiceWorkerEventTypes.SyncScheduler: {
          const data = eventData.data;
          this.eventBus.emit(new SyncStatusChangedEvent(data as Sync));
          break;
        }

        case ServiceWorkerEventTypes.CheckinNotify: {
          this.eventBus.emit(new CheckinNotifyEvent());
          break;
        }

        case ServiceWorkerEventTypes.EventBus: {
          const data = eventData.data;
          const eventName = eventData.eventName;

          if (typeof data !== 'string') {
            throw new Error('EventBus data must be a string');
          }

          if (typeof eventName !== 'string') {
            throw new Error('EventBus eventName must be a string');
          }

          this.emitEventFromSW(eventName, data);
          break;
        }
      }
    });
  }

  emitEventFromSW(eventName: string, eventData: string): void {
    let event: EventBusEvent | null = null;
    switch (eventName) {
      case new SyncFailureEvent().name:
        event = new SyncFailureEvent();
        break;
      case new SyncTableUpdateEvent().name:
        event = new SyncTableUpdateEvent();
        break;
      case new ErrorTriggerEvent(eventData).name:
        event = new ErrorTriggerEvent(eventData);
        break;
      case new VerificationDialogShow().name:
        event = new VerificationDialogShow();
        break;
      case new ChangeUrlEvent(eventData).name:
        event = new ChangeUrlEvent(eventData);
        break;
      case new LogoutEvent().name:
        event = new LogoutEvent();
        break;
      case new OrderUpdateEvent(eventData).name:
        event = new EntityUpdatedEvent([
          {
            entity: Order,
            ids: JSON.parse(eventData) as string[],
            updated: true,
          },
        ]);
        break;
    }
    if (!event) {
      return;
    }
    this.eventBus.emit(event);
  }

  async notifySync(instanceId: string): Promise<void> {
    const registration = await this.serviceWorker.ready;
    if (registration) {
      await registration.sync.register(instanceId);
    } else {
      throw Error('Sync registration is not available');
    }
  }

  async registerPeriodicSync(
    tag: string,
    period: number = 60 * 1000,
  ): Promise<void> {
    if (
      !(await this.isPeriodicSyncRegistered(tag)) &&
      (await this.isPeriodicSyncPermissionGranted())
    ) {
      try {
        const registration = await this.serviceWorker.ready;
        if (registration) {
          await registration.periodicSync.register(tag, {
            minInterval: period,
          });
        }
      } catch (e) {
        loggerServicePlugin
          .get()
          .error(`Periodic sync '${tag}' could not be registered!`);
      }
    }
  }

  showNotification(title: string, options?: NotificationOptions): void {
    // eslint-disable-next-line
    void this.notification.requestPermission(async (result) => {
      if (result === 'granted') {
        const registration = await this.serviceWorker.ready;
        if (registration) {
          await registration.showNotification(
            title,
            options && options.body
              ? options
              : { body: 'message', data: { route: '/' } },
          );
        }
      }
    });
  }

  handlePush(event: PushEventData): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const entityUpdateData: EntityUpdate<EntityType<any>>[] = [
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        entity: getEntityByPushType(event.data.entity) as EntityType<any>,
        ids: event.data.ids as string[],
        updated: event.data.action === 'get',
        removed: event.data.action === 'delete',
      },
    ];

    this.eventBus.emit(new EntityUpdatedEvent(entityUpdateData));
    if (
      event.data.action === 'delete' &&
      event.data.ids &&
      event.data.ids.length > 0
    ) {
      (event.data.ids as string[]).some((id: string) =>
        this.handleRouteRedirectForRemovedOrders(id),
      );
    }
  }

  private handleRouteRedirectForRemovedOrders(id: string): boolean {
    const currentOrder = getOrderFromWindow(window.location);

    const isEqual = id === currentOrder;
    if (isEqual) {
      loggerServicePlugin
        .get()
        .warn(
          `Order ${id} received a DELETE push and user was redirected back to the order list`,
        );

      this.eventBus.emit(
        new ChangeUrlEvent('/', { bypassRouteNavigationGuard: true }),
      );
    }

    return isEqual;
  }

  private async isPeriodicSyncRegistered(tag: string): Promise<boolean> {
    const registration = await this.serviceWorker.ready;
    const syncTags = await registration.periodicSync.getTags();
    return syncTags.includes(tag);
  }

  private async isPeriodicSyncPermissionGranted(): Promise<boolean> {
    const status = await navigator.permissions.query({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore PermissionName type has no 'periodic-background-sync'
      name: BackgroundSyncTags.PeriodicBackgroundSync,
    });
    return status.state === 'granted';
  }
}
