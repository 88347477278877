import {
  ExecPluginFactory,
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { authServicePlugin } from '@/features/core/auth';
import { dataFetchQueueServicePlugin } from '@/features/data-fetch-queue';
import { AppBootCheckerService } from './services';

const appBootCheckerServicePlugin =
  ProviderPluginFactory.create<AppBootCheckerService>({
    key: Symbol('AppBootCheckerService'),
    defaultFactory: {
      create: () =>
        new AppBootCheckerService(
          authServicePlugin.get(),
          dataFetchQueueServicePlugin.get(),
        ),
    },
  });

const appBootCheckerExecutePlugin = ExecPluginFactory.fn(() => {
  void appBootCheckerServicePlugin.get().triggerSync();
});

export const appBootCheckerPlugin = MultiPluginFactory.with({
  appBootCheckerServicePlugin,
  appBootCheckerExecutePlugin,
});
