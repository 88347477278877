import type { DataSync } from '../entities/dataSync';

export enum DataSyncScopes {
  ProductSync = 'product-sync',
}

export interface DataSyncServiceConfig {
  dataUpdateInterval: number;
}

export interface DataSyncService {
  setDataSync(scope: DataSyncScopes, lastSync: Date): Promise<void>;
  getById(scope: DataSyncScopes): Promise<DataSync | undefined>;
  isDataReadyToSync(scope: DataSyncScopes): Promise<boolean>;
  clearAll(): Promise<void>;
}
