import type { LogLevel, MessageTransferObject, Transporter } from '../types';
import { deviceIdServicePlugin } from '@/features/device-id';

export class DynatraceTransporter implements Transporter {
  constructor() {
    void this.init();
  }

  private defaultDeviceId = 'Unknown';
  private deviceId = this.defaultDeviceId;

  defaultLogLevel: LogLevel = 50;
  private currentLogLevel: LogLevel = this.defaultLogLevel;
  private supportedEnvironments: string[] = ['qa_au'];
  private currentEnvironment: string | undefined =
    process.env.VUE_APP_ENV_FOR_ISSUE_LOGGING_SCRIPT;

  write(logLevel: LogLevel, msgObject: MessageTransferObject): void {
    if (logLevel < this.currentLogLevel) {
      return;
    }
    this.noticeError(msgObject.err);
  }

  setLogLevel(logLevel: LogLevel): void {
    this.currentLogLevel = logLevel;
  }

  noticeError(error: Error | string): void {
    if (!error) return;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    window.dtrum?.reportCustomError(
      'general-pa-error',
      JSON.stringify(error),
      this.deviceId,
    );
  }

  private async init(): Promise<void> {
    if (
      this.currentEnvironment &&
      this.supportedEnvironments.includes(this.currentEnvironment)
    ) {
      try {
        const script = document.createElement('script');
        script.src = `/EXTscripts/Dynatrace.${String(
          process.env.VUE_APP_ENV_FOR_ISSUE_LOGGING_SCRIPT,
        )}.min.js`;
        script.async = true;
        document.head.appendChild(script);
        this.deviceId =
          (await deviceIdServicePlugin.get().getDeviceId()) ||
          this.defaultDeviceId;
      } catch (e) {
        console.log('Error:', e);
      }
    }
  }
}
