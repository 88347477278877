import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "replacement-block",
  "data-e2e": "replacement-block"
}
const _hoisted_2 = {
  key: 0,
  class: "title"
}

import { Button } from '@/features/ui';
import { ReplacementIcon } from '@/features/ui/icons';

enum Events {
  Cancel = 'cancel',
  Replace = 'replace',
  NoReplacement = 'no-replacement',
}

interface Emits {
  (e: Events): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ReplacementBlock',
  props: {
  title: {
    type: String,
    default: '',
  },
  isReplaceable: {
    type: Boolean,
    default: false,
  },
},
  setup(__props, { emit: __emit }) {



const emit = __emit;

const handleEvent = (e: Events) => {
  emit(e);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.title)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(__props.title), 1))
      : _createCommentVNode("", true),
    (__props.isReplaceable)
      ? (_openBlock(), _createBlock(_unref(Button), {
          key: 1,
          class: "replacement-button",
          "data-e2e": "replacement-button",
          onClick: _cache[0] || (_cache[0] = () => handleEvent(Events.Replace))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(ReplacementIcon), { width: "20" }),
            _createElementVNode("span", null, _toDisplayString(__props.title
            ? _ctx.$t('components.picking-replacement-block.title-the-rest.text')
            : _ctx.$t('components.picking-replacement-block.title-this-article.text')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_unref(Button), {
      class: "no-replacement-button",
      "data-e2e": "no-replacement-button",
      btnOutline: "",
      onClick: _cache[1] || (_cache[1] = () => handleEvent(Events.NoReplacement))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(__props.isReplaceable
          ? _ctx.$t(
              'components.picking-replacement-block.button-no-replacement.text',
            )
          : _ctx.$t('components.picking-replacement-block.button-confirm.text')), 1)
      ]),
      _: 1
    }),
    _createVNode(_unref(Button), {
      "data-e2e": "cancel-replacement-button",
      btnOutline: "",
      plain: "",
      onClick: _cache[2] || (_cache[2] = () => handleEvent(Events.Cancel))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('components.picking-replacement-block.button-cancel.text')), 1)
      ]),
      _: 1
    })
  ]))
}
}

})