import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { User } from '../entities';

/**
 * Get user role from the DTO and save it to the provided DTO key
 */

export class SetUserRolePlugin<T extends { user: User | undefined }>
  implements PipelinePlugin<T>
{
  constructor(private dtoKey: keyof T) {}

  public execute(dto: T): T {
    const userRoles = dto.user?.roles;
    dto[this.dtoKey] = userRoles as unknown as T[keyof T];
    return dto;
  }
}
