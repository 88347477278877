import type { Ref } from 'vue';
import { ref } from 'vue';
import { router } from '@/features/core/router';
import type { RouteLocationNormalized } from 'vue-router';

interface UseRouteLeaveGuard {
  closeDialog: () => void;
  isDialogVisible: Ref<boolean>;
  isSameDayDialogVisible: Ref<boolean>;
  dialogText: Ref<string>;
  setupNavigationGuard: () => void;
  showDialog: (dialogMessage: string) => void;
  canLeaveRoute: (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ) => boolean;
  cancelSameDayNotification: () => void;
  unregisterNavigationGuard: () => void;
}

interface BlockGoingBackMeta {
  targetRoutes: string[];
  message: string;
}

export function useRouteLeaveGuard(): UseRouteLeaveGuard {
  const beforeEachHook: Ref<undefined | (() => void)> = ref(undefined);
  const isDialogVisible = ref(false);
  const isSameDayDialogVisible = ref(false);
  const dialogText = ref('');

  const showDialog = (dialogMessage: string) => {
    isDialogVisible.value = true;
    dialogText.value = dialogMessage;
  };

  const canLeaveRoute = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ) => {
    const leavingRouteMeta = from.meta.blockGoingBack as BlockGoingBackMeta;
    if (
      leavingRouteMeta !== undefined &&
      typeof to.name === 'string' &&
      leavingRouteMeta.targetRoutes.includes(to.name)
    ) {
      return false;
    }
    return true;
  };

  const setupNavigationGuard = (): void => {
    beforeEachHook.value = router.get().beforeEach((to, from) => {
      const leavingRouteMeta = from.meta.blockGoingBack as BlockGoingBackMeta;

      if (shouldSameDayNotificationBeVisible(to, from)) {
        isSameDayDialogVisible.value = true;

        return false;
      }

      if (!canLeaveRoute(to, from)) {
        showDialog(leavingRouteMeta.message);
        return false;
      }
    });
  };

  const unregisterNavigationGuard = (): void => {
    try {
      if (typeof beforeEachHook.value === 'undefined') {
        return;
      }

      beforeEachHook.value();
    } catch (e) {
      console.error('Could not unregister navigation guard', e);
    }
  };

  const shouldSameDayNotificationBeVisible = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ) => {
    const showSameDayNotificationMeta = from.meta.showSameDayNotification;
    const hasQueryParamId = to.hash.startsWith('#picking?');

    return showSameDayNotificationMeta && hasQueryParamId;
  };

  const closeDialog = (): void => {
    isDialogVisible.value = false;
  };

  const cancelSameDayNotification = (): void => {
    isSameDayDialogVisible.value = false;
  };

  return {
    closeDialog,
    isDialogVisible,
    dialogText,
    setupNavigationGuard,
    showDialog,
    isSameDayDialogVisible,
    cancelSameDayNotification,
    canLeaveRoute,
    unregisterNavigationGuard,
  };
}
