import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import 'vue';
import { TabVariants } from '@/features/ui';
import type { Order, OrderItem } from '@/features/orders';
import { OrderItemStatus } from '@/features/orders';

import HandoverItemFrame from '@/features/handover/components/HandoverItemFrame.vue';
import { computed, toRefs } from 'vue';
import HandoverCard from '@/features/handover/components/HandoverCard.vue';
import { Status } from '@/features/age-verification';
import type { Product } from '@/features/products';
import type { MappingCallback } from '@/features/handover';
import {
  getItemAndReplacementIds,
  getTotalAmountByStatus,
  getTotalQuantityByStatus,
  mapItemsToEntries,
} from '@/features/handover';


export default /*@__PURE__*/_defineComponent({
  __name: 'HandoverItem',
  props: {
    item: {},
    sectionStatus: {},
    order: {},
    currentTab: {}
  },
  emits: ["apply", "reject", "restore", "editWeight"],
  setup(__props: any) {

const props = __props;

const { item } = toRefs(props);



const itemIds = computed(() => getItemAndReplacementIds(props.item));

function createPerItemMapping<T>(getValue: MappingCallback<T>) {
  return computed(() => mapItemsToEntries(itemIds.value, getValue));
}

const quantitiesPerItem = createPerItemMapping(({ id, originalId }) => ({
  staged: getTotalQuantityByStatus(
    props.order?.items,
    id,
    OrderItemStatus.staged,
    originalId,
  ),
  rejected: getTotalQuantityByStatus(
    props.order?.items,
    id,
    OrderItemStatus.rejected,
    originalId,
  ),
  cancelled: getTotalQuantityByStatus(
    props.order?.items,
    id,
    OrderItemStatus.cancelled,
    originalId,
  ),
}));

const amountsPerItem = createPerItemMapping(({ id, originalId }) => ({
  staged: getTotalAmountByStatus(
    props.order?.items,
    id,
    OrderItemStatus.staged,
    originalId,
  ),
  rejected: getTotalAmountByStatus(
    props.order?.items,
    id,
    OrderItemStatus.rejected,
    originalId,
  ),
  cancelled: getTotalAmountByStatus(
    props.order?.items,
    id,
    OrderItemStatus.cancelled,
    originalId,
  ),
}));

const shownReplacements = computed(() =>
  props.sectionStatus === TabVariants.Rejected
    ? props.item.replacements?.filter((replacement) => replacement.isRejected)
    : props.item.replacements,
);

const allRejectedPerItem = createPerItemMapping(
  ({ id }) =>
    quantitiesPerItem.value[id].rejected > 0 &&
    quantitiesPerItem.value[id].staged === 0,
);

const allCancelledPerItem = createPerItemMapping(
  ({ id }) =>
    quantitiesPerItem.value[id].cancelled > 0 &&
    quantitiesPerItem.value[id].staged === 0 &&
    quantitiesPerItem.value[id].rejected === 0,
);

const canRejectPerItem = createPerItemMapping(
  ({ id }) => quantitiesPerItem.value[id].staged > 0,
);

const ageVerificationFailed = (product: Product) =>
  props.order &&
  props.order.ageVerification?.status === Status.Rejected &&
  product.ageRestricted !== 0;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(HandoverCard, null, {
    default: _withCtx(() => [
      _createVNode(HandoverItemFrame, {
        "total-quantity": _unref(item).quantityOriginal,
        "total-amount": _unref(item).amountOriginal,
        product: _unref(item).product,
        "show-as-rejected": _ctx.sectionStatus === _unref(TabVariants).Rejected,
        disabled: 
        _unref(allRejectedPerItem)[_unref(item).id] || ageVerificationFailed(_unref(item).product)
      ,
        "image-disabled": _unref(allCancelledPerItem)[_unref(item).id],
        rejectable: _unref(canRejectPerItem)[_unref(item).id],
        restoreable: _unref(allRejectedPerItem)[_unref(item).id],
        "staged-quantity": _unref(quantitiesPerItem)[_unref(item).id].staged,
        "available-quantity": 
        _unref(quantitiesPerItem)[_unref(item).id].staged + _unref(quantitiesPerItem)[_unref(item).id].rejected
      ,
        "staged-amount": _unref(amountsPerItem)[_unref(item).id].staged,
        "available-amount": 
        _unref(amountsPerItem)[_unref(item).id].staged + _unref(amountsPerItem)[_unref(item).id].rejected
      ,
        class: "handover-item__frame",
        "show-rejected-chip": 
        _unref(item).isRejected || ageVerificationFailed(_unref(item).product)
      ,
        onApply: _cache[0] || (_cache[0] = (count) => _ctx.$emit('apply', _unref(item), count)),
        onReject: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('reject', _unref(item)))),
        onRestoreAll: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('restore', _unref(item)))),
        onPackagedClicked: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('editWeight', _unref(item))))
      }, null, 8, ["total-quantity", "total-amount", "product", "show-as-rejected", "disabled", "image-disabled", "rejectable", "restoreable", "staged-quantity", "available-quantity", "staged-amount", "available-amount", "show-rejected-chip"]),
      (_unref(item).replacements)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(shownReplacements.value, (replacement) => {
            return (_openBlock(), _createBlock(HandoverItemFrame, {
              key: replacement.id,
              "show-as-rejected": _ctx.sectionStatus === _unref(TabVariants).Rejected,
              product: replacement.product,
              disabled: 
          _unref(allRejectedPerItem)[replacement.id] ||
          ageVerificationFailed(replacement.product)
        ,
              "image-disabled": _unref(allCancelledPerItem)[replacement.id],
              rejectable: _unref(canRejectPerItem)[replacement.id],
              restoreable: _unref(allRejectedPerItem)[replacement.id],
              "staged-quantity": _unref(quantitiesPerItem)[replacement.id].staged,
              "available-quantity": 
          _unref(quantitiesPerItem)[replacement.id].staged +
          _unref(quantitiesPerItem)[replacement.id].rejected
        ,
              "staged-amount": _unref(amountsPerItem)[replacement.id].staged,
              "available-amount": 
          _unref(amountsPerItem)[replacement.id].staged +
          _unref(amountsPerItem)[replacement.id].rejected
        ,
              "is-replacement": "",
              class: "handover-item__frame",
              "show-rejected-chip": 
          _unref(item).isRejected || ageVerificationFailed(replacement.product)
        ,
              onApply: (count) => _ctx.$emit('apply', replacement, count),
              onReject: ($event: any) => (_ctx.$emit('reject', replacement)),
              onRestoreAll: ($event: any) => (_ctx.$emit('restore', replacement)),
              onPackagedClicked: ($event: any) => (_ctx.$emit('editWeight', replacement))
            }, null, 8, ["show-as-rejected", "product", "disabled", "image-disabled", "rejectable", "restoreable", "staged-quantity", "available-quantity", "staged-amount", "available-amount", "show-rejected-chip", "onApply", "onReject", "onRestoreAll", "onPackagedClicked"]))
          }), 128))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})