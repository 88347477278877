import {
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { loggerServicePlugin } from '@/features/core/logger';
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { notificationPlugin } from '@/features/core/notifications';
import { configurationServicePlugin } from '@/features/configuration';
import {
  BarcodeFoundService,
  BarcodeService,
  VueBarcodeService,
} from './services';
import type { BarcodeServiceConfig } from './interfaces';
import type { IBarcodeFoundService } from './types';

const barcodePlugin = ProviderPluginFactory.create<
  BarcodeService,
  BarcodeServiceConfig
>({
  key: Symbol('barcodePlugin'),
  defaultFactory: {
    create: (app, options) => new BarcodeService(options),
  },
});

export const vueBarcodePlugin = ProviderPluginFactory.create<
  VueBarcodeService,
  BarcodeServiceConfig
>({
  key: Symbol('vueBarcodePlugin'),
  defaultFactory: {
    create: (): VueBarcodeService => new VueBarcodeService(barcodePlugin.get()),
  },
});

export const barcodeConfigPlugin = MultiPluginFactory.with({
  barcodePlugin,
  vueBarcodePlugin,
});

export const barcodeServicePlugin =
  ProviderPluginFactory.create<IBarcodeFoundService>({
    key: Symbol('BarcodeService'),
    defaultFactory: {
      create: () => {
        return new BarcodeFoundService(
          entityRepositoryPlugin.get(),
          configurationServicePlugin.get(),
          notificationPlugin.get(),
          loggerServicePlugin.get(),
        );
      },
    },
  });
