import type { ConfigurationApiClient } from './configuration';
import type { RegistryEntityRepositoryRegistry } from '@/features/core/entity-repository/registry-entity-repository';
import { Configuration } from '../entities';

export const registerConfigurationApi = (
  onlineEntityRepositoryRegistry: RegistryEntityRepositoryRegistry,
  configurationApi: ConfigurationApiClient,
): void => {
  onlineEntityRepositoryRegistry.register(Configuration, {
    getAll: configurationApi.getAll.bind(configurationApi),
  });
};
