import type { OrderItem } from '@/features/orders';
import { isOrderItemRandomWeight, OrderItemStatus } from '@/features/orders';
import type { OverpickingThresholds } from '@/features/picking';
import { getMultipliedValueThreshold } from '@/features/picking/helpers/getMultipliedValueThreshold';

export const isFullyPickedItemRejected = (
  item: OrderItem,
  items: OrderItem[],
  overpickingThreshold?: OverpickingThresholds,
): boolean => {
  if (item.status !== OrderItemStatus.staged) {
    return false;
  }
  const rejectedItem = items.find(
    (orderItem) =>
      orderItem.id === item.id && orderItem.status === OrderItemStatus.rejected,
  );
  if (!rejectedItem) {
    return false;
  }
  if (!isOrderItemRandomWeight(item)) {
    return item.quantity + rejectedItem.quantity === item.quantityOriginal;
  }
  const minAmount = getMultipliedValueThreshold(
    item.amountOriginal,
    overpickingThreshold?.minMultiplier,
  );
  const maxAmount = getMultipliedValueThreshold(
    item.amountOriginal,
    overpickingThreshold?.maxMultiplier,
  );
  const itemPickedAmount = item.amount + rejectedItem.amount;
  return itemPickedAmount >= minAmount && itemPickedAmount <= maxAmount;
};
