import type { Ref } from 'vue';

export interface UseStart {
  loadData: () => Promise<void | string>;
  loadingData: Ref<{ [key: string]: LoadingProcess }>;
}

export enum LoadingProcess {
  NotStarted = 'not started',
  Loading = 'loading',
  Loaded = 'loaded',
}
