import type { Resolver } from '../types';
import { noop } from '@/utils/helpers/noop';

export class ResolverService {
  private resolvers: Promise<void>[] = [];

  getResolver(): Resolver {
    let resolve = noop;
    let reject = noop;
    this.resolvers.push(
      new Promise<void>((res, rej) => ((resolve = res), (reject = rej))),
    );
    return { resolve, reject };
  }

  async getAllResolvers(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return await Promise.all(this.resolvers).then(noop);
  }
}
