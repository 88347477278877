import type { Ref } from 'vue';
import { nextTick } from 'vue';
import type { ScannableDeliveryUnit } from '../types';

export async function resetDeliveryUnitPositionsAndFocusFirst(
  deliveryUnits: Ref<ScannableDeliveryUnit[]>,
  deliveryUnitBlockRefs: Ref<Record<string, unknown>>,
): Promise<void> {
  const FIRST_DELIVERY_UNIT_BLOCK = 'deliveryUnitBlock-0';

  deliveryUnits.value.forEach((unit: ScannableDeliveryUnit) => {
    unit.position = '';
  });

  await nextTick();

  const firstDeliveryUnitBlock =
    deliveryUnitBlockRefs.value[FIRST_DELIVERY_UNIT_BLOCK];

  if (
    firstDeliveryUnitBlock &&
    typeof firstDeliveryUnitBlock === 'object' &&
    'openEditingDeliveryUnitPosition' in firstDeliveryUnitBlock &&
    typeof (
      firstDeliveryUnitBlock as { openEditingDeliveryUnitPosition: unknown }
    ).openEditingDeliveryUnitPosition === 'function'
  ) {
    (
      firstDeliveryUnitBlock as { openEditingDeliveryUnitPosition: () => void }
    ).openEditingDeliveryUnitPosition();
  }
}
