
import { defineComponent } from 'vue';
import { AppServiceResolver } from '@/features/core/resolver/';
import Notification from '@/features/core/notifications/components/Notification.vue';
import RouteLeaveGuard from '@/features/route-leave-guard/components/RouteLeaveGuard.vue';

export default defineComponent({
  name: 'App',
  components: {
    AppServiceResolver,
    Notification,
    RouteLeaveGuard,
  },
});
