
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    name: String,
    count: [String, Number],
    id: String,
  },
  setup: function (props) {
    return { ...props };
  },
});
