import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "list" }
const _hoisted_2 = {
  class: "label",
  "data-e2e": "error-stack-label"
}
const _hoisted_3 = {
  class: "value",
  "data-e2e": "error-stack-value"
}
const _hoisted_4 = {
  class: "label",
  "data-e2e": "error-info-label"
}
const _hoisted_5 = {
  class: "value",
  "data-e2e": "error-info-value"
}
const _hoisted_6 = {
  class: "label",
  "data-e2e": "error-file-label"
}
const _hoisted_7 = {
  class: "value",
  "data-e2e": "error-file-value"
}

import ErrorStackHeader from './ErrorStackHeader.vue';
import type { ComponentPublicInstance, PropType } from 'vue';
import { $t } from '@/i18n';
import ThreeSectionLayout from '@/features/ui/layouts/ThreeSectionLayout.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorStack',
  props: {
    error: {},
    instance: {},
    info: {}
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ThreeSectionLayout, { "with-router": false }, {
    header: _withCtx(() => [
      _createVNode(ErrorStackHeader, {
        onClose: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_unref($t)('pages.app-service-resolver.application-error.text')), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.error.stack || _ctx.error), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_unref($t)('pages.error.error-info-label.text')), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.info), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_unref($t)('pages.error.error-file-label.text')), 1),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.instance.$.type.__file), 1)
      ])
    ]),
    _: 1
  }))
}
}

})