import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Transition as _Transition, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "scalable-product__title" }
const _hoisted_2 = { class: "scalable-product__image" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "scalable-product__hint" }

import type { Ref } from 'vue';
import { ref, nextTick } from 'vue';
import { ZoomInIcon } from '@/features/ui/icons';
import { Image, Popup } from '@/features/ui';
import { useScalableImage } from '../composables';

const maxScalableWidth = 800;


export default /*@__PURE__*/_defineComponent({
  __name: 'ScalableImage',
  props: {
  imageSrc: {
    type: String,
    required: true,
  },
  class: {
    type: String,
    default: 'product-image',
  },
  title: {
    type: String,
    required: true,
  },
  imageAlt: {
    type: String,
    default: '',
  },
  grayscale: {
    type: Boolean,
    default: false,
  },
  disableScale: {
    type: Boolean,
    default: false,
  },
  e2e: String,
},
  setup(__props) {

const props = __props;

const { setImageScale, isImagePopupVisible, toggleImagePopup } =
  useScalableImage();
const scalableImage: Ref<HTMLElement | null> = ref(null);
const scalableArea: Ref<HTMLElement | null> = ref(null);
function onImageLoad() {
  void nextTick(() => {
    if (scalableArea.value && scalableImage.value) {
      setImageScale(scalableImage.value, scalableArea.value, maxScalableWidth);
    }
  });
}

const onImageClick = () => {
  if (props.disableScale) {
    return;
  }
  toggleImagePopup();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(props.class)
    }, [
      _createVNode(_unref(Image), {
        image: __props.imageSrc,
        title: __props.imageAlt,
        e2e: __props.e2e,
        grayscale: __props.grayscale,
        onClick: onImageClick,
        blendedImageBackground: true
      }, null, 8, ["image", "title", "e2e", "grayscale"])
    ], 2),
    _createVNode(_Transition, { name: "popIn" }, {
      default: _withCtx(() => [
        _createVNode(_unref(Popup), {
          onClose: _unref(toggleImagePopup),
          visible: _unref(isImagePopupVisible),
          "full-page-size": "",
          "without-header": "",
          class: "scalable-product__popup"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "scalable-product",
              ref_key: "scalableArea",
              ref: scalableArea
            }, [
              _createElementVNode("div", _hoisted_1, _toDisplayString(__props.title), 1),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  ref_key: "scalableImage",
                  ref: scalableImage,
                  src: __props.imageSrc,
                  alt: __props.imageAlt,
                  onLoad: onImageLoad
                }, null, 40, _hoisted_3)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_unref(ZoomInIcon)),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('components.scalable-image.hint.text')), 1)
              ])
            ], 512)
          ]),
          _: 1
        }, 8, ["onClose", "visible"])
      ]),
      _: 1
    })
  ], 64))
}
}

})