import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["data-e2e"]
const _hoisted_2 = { class: "dialog__wrap" }
const _hoisted_3 = {
  key: 0,
  class: "dialog__text",
  "data-e2e": "dialog-text"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "dialog__slot-component",
  "data-e2e": "dialog-slot-component"
}

import { computed, onBeforeUnmount, ref, watch } from 'vue';
import { Button } from '@/features/ui/components';
import type { ScrollEvent } from '../types';
import { $t } from '@/i18n';
import { useSecretToggle } from '@/features/ui/composables';
import type { Subscription } from 'rxjs';

interface Props {
  visible?: boolean;
  titleText: string;
  contentText?: string;
  confirmText: string;
  cancelText?: string;
  isSpaceToContentExist?: boolean;
  fullWidth?: boolean;
  showOnlyConfirm?: boolean;
  isTitleCentered?: boolean;
  dataE2E?: string;
  isConfirmButtonDisabled?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Dialog',
  props: {
    visible: { type: Boolean, default: false },
    titleText: {},
    contentText: {},
    confirmText: {},
    cancelText: { default: $t('common.cancel.text') },
    isSpaceToContentExist: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
    showOnlyConfirm: { type: Boolean, default: false },
    isTitleCentered: { type: Boolean, default: true },
    dataE2E: { default: 'dialog' },
    isConfirmButtonDisabled: { type: Boolean, default: false }
  },
  emits: ['confirmed', 'canceled', 'secretToggleClicked'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const contentRef = ref<HTMLElement | null>(null);
const subscription = ref<Subscription | null>(null);

const confirmChanges = () => {
  emit('confirmed');
};

const cancelChanges = () => {
  emit('canceled');
};

const handleSecretToggleClicked = () => {
  emit('secretToggleClicked');
};

const scrolledToBottom = ref(true);
const scrolledToTop = ref(true);

const getScrollClasses = computed(() => {
  return {
    'dialog__content--scrolled-to-top': scrolledToTop.value,
    'dialog__content--scrolled-to-bottom': scrolledToBottom.value,
  };
});

const onScroll = ({
  target: { scrollTop, clientHeight, scrollHeight },
}: ScrollEvent) => {
  const isOverflowing = scrollHeight > clientHeight;
  if (!isOverflowing) {
    scrolledToBottom.value = true;
    scrolledToTop.value = true;
    return;
  }

  const offset = 10;
  scrolledToBottom.value = scrollTop + clientHeight >= scrollHeight - offset;
  scrolledToTop.value = scrollTop <= offset;
};

watch(contentRef, (newValue) => {
  if (subscription.value) {
    subscription.value.unsubscribe();
  }

  if (newValue !== null) {
    subscription.value = useSecretToggle(
      contentRef.value as HTMLElement,
      'click',
      () => handleSecretToggleClicked(),
    );
  }
});

onBeforeUnmount(() => {
  if (subscription.value) {
    subscription.value.unsubscribe();
  }
});

return (_ctx: any,_cache: any) => {
  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([{
      'full-width': _ctx.fullWidth,
      margin: _ctx.isSpaceToContentExist,
    }, "dialog"]),
        "data-e2e": _ctx.dataE2E,
        onClick: _withModifiers(cancelChanges, ["self"])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            ref_key: "contentRef",
            ref: contentRef,
            class: _normalizeClass([getScrollClasses.value, "dialog__content"]),
            "data-e2e": "dialog-content",
            onScroll: onScroll
          }, [
            _createElementVNode("h3", {
              class: _normalizeClass([{
            'dialog__title-left': !_ctx.isTitleCentered,
            'dialog__title__single-line': !_ctx.contentText,
          }, "dialog__title"]),
              "data-e2e": "dialog-title"
            }, _toDisplayString(_ctx.titleText), 3),
            (_ctx.contentText)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", { innerHTML: _ctx.contentText }, null, 8, _hoisted_4)
                ]))
              : _createCommentVNode("", true),
            (_ctx.$slots.default)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "default")
                ]))
              : _createCommentVNode("", true)
          ], 34),
          _renderSlot(_ctx.$slots, "actions", {}, () => [
            _createVNode(_unref(Button), {
              disabled: _ctx.isConfirmButtonDisabled,
              "data-e2e": "confirm-button",
              onClick: confirmChanges
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.confirmText), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          (!_ctx.showOnlyConfirm)
            ? (_openBlock(), _createBlock(_unref(Button), {
                key: 0,
                "btn-outline": "",
                class: "mt-8",
                "data-e2e": "cancel-button",
                plain: "",
                onClick: cancelChanges
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.cancelText), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})