import {
  ExecPluginFactory,
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { E2ETestHelperService, NoopE2ETestHelperService } from './services';

const e2eTestHelperServicePlugin = ProviderPluginFactory.create<
  E2ETestHelperService | NoopE2ETestHelperService
>({
  key: Symbol('E2ETestHelperService'),
  defaultFactory: {
    create: () =>
      process.env.VUE_APP_CI === 'true'
        ? new E2ETestHelperService()
        : new NoopE2ETestHelperService(),
  },
});

const e2eTestHelperExecutePlugin = ExecPluginFactory.fn(() => {
  window.e2eTestHelper = e2eTestHelperServicePlugin.get();
});

export const e2eTestHelperPlugin = MultiPluginFactory.with({
  e2eTestHelperServicePlugin,
  e2eTestHelperExecutePlugin,
});
