import { Status } from '@/features/age-verification/types';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { hasOrderLocalStatus } from '@/features/orders/helpers/order-status';
import type { OrderItemRaw, OrderParsePluginDto } from '../types';
import { OrderItemStatus, OrderLocalStatus } from '../types';
import { LoadExistingOrderPlugin } from '@/features/orders/plugins/load-existing-order';
import { LoadOrderProductsPlugin } from '@/features/orders/plugins/load-order-products';
import { NormalizeOrderPlugin } from '@/features/orders/plugins/normalize-order';
import { NormalizeItemsPlugin } from '@/features/orders/plugins/normalize-items';

/**
 * Get restrictedAges from the order products
 * Add restrictedAges and ageVerification to the order
 **/
export class RestrictedOrderCheckPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  public readonly requiredPlugins = [
    LoadExistingOrderPlugin,
    LoadOrderProductsPlugin,
    NormalizeOrderPlugin,
    NormalizeItemsPlugin,
  ];

  public execute(dataTransferObject: OrderParsePluginDto): OrderParsePluginDto {
    const existingOrder = dataTransferObject.existingOrder;
    const localStatus = dataTransferObject.order.localStatus;
    const items = dataTransferObject.rawItems as OrderItemRaw[];
    const products = dataTransferObject.products || [];
    let restrictedAges: number[] = [];
    let ageVerification = null;

    if (
      existingOrder &&
      hasOrderLocalStatus(existingOrder, OrderLocalStatus.HandoverInProgress)
    ) {
      restrictedAges = existingOrder.restrictedAges;
      ageVerification = existingOrder.ageVerification;
    } else if (
      [
        OrderLocalStatus.HandoverReady,
        OrderLocalStatus.HandoverInProgress,
        OrderLocalStatus.PickingReady,
        OrderLocalStatus.PickingInProgress,
      ].includes(localStatus)
    ) {
      restrictedAges = Array.from(
        new Set(
          items.reduce((acc, orderItem) => {
            if (orderItem?.status !== OrderItemStatus.cancelled) {
              const product = products.find(
                (item) => item.sku === orderItem.product,
              );
              product && acc.push(product.ageRestricted);
            }
            return acc;
          }, [] as number[]),
        ),
      );
      const requiredAge = restrictedAges.reduce(
        (acc, item) => (item > acc ? item : acc),
        0,
      );

      ageVerification = {
        status: requiredAge > 0 ? Status.NotVerified : Status.Verified,
        dateOfBirth: null,
        userIdType: null,
        rejectionReason: null,
        requiredAge,
      };
    }

    dataTransferObject.order.restrictedAges = restrictedAges;
    dataTransferObject.order.ageVerification = ageVerification;

    return dataTransferObject;
  }
}
