<template>
  <div class="profile">
    <dl v-if="lastProductSyncData" class="item">
      <dt class="item-label" data-e2e="product-sync-date-label">
        {{ $t('components.profile.last-product-sync.text') }}
      </dt>
      <dd class="item-value" data-e2e="product-sync-date-value">
        {{ lastProductSyncData }}
      </dd>
    </dl>
    <dl class="item">
      <dt class="item-label" data-e2e="store-name-label">
        {{ $t('components.profile.store-name.text') }}
      </dt>
      <dd class="item-value" data-e2e="store-name-value">
        {{ user?.merchant?.name }}
      </dd>
    </dl>
    <dl class="item">
      <dt class="item-label" data-e2e="street-merchant-address-label">
        {{ $t('components.profile.merchant-address.text') }}
      </dt>
      <dd class="item-value" data-e2e="street-merchant-address-value">
        {{ user?.merchant?.formattedAddress }}
      </dd>
    </dl>
    <dl class="item">
      <dt class="item-label" data-e2e="employee-merchant-reference-label">
        {{ $t('components.profile.merchant-reference.text') }}
      </dt>
      <dd class="item-value" data-e2e="employee-merchant-reference-value">
        {{ user?.merchant?.reference }}
      </dd>
    </dl>
    <dl class="item">
      <dt class="item-label" data-e2e="employee-email-label">
        {{ $t('components.profile.merchant-email.text') }}
      </dt>
      <dd class="item-value" data-e2e="employee-email-value">
        {{ user?.email }}
      </dd>
    </dl>
  </div>
  <LogoutButton :disabled="true" />
  <Button
    v-if="showReceiveDataButton"
    class="mt-3"
    data-e2e="receive-data-button"
    @click="receiveData"
  >
    {{ $t('common.receive-data.text') }}
  </Button>
  <p
    v-if="hasFailedTransmissions || hasPendingTransmissions"
    class="transmission-note"
  >
    <InfoIcon fill="rgb(var(--aldi-gray-mid))" width="16" />
    <span>
      {{ $t('components.profile.logout-disabled-transmission.first-part') }}
      <span class="link" @click="() => toggleTransmissionsWindowVisibility()">
        {{ $t('components.profile.logout-disabled-transmission.second-part') }}
      </span>
    </span>
  </p>
  <div v-if="hasFailedTransmissions" class="menu">
    <hr class="menu__separator" />
    <div
      class="menu__link menu__link--error"
      @click="() => toggleTransmissionsWindowVisibility()"
    >
      <InfoIcon fill="rgb(var(--aldi-red-100))" />
      <span>{{ $t('components.profile.failed-transmission.text') }}</span>
      <ChevronRight fill="rgb(var(--aldi-gray-mid))" />
    </div>
  </div>
  <div v-else-if="hasPendingTransmissions" class="menu">
    <hr class="menu__separator" />
    <div
      class="menu__link"
      data-e2e="ongoing-transmissions-link"
      @click="() => toggleTransmissionsWindowVisibility()"
    >
      <LoadingIcon class="loading-icon-animation" />
      <span>{{ $t('components.profile.ongoing-transmission.text') }}</span>
      <ChevronRight fill="rgb(var(--aldi-gray-mid))" />
    </div>
  </div>
  <hr class="menu__separator" />
  <div
    class="menu__link menu__link--no-icon"
    data-e2e="info-link"
    @click="toggleInfoMenu"
  >
    <span>{{ $t('components.profile.information.text') }}</span>
    <ChevronRight fill="rgb(var(--aldi-gray-mid))" />
  </div>
  <template v-if="showUserReportLink">
    <hr class="menu__separator" />
    <div
      class="menu__link menu__link--no-icon"
      data-e2e="report-link"
      @click="pushUserReport"
    >
      <span>{{ $t('components.profile.user-report.text') }}</span>
      <ChevronRight fill="rgb(var(--aldi-gray-mid))" />
    </div>
  </template>
  <Teleport to="body">
    <InfoMenu v-if="infoMenuOpen" @close="toggleInfoMenu" />
  </Teleport>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/features/core/router';
import { oauthServicePlugin } from '@/features/oauth';
import { userServicePlugin, useUser } from '@/features/user';
import type { StorageEntityRepository } from '@/features/core/entity-repository';
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { useFeatureToggle } from '@/features/feature-toggle';
import { useTransmissions } from '@/features/transmissions';
import { Button } from '@/features/ui';
import LogoutButton from '@/features/ui/components/LogoutButton.vue';
import { ChevronRight, InfoIcon, LoadingIcon } from '@/features/ui/icons';
import { useProfileReceiveData } from '../composables/useProfileReceiveData';
import InfoMenu from '../components/InfoMenu.vue';
import { configurationServicePlugin } from '@/features/configuration';

export default defineComponent({
  name: 'Profile',
  components: {
    LogoutButton,
    InfoIcon,
    ChevronRight,
    LoadingIcon,
    Button,
    InfoMenu,
  },
  emits: ['update'],
  setup(props, { emit }) {
    const route = useRoute();
    const { user, loading, loadUser } = useUser();

    const { refreshAll } = useProfileReceiveData();
    const isLogoutDisabled = computed(
      () =>
        route.meta?.disabledLogout === true ||
        hasPendingTransmissions.value ||
        hasFailedTransmissions.value,
    );

    const showUserReportLink = ref(false);

    onMounted(async () => {
      await loadUser();
      initTransmissionsCheck();
      showUserReportLink.value = (
        await configurationServicePlugin.get().isFeatureActive('bugReporting')
      ).value;
    });

    const infoMenuOpen = ref(false);
    const toggleInfoMenu = () => {
      infoMenuOpen.value = !infoMenuOpen.value;
    };

    const logout = async () => {
      if (hasPendingTransmissions.value) {
        return;
      }

      await oauthServicePlugin.get().logout('empowerId');
      await userServicePlugin.get().clearUser();
      (entityRepositoryPlugin.get() as StorageEntityRepository).clearRefCache();
    };

    const receiveData = async () => {
      await refreshAll({
        refreshOrders: true,
        removeSyncData: true,
      });

      if (route?.name !== 'default') {
        return await router.get().push('/');
      }

      emit('update');
    };

    //#region transmissions
    const {
      hasPendingTransmissions,
      hasFailedTransmissions,
      toggleTransmissionsWindowVisibility,
      initTransmissionsCheck,
    } = useTransmissions();
    //#endregion

    //#region feature list
    const { featureList } = useFeatureToggle();

    const showReceiveDataButton = computed(
      () =>
        process.env.VUE_APP_SHOW_RECEIVE_DATA_BUTTON === 'true' ||
        featureList.value.manualDataLoading.active,
    );
    //#endregion

    const pushUserReport = async () => {
      await router.get().push({
        name: 'user-report',
      });
    };

    return {
      logout,
      receiveData,
      user,
      loading,
      isLogoutDisabled,
      hasPendingTransmissions,
      hasFailedTransmissions,
      toggleTransmissionsWindowVisibility,
      showReceiveDataButton,
      toggleInfoMenu,
      infoMenuOpen,
      showUserReportLink,
      pushUserReport,
      InfoMenu,
    };
  },
});
</script>

<style lang="scss" scoped>
.profile {
  display: grid;
  font-size: 16px;
  color: $default-text-color;
  gap: 17px 0;
  line-height: 1.3em;
  letter-spacing: 0.015em;
}

.item {
  display: grid;
  gap: 8px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.015em;
  text-align: left;
}

.item-label {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;
}

.wrap-anywhere {
  overflow-wrap: anywhere;
}

.logout-button {
  margin-top: 18px;
}

.menu {
  margin-top: 32px;
}

.menu__separator {
  margin: 24px 0 0;
  height: 1px;
  background: rgba(var(--aldi-mid-blue-40), 0.6);
  border: none;
}

.menu__link {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0 8px;
  padding: 16px 0;
  cursor: pointer;
  color: rgba(var(--aldi-mid-blue-100), 1);

  &:first-of-type {
    margin-top: 8px;
  }

  &--no-icon {
    grid-template-columns: 1fr auto;
  }

  &--error {
    color: rgba(var(--aldi-red-100), 1);
  }
}

.transmission-note {
  display: flex;
  margin-top: 8px;
  color: rgba(var(--aldi-gray-mid), 1);
  gap: 0 4px;
}

.link {
  text-decoration: underline;
  color: rgba(var(--aldi-mid-blue-100), 1);
  cursor: pointer;
}

.spinner {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}
</style>
