import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "manual-scan__title" }
const _hoisted_2 = { class: "manual-scan__sub-title" }
const _hoisted_3 = { class: "manually-scan-count__box" }
const _hoisted_4 = { "data-e2e": "manually-scan-count" }
const _hoisted_5 = { class: "controls" }

import { computed, ref, toRefs, watch } from 'vue';
import { Popup, Button, PlusIcon, MinusIcon } from '@/features/ui';


export default /*@__PURE__*/_defineComponent({
  __name: 'BagsManualScanning',
  props: {
  maxDeliveryUnitCount: Number,
  deliveryManualCount: Number,
},
  emits: ['apply'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const { maxDeliveryUnitCount, deliveryManualCount } = toRefs(props);
const manualScannedDeliveryUnitCount = ref(0);
const visibleManualScanning = ref(false);

const toggleManualScanning = () => {
  visibleManualScanning.value = !visibleManualScanning.value;
};

const increaseManualScannedDeliveryUnitCount = () => {
  manualScannedDeliveryUnitCount.value++;
};

const decreaseManualScannedDeliveryUnitCount = () => {
  manualScannedDeliveryUnitCount.value--;
};

const canDecrease = computed(() => manualScannedDeliveryUnitCount.value > 0);
const canIncrease = computed(
  () => manualScannedDeliveryUnitCount.value < maxDeliveryUnitCount.value,
);

watch(visibleManualScanning, () => {
  manualScannedDeliveryUnitCount.value = deliveryManualCount.value;
});

const openManualScanning = () => {
  toggleManualScanning();
};

const applyManualScanning = () => {
  emit('apply', manualScannedDeliveryUnitCount.value);
  toggleManualScanning();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Button), {
      "data-e2e": "cant-scan-bag-button",
      class: "cant-scan-bag__button",
      plain: "",
      onClick: openManualScanning
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('pages.scan-bag-position.cant-scan-bag-items.text')), 1)
      ]),
      _: 1
    }),
    _createVNode(_unref(Popup), {
      visible: visibleManualScanning.value,
      bottom: true,
      isCloseButtonExist: false,
      withoutHeader: true,
      onClose: toggleManualScanning
    }, {
      default: _withCtx(() => [
        _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('pages.scan-bag-position.manually-scan-title.text')), 1),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('pages.scan-bag-position.manually-scan-subtitle.text')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(Button), {
            "data-e2e": "manually-scan-count-button-decrease",
            disabled: !canDecrease.value,
            icon: "",
            btnOutline: "",
            onClick: decreaseManualScannedDeliveryUnitCount
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(MinusIcon), {
                stroke: "currentColor",
                fill: "currentColor"
              })
            ]),
            _: 1
          }, 8, ["disabled"]),
          _createElementVNode("span", _hoisted_4, _toDisplayString(manualScannedDeliveryUnitCount.value), 1),
          _createVNode(_unref(Button), {
            disabled: !canIncrease.value,
            "data-e2e": "manually-scan-count-button-increase",
            icon: "",
            btnOutline: "",
            onClick: increaseManualScannedDeliveryUnitCount
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(PlusIcon), {
                stroke: "currentColor",
                fill: "currentColor"
              })
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(Button), {
            "data-e2e": "manually-scan-apply",
            onClick: applyManualScanning
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('pages.scan-bag-position.manually-scan-apply-button.text')), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(Button), {
            "data-e2e": "manually-scan-cancel",
            text: "",
            btnOutline: "",
            onClick: toggleManualScanning
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('pages.scan-bag-position.manually-scan-cancel-button.text')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})