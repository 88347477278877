import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "counts" }
const _hoisted_2 = { class: "in-category" }
const _hoisted_3 = { class: "separator" }
const _hoisted_4 = { class: "total" }
const _hoisted_5 = {
  key: 1,
  class: "separator"
}

import 'vue';
import { $t } from '@/i18n';
import Chip from '@/features/ui/components/Chip.vue';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HandoverPackagedRandomWeightFooter',
  props: {
    staged: {},
    total: {},
    available: {},
    rejected: {},
    disabled: { type: Boolean },
    showRejectedChip: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const onClick = () => {
  if (disableButton.value) {
    return;
  }

  emit('click');
};

const disableButton = computed(() => props.disabled || props.staged === 0);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ content: true, disabled: disableButton.value })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: "input",
        onClick: onClick
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.staged), 1),
        (_ctx.total !== undefined)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_unref($t)('components.handover-item.of.text')), 1),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.total), 1)
            ], 64))
          : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref($t)('components.handover-item.items.text', { count: _ctx.staged })), 1))
      ]),
      (_ctx.rejected !== 0 && _ctx.showRejectedChip)
        ? (_openBlock(), _createBlock(Chip, {
            key: 0,
            text: 
          _ctx.rejected === _ctx.available
            ? _unref($t)('components.handover-item.all-rejected.text')
            : _unref($t)('components.handover-item.count-rejected.text', {
                count: _ctx.rejected,
              })
        ,
            variant: "danger",
            size: "medium"
          }, null, 8, ["text"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})