import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }

import { TransitionSpinner } from '@/features/ui/icons';

export default /*@__PURE__*/_defineComponent({
  __name: 'ScreenLoading',
  props: {
  title: {
    type: String,
  },
  blur: {
    type: Boolean,
    default: false,
  },
  inFront: {
    type: Boolean,
    default: false,
  },
  fixed: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['loading', { blur: __props.blur, 'in-front': __props.inFront, fixed: __props.fixed }])
  }, [
    _createVNode(_unref(TransitionSpinner)),
    (__props.title)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(__props.title), 1))
      : _createCommentVNode("", true)
  ], 2))
}
}

})