import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "three-section-layout" }
const _hoisted_2 = {
  class: "three-section-layout__header",
  "data-e2e": "three-section-header-section"
}
const _hoisted_3 = {
  class: "three-section-layout__content",
  "data-e2e": "three-section-content-section"
}
const _hoisted_4 = {
  class: "three-section-layout__footer",
  "data-e2e": "three-section-footer-section"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ThreeSectionLayout',
  props: {
  withRouter: {
    default: true,
    type: Boolean,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "header")
    ]),
    _createElementVNode("div", _hoisted_3, [
      (__props.withRouter)
        ? (_openBlock(), _createBlock(_component_RouterView, { key: 0 }))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "footer")
    ])
  ]))
}
}

})