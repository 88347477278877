import type { SyncSchedulerService } from '@/features/sync-scheduler';
import { SyncSchedulerExecutorFilterDto } from '@/features/sync-scheduler';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { NormalizeOrderPlugin } from '@/features/orders/plugins/normalize-order';
import type { Order } from '../entities';
import type { OrderParsePluginDto } from '@/features/orders/types';
import { OrderLocalStatus } from '../types';

/**
 * Get all sync scheduler entries
 * Filter sync scheduler entries by PickingCompleted status
 * Remove sync scheduler entries in PickingCompleted status
 **/
export class DeleteOutstandingSyncsPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  constructor(private syncSchedulerService: SyncSchedulerService) {}

  public readonly requiredPlugins = [NormalizeOrderPlugin];

  public async execute(
    dataTransferObject: OrderParsePluginDto,
  ): Promise<OrderParsePluginDto> {
    const syncOutstandingList = (
      await this.syncSchedulerService.getAllSyncByEntity(
        new SyncSchedulerExecutorFilterDto(
          'order',
          dataTransferObject.orderRaw.id,
        ),
      )
    ).filter(
      (sync) =>
        (sync.data.entitySnapshot as Order).localStatus ===
        OrderLocalStatus.PickingCompleted,
    );

    if (syncOutstandingList.length) {
      await Promise.all(
        syncOutstandingList.map((sync) => {
          return sync.cancel();
        }),
      );
    }

    return dataTransferObject;
  }
}
