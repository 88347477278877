import { loggerServicePlugin } from '@/features/core/logger/plugin';
import { ExecPluginFactory, MultiPluginFactory } from '@/features/core/plugin';

export const visibilityChangeLogExecutePlugin = ExecPluginFactory.fn(() => {
  loggerServicePlugin.get().info('App was opened');

  if (document === undefined) {
    return;
  }

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      loggerServicePlugin.get().info('App is now in the foreground');
    }
  });
});

export const visibilityChangeLogPlugin = MultiPluginFactory.with({
  visibilityChangeLogExecutePlugin,
});
