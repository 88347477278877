/* eslint-disable @typescript-eslint/no-explicit-any */
import type { InferType } from '@/utils/types';
import type { RestorableType, Storable } from './storable';

export interface Storage {
  getAll<T extends RestorableType<any>>(
    type: T,
    options?: StorageReadOptions<InferType<T>>,
  ): Promise<InferType<T>[]>;

  getById<T extends RestorableType<any>>(
    type: T,
    options: StorageReadIdOptions,
  ): Promise<InferType<T> | undefined>;

  getByIds<T extends RestorableType<any>>(
    type: T,
    options: StorageReadIdsOptions,
  ): Promise<InferType<T>[]>;

  count<T extends RestorableType<any>>(
    type: T,
    options?: StorageReadOptions<InferType<T>>,
  ): Promise<number>;

  save<T extends Storable>(data: T, options?: StorageWriteOptions): Promise<T>;

  bulkSave<T extends Storable>(dataArr: T[]): Promise<T[]>;

  remove(data: Storable, options?: StorageWriteOptions): Promise<void>;

  removeSeveral(
    type: RestorableType<any>,
    options: Required<Pick<StorageWriteOptions, 'ids'>>,
  ): Promise<void>;

  removeAll(
    type: RestorableType<any>,
    options?: StorageWriteOptions,
  ): Promise<void>;
}

export interface StorageReadOptions<Entity = Record<string, unknown>>
  extends StorageFilterOptions<Entity>,
    StorageSortOptions {
  limit?: number;
  offset?: number;
}

export interface StorageReadIdsOptions extends StorageSortOptions {
  ids: string[];
}

export interface StorageReadIdOptions {
  id: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StorageWriteOptions {
  ids?: string[];
}

export interface StorageFilterOptions<Entity = Record<string, unknown>> {
  filter?: StorageFilters<Entity>;
}

type StorageFiltersCombinators<Entity = Record<string, unknown>> =
  | StorageFiltersAndCombinator<Entity>
  | StorageFiltersOrCombinator<Entity>;

interface StorageFiltersAndCombinator<Entity = Record<string, unknown>> {
  $and?: StorageFilters<Entity>;
}

interface StorageFiltersOrCombinator<Entity = Record<string, unknown>> {
  $or?: StorageFilters<Entity>;
}

export type StorageFilters<Entity = Record<string, unknown>> = Partial<
  | StorageFiltersCombinators<Entity>
  // Add Entity keys as autocomplete for filter keys
  | Record<keyof Entity, StorageFilter>
  // But allow any custom filter keys
  | Record<string, StorageFilter>
>;

export type StorageFilter =
  | StorageFilterEquals
  | StorageFilterNotEquals
  | StorageFilterMatch
  | StorageFilterAnyOf
  | StorageFilterNoneOf
  | StorageFilterRange
  | StorageFilterStartsWith;

interface StorageFilterEquals {
  equals?: StorageFilterValues;
}

interface StorageFilterStartsWith {
  startsWith?: string;
}

interface StorageFilterNotEquals {
  notEquals?: StorageFilterValues;
}

interface StorageFilterMatch {
  match?: string;
}

interface StorageFilterAnyOf {
  anyOf?: StorageFilterValue[];
}

interface StorageFilterNoneOf {
  noneOf?: StorageFilterValue[];
}

interface StorageFilterRange {
  from?: StorageFilterValues;
  to?: StorageFilterValues;
}

type StorageFilterValue = string | number | BooleanNumber | Date;
type StorageFilterValues = StorageFilterValue | StorageFilterValue[];

interface StorageSortOptions {
  sortBy?: string;
  sortDir?: StorageSortDirection;
}

export enum StorageSortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum BooleanNumber {
  False = 0,
  True = 1,
}
