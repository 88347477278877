import type { RestorableType, Storable } from '@/features/core/storage';

export interface Entity extends NewEntity, Storable {
  id: string;
}

export interface NewEntity {
  type: string;
}

export type AnyEntity = Entity | NewEntity;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EntityType<T extends Entity> extends RestorableType<T> {}

export class RestorableEntity {
  static from<T extends Entity>(this: new () => T, data: Partial<T>): T {
    const entity = new this();
    Object.assign(entity, data);
    return entity;
  }
}
