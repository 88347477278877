import type { ApiClient } from '@/features/core/api';
import type { ReportEmail } from '../types';
import type { AxiosResponse } from 'axios';

export class ReportsApiClient {
  constructor(private api: ApiClient) {}

  async save(params: ReportEmail): Promise<AxiosResponse> {
    return await this.api.client.post(`reports`, { data: params }, {});
  }
}
