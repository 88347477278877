import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { OrderParsePluginDto } from '@/features/orders/types';
import { NormalizeOrderPlugin } from '@/features/orders/plugins/normalize-order';
import { LoadExistingOrderPlugin } from '@/features/orders/plugins/load-existing-order';
import { clearNotificationSingleton } from '@/features/clear-notification';
import type { NotificationTags } from '@/features/push-notification';
import { replaceIdsInNotificationField } from '@/features/push-notification/helpers/replaceIdsInNotificationField';

/**
 * Clear same date order notification if order localStatus changed from PickingReady to PickingInProgress
 * Clear check in notification if order localStatus changed from HandoverInProgress to HandoverReady
 **/
export class ClearNotificationPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  constructor(private tagName: NotificationTags) {}

  public readonly requiredPlugins = [
    LoadExistingOrderPlugin,
    NormalizeOrderPlugin,
  ];

  public async execute(
    dataTransferObject: OrderParsePluginDto,
  ): Promise<OrderParsePluginDto> {
    await clearNotificationSingleton.clearNotificationByTag(
      this.getTagName(dataTransferObject),
    );

    return dataTransferObject;
  }

  private getTagName(dataTransferObject: OrderParsePluginDto) {
    return replaceIdsInNotificationField(
      this.tagName,
      dataTransferObject.order.id,
    );
  }
}
