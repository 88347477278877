
import { defineComponent } from 'vue';
import BaseButton from '@/features/ui/components/Button.vue';
import MinusIcon from '@/features/ui/icons/MinusIcon.vue';
import PlusIcon from '@/features/ui/icons/PlusIcon.vue';

export default defineComponent({
  components: { PlusIcon, MinusIcon, BaseButton },
  props: {
    sku: { type: String, required: true },
    quantity: { type: Number, default: 0 },
    minimumBagCount: { type: Number, default: 0 },
    name: { type: String, required: true },
  },
  setup(props, { emit }) {
    const updateQuantity = (quantity: number) => {
      quantity = Math.max(0, quantity);
      emit('update:quantity', quantity);
    };

    return { updateQuantity };
  },
});
