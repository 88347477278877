import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Button from '@/features/ui/components/Button.vue';
import BaseIcon from '@/features/ui/icons/BaseIcon.vue';
import TrashIcon from '@/features/ui/icons/TrashIcon.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'CloseButton',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Button, _mergeProps({
    class: "icon",
    icon: "",
    btnOutline: ""
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      _createVNode(BaseIcon, {
        width: 24,
        height: 24,
        viewBox: "0 0 24 24"
      }, {
        default: _withCtx(() => [
          _createVNode(TrashIcon)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 16))
}
}

})