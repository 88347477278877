import type {
  Entity,
  NewEntity,
} from '@/features/core/entity-repository/entity';
import { RestorableEntity } from '@/features/core/entity-repository/entity';
import type {
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { DBVersions } from '@/features/core/storage/dexie/versions';
import type { MessageTransferObject } from '../types';

class NewLogs extends RestorableEntity implements NewEntity {
  type = 'logs';
}

export class Logs extends NewLogs implements Entity {
  id!: string;
  timestamp!: string;
  logLevel!: number;
  message!: MessageTransferObject;
}

export class LogsStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.Logs_1).stores({
      [storeName]: '&id,timestamp,logLevel,message',
    });
    version(DBVersions.Logs_2).stores({
      [storeName]: '&id',
    });

    return Promise.resolve();
  }
}
