import type { EventBusEvent } from '@/features/core/event-bus';
import type { ChangeUrlEventOptions } from '../types';

export class ChangeUrlEvent implements EventBusEvent {
  name = 'ChangeUrlEvent';

  constructor(
    public readonly url: string,
    public readonly options?: ChangeUrlEventOptions,
  ) {}
}
