
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { OrderEventNames, ordersServicePlugin } from '@/features/orders';
import { useBagsStorage } from '@/features/bags/composables';
import { AppHeader, BackButton, Bags, Button } from '@/features/ui';
import ScreenLoading from '@/features/ui/components/ScreenLoading.vue'; // NOTE:  Failed to resolve component, when importing via barrel
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';
import { useOrder as usePickingOrder } from '@/features/picking';
import { useOrder } from '..';

export default defineComponent({
  components: {
    Bags,
    Button,
    AppHeader,
    BackButton,
    ScreenLoading,
  },
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const scrollTo = ref<null | HTMLElement>(null);
    const { bags, loading, order, loadOrder, setBagQuantity } = useOrder();

    const { redirectIfOrderNotPicking } = usePickingOrder();

    const { saveBagsQuantityToStorage, getBagsQuantityFromStorage } =
      useBagsStorage();
    const processing = ref(false);
    const titleRef = ref(Element);
    const router = useRouter();
    const { startTracking, stopTracking } = usePerformanceTracker();

    onMounted(async () => {
      processing.value = true;
      await getBags();
      processing.value = false;
      stopTracking(`review-order-to-order-bags-${props.id}`);
      await redirectIfOrderNotPicking(order.value);
      if (order.value) {
        await ordersServicePlugin
          .get()
          .trackEvent(order.value, OrderEventNames.staging_started);
      }
    });

    const setOrderBagsQuantityFromStorage = () => {
      const storedBags = getBagsQuantityFromStorage();
      if (storedBags && Array.isArray(storedBags)) {
        storedBags.forEach((storedBag) => {
          const bag = order.value?.bags.find(
            (bagItem) => bagItem.sku === storedBag.sku,
          );

          if (bag) {
            setBagQuantity(bag, storedBag.quantity);
          }
        });
      }
    };

    const initWatcher = () =>
      watch(
        bags,
        () => {
          if (order.value) saveBagsQuantityToStorage(bags.value);
        },
        { deep: true },
      );

    const getBags = async () => {
      await loadOrder(props.id);
      setOrderBagsQuantityFromStorage();
      initWatcher();
    };

    const goNext = async () => {
      processing.value = true;

      if (order.value) {
        await ordersServicePlugin.get().saveOrder(order.value);
      }

      startTracking(
        `review-orders-to-picking-labels-${props.id}`,
        PerformanceThresholdEnum.ROUTE_CHANGE,
      );

      await router.push({
        name: 'picking-labels',
        params: {
          id: props.id,
        },
      });
    };

    const isBagFocused = ref(false);
    const focusInHandler = () => {
      isBagFocused.value = true;
      scrollTo.value?.scrollIntoView();
    };
    const focusOutHandler = () => {
      isBagFocused.value = false;
    };

    return {
      bags,
      goNext,
      order,
      setBagQuantity,
      isBagFocused,
      focusInHandler,
      focusOutHandler,
      processing,
      titleRef,
      scrollTo,
      loading,
    };
  },
});
