
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { $t } from '@/i18n';
import BaseButton from '@/features/ui/components/Button.vue';
import { formatDateTime } from '@/composables/useDateFormat';
import { AppHeader, BackButton } from '@/features/ui';
import ScreenLoading from '@/features/ui/components/ScreenLoading.vue'; // NOTE:  Failed to resolve component, when importing via barrel
import { pickingCompletedSave, useOrder } from '@/features/review/composables';
import { useOrder as usePickingOrder } from '@/features/picking';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';

export default defineComponent({
  components: {
    BaseButton,
    BackButton,
    AppHeader,
    ScreenLoading,
  },
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const { order, loadOrder, loading } = useOrder();
    const { redirectIfOrderNotPicking } = usePickingOrder();
    const { startTracking, stopTracking } = usePerformanceTracker();

    onMounted(async () => {
      await loadOrder(props.id);
      stopTracking(`printing-to-complete-picking-order-${props.id}`);
      await redirectIfOrderNotPicking(order.value);
    });

    const formattedTime = computed(() => {
      return order.value && order.value.startTime
        ? formatDateTime(new Date(order.value.startTime))
        : '';
    });
    const router = useRouter();
    const processing = ref(false);

    const completeButtonText = computed(() => {
      return hasDeliveryUnit.value
        ? $t('pages.review-customer-note.finish-placing-label-button.text')
        : $t('pages.review-customer-note.finish-picking-button.text');
    });

    const backButtonURL = 'picking-labels';

    const titleText = computed(() =>
      hasDeliveryUnit.value
        ? $t('pages.review-customer-note.title.text')
        : $t('pages.review-customer-note.title-no-labels.text'),
    );

    const pickingComplete = async () => {
      if (order && order.value) {
        processing.value = true;

        if (hasDeliveryUnit.value) {
          processing.value = false;
          startTracking(
            `customer-note-to-bags-position-${props.id}`,
            PerformanceThresholdEnum.ROUTE_CHANGE,
          );
          await router.push('bag-position');
          return;
        }

        await pickingCompletedSave().save(order);
        processing.value = false;
      }
    };

    const hasDeliveryUnit = computed<boolean>(
      () => !!order.value?.deliveryUnits.length,
    );

    const countOfLabels = computed(() => order.value?.numberOfLabels ?? 0);

    return {
      order,
      pickingComplete,
      formattedTime,
      processing,
      loading,
      countOfLabels,
      hasDeliveryUnit,
      completeButtonText,
      titleText,
      backButtonURL,
    };
  },
});
