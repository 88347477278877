import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "popup__title",
  "data-e2e": "popup-title-failed-transmission"
}
const _hoisted_2 = { class: "info-item-with-switch" }

import { useDeviceInfo } from '@/features/device-id';
import { CheckboxToggler, Popup } from '@/features/ui';
import { onMounted, ref } from 'vue';
import InfoMenuItem from './InfoMenuItem.vue';
import { backendLoggingTransporterPlugin } from '@/features/core/logger/transporter-plugins';
import { DataSyncScopes, dataSyncServicePlugin } from '@/features/data-sync';
import { formatDateTimeHours } from '@/utils/helpers/DateFormatter';


export default /*@__PURE__*/_defineComponent({
  __name: 'InfoMenu',
  setup(__props) {

const { deviceId } = useDeviceInfo();
const isExtended = ref(
  backendLoggingTransporterPlugin.get().isExtendedLogging(),
);
const switchExtendedLogging = (isActive: boolean) => {
  isExtended.value = isActive;
  const bELoggerLogLevel = isExtended.value
    ? 20
    : backendLoggingTransporterPlugin.get().defaultLogLevel;
  backendLoggingTransporterPlugin.get().setLogLevel(bELoggerLogLevel);
};

const productDataSync = async () => {
  const dataProductSync = await dataSyncServicePlugin
    .get()
    .getById(DataSyncScopes.ProductSync);
  return dataProductSync
    ? formatDateTimeHours(new Date(dataProductSync.lastSync))
    : '';
};

const lastProductSyncData = ref('');

onMounted(async () => {
  lastProductSyncData.value = await productDataSync();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Popup), {
    visible: true,
    bottom: "",
    fixedHeader: "",
    fullPageSize: ""
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('components.info-menu.title.text')), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(InfoMenuItem, { dataE2EPrefix: "product-sync-date" }, {
        label: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('components.profile.last-product-sync.text')), 1)
        ]),
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString(lastProductSyncData.value), 1)
        ]),
        _: 1
      }),
      _createVNode(InfoMenuItem, { dataE2EPrefix: "device-id" }, {
        label: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('components.profile.device-id.text')), 1)
        ]),
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString(_unref(deviceId)), 1)
        ]),
        _: 1
      }),
      _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(InfoMenuItem, null, {
          label: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('components.info-menu.extended-logging.title.text')), 1)
          ]),
          default: _withCtx(() => [
            _createTextVNode(" " + _toDisplayString(_ctx.$t('components.info-menu.extended-logging.description.text')), 1)
          ]),
          _: 1
        }),
        _createVNode(_unref(CheckboxToggler), {
          "model-value": isExtended.value,
          big: "",
          "data-e2e": "extended-logging-switch",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (switchExtendedLogging($event)))
        }, null, 8, ["model-value"])
      ])
    ]),
    _: 1
  }))
}
}

})