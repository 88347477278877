import type { Ref } from 'vue';
import { PluginPipeline } from '@ads/plugin-pipeline';
import { errorPlugin } from '@/features/core/errors';
import router from '@/features/core/router';
import type { Order, OrderPluginDto } from '@/features/orders';
import {
  AppCrashPlugin,
  BooleanSwitcherPlugin,
  EmitEventBusStatusChangePlugin,
  LogStartOrderEventsPlugin,
  NavigateToTargetRoutePlugin,
  OrderLocalStatus,
  ordersServicePlugin,
  SaveOrderAndSyncScheduledPlugin,
  SaveOrderPlugin,
  SetEventsToPatchPlugin,
  SetLastOrderStatusChangedPlugin,
  SetOrderStatusPlugin,
  SetTargetRoutePlugin,
  StartConfirmationPlugin,
  StartPerformanceTrackingPlugin,
  TrackOrderEventsPlugin,
} from '@/features/orders';
import { PipelineExecutionError } from '@ads/plugin-pipeline/build/pipeline-execution-error';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';

export async function startHandoverProcess(
  order: Order,
  switcher: Ref<boolean>,
): Promise<void> {
  const performanceTrackerComposable = usePerformanceTracker();

  try {
    const startOrderPlugin = new PluginPipeline<OrderPluginDto>();

    startOrderPlugin.registerPlugins([
      new StartConfirmationPlugin(),
      new BooleanSwitcherPlugin(switcher, true),
      new StartPerformanceTrackingPlugin(
        performanceTrackerComposable,
        `start-handover-process-${order.id}`,
        PerformanceThresholdEnum.ROUTE_CHANGE,
      ),
      new EmitEventBusStatusChangePlugin('handover-started'),
      new LogStartOrderEventsPlugin(),
      new SetLastOrderStatusChangedPlugin(),
      new TrackOrderEventsPlugin(),
      new SaveOrderAndSyncScheduledPlugin(),
      new SetEventsToPatchPlugin(),
      new SetOrderStatusPlugin(OrderLocalStatus.HandoverInProgress),
      new SaveOrderPlugin(ordersServicePlugin.get()),
      new AppCrashPlugin(),
      new SetTargetRoutePlugin({
        name: 'handover-customer-information',
        params: {
          id: order.id,
        },
      }),
      new NavigateToTargetRoutePlugin(),
      new BooleanSwitcherPlugin(switcher, false),
    ]);

    await startOrderPlugin.execute({
      order,
    });
  } catch (error) {
    switcher.value = false;
    if (error instanceof PipelineExecutionError) {
      errorPlugin.get().handle(error.originalError);
    } else {
      errorPlugin.get().handle(error);
    }

    performanceTrackerComposable.cancelTracking(
      `start-handover-process-${order.id}`,
    );
    await router.get().push('/');
  } finally {
    performanceTrackerComposable.stopTracking(
      `start-handover-process-${order.id}`,
    );
  }
}
