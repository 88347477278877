import type {
  CompletePipelineWith,
  PipelinePlugin,
} from '@ads/plugin-pipeline';
import type { OrdersService } from '@/features/orders';
import {
  Order,
  parseOrderBeforeSave,
  useOnlineStatus,
} from '@/features/orders';
import type { LoggerService } from '@/features/core/logger';
import type { RestageOrderPluginDto } from '../types';
import type { ErrorHandler } from '@/features/core/errors';
import { EmitHandoverAbortedBusEventPlugin } from './emit-handover-aborted-bus-event';
import { TrackEventRestagingCompletedPlugin } from './track-event-restaging-completed';
import { UpdateLocalStatusPlugin } from './update-local-status';
import { AppCrashPlugin } from './app-crash';
import type { Router } from '@/features/core/router/types';

/**
 * Save restaged order
 **/
export class SaveRestagedOrderPlugin
  implements PipelinePlugin<RestageOrderPluginDto>
{
  public readonly requiredPlugins = [
    EmitHandoverAbortedBusEventPlugin,
    TrackEventRestagingCompletedPlugin,
    UpdateLocalStatusPlugin,
    AppCrashPlugin,
  ];

  constructor(
    private ordersService: OrdersService,
    private loggerService: LoggerService,
    private errorHandler: ErrorHandler,
    private router: Router,
  ) {}

  public async execute(
    dataTransferObject: RestageOrderPluginDto,
    completePipelineWith: CompletePipelineWith<RestageOrderPluginDto>,
  ): Promise<RestageOrderPluginDto> {
    try {
      const { isOnline } = useOnlineStatus();
      const parsedOrder = parseOrderBeforeSave(
        Order.from(dataTransferObject.order),
      );
      this.loggerService.debug(
        `Order ${dataTransferObject.order.id} prepared for saving`,
        {
          order: dataTransferObject.order,
        },
      );

      await this.ordersService.saveOrder(parsedOrder, true, false, false);
      this.loggerService.info(
        `Handover for Order ${dataTransferObject.order.id} aborted ${
          isOnline.value ? '' : '(offline)'
        }`,
        {
          orderId: dataTransferObject.order.id,
          orderReference: dataTransferObject.order.orderReference,
        },
      );
    } catch (error) {
      this.loggerService.error(
        `Order ${dataTransferObject.order.id} couldn't be saved`,
        error,
      );
      this.errorHandler.handle(error);

      return completePipelineWith(dataTransferObject);
    } finally {
      await this.router.push({
        name: 'default',
      });
    }

    return dataTransferObject;
  }
}
