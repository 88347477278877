import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'AppHeader',
  props: {
  borderBottom: {
    type: Boolean,
    default: false,
  },
  fixed: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["header", { 'header__border--bottom': __props.borderBottom, 'header--fixed': __props.fixed }])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})