import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, renderList as _renderList, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  "keep-alive": "",
  class: "search-list"
}
const _hoisted_2 = {
  "data-e2e": "search-list-title",
  class: "search-list__title"
}
const _hoisted_3 = {
  key: 0,
  "data-e2e": "search-loading-results",
  class: "search-list__loading-results"
}
const _hoisted_4 = {
  key: 1,
  "data-e2e": "search-no-results",
  class: "search-list__no-results-text"
}
const _hoisted_5 = {
  key: 1,
  class: "search-list__offline-block"
}
const _hoisted_6 = {
  class: "offline-title",
  "data-e2e": "search-not-available-msg"
}
const _hoisted_7 = {
  class: "offline-subtitle",
  "data-e2e": "device-offline-msg"
}

import type { ComputedRef } from 'vue';
import { computed, onMounted, ref, watch } from 'vue';
import type { LocationQueryRaw } from 'vue-router';
import { useRoute, useRouter } from 'vue-router';
import { AppHeader, LoadingSpinner, Search } from '@/features/ui/components';
import { vueBarcodePlugin } from '@/features/barcode';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import { useOnlineStatus } from '@/features/orders';
import type { Cancellable } from '@/utils/types';
import { debounce } from '@/utils/helpers/debounce';
import { $t } from '@/i18n';
import { useProductSearch } from '@/features/product-search/composables';
import { ProductSearchCard } from '@/features/product-search/components';
import { getStringQueryFromRoute } from '@/utils/helpers/getStringQueryFromRoute';
import type { Product } from '@/features/products/entities';
import { usePerformanceTracker } from '@/features/performance-tracker';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductSearch',
  setup(__props) {

const productSearchRef = ref<null | typeof Search>(null);
const route = useRoute();
const router = useRouter();
const { products, searchProducts } = useProductSearch();
const { stopTracking } = usePerformanceTracker();
const returnUrl: string = route.query['return-url'] as string;

const redirect = (query: LocationQueryRaw) => {
  if (returnUrl) {
    void router.push({ path: returnUrl, query });
  }
};

// Barcode scan

const barcodeService = vueBarcodePlugin.get();

barcodeService.onBarcode({
  next(barcode: string) {
    redirect({ barcode });
  },
});

// Offline notification

const { isOnline } = useOnlineStatus();
let showNotification: Cancellable;

const visibleOfflineToast = (): void => {
  if (isOnline.value) {
    showNotification?.cancel();
  } else {
    showNotification = notificationPlugin.get().show({
      text: $t('common.device-offline.text'),
      type: NotificationType.Error,
      duration: -1,
    });
  }
};

watch(
  isOnline,
  () => {
    visibleOfflineToast();
  },
  { immediate: true },
);

// Product search

const getFilteredSkus = () => {
  const filteredSkusString = getStringQueryFromRoute(route, 'filtered-skus');
  return filteredSkusString ? filteredSkusString.split(',') : [];
};

const filteredSkus = getFilteredSkus();
const searchPlaceholder = getStringQueryFromRoute(route, 'placeholder-text');
const categorySlugText = getStringQueryFromRoute(route, 'category-slug-text');

const search = ref('');
const isSearchActive = ref(false);

const activatedSearch = (isActive: boolean) => {
  isSearchActive.value = isActive;
};

const showBarcodeSearchIcon = computed(
  () => isSearchActive.value && (search.value === null || !search.value.length),
);

const searchProductsWithLoadingState = (
  query: string,
): Promise<Product[] | void> => {
  searchLoading.value = true;
  const searchPromise = searchProducts(query, categorySlugText, filteredSkus);
  searchPromise.finally(() => {
    searchLoading.value = false;
  });
  return searchPromise;
};

const searchForProducts = (searchQuery: string): void => {
  const productSearchQuery = hasLongEnoughSearchQuery.value ? searchQuery : '';
  void searchProductsWithLoadingState(productSearchQuery);
};
const debouncedSearchForProducts = debounce(searchForProducts, 600);

watch(search, (searchQuery) => {
  debouncedSearchForProducts(searchQuery);
});

/**
 * Indicates whether the search query currently entered by the user is long enough to perform a search
 */
const hasLongEnoughSearchQuery: ComputedRef<boolean> = computed(
  () => search.value !== null && search.value.length >= 2,
);
const searchLoading = ref(false);
const isNoResultsTextVisible: ComputedRef<boolean> = computed(
  () => !searchLoading.value && !products.value.length,
);
const isSearchLoadingSpinnerVisible: ComputedRef<boolean> = computed(
  // Only show the spinner if there are no products yet and search is currently loading
  // The first condition prevents the UI jumping around once some results are already visible
  () => !products.value.length && searchLoading.value,
);

onMounted(async () => {
  if (isOnline.value) {
    if (productSearchRef.value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      productSearchRef.value.toggleSearch();
    }
    await searchProductsWithLoadingState('');
  }

  stopTracking(
    `replacement-to-product-search-${
      getStringQueryFromRoute(route, 'filtered-skus') ?? ''
    }`,
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(AppHeader), {
      "border-bottom": "",
      fixed: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Search), {
          modelValue: search.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
          ref_key: "productSearchRef",
          ref: productSearchRef,
          searchTitle: _unref(searchPlaceholder),
          placeholderTitle: _unref(searchPlaceholder),
          showBarcodeBlock: showBarcodeSearchIcon.value,
          onSearchActivated: activatedSearch,
          backTo: _unref(returnUrl),
          isProfileExist: false,
          isBackExist: ""
        }, null, 8, ["modelValue", "searchTitle", "placeholderTitle", "showBarcodeBlock", "backTo"])
      ]),
      _: 1
    }),
    (_unref(isOnline))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref($t)('pages.product-search.results.text', {
            productsLength: _unref(products).length,
          })), 1),
          (isSearchLoadingSpinnerVisible.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_unref(LoadingSpinner), { isInline: true })
              ]))
            : _createCommentVNode("", true),
          (isNoResultsTextVisible.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", null, _toDisplayString(hasLongEnoughSearchQuery.value
              ? _unref($t)('pages.product-search.no-results.text', {
                  search: search.value,
                })
              : _unref($t)('pages.product-search.no-results.text-without-search')), 1),
                (hasLongEnoughSearchQuery.value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_unref($t)('pages.product-search.no-results.hint')), 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(products), (product) => {
            return (_openBlock(), _createBlock(_unref(ProductSearchCard), {
              key: product.sku,
              name: product.productName,
              image: product.image || '',
              sku: product.sku,
              barcodes: product.barcodes,
              onOnAction: _cache[1] || (_cache[1] = ($event: any) => (redirect({ sku: $event })))
            }, null, 8, ["name", "image", "sku", "barcodes"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_unref($t)('pages.product-search.search-not-available.text')), 1),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_unref($t)('common.device-offline.text')), 1)
        ]))
  ]))
}
}

})