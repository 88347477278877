import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "counter__item" }
const _hoisted_2 = ["data-e2e"]
const _hoisted_3 = ["data-e2e"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "counter__block_bordered"
}
const _hoisted_6 = { class: "counter__item" }
const _hoisted_7 = ["data-e2e"]
const _hoisted_8 = ["data-e2e"]
const _hoisted_9 = { key: 0 }

import { roundValue } from '@/utils/helpers/roundValue';
import { $t } from '@/i18n';
import { unitToDisplay } from '@/utils/helpers/unitPluralHandler';


export default /*@__PURE__*/_defineComponent({
  __name: 'WeightBlock',
  props: {
    quantity: { default: 0 },
    quantityOriginal: { default: 0 },
    amount: { default: 0 },
    amountOriginal: { default: 0 },
    sku: {},
    countWeight: { type: Boolean, default: true },
    left: { type: Boolean, default: false },
    unit: {},
    showOriginal: { type: Boolean, default: true },
    additionalWeightAllowed: { type: Boolean, default: false },
    isOverpickingThresholdReached: { type: Boolean, default: false },
    hideQuantityOriginal: { type: Boolean, default: false },
    isReplacementThresholdReached: { type: Boolean, default: false }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'counter__left-position': _ctx.left }, "counter"])
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_1, [
        _createElementVNode("span", {
          class: _normalizeClass({
            counter__error:
              _ctx.additionalWeightAllowed ||
              _ctx.isOverpickingThresholdReached ||
              _ctx.isReplacementThresholdReached,
          }),
          "data-e2e": `pickup-amount-label-${_ctx.sku}`
        }, _toDisplayString(_unref(roundValue)(_ctx.amount)), 11, _hoisted_2)
      ]),
      _createElementVNode("p", {
        "data-e2e": `pickup-amount-original-label-${_ctx.sku}`
      }, [
        (_ctx.showOriginal)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref($t)('components.card-row-floating-footer-with-weight.of.text')) + " " + _toDisplayString(_unref(roundValue)(_ctx.amountOriginal)), 1))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_unref(unitToDisplay)(_ctx.unit, _unref(roundValue)(_ctx.amountOriginal))), 1)
      ], 8, _hoisted_3)
    ]),
    (_ctx.countWeight)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, [
            _createElementVNode("span", {
              "data-e2e": `pickup-quantity-label-${_ctx.sku}`,
              class: "counter__item"
            }, _toDisplayString(_unref(roundValue)(_ctx.quantity)), 9, _hoisted_7)
          ]),
          _createElementVNode("p", {
            "data-e2e": `pickup-quantity-original-label-${_ctx.sku}`
          }, [
            (!_ctx.hideQuantityOriginal && _ctx.showOriginal)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref($t)('components.card-row-floating-footer-with-weight.of.text')) + " " + _toDisplayString(_unref(roundValue)(_ctx.quantityOriginal)), 1))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_unref($t)('components.weight-block.pcs.text')), 1)
          ], 8, _hoisted_8)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})