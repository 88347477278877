import type { ImageCachingQueueServiceImplementation } from '@/features/imageCachingQueue/services';
import { LoadOrderProductsPlugin } from '@/features/orders/plugins/load-order-products';
import { NormalizeOrderPlugin } from '@/features/orders/plugins/normalize-order';
import type { OrderParsePluginDto } from '@/features/orders/types';
import type { PipelinePlugin } from '@ads/plugin-pipeline';

/**
 * Get the products list related to the order
 * Cache this images
 **/
export class CacheProductImagesPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  constructor(
    private imageCachingService: ImageCachingQueueServiceImplementation,
  ) {}

  public readonly requiredPlugins = [
    LoadOrderProductsPlugin,
    NormalizeOrderPlugin,
  ];

  public execute(dataTransferObject: OrderParsePluginDto): OrderParsePluginDto {
    const products = dataTransferObject.products || [];
    products.map((product) => {
      if (product.image) {
        this.imageCachingService.addToCachingQueue(product.image);
      }
    });

    return dataTransferObject;
  }
}
