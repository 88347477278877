import type { ScannableDeliveryUnit } from '../types';

export function filterScannedDeliveryUnits(
  deliveryUnits: ScannableDeliveryUnit[],
): ScannableDeliveryUnit[] {
  return deliveryUnits
    .filter((unit) => unit.scanned)
    .sort(function (a, b) {
      if (!b.position) return -1;
      return 0;
    });
}
