import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { OrderEventBusEventsMap } from '@/features/orders';
import type { EventBus } from '@/features/core/event-bus';
import type { RestageOrderPluginDto } from '../types';

/**
 * Emits HandoverAbortedEvent
 **/
export class EmitHandoverAbortedBusEventPlugin
  implements PipelinePlugin<RestageOrderPluginDto>
{
  constructor(private eventBus: EventBus) {}

  public execute(
    dataTransferObject: RestageOrderPluginDto,
  ): RestageOrderPluginDto {
    this.eventBus.emit(OrderEventBusEventsMap['handover-aborted']);

    return dataTransferObject;
  }
}
