import { i18n } from '../index';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

export const useCurrentLocale = (): ComputedRef<string> => {
  return computed(() => {
    if (i18n.global.availableLocales.includes(i18n.global.locale.value)) {
      return i18n.global.locale.value;
    }
    const twoLetterLocale = i18n.global.locale.value.slice(0, 2);
    if (i18n.global.availableLocales.includes(twoLetterLocale)) {
      return twoLetterLocale;
    }
    return 'en';
  });
};
