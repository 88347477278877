<template>
  <div></div>
  <div class="footer__body">
    <WeightBlock
      :amount="amount"
      :amount-original="amountOriginal"
      :class="{ 'counter--labels': isControlsDisabled }"
      :count-weight="orderItem.product?.rwpType === 1"
      :hide-quantity-original="hideQuantityOriginal"
      :quantity="quantity"
      :quantity-original="quantityOriginal"
      :show-original="showOriginal"
      :sku="orderItem.product?.sku"
      :unit="orderItem.unit"
      left
    />
  </div>
  <Button
    v-show="isVisibleEditButton"
    :data-e2e="`pickup-submit-button-${orderItem.product?.sku}`"
    btn-outline
    class="footer__end icon"
    icon
    @click="handleEditButton"
  >
    <EditIcon />
  </Button>
  <Button
    v-show="isDeleted"
    :data-e2e="`pickup-restore-button-${orderItem.product?.sku}`"
    :disabled="!isRestorable"
    btn-outline
    class="restore-button"
    @click="$emit(Events.Restore, orderItem.product?.sku)"
  >
    {{
      $t('components.card-row-floating-footer-with-weight.restore-item.text')
    }}
  </Button>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import type { OrderItem } from '@/features/orders/types';
import { Button, EditIcon, WeightBlock } from '@/features/ui';

enum Events {
  Restore = 'restore',
  Edit = 'edit',
}

export default defineComponent({
  name: 'CardRowFloatingFooterWithWeight',
  props: {
    orderItem: {
      type: Object as PropType<OrderItem>,
      default: () => ({}),
    },
    isControlsDisabled: {
      type: Boolean,
      default: false,
    },
    hideControls: {
      type: Boolean,
      default: false,
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
    quantity: {
      type: Number,
      default: 0,
    },
    quantityOriginal: {
      type: Number,
      default: 0,
    },
    amount: {
      type: Number,
      default: 0,
    },
    amountOriginal: {
      type: Number,
      default: 0,
    },
    isRestorable: {
      type: Boolean,
      default: true,
    },
    currentTab: String,
    showOriginal: {
      type: Boolean,
      default: true,
    },
    hideQuantityOriginal: {
      type: Boolean,
      default: false,
    },
  },
  emits: Object.values(Events),
  components: {
    WeightBlock,
    EditIcon,
    Button,
  },
  setup(props, { emit }) {
    const handleEditButton = () => {
      emit(Events.Edit, props.orderItem.id);
    };

    const isVisibleEditButton = computed(
      () => !props.isControlsDisabled && !props.hideControls,
    );

    return {
      handleEditButton,
      isVisibleEditButton,
      Events,
    };
  },
});
</script>

<style lang="scss" scoped>
.footer {
  &__end {
    justify-self: end;
  }
}

.restore-button {
  margin-top: 16px;
  grid-column: 1/-1;
}

.icon {
  padding: 0;
  width: 36px;
  min-width: unset;
  height: 36px;
}
</style>
