import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { loggerServicePlugin } from '@/features/core/logger';
import * as QRCode from 'qrcode';
import { onMounted, ref, toRefs } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'QRcode',
  props: {
    data: {}
  },
  emits: ['loaded'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const { data } = toRefs(props);
const QRCodeImage = ref<HTMLElement>();

onMounted(async () => {
  try {
    /* istanbul ignore next */
    if (!QRCodeImage.value) {
      throw new Error('QRcode image was not found');
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    const url = (await QRCode.toDataURL(data.value)) as string;
    QRCodeImage.value.setAttribute('src', url);
  } catch (error: unknown) {
    loggerServicePlugin.get().error(error as Error);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", {
    class: "qr-code",
    ref_key: "QRCodeImage",
    ref: QRCodeImage,
    onLoad: _cache[0] || (_cache[0] = ($event: any) => (emit('loaded')))
  }, null, 544))
}
}

})