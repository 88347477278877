import {
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { AppServiceWorkerService } from './appServiceWorker';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import type { Options } from './types';
import { syncSchedulerExecutorAdapterPlugin } from '@/features/sync-scheduler';

export const appServiceWorkerServicePlugin =
  ProviderPluginFactory.create<AppServiceWorkerService>({
    key: Symbol('appServiceWorkerService'),
    defaultFactory: {
      create: (app, options: Options) => {
        return new AppServiceWorkerService(
          eventBusServicePlugin.get(),
          options.skipSW
            ? syncSchedulerExecutorAdapterPlugin.get()
            : navigator.serviceWorker,
        );
      },
    },
  });

export const serviceWorkerPlugin = MultiPluginFactory.with({
  appServiceWorkerServicePlugin,
});
