import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

import { ref, onMounted, watch } from 'vue';
import Hammer from 'hammerjs';
import { TransitionSpinner } from '@/features/ui/icons';

const threshold = 160; // define the distance the user needs to pull down to trigger the refresh; in px

export default /*@__PURE__*/_defineComponent({
  __name: 'PullToRefresh',
  props: {
    trigger: {},
    loading: { type: Boolean }
  },
  emits: ['refresh'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;

const container = ref<HTMLElement | null>(null);
const isPanning = ref(false);
const isRefreshing = ref(false);
const isAnimatingBack = ref(false);
const startY = ref(0);
const deltaY = ref(0);

const refresh = async () => {
  if (isRefreshing.value) return;
  isRefreshing.value = true;
  await new Promise((resolve) => setTimeout(resolve, 500));
  emit('refresh');
  await animateBack();
};

watch(
  () => deltaY.value,
  (newVal) => {
    if (newVal >= threshold && !isRefreshing.value && !isAnimatingBack.value) {
      void refresh();
    }
  },
);

const animateBack = () => {
  return new Promise((resolve) => {
    isAnimatingBack.value = true;
    startY.value = 0;
    const intervalId = setInterval(() => {
      if (deltaY.value === 0) {
        clearInterval(intervalId);
        isAnimatingBack.value = false;
        isRefreshing.value = false;
        resolve(true);
      } else {
        deltaY.value = deltaY.value - 1;
      }
    }, 8);
  });
};

const atTopOfPage = () => {
  return window.scrollY === 0;
};

const initPullToRefresh = () => {
  container.value = document.querySelector(props.trigger);
  if (!container.value) return;

  const shouldHandlePanEvents = (): boolean =>
    atTopOfPage() && !isRefreshing.value;

  const shouldHandlePanMove = (): boolean =>
    isPanning.value && shouldHandlePanEvents();

  const shouldHandlePanStart = (): boolean =>
    shouldHandlePanEvents() &&
    // If any popup is open, prevent handling move events
    // Prevents refreshing under popups while scrolling their contents
    // Relies on the dialog element being removed from DOM via v-if when closed
    document.querySelector('dialog') === null;

  const hammertime = new Hammer(container.value, {
    touchAction: 'auto',
    inputClass: Hammer.TouchInput,
  });
  hammertime.get('pan').set({ direction: Hammer.DIRECTION_DOWN });

  hammertime.on('panstart', function (ev) {
    if (!shouldHandlePanStart()) return;
    if (deltaY.value === 0 && atTopOfPage() && window.scrollY === 0) {
      startY.value = ev.center.y;
      isPanning.value = true;
    }
  });

  hammertime.on('panmove', function (ev) {
    if (!shouldHandlePanMove()) return;
    if (deltaY.value > 0 || (atTopOfPage() && ev.center.y > startY.value)) {
      deltaY.value = ev.deltaY;
    }
  });

  hammertime.on('panend', function () {
    if (!isPanning.value) return;
    isPanning.value = false;
    if (deltaY.value > threshold) {
      void refresh();
    } else {
      void animateBack();
    }
  });
};

onMounted(() => {
  initPullToRefresh();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
      paddingBottom: `${deltaY.value}px`,
    }),
    class: "spacing"
  }, [
    (isRefreshing.value || _ctx.loading)
      ? (_openBlock(), _createBlock(_unref(TransitionSpinner), { key: 0 }))
      : _createCommentVNode("", true)
  ], 4))
}
}

})