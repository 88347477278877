import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "item" }
const _hoisted_2 = ["data-e2e"]
const _hoisted_3 = ["data-e2e"]


export default /*@__PURE__*/_defineComponent({
  __name: 'InfoMenuItem',
  props: {
  dataE2EPrefix: {
    type: String,
    default: 'info-menu',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("dl", _hoisted_1, [
    _createElementVNode("dt", {
      class: "item-label",
      "data-e2e": `${__props.dataE2EPrefix}-label`
    }, [
      _renderSlot(_ctx.$slots, "label")
    ], 8, _hoisted_2),
    _createElementVNode("dd", {
      class: "item-value",
      "data-e2e": `${__props.dataE2EPrefix}-value`
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_3)
  ]))
}
}

})