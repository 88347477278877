import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "group"
}
const _hoisted_3 = { class: "group__title" }
const _hoisted_4 = { class: "replacement__remaining-weight" }
const _hoisted_5 = {
  key: 0,
  "data-e2e": "replacement-remaining-weight"
}
const _hoisted_6 = ["data-e2e"]
const _hoisted_7 = { class: "group" }
const _hoisted_8 = { class: "group__title" }
const _hoisted_9 = {
  key: 2,
  class: "error",
  "data-e2e": "replacement-candidate-quantity-exceeded"
}
const _hoisted_10 = { class: "footer" }
const _hoisted_11 = {
  key: 2,
  class: "loading-bg"
}

import type { ComputedRef, Ref } from 'vue';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import {
  AppHeader,
  Button,
  CardRowFloating,
  CardRowFloatingFooterDefault,
  CardRowFloatingWithWeight,
  CompletedOverlay,
  ErrorIcon,
  Search,
  TabVariants,
  TransitionSpinner,
  WeightPopup,
} from '@/features/ui';
import { useOrder } from '@/features/picking';
import type { OrderItem } from '@/features/orders';
import {
  isOrderItemRandomWeight,
  OrderEventNames,
  OrderItemStatus,
  ordersServicePlugin,
} from '@/features/orders';
import {
  BarcodeNotFoundError,
  barcodeServicePlugin,
  ProductPickingCompletedError,
  vueBarcodePlugin,
} from '@/features/barcode';
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { Product } from '@/features/products';
import { errorPlugin } from '@/features/core/errors';
import { MAX_PRODUCT_QUANTITY } from '@/features/handover/constants';
import { BarcodeHasLimit } from '@/features/barcode/errors';
import { authServicePlugin } from '@/features/core/auth';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import { $t } from '@/i18n';
import { useDynamicDialog } from '@/features/ui/composables/useDynamicDialog';
import { ScanHint } from '@/features/replacement/components';
import { SubstituteRestrictionError } from '@/features/replacement/errors';
import {
  deviceFeedbackServicePlugin,
  playlistActionRequiredBarcodeScan,
  playlistSuccessBarcodeScan,
  playlistSuccessBarcodeScanThenActionRequired,
} from '@/features/device-feedback';
import { roundValue } from '@/utils/helpers/roundValue';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';
import { unitToDisplay } from '@/utils/helpers/unitPluralHandler';


export default /*@__PURE__*/_defineComponent({
  __name: 'Replacement',
  props: {
  id: {
    type: String,
    default: '',
  },
  orderItemId: {
    type: String,
    default: '',
  },
},
  setup(__props) {

const props = __props;

const { confirm } = useDynamicDialog();

const {
  order,
  loadOrder,
  onIncrease,
  onDecrease,
  onEditWeight,
  onChangeWeightBlock,
  redirectIfOrderNotPicking,
  selectedId,
  visibleWeightPopup,
} = useOrder();
const { startTracking, stopTracking } = usePerformanceTracker();

const RANDOM_WEIGHT_TYPES = [1, 2, 3];

const isSelectedIdRandomWeightType = (selectedId: string) => {
  const selectedItem = replacements.value.find(
    (item) => item.id === selectedId,
  );

  return selectedItem && isRandomWeightType(selectedItem.product);
};

const isRandomWeightType = (product: Product): boolean =>
  RANDOM_WEIGHT_TYPES.includes(product.rwpType);

const isWeightCard: ComputedRef<(item: OrderItem) => boolean> = computed(
  () => (item: OrderItem) => isRandomWeightType(item.product),
);

const currentOrderItem: ComputedRef<OrderItem | undefined> = computed(() =>
  order.value?.items.find((orderItem) => orderItem.id === props.orderItemId),
);

const limitReplacement: Ref<number> = ref(1);
const viewScanHint: ComputedRef<boolean> = computed(() => {
  return replacements.value?.length < limitReplacement.value;
});

const isQuantityExceeded = computed(() =>
  order.value?.items.some(
    (item) =>
      item.product.rwpType === 0 && item.quantity > item.quantityOriginal,
  ),
);
const disableSubmitButton: ComputedRef<boolean> = computed(() => {
  return productRemnant.value < 0 || isQuantityExceeded.value;
});

const barcodeService = vueBarcodePlugin.get();
const entityRepository = entityRepositoryPlugin.get();

let merchantRef: string | undefined;
const replacements: ComputedRef<OrderItem[]> = computed(
  () =>
    order.value?.items.filter(
      (orderItem) => orderItem.originalId === currentOrderItem.value?.id,
    ) || [],
);

const replacementItemsSkus: ComputedRef<string[]> = computed(() =>
  replacements.value.map((replacement: OrderItem) => replacement.product.sku),
);

const remainingWeight: ComputedRef<number> = computed(() =>
  roundValue(
    calculateReplacementRemainingValue(
      currentOrderItem.value.amountOriginal,
      currentOrderItem.value.amount,
    ),
  ),
);

const router = useRouter();
onMounted(async () => {
  await loadOrder(props.id);
  stopTracking(`picking-order-replacement-${props.id}-${props.orderItemId}`);
  await redirectIfOrderNotPicking(order.value);
  merchantRef = await authServicePlugin.get().merchantReference();
  if (router.currentRoute.value.query) {
    const code =
      router.currentRoute.value.query.sku ??
      router.currentRoute.value.query.barcode;
    if (code) {
      await addReplacementItem(
        code as string,
        Boolean(router.currentRoute.value.query.barcode),
        Boolean(router.currentRoute.value.query.barcode),
      );
    }
  }
});

onBeforeUnmount(async () => {
  if (router.currentRoute.value.name === 'picking-order' && order.value) {
    order.value.items = order.value.items.filter(
      (item) =>
        !(
          item.status === OrderItemStatus.acknowledged &&
          item.originalId === currentOrderItem.value?.id
        ),
    );
    await ordersServicePlugin.get().saveOrder(order.value);
  }
});

const getProductByCode = async (
  code: string,
  isBarcode: boolean,
): Promise<Product | undefined> => {
  const filter = isBarcode
    ? {
        barcodes: { anyOf: [code] },
      }
    : {
        sku: { equals: code },
      };
  const productRef = await entityRepository.getAll(Product, {
    filter,
  });

  return productRef.value[0];
};

const isCurrentProductHasBarcode = (barcode: string) => {
  return currentOrderItem.value?.product.barcodes?.includes(barcode);
};

const calculateReplacementRemainingValue = (
  original: number,
  picked: number,
): number => Math.max(original - picked, 0);

const generateReplacementItem = (
  product: Product,
  increaseQuantity = true,
): OrderItem => {
  const replacementItem: OrderItem = {
    id: createProductId(product),
    quantity: increaseQuantity && product.rwpType !== 1 ? 1 : 0,
    quantityOriginal: MAX_PRODUCT_QUANTITY,
    amount: 0,
    amountOriginal: 0,
    unit: product.unit,
    status: OrderItemStatus.acknowledged,
    product: product,
    isReplaceable: false,
    cartNote: '',
    originalId: currentOrderItem.value?.id,
  };

  if (!currentOrderItem.value) {
    return replacementItem;
  }

  if (
    isRandomWeightType(currentOrderItem.value.product) &&
    isRandomWeightType(product)
  ) {
    replacementItem.quantityOriginal = calculateReplacementRemainingValue(
      currentOrderItem.value.quantityOriginal,
      currentOrderItem.value.quantity,
    );

    replacementItem.amountOriginal = calculateReplacementRemainingValue(
      currentOrderItem.value.amountOriginal,
      currentOrderItem.value.amount,
    );
  }

  return replacementItem;
};

const addReplacementItem = async (
  code: string,
  withBarcode = true,
  increaseQuantity = true,
): Promise<boolean> => {
  if (withBarcode && isCurrentProductHasBarcode(code)) {
    increaseOrderItemQuantity(currentOrderItem.value?.product.sku, false);
    return true;
  }

  if (!withBarcode && currentOrderItem.value?.product.sku === code) {
    increaseOrderItemQuantity(currentOrderItem.value?.product.sku, false);
    return true;
  }

  const replaced = replacements.value?.find((orderItem) =>
    withBarcode
      ? orderItem.product.barcodes?.includes(code)
      : orderItem.product.sku === code,
  );

  if (replaced) {
    increaseOrderItemQuantity(replaced.product.sku, true);
    return true;
  }

  const product: Product | undefined = await getProductByCode(
    code,
    withBarcode,
  );

  if (!product) {
    errorPlugin.get().handle(new BarcodeNotFoundError({ barcodeValue: code }));

    return false;
  }
  if (!viewScanHint.value) {
    errorPlugin
      .get()
      .handle(new BarcodeHasLimit({ limit: limitReplacement.value }));

    return false;
  }

  const orderItem: OrderItem = generateReplacementItem(
    product,
    increaseQuantity,
  );

  order.value?.items.push(orderItem);

  return true;
};

const loading = ref(false);
barcodeService.onBarcode({
  async next(barcode: string) {
    loading.value = true;
    const [resultForItem, resultForReplacement, resultForProduct] =
      await Promise.all([
        barcodeServicePlugin
          .get()
          .findItemByBarcode([currentOrderItem.value], barcode),
        barcodeServicePlugin
          .get()
          .findItemByBarcode(replacements.value, barcode),
        barcodeServicePlugin.get().searchForProductByBarcode(barcode),
      ]);

    let addingReplacementItemResult = false;

    //If the barcode doesn't exist
    if (
      !resultForItem.item &&
      !resultForReplacement.item &&
      !resultForProduct.product
    ) {
      loading.value = false;
      errorPlugin
        .get()
        .handle(new BarcodeNotFoundError({ barcodeValue: barcode }));
      await deviceFeedbackServicePlugin
        .get()
        .trigger(playlistSuccessBarcodeScanThenActionRequired);
      return;
    }

    const isCurrentOrderAlcoholAllowed =
      order.value?.ageVerification?.requiredAge &&
      order.value?.ageVerification?.requiredAge > 0;

    //If the order doesn't contain age restricted product, then the replacement cant be "age restricted" neither.
    if (
      !isCurrentOrderAlcoholAllowed &&
      resultForProduct.product?.ageRestricted &&
      resultForProduct.product?.ageRestricted > 0
    ) {
      loading.value = false;
      errorPlugin.get().handle(new SubstituteRestrictionError());

      await deviceFeedbackServicePlugin
        .get()
        .trigger(playlistActionRequiredBarcodeScan);
      return;
    }

    //Increasing the original item (not replacement) and it reached the necessary quantity
    if (
      resultForItem.item &&
      currentOrderItem.value?.quantity ===
        currentOrderItem.value?.quantityOriginal
    ) {
      loading.value = false;
      errorPlugin.get().handle(new ProductPickingCompletedError());

      await deviceFeedbackServicePlugin
        .get()
        .trigger(playlistActionRequiredBarcodeScan);
      return;
    }

    //Increasing the original item (not replacement)
    if (resultForItem.item) {
      loading.value = false;
      addingReplacementItemResult = await addReplacementItem(
        resultForItem.item?.product.sku,
        false,
      );

      if (resultForItem.item?.product.rwpType) {
        onEditWeight(resultForItem.item.id, barcode, resultForItem.weight);
        updateReplacementsQuantities(resultForItem.item);
      }

      await deviceFeedbackServicePlugin
        .get()
        .trigger(
          addingReplacementItemResult
            ? playlistSuccessBarcodeScan
            : playlistActionRequiredBarcodeScan,
        );
      return;
    }

    //Increasing the earlier added replacement product
    if (resultForReplacement.item) {
      loading.value = false;
      addingReplacementItemResult = await addReplacementItem(
        resultForReplacement.item?.product.sku,
        false,
      );
      if (resultForReplacement.item.product.rwpType) {
        onEditWeight(
          resultForReplacement.item.id,
          barcode,
          resultForReplacement.weight,
        );
      }

      await deviceFeedbackServicePlugin
        .get()
        .trigger(
          addingReplacementItemResult
            ? playlistSuccessBarcodeScan
            : playlistActionRequiredBarcodeScan,
        );
      return;
    }

    //Adding the new replacement
    if (resultForProduct.product) {
      addingReplacementItemResult = await addReplacementItem(
        resultForProduct.product.sku,
        false,
      );

      if (resultForProduct.product.rwpType === 1) {
        onEditWeight(
          createProductId(resultForProduct.product),
          barcode,
          resultForProduct.weight,
        );
      }

      loading.value = false;
      await deviceFeedbackServicePlugin
        .get()
        .trigger(
          addingReplacementItemResult
            ? playlistSuccessBarcodeScan
            : playlistActionRequiredBarcodeScan,
        );
    }
  },
});

const createProductId = (product: Product): string => {
  return `${product.sku}_${product.sku}_${String(merchantRef)}`;
};

const updateReplacementsQuantities = (selectedItem: OrderItem): void => {
  if (!selectedItem || !currentOrderItem.value) {
    return;
  }

  if (
    currentOrderItem.value.id !== selectedItem.id ||
    !isRandomWeightType(selectedItem.product)
  ) {
    return;
  }

  const replacementsLength = replacements.value.length;
  for (let i = 0; i < replacementsLength; i++) {
    if (!isRandomWeightType(replacements.value[i].product)) {
      continue;
    }

    replacements.value[i].quantityOriginal = calculateReplacementRemainingValue(
      currentOrderItem.value.quantityOriginal,
      currentOrderItem.value.quantity,
    );

    replacements.value[i].amountOriginal = calculateReplacementRemainingValue(
      currentOrderItem.value.amountOriginal,
      currentOrderItem.value.amount,
    );
  }
};

const onEditWeightBlock = async (selectedItem: OrderItem) => {
  await onChangeWeightBlock(selectedItem);
  updateReplacementsQuantities(selectedItem);
  closeWeightBlock();
};

const closeWeightBlock = () => {
  visibleWeightPopup.value = false;
};

const hasEmptyReplacements = (): boolean => {
  return replacements.value.some(
    (replacement: OrderItem) => replacement.quantity === 0,
  );
};

const completedOverlayVisible: Ref<boolean> = ref(false);

const showCompletedOverlay = (onCompleted: () => void): void => {
  completedOverlayVisible.value = true;

  setTimeout(() => {
    completedOverlayVisible.value = false;
    onCompleted();
  }, 900);
};

const onSubmit = async () => {
  if (!(order.value && currentOrderItem.value)) {
    return;
  }

  if (!replacements.value.length) {
    const confirmed = await showNoSubstitutionDialog();
    if (!confirmed) {
      return;
    }
  }

  if (hasEmptyReplacements()) {
    const confirmed = await showSubstitutionQuantityZeroDialog();
    if (!confirmed) {
      return;
    }
  }

  order.value.items = order.value.items.filter(
    (orderItem) =>
      !(
        orderItem.originalId &&
        (isOrderItemRandomWeight(orderItem)
          ? !orderItem.amount
          : !orderItem.quantity)
      ),
  );
  const replacementList = replacements.value.filter((item) => item.quantity);
  if (
    (isOrderItemRandomWeight(currentOrderItem.value)
      ? currentOrderItem.value.amount >= currentOrderItem.value.amountOriginal
      : currentOrderItem.value.quantity >=
        currentOrderItem.value.quantityOriginal) &&
    replacementList.length
  ) {
    showItemAmountReachedDialog();
    return;
  }
  if (currentOrderItem.value) {
    currentOrderItem.value.status = OrderItemStatus.picked;
  }
  if (order.value) {
    order.value?.items.forEach((item) => {
      if (item.quantity > 0 && item.originalId === currentOrderItem.value.id) {
        item.status = OrderItemStatus.picked;
      }
      delete item.replacements;
    });
  }

  const pickedCount = order.value.items.filter(
    (item) => item.status === OrderItemStatus.picked,
  ).length;

  const redirect =
    pickedCount === order.value.items.length
      ? {
          name: 'complete-picking',
          params: { id: order.value.id },
        }
      : {
          name: 'picking-order',
          params: { id: order.value.id, tab: TabVariants.Changed },
        };
  await ordersServicePlugin
    .get()
    .trackEvent(order.value, OrderEventNames.item_handling_completed, {
      skipSaving: true,
      allowMultipleTracking: true,
    });

  await ordersServicePlugin.get().saveOrder(order.value);

  showCompletedOverlay(() => void router.push(redirect));
};

const handleUseSearch = async (): Promise<void> => {
  if (currentOrderItem.value) {
    const name = currentOrderItem.value.product.productName;
    const categoryUrlSlugText =
      currentOrderItem.value.product?.categoryUrlSlugText;
    const sku = currentOrderItem.value.product?.sku;
    const filteredSkus = [sku, ...replacementItemsSkus.value];
    startTracking(
      `replacement-to-product-search-${filteredSkus.join()}`,
      PerformanceThresholdEnum.SCREEN_TRANSITION,
    );
    await ordersServicePlugin.get().saveOrder(order.value);
    await router.push({
      name: 'product-search',
      query: {
        'placeholder-text': `Replace ${name}`,
        'return-url': router.currentRoute.value.fullPath,
        'category-slug-text': categoryUrlSlugText,
        'filtered-skus': filteredSkus.join(),
        'order-id': order.value.id,
      },
    });
  }
};

const removeOrderItem = (orderItemId: string) => {
  if (order.value?.items) {
    const index = order.value.items.findIndex(
      (item) => item.id === orderItemId && item.originalId,
    );
    if (index < 0) return;
    order.value.items.splice(index, 1);
  }
};

// #region product quantity check
const pickedQuantity = computed(
  () =>
    order.value?.items.reduce((acc, orderItem) => {
      if (orderItem.product.id === replacements.value[0].product.id) {
        acc += orderItem.quantity;
      }
      return acc;
    }, 0) ?? 0,
);
const productRemnant = computed(() => {
  if (
    !replacements.value[0] ||
    replacements.value[0].product.maxQuantity === null
  ) {
    return Number.POSITIVE_INFINITY;
  }
  return replacements.value[0].product.maxQuantity - pickedQuantity.value;
});

// NOTE: this is necessary for the case when the quantity is entered from the keyboard. In the opposite case we can't calculate quantity of product.
const handleUpdate = (orderItem: OrderItem, quantity: number) => {
  orderItem.quantity = quantity;
};

watch(productRemnant, (quantity) => {
  if (quantity === 0) {
    notificationPlugin.get().show({
      text: 'Restricted quantity reached',
      type: NotificationType.Info,
    });
  }
});
// #endregion

const showNoSubstitutionDialog = async () => {
  return await confirm({
    title: $t('components.replacement.no-substitution-dialog.title'),
    isTitleCentered: false,
    contentText:
      currentOrderItem.value && currentOrderItem.value.quantity
        ? String($t('components.replacement.no-substitution-dialog.content')) +
          String(
            $t('components.replacement.no-substitution-dialog.sub-content', {
              count: currentOrderItem.value.quantity,
            }),
          )
        : $t('components.replacement.no-substitution-dialog.content'),
    confirmText: $t(
      'components.replacement.no-substitution-dialog.confirm-button-text',
    ),
  });
};

const showSubstitutionQuantityZeroDialog = async () => {
  return await confirm({
    title: $t('components.replacement.substitution-quantity-zero.title'),
    isTitleCentered: false,
    contentText:
      currentOrderItem.value && currentOrderItem.value.quantity
        ? String(
            $t('components.replacement.substitution-quantity-zero.content'),
          ) +
          String(
            $t(
              'components.replacement.substitution-quantity-zero.sub-content',
              {
                count: currentOrderItem.value.quantity,
              },
            ),
          )
        : $t('components.replacement.substitution-quantity-zero.content'),
    confirmText: $t(
      'components.replacement.substitution-quantity-zero.confirm-button-text',
    ),
  });
};

const showItemAmountReachedDialog = () => {
  void confirm({
    title: $t('components.replacement.amount-reached-modal.title'),
    isTitleCentered: false,
    showOnlyConfirm: true,
    contentText: $t('components.replacement.amount-reached-modal.content'),
    confirmText: $t('components.replacement.amount-reached-modal.confirm'),
  });
};

const cards = ref<Record<string, InstanceType<typeof CardRowFloating>>>({});
const applyToCardRef = (sku: string) => {
  return (element?: InstanceType<typeof CardRowFloating>) => {
    if (!element) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    cards.value[sku] = element;
  };
};

const decreaseOrderItemQuantity = (sku: string, isReplacement: boolean) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const card = cards.value[sku];
  onDecrease(sku, isReplacement);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  card.highlightCard();
};

const increaseOrderItemQuantity = (sku: string, isReplacement: boolean) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const card = cards.value[sku];
  onIncrease(sku, isReplacement);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  card.highlightCard();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(order))
      ? (_openBlock(), _createBlock(_unref(AppHeader), {
          key: 0,
          class: "header",
          fixed: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(Search), {
              backTo: { name: 'picking-order', params: { id: _unref(order).id } },
              isBackExist: true,
              isProfileExist: false,
              searchTitle: 
        _unref($t)('components.search.replacement.title', {
          productName: currentOrderItem.value?.product.productName,
        })
      ,
              showBarcodeBlock: true
            }, null, 8, ["backTo", "searchTitle"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("body", _hoisted_1, [
      (currentOrderItem.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_unref($t)('components.replacement.original-item-title.text')), 1),
            (_openBlock(), _createBlock(_unref(CardRowFloating), {
              key: currentOrderItem.value.id,
              ref: applyToCardRef(currentOrderItem.value.product.sku),
              class: _normalizeClass(
          !isWeightCard.value(currentOrderItem.value)
            ? 'replacement-candidate'
            : 'replacement-candidate--weight'
        ),
              orderItem: currentOrderItem.value,
              quantityOriginal: currentOrderItem.value.quantityOriginal,
              "data-e2e": "replacement-candidate-card"
            }, {
              footer: _withCtx(() => [
                (isWeightCard.value(currentOrderItem.value))
                  ? (_openBlock(), _createBlock(_unref(CardRowFloatingWithWeight), {
                      key: 0,
                      amount: currentOrderItem.value.amount,
                      "amount-original": currentOrderItem.value.amountOriginal,
                      isControlsDisabled: false,
                      orderItem: currentOrderItem.value,
                      quantity: currentOrderItem.value.quantity,
                      quantityOriginal: currentOrderItem.value.quantityOriginal,
                      onEdit: _unref(onEditWeight)
                    }, null, 8, ["amount", "amount-original", "orderItem", "quantity", "quantityOriginal", "onEdit"]))
                  : (_openBlock(), _createBlock(_unref(CardRowFloatingFooterDefault), {
                      key: 1,
                      orderItem: currentOrderItem.value,
                      quantity: currentOrderItem.value.quantity,
                      quantityOriginal: currentOrderItem.value.quantityOriginal,
                      quantityOriginalFilteredByStatus: 
              currentOrderItem.value.quantityOriginal
            ,
                      onDecreaseTo: _cache[0] || (_cache[0] = ($event: any) => (
              decreaseOrderItemQuantity(currentOrderItem.value.product.sku, false)
            )),
                      onIncreaseTo: _cache[1] || (_cache[1] = ($event: any) => (
              increaseOrderItemQuantity(currentOrderItem.value.product.sku, false)
            )),
                      onUpdate: _cache[2] || (_cache[2] = (e) => handleUpdate(currentOrderItem.value, e.value))
                    }, null, 8, ["orderItem", "quantity", "quantityOriginal", "quantityOriginalFilteredByStatus"]))
              ]),
              _: 1
            }, 8, ["class", "orderItem", "quantityOriginal"])),
            _createElementVNode("div", _hoisted_4, [
              (remainingWeight.value > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref($t)('pages.weight-list.remaining-weight.text', {
              remainingWeight: remainingWeight.value,
              unit: _unref(unitToDisplay)(currentOrderItem.value.unit, remainingWeight.value),
            })), 1))
                : _createCommentVNode("", true)
            ]),
            (currentOrderItem.value.cartNote)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  "data-e2e": `item-customer-note-${currentOrderItem.value.product.sku}`,
                  class: "customer-note-text"
                }, [
                  _cache[3] || (_cache[3] = _createElementVNode("span", null, "Customer Note", -1)),
                  _createTextVNode(": " + _toDisplayString(currentOrderItem.value.cartNote), 1)
                ], 8, _hoisted_6))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", _hoisted_8, _toDisplayString(_unref($t)('components.replacement.title.text')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(replacements.value, (replacement) => {
          return (_openBlock(), _createBlock(_unref(CardRowFloating), {
            key: replacement.id,
            ref_for: true,
            ref: applyToCardRef(replacement.product.sku),
            canDelete: true,
            class: _normalizeClass(
          !isWeightCard.value(replacement)
            ? 'replacement-candidate'
            : 'replacement-candidate--weight'
        ),
            "data-e2e": `replacement-card-${replacement.id}`,
            orderItem: replacement,
            quantityOriginal: replacement.quantityOriginal,
            onReject: ($event: any) => (removeOrderItem(replacement.id))
          }, {
            footer: _withCtx(() => [
              (isWeightCard.value(replacement))
                ? (_openBlock(), _createBlock(_unref(CardRowFloatingWithWeight), {
                    key: 0,
                    amount: replacement.amount,
                    "amount-original": replacement.amountOriginal,
                    "hide-quantity-original": true,
                    isControlsDisabled: false,
                    orderItem: replacement,
                    quantity: replacement.quantity,
                    quantityOriginal: replacement.quantityOriginal,
                    onEdit: _unref(onEditWeight)
                  }, null, 8, ["amount", "amount-original", "orderItem", "quantity", "quantityOriginal", "onEdit"]))
                : (_openBlock(), _createBlock(_unref(CardRowFloatingFooterDefault), {
                    key: 1,
                    error: productRemnant.value <= 0,
                    noQuantityOriginal: true,
                    orderItem: replacement,
                    quantity: replacement.quantity,
                    quantityOriginal: replacement.quantityOriginal,
                    quantityOriginalFilteredByStatus: replacement.quantityOriginal,
                    onDecreaseTo: ($event: any) => (
              decreaseOrderItemQuantity(replacement.product.sku, true)
            ),
                    onIncreaseTo: ($event: any) => (
              increaseOrderItemQuantity(replacement.product.sku, true)
            ),
                    onUpdate: (e) => handleUpdate(replacement, e.value)
                  }, null, 8, ["error", "orderItem", "quantity", "quantityOriginal", "quantityOriginalFilteredByStatus", "onDecreaseTo", "onIncreaseTo", "onUpdate"])),
              (productRemnant.value < 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                    _createVNode(_unref(ErrorIcon)),
                    _cache[4] || (_cache[4] = _createTextVNode("Restricted quantity exceeded "))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["class", "data-e2e", "orderItem", "quantityOriginal", "onReject"]))
        }), 128)),
        (_unref(visibleWeightPopup))
          ? (_openBlock(), _createBlock(_unref(WeightPopup), {
              key: 0,
              id: _unref(selectedId),
              orderItems: [currentOrderItem.value, ...replacements.value],
              showOriginal: currentOrderItem.value.id === _unref(selectedId),
              showRemainingWeight: isSelectedIdRandomWeightType(_unref(selectedId)),
              showScannedBarcodePreview: true,
              onClose: closeWeightBlock,
              onOnEditWeightBlock: onEditWeightBlock
            }, null, 8, ["id", "orderItems", "showOriginal", "showRemainingWeight"]))
          : _createCommentVNode("", true),
        (viewScanHint.value)
          ? (_openBlock(), _createBlock(_unref(ScanHint), {
              key: 1,
              onUseSearch: handleUseSearch
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("footer", _hoisted_10, [
      _createVNode(_unref(Button), {
        disabled: disableSubmitButton.value,
        btnOutline: "",
        "data-e2e": "pickup-confirm-button",
        onClick: onSubmit
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref($t)('components.replacement.confirm-button.text')), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    (completedOverlayVisible.value)
      ? (_openBlock(), _createBlock(_unref(CompletedOverlay), { key: 1 }))
      : _createCommentVNode("", true),
    (loading.value && !_unref(visibleWeightPopup))
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_unref(TransitionSpinner), { class: "spinner" }),
          _createElementVNode("p", null, _toDisplayString(_unref($t)('components.replacement.loading.text')), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})