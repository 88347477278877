import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { authServicePlugin } from '../plugin';
import type { RefreshUserTokenDTO } from '../types';

export class RefreshUserTokenPlugin<T extends RefreshUserTokenDTO>
  implements PipelinePlugin<T>
{
  public async execute(dto: T): Promise<T> {
    const isTokenRefreshed = await authServicePlugin
      .get()
      .forceRefreshUserToken();

    if (!isTokenRefreshed) {
      dto.isTokenRefreshFailed = true;
    }
    return dto;
  }
}
