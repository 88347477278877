<template>
  <AppHeader v-if="order" class="header">
    <BackButton :to="{ name: 'order-bags', params: { id: order.id } }" />
    <div
      v-if="isPaginationVisible"
      class="label-generator-header-pagination"
      data-e2e="label-generator-pagination"
    >
      {{ paginationText }}
    </div>
  </AppHeader>

  <div v-if="order">
    <div
      ref="infoboxRef"
      :class="['infobox', { 'infobox--no-footer': !isFooterVisible }]"
    >
      <div class="infobox__container">
        <div id="print" class="label-generator">
          <div class="label-generator-wrapper">
            <div
              v-for="(QRCode, index) in zplLabelQRCodes"
              :key="index"
              :data-e2e="`order-label-qr-${index}`"
              class="label-generator__qr"
            >
              <QRcode v-show="index === activeQRCodeIndex" :data="QRCode" />
            </div>
          </div>
        </div>
        <p class="infobox__subtitle" data-e2e="infobox-subtitle">
          {{
            $t(
              'pages.label-printer-zebra-label-qr-code-printer.infobox-subtitle.text',
            )
          }}
        </p>
        <div v-if="isPaginationVisible" class="label-generator__pagination">
          <Button
            :disabled="activeQRCodeIndex <= 0"
            btnOutline
            data-e2e="qr-code-previous-button"
            icon
            @click="previousQRCode"
          >
            <ArrowLeftIcon
              fill="currentColor"
              stroke="currentColor"
              width="30"
            />
          </Button>
          <span
            class="label-generator__pagination-text"
            data-e2e="label-generator-navigation-text"
          >
            {{ paginationText }} Codes
          </span>
          <Button
            :disabled="activeQRCodeIndex + 1 >= zplLabelQRCodes.length"
            btnOutline
            data-e2e="qr-code-next-button"
            icon
            @click="nextQRCode"
          >
            <ArrowRightIcon
              fill="currentColor"
              stroke="currentColor"
              width="30"
            />
          </Button>
        </div>
      </div>
      <footer v-show="isFooterVisible" class="infobox__footer">
        <Button data-e2e="print-successful-btn" @click="goNext">
          {{
            $t(
              'pages.label-printer-zebra-label-qr-code-printer.footer-button-successful.text',
            )
          }}
        </Button>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import type { Ref } from 'vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import Hammer from 'hammerjs';
import { useOrder } from '@/features/picking';
import QRcode from '@/features/ui/components/QRcode.vue';
import Button from '@/features/ui/components/Button.vue';
import { router } from '@/features/core/router';
import { AppHeader, BackButton } from '@/features/ui';
import { ArrowLeftIcon, ArrowRightIcon } from '@/features/ui/icons';
import { configurationServicePlugin } from '@/features/configuration';
import { loggerServicePlugin } from '@/features/core/logger';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';
import { useOrderLabels } from '../composables';

export default defineComponent({
  name: 'ZebraLabelQRCodePrinter',
  components: {
    Button,
    QRcode,
    BackButton,
    AppHeader,
    ArrowLeftIcon,
    ArrowRightIcon,
  },
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const maxLabelsInQRCode = ref(0);
    const { order, loadOrder } = useOrder();
    const { zplLabels, zplLabelQRCodes } = useOrderLabels(
      order,
      maxLabelsInQRCode,
    );
    const { startTracking, stopTracking } = usePerformanceTracker();

    const activeQRCodeIndex = ref(0);

    const getAmountOfLabelsInQRCode = async (): Promise<number> => {
      const isPrintingStorageLabelsActive = (
        await configurationServicePlugin
          .get()
          .isFeatureActive('printingStorageLabels')
      ).value;

      if (!isPrintingStorageLabelsActive) {
        return 0;
      }

      const maxLabelsInQRCode = await configurationServicePlugin
        .get()
        .getFeatureOption(
          'printingStorageLabels',
          'numberOfLabelsPerQRCode',
          'number',
        );

      return maxLabelsInQRCode || 0;
    };

    const paginationText = computed(() => {
      return `${activeQRCodeIndex.value + 1} of ${
        zplLabelQRCodes.value.length
      }`;
    });

    const isPaginationVisible = computed(
      () => zplLabelQRCodes.value.length > 1,
    );

    const isFooterVisible = computed(() => {
      return activeQRCodeIndex.value + 1 === zplLabelQRCodes.value.length;
    });

    const nextQRCode = () => {
      activeQRCodeIndex.value++;
    };

    const previousQRCode = () => {
      activeQRCodeIndex.value--;
    };

    const infoboxRef: Ref<HTMLElement | null> = ref(null);
    let isSwipeInProgress = false;

    // hammerjs swipeLeft will trigger 3 events: left left right, which will block swiping left behaviour.
    // swipeProgressDebounce was added to only accept first event and ignore 2 others.
    const swipeProgressDebounce = () => {
      isSwipeInProgress = true;
      setTimeout(() => {
        isSwipeInProgress = false;
      }, 100);
    };

    const initSwipeListener = (): void => {
      if (!infoboxRef.value || !isPaginationVisible.value) return;
      const hammerjs = new Hammer(infoboxRef.value);
      hammerjs.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
      hammerjs.on('swipe swiperight', () => {
        if (isSwipeInProgress) {
          return;
        }
        swipeProgressDebounce();
        if (activeQRCodeIndex.value !== 0) {
          previousQRCode();
        }
      });
      hammerjs.on('swipe swipeleft', () => {
        if (isSwipeInProgress) {
          return;
        }
        swipeProgressDebounce();
        if (activeQRCodeIndex.value + 1 !== zplLabelQRCodes.value.length) {
          nextQRCode();
        }
      });
    };

    onMounted(async () => {
      maxLabelsInQRCode.value = await getAmountOfLabelsInQRCode();
      await loadOrder(props.id);
      stopTracking(`assign-labels-to-printing-${props.id}`);
      initSwipeListener();
    });

    const goNext = async () => {
      loggerServicePlugin
        .get()
        .debug(
          'The user confirms that labels are printed. User will be navigated to complete-picking-order route',
        );

      startTracking(
        `printing-to-complete-picking-order-${props.id}`,
        PerformanceThresholdEnum.ROUTE_CHANGE,
      );
      await router.get().push({
        name: 'complete-picking-order',
        params: {
          id: props.id,
        },
      });
    };

    return {
      zplLabels,
      zplLabelQRCodes,
      activeQRCodeIndex,
      paginationText,
      isFooterVisible,
      order,
      previousQRCode,
      nextQRCode,
      goNext,
      infoboxRef,
      isPaginationVisible,
    };
  },
});
</script>

<style lang="scss" scoped>
.header {
  padding: 16px;
}

.infobox {
  display: flex;
  height: calc(100vh - 60px);
  flex-direction: column;

  &--no-footer {
    padding-bottom: 96px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 0 24px;
    margin-bottom: 22px;
  }

  &__subtitle {
    padding: 24px;
    font-size: 16px;
  }

  &__footer {
    padding: 24px;
    height: 96px;
    border-top: 2px solid $stroke-default-color;
  }
}

.label-generator {
  display: flex;
  justify-content: center;
  width: 100%;

  &__pagination {
    display: flex;
    align-items: center;
  }

  &__pagination-text {
    margin: 0 8px;
    width: 140px;
    font-size: 14px;
    color: rgba(var(--aldi-gray-mid), 1);
    line-height: 24px;
  }
}

.label-generator-header-pagination {
  position: absolute;
  top: 13px;
  left: 0;
  z-index: -1;
  width: 100%;
  font-size: 21px;
  font-weight: 700;
  line-height: 32px;
}

.label-generator-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.label-generator__qr {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: row;
}

.qr-code {
  max-width: 100%;
  max-height: 100%;
}
</style>
