import type { OrderItem } from '@/features/orders';
import { isOrderItemRandomWeight } from '@/features/orders';
import type { OverpickingThresholds } from '../composables/useOverpickingThresholds';
import { getMultipliedValueThreshold } from './getMultipliedValueThreshold';

/**
 * Determines whether the provided order item is considered "changed"
 *
 * For RWP type 0 any different in quantity means the item was "changed"
 *
 * For RWP type 1 and above the quantity is ignored and only the amount
 * (weight) is considered for "changed" status.
 */
export const isOrderItemChanged = (
  item: OrderItem,
  overpickingThreshold?: OverpickingThresholds,
): boolean => {
  if (!isOrderItemRandomWeight(item)) {
    // Non-RW quantity is different from what was ordered
    return item.quantity !== item.quantityOriginal;
  }
  const minAmount = getMultipliedValueThreshold(
    item.amountOriginal,
    overpickingThreshold?.minMultiplier,
  );
  const maxAmount = getMultipliedValueThreshold(
    item.amountOriginal,
    overpickingThreshold?.maxMultiplier,
  );
  // RW amount is above or below minimum picking threshold
  return item.amount < minAmount || item.amount > maxAmount;
};
