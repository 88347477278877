import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hint" }

import { BarcodeScannerIcon } from '@/features/ui/icons';

enum Events {
  Search = 'use-search',
}

interface Emits {
  (e: Events): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ScanHint',
  setup(__props, { emit: __emit }) {

const emit = __emit;

const handleEvent = (e: Events) => {
  emit(e);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(BarcodeScannerIcon), {
      class: "icon",
      fill: "#0D3A93"
    }),
    _createElementVNode("span", null, _toDisplayString(_ctx.$t('components.replacement-scan-hint.hint.text')), 1),
    _createElementVNode("a", {
      "data-e2e": "search-link",
      href: "#",
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => handleEvent(Events.Search), ["prevent"]))
    }, _toDisplayString(_ctx.$t('components.replacement-scan-hint.hint.link')), 1)
  ]))
}
}

})