import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { "data-e2e": "scanned-delivery-unit-count" }
const _hoisted_2 = {
  key: 0,
  "data-e2e": "scanned-delivery-unit-position"
}
const _hoisted_3 = {
  key: 0,
  class: "error"
}

import { ref, toRefs } from 'vue';
import { Button, CheckIcon, EditIcon } from '@/features/ui';


export default /*@__PURE__*/_defineComponent({
  __name: 'DeliveryUnitBlock',
  props: {
  deliveryUnit: { required: true },
},
  emits: [
  'editDeliveryUnitPosition',
  'openEditingDeliveryUnitPosition',
],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;
const emit = __emit;

const editPosition = ref(false);
const { deliveryUnit } = toRefs(props);
const position = ref(deliveryUnit.value.position);
const lengthErrorVisible = ref(false);

const openEditingDeliveryUnitPosition = () => {
  editPosition.value = true;
  emit('openEditingDeliveryUnitPosition', deliveryUnit, position.value);
};

const editDeliveryUnitPosition = (deliveryUnit) => {
  emit('editDeliveryUnitPosition', deliveryUnit, position.value);
  editPosition.value = false;
};

const updateInput = (event: Event) => {
  const target = event.target as HTMLInputElement;

  lengthErrorVisible.value = target.value.length > 10;
};

__expose({
  openEditingDeliveryUnitPosition,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("strong", null, _toDisplayString(_unref(deliveryUnit).count), 1),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('pages.scan-bag-position.bags.text')), 1)
    ]),
    (!editPosition.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("strong", null, _toDisplayString(_unref(deliveryUnit).position), 1)
        ]))
      : _createCommentVNode("", true),
    (editPosition.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["scanned-delivery-unit-position-wrapper", { 'error-wrapper': lengthErrorVisible.value }])
        }, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('pages.scan-bag-position.position.text')), 1),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((position).value = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (updateInput($event))),
            class: "input",
            "data-e2e": "scanned-delivery-unit-position-input"
          }, null, 544), [
            [_vModelText, position.value]
          ]),
          (lengthErrorVisible.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('pages.scan-bag-position.position-error.text')), 1))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      (!editPosition.value)
        ? (_openBlock(), _createBlock(_unref(Button), {
            key: 0,
            "data-e2e": "open-editing-box",
            plain: "",
            btnOutline: "",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (openEditingDeliveryUnitPosition())),
            disabled: lengthErrorVisible.value
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(EditIcon))
            ]),
            _: 1
          }, 8, ["disabled"]))
        : _createCommentVNode("", true),
      (editPosition.value)
        ? (_openBlock(), _createBlock(_unref(Button), {
            key: 1,
            "data-e2e": "close-editing-box",
            icon: "",
            btnOutline: "",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (editDeliveryUnitPosition(_unref(deliveryUnit)))),
            disabled: lengthErrorVisible.value
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(CheckIcon), { fill: "currentColor" })
            ]),
            _: 1
          }, 8, ["disabled"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})