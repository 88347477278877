import {
  ExecPluginFactory,
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { storagePlugin, storageSetupPlugin } from '../storage';
import { AuthService } from './auth';
import { AuthData, AuthDataStorageSetup } from './entity';
import type { AuthClientOptions } from './types';
import { empowerIdApiPlugin } from '../api';
import { errorPlugin } from '../errors';
import { eventBusServicePlugin } from '../event-bus';
import { loggerServicePlugin } from '../logger';

export const authServicePlugin = ProviderPluginFactory.create<
  AuthService,
  AuthClientOptions
>({
  key: Symbol('AuthService'),
  defaultFactory: {
    create: (app, options) =>
      new AuthService(
        options,
        storagePlugin.get(),
        errorPlugin.get(),
        empowerIdApiPlugin.get(),
        eventBusServicePlugin.get(),
        loggerServicePlugin.get(),
      ),
  },
});

const authEntityPlugin = ExecPluginFactory.fn(() => {
  storageSetupPlugin.get().add(AuthData, new AuthDataStorageSetup());
});

const checkSessionExpirationExecutePlugin = ExecPluginFactory.fn(() => {
  void authServicePlugin.get().checkSessionExpiration();
});

export const authConfigPlugin = MultiPluginFactory.with({
  authServicePlugin,
  authEntityPlugin,
  checkSessionExpirationExecutePlugin,
});
