import type { Type } from '@/utils/types';
import Dexie from 'dexie';
import type { Storable } from '../storable';
import type {
  GlobalStorageSetup,
  StorageSetupMeta,
  StorageSetupStrategy,
} from '../storage-setup';
import type { DexieStorage } from './dexie-storage';

declare module '../storage-setup' {
  interface StorageSetupMeta {
    version: Dexie['version'];
  }
}

export class DexieStorageSetup implements GlobalStorageSetup {
  private readonly dbName = 'picking-app-dexie-storage';

  async setup(
    storage: DexieStorage,
    setupMap: Map<Type<Storable>, StorageSetupStrategy | undefined>,
  ): Promise<void> {
    const isPersisted = (await navigator?.storage?.persisted()) ?? false;

    if (!isPersisted) {
      await navigator?.storage?.persist?.();
    }

    Dexie.debug =
      process.env.NODE_ENV === 'development' ||
      process.env.DEXIE_DEBUG !== undefined
        ? true
        : false;

    const db = new Dexie(this.dbName);

    for (const [storable, strategy] of setupMap.entries()) {
      if (!strategy) continue;
      const storeName = new storable().type;

      const meta: StorageSetupMeta = {
        storage,
        storeName,
        version: db.version.bind(db),
      };

      await strategy.setup(meta);

      db.table(storeName).mapToClass(storable);
    }

    await storage.init(db);
  }
}
