import { CheckInBannerContextEnum } from '../types';
import type { Ref } from 'vue';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { PluginPipeline } from '@ads/plugin-pipeline';
import type { Order, OrderPluginDto } from '@/features/orders';
import {
  BooleanSwitcherPlugin,
  HandleRestrictedOrderPlugin,
  NavigateToTargetRoutePlugin,
  PausePickingPlugin,
  SaveOrderToStorage,
  SetCustomerNoteAsTargetRoutePlugin,
  SetPickingEventsAsAlreadyPatchedPlugin,
  SetTargetRoutePlugin,
  ShowPausePickingDialogPlugin,
  StartPerformanceTrackingPlugin,
  TrackOrderEventsPlugin,
} from '@/features/orders';
import { AcknowledgeCheckInNotificationPlugin } from '@/features/push-notification/plugins/acknowledge-check-in-notification';
import { ordersServicePlugin } from '@/features/orders/services';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import type { UsePerformanceTracker } from '@/features/performance-tracker';
import { PerformanceThresholdEnum } from '@/features/performance-tracker';

export const getPickingFlowPluginPipeline = (
  pickingOrderId: string,
): PipelinePlugin<OrderPluginDto>[] => {
  return [
    new ShowPausePickingDialogPlugin(),
    new PausePickingPlugin(pickingOrderId),
  ];
};

export const getOrderOverviewPluginPipeline =
  (): PipelinePlugin<OrderPluginDto>[] => {
    return [new HandleRestrictedOrderPlugin()];
  };

export const getStartHandoverPluginPipeline = (
  order: Order,
  switcher: Ref<boolean>,
  context: CheckInBannerContextEnum,
  performanceTrackerComposable: UsePerformanceTracker,
  pickingOrderId?: string,
): PluginPipeline<OrderPluginDto> => {
  const startHandoverPlugin = new PluginPipeline<OrderPluginDto>();

  startHandoverPlugin.registerPlugins(
    getOrderOverviewPluginPipeline(),
    () => context === CheckInBannerContextEnum.ORDER_OVERVIEW,
  );

  startHandoverPlugin.registerPlugins(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - this line is being ignored because type undefined is not possible due to the condition below
    getPickingFlowPluginPipeline(pickingOrderId),
    () =>
      context === CheckInBannerContextEnum.PICKING_FLOW &&
      typeof pickingOrderId === 'string',
  );

  startHandoverPlugin.registerPlugins([
    new BooleanSwitcherPlugin(switcher, true),
    new SetPickingEventsAsAlreadyPatchedPlugin(),
    new SetTargetRoutePlugin({
      name: 'bags-collection',
      params: {
        id: order.id,
      },
    }),
    new TrackOrderEventsPlugin(),
  ]);

  startHandoverPlugin.registerPlugins(
    [
      new AcknowledgeCheckInNotificationPlugin(
        ordersServicePlugin.get(),
        eventBusServicePlugin.get(),
      ),
    ],
    (dto) =>
      dto.order.checkIn?.isCheckedIn === 1 &&
      !dto.order.checkIn?.isAcknowledged,
  );

  startHandoverPlugin.registerPlugins([
    new StartPerformanceTrackingPlugin(
      performanceTrackerComposable,
      `checkin-banner-clicked-${order.id}`,
      PerformanceThresholdEnum.ROUTE_CHANGE,
    ),
    new SaveOrderToStorage(),
    new SetCustomerNoteAsTargetRoutePlugin(),
    new NavigateToTargetRoutePlugin(),
    new BooleanSwitcherPlugin(switcher, false),
  ]);

  return startHandoverPlugin;
};
