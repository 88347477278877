import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "main" }
const _hoisted_5 = { class: "item-wrapper" }

import ToggleItem from './ToggleItem.vue';
import Credits from './Credits.vue';
import { useFeatureToggle } from '../composables/useFeatureToggle';

export default /*@__PURE__*/_defineComponent({
  __name: 'FeatureToggleSection',
  setup(__props) {

const { featureList } = useFeatureToggle();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("hgroup", null, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('components.feature-toggle.feature-toggle-selection.title.text')), 1)
      ])
    ]),
    _createElementVNode("main", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_unref(featureList)), ([key, feature]) => {
          return (_openBlock(), _createBlock(ToggleItem, {
            key: key + feature.title,
            "data-e2e": key,
            e2e: key,
            feature: feature,
            "onUpdate:modelValue": ($event: any) => (feature.active = $event)
          }, null, 8, ["data-e2e", "e2e", "feature", "onUpdate:modelValue"]))
        }), 128))
      ]),
      _createVNode(Credits)
    ])
  ]))
}
}

})