import { expandPaths, getPath, setPath } from '@/utils/helpers/path';
import type { Storable } from './storable';
import { getStorableType } from './storable';
import type { Storage } from './storage';

export function storageFkResolverMixin(storage: Storage): Storage {
  const getById = storage.getById.bind(storage);
  const getByIds = storage.getByIds.bind(storage);
  const getAll = storage.getAll.bind(storage);
  const save = storage.save.bind(storage);
  const resolveFks = getResolveFks(storage);

  storage.getById = async (...args) => {
    const data = await getById(...args);
    if (data) {
      return resolveFks(data);
    }
  };

  storage.getByIds = async (...args) => {
    const data = await getByIds(...args);
    const resolvedData = await Promise.all(data.map(resolveFks));
    return resolvedData;
  };

  storage.getAll = async (...args) => {
    const data = await getAll(...args);
    const resolvedData = await Promise.all(data.map(resolveFks));
    return resolvedData;
  };

  storage.save = async (data, options) => {
    data = stripFks(data);
    data = await save(data, options);
    return resolveFks(data);
  };

  return storage;
}

const DefaultFKKey = 'id';

function getResolveFks(storage: Storage) {
  return async <T extends Storable>(data: T) => {
    const type = getStorableType(data);
    const fks = type.FKs;
    if (!fks) {
      return data;
    }

    data = type.from(data);

    const promises = Object.entries(fks).map(async ([key, fkMeta]) => {
      const paths = expandPaths(data, key);
      const ids = paths.map((path) => String(getPath(data, path)));
      const fkType = 'type' in fkMeta ? fkMeta.type : fkMeta;
      const fkKey = 'key' in fkMeta ? fkMeta.key : DefaultFKKey;

      const fksData = await (fkKey === DefaultFKKey
        ? storage.getByIds(fkType, { ids })
        : storage.getAll(fkType, { filter: { [fkKey]: { anyOf: ids } } }));

      const fksDataMap = Object.fromEntries(
        fksData.map((fkData) => [String(getPath(fkData, fkKey)), fkData]),
      );

      paths.forEach((path, pathIdx) => {
        const id = ids[pathIdx];
        const fkData = fksDataMap[id];

        if (!fkData) {
          return;
        }

        setPath(data, path, fkData);
      });
    });

    await Promise.all(promises);

    return data;
  };
}

export function stripFks<T extends Storable>(data: T): T {
  const type = getStorableType(data);
  const fks = type.FKs;

  if (!fks) {
    return data;
  }

  data = type.from(data);

  Object.entries(fks).forEach(([key, fkMeta]) => {
    const paths = expandPaths(data, key);
    const fkKey = 'key' in fkMeta ? fkMeta.key : DefaultFKKey;

    paths.forEach((path) => {
      const fkData = getPath(data, path) as Storable | undefined;
      if (fkData === undefined) {
        return;
      }

      const key = fkData[fkKey as keyof Storable] as unknown;
      if (typeof key !== 'string') {
        return;
      }

      setPath(data, path, key);
    });
  });

  return data;
}
