
import { defineComponent } from 'vue';
import { useAppServiceResolver } from '../composables';
import { onMounted } from 'vue';

import Error from '@/features/core/errors/Error.vue';
import { measureMemoryUsage } from '@/utils/helpers/measureMemoryUsage';

export default defineComponent({
  name: 'AppServiceResolver',
  components: { Error },
  setup() {
    const { ready, error, instance, info } = useAppServiceResolver();
    const { performMemoryAuditWithLogs } = measureMemoryUsage();
    onMounted(() => {
      performMemoryAuditWithLogs();
    });

    return {
      ready,
      error,
      instance,
      info,
    };
  },
});
