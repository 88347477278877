import type { SyncSchedulerExecutor } from './sync-scheduler-executor';
import type { AdoptedServiceWorker } from '@/features/service-worker';
import { ServiceWorkerEventTypes } from '@/features/service-worker';
import {
  BackgroundSyncTags,
  dataBackgroundSyncServicePlugin,
} from '@/features/data-background-sync';
import { ENTITY_SYNC_TAG } from '@/features/sync-scheduler';

declare global {
  interface Navigator {
    connection: EventTarget;
  }
}

export class SyncSchedulerExecutorAdapter implements AdoptedServiceWorker {
  constructor(
    private syncSchedulerExecutor: SyncSchedulerExecutor,
    private skipSW: boolean,
    private serviceWorker = navigator.serviceWorker,
    private navigatorService = window.navigator,
  ) {
    this.navigatorService.connection.addEventListener('change', () => {
      if (this.skipSW && this.navigatorService.onLine) {
        void this.executeScheduler();
      }
    });
    this.serviceWorker?.addEventListener('message', (event) => {
      this.messageListeners.forEach((listener: (event: unknown) => void) => {
        listener(event);
      });
    });
  }

  private executing = false;
  private executingScheduled = false;
  private errorCount = 0;

  private messageListeners = new Set<(event: unknown) => void>();

  private isSWRegistered: boolean | null = null;
  private checkServiceWorkerRegisteration = async () => {
    if (!this.serviceWorker) {
      return false;
    }
    if (this.isSWRegistered !== null) {
      return this.isSWRegistered;
    }
    const registrations = await this.serviceWorker.getRegistrations();
    this.isSWRegistered = Boolean(registrations.length);
    return this.isSWRegistered;
  };

  private syncRegister = async (tag: string): Promise<void> => {
    switch (tag) {
      case BackgroundSyncTags.NormalBackgroundSync:
        await dataBackgroundSyncServicePlugin.get().fetchDataOnBackgroundSync();
        break;
      case ENTITY_SYNC_TAG:
      default:
        await this.executeScheduler.bind(this)();
        break;
    }
  };

  private getRegistrationObject = () => {
    return {
      sync: {
        register: this.syncRegister.bind(this),
      },
      periodicSync: {
        register: async (tag: string, period: number = 60 * 1000) => {
          const isSWRegistered = await this.checkServiceWorkerRegisteration();
          if (!isSWRegistered) {
            return;
          }
          const registration = await this.serviceWorker.ready;
          if (registration) {
            await registration.periodicSync.register(tag, {
              minInterval: period,
            });
          }
        },
        getTags: async () => {
          const isSWRegistered = await this.checkServiceWorkerRegisteration();
          if (!isSWRegistered) {
            return [];
          }
          const registration = await this.serviceWorker.ready;
          return await registration.periodicSync.getTags();
        },
      },
      showNotification: async (
        title: string,
        options?: NotificationOptions,
      ) => {
        const registration = await this.serviceWorker.ready;
        if (registration) {
          await registration.showNotification(title, options);
        }
      },
    } as unknown as ServiceWorkerRegistration;
  };

  private async executeScheduler() {
    if (this.executing || !this.navigatorService.onLine) {
      this.executingScheduled = true;
      return;
    }
    this.executing = true;
    this.executingScheduled = false;
    try {
      const syncList = await this.syncSchedulerExecutor.executeScheduler();
      if (!syncList?.length) {
        this.executing = false;
        return;
      }
      for (const sync of syncList) {
        this.messageListeners.forEach((listener: (event: unknown) => void) => {
          listener({
            data: {
              type: ServiceWorkerEventTypes.SyncScheduler,
              data: sync,
            },
          });
        });
      }
      this.errorCount = 0;
    } catch (e) {
      this.errorCount++;
      this.executingScheduled = true;
    }
    this.executing = false;
    if (this.executingScheduled) {
      setTimeout(() => void this.executeScheduler(), this.errorCount * 5000);
    }
  }

  public ready = new Promise<ServiceWorkerRegistration>((resolve) => {
    resolve({
      ...this.getRegistrationObject(),
    });
  });

  addEventListener(type: string, listener: (event: unknown) => void): void {
    if (type === 'message') {
      this.messageListeners.add(listener);
    }
  }
}
