import type { Storage } from '@/features/core/storage';
import { DataSync } from '../entities';
import type {
  DataSyncService,
  DataSyncScopes,
  DataSyncServiceConfig,
} from '../types';

export class DataSyncServiceImplementation implements DataSyncService {
  constructor(
    private options: DataSyncServiceConfig,
    private storage: Storage,
  ) {}

  async setDataSync(scope: DataSyncScopes, lastSync: Date): Promise<void> {
    await this.storage.save(
      DataSync.from({
        type: 'dataSync',
        id: scope,
        lastSync: new Date(lastSync).toISOString(),
      }),
    );
  }

  async getById(scope: DataSyncScopes): Promise<DataSync | undefined> {
    return await this.storage.getById(DataSync, {
      id: scope,
    });
  }

  async isDataReadyToSync(scope: DataSyncScopes): Promise<boolean> {
    const dataSyncScope = await this.getById(scope);
    return dataSyncScope
      ? new Date().getTime() - new Date(dataSyncScope.lastSync).getTime() >
          this.options.dataUpdateInterval
      : true;
  }

  async clearAll(): Promise<void> {
    await this.storage.removeAll(DataSync);
  }
}
