import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "manual-scan__title" }
const _hoisted_2 = { class: "manual-scan__sub-title" }
const _hoisted_3 = { class: "controls" }

import { $t } from '@/i18n';
import type { PropType } from 'vue';
import { ref, toRefs, watch } from 'vue';
import { deepClone } from '@/utils/helpers/deepClone';
import type {
  DeliveryUnitPosition,
  DeliveryUnitPositions,
} from '@/features/delivery-unit';
import { BagWithPosition, Button, Popup } from '@/features/ui';
import { LABEL_FOR_MANUALLY_SCAN } from '@/features/bags/types';
import { DEFAULT_MANUALLY_SCAN } from '../types';


export default /*@__PURE__*/_defineComponent({
  __name: 'DeliveryUnitsManualScanning',
  props: {
  deliveryUnitPositions: {
    type: Object as PropType<DeliveryUnitPositions>,
    required: true,
  },
  disabled: {
    default: false,
  },
},
  emits: ['apply'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const { deliveryUnitPositions, disabled } = toRefs(props);
const deliveryUnitPositionsCopy = ref(
  deepClone(
    Object.fromEntries(
      Object.entries(deliveryUnitPositions.value).filter(
        ([, value]) => value.totalCount !== value.scannedBarcode.length,
      ),
    ),
  ),
);

const titleByUnitPosition = (key: string) => {
  return key === LABEL_FOR_MANUALLY_SCAN
    ? $t('pages.scan-bag-position.placed-without-scan.text')
    : key;
};

const visibleManualScanning = ref(false);

const toggleManualScanning = () => {
  visibleManualScanning.value = !visibleManualScanning.value;
};

watch(visibleManualScanning, () => {
  deliveryUnitPositionsCopy.value = deepClone(
    Object.fromEntries(
      Object.entries(deliveryUnitPositions.value).filter(
        ([, value]) => value.totalCount !== value.scannedBarcode.length,
      ),
    ),
  );
});

const openManualScanning = () => {
  toggleManualScanning();
};

const applyManualScanning = () => {
  emit('apply', deliveryUnitPositionsCopy.value);
  toggleManualScanning();
};

const triggerCheckBagsPosition = (item: {
  deliveryUnit: DeliveryUnitPosition;
  checked: boolean;
}) => {
  if (item.checked) {
    const scannedBarcodeList = Array.from(
      {
        length:
          item.deliveryUnit.totalCount -
          item.deliveryUnit.scannedBarcode.length,
      },
      () => DEFAULT_MANUALLY_SCAN,
    );
    item.deliveryUnit.scannedBarcode.push(...scannedBarcodeList);
  } else {
    item.deliveryUnit.scannedBarcode = item.deliveryUnit.scannedBarcode.filter(
      (item) => item !== DEFAULT_MANUALLY_SCAN,
    );
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Button), {
      "data-e2e": "cant-scan-bag-button",
      class: "cant-scan-bag__button",
      plain: "",
      disabled: _unref(disabled),
      onClick: openManualScanning
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_unref($t)('pages.bags-collection.cant-scan-bag-items.text')), 1)
      ]),
      _: 1
    }, 8, ["disabled"]),
    _createVNode(_unref(Popup), {
      visible: visibleManualScanning.value,
      bottom: true,
      isCloseButtonExist: false,
      withoutHeader: true,
      onClose: toggleManualScanning
    }, {
      default: _withCtx(() => [
        _createElementVNode("h1", _hoisted_1, _toDisplayString(_unref($t)('pages.bags-collection.manually-scan-title.text')), 1),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_unref($t)('pages.bags-collection.manually-scan-subtitle.text')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(deliveryUnitPositionsCopy.value, (item, key) => {
          return (_openBlock(), _createBlock(_unref(BagWithPosition), {
            key: key,
            "delivery-unit": item,
            "manually-placed": key === _unref(LABEL_FOR_MANUALLY_SCAN),
            title: titleByUnitPosition(key),
            position: key,
            checked: item.totalCount === item.scannedBarcode.length,
            onToggleBagsPosition: triggerCheckBagsPosition
          }, null, 8, ["delivery-unit", "manually-placed", "title", "position", "checked"]))
        }), 128)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(Button), {
            "data-e2e": "manually-scan-apply",
            onClick: applyManualScanning
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref($t)('pages.bags-collection.manually-scan-apply-button.text')), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(Button), {
            "data-e2e": "manually-scan-cancel",
            text: "",
            btnOutline: "",
            onClick: toggleManualScanning
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref($t)('pages.bags-collection.manually-scan-cancel-button.text')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})