import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "section-list" }

import {
  EmptySection,
  handoverFilters,
  StatusTypes,
  TabVariants,
  WeightPopup,
} from '@/features/ui';
import { HandoverSection } from '@/features/handover';
import HandoverItemList from '@/features/handover/components/HandoverItemList.vue';
import { computed, ref, toRefs } from 'vue';
import { useHandoverOrder } from '@/features/handover/composables';
import type { OrderItem, OrderWeight } from '@/features/orders';
import { OrderItemStatus } from '@/features/orders';
import type { PartitionedHandoverItems } from '@/features/handover/constants';
import RestoreAgeVerificationContent from '@/features/age-verification/components/RestoreAgeVerificationContent.vue';
import { Status } from '@/features/age-verification';
import router from '@/features/core/router';
import { useDynamicDialog } from '@/features/ui/composables';
import { $t } from '@/i18n';
import { focusOutInputHandler } from '@/utils/helpers/hideOnFocus';


export default /*@__PURE__*/_defineComponent({
  __name: 'HandoverSectionList',
  props: {
    partitionedOrderItems: {},
    currentTab: {}
  },
  emits: ["save"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { partitionedOrderItems, currentTab } = toRefs(props);

const { order, updateOrderWeightItem, rejectOrderItem, restoreItem } =
  useHandoverOrder();

const selectedId = ref<string | null>(null);
const selectedItem = ref<OrderItem | undefined>(undefined);

const visibleWeightPopup = ref(false);

const getOrderItemsCountOnTab = computed((): number => {
  switch (currentTab.value) {
    case TabVariants.Changed:
      return partitionedOrderItems.value.changed.length;
    case TabVariants.Found:
      return partitionedOrderItems.value.staged.length;
    case TabVariants.Rejected:
      return partitionedOrderItems.value.rejected.length;
    default:
      return (
        partitionedOrderItems.value.changed.length +
        partitionedOrderItems.value.staged.length
      );
  }
});

const onEditWeightBlock = (
  selectedItem: OrderItem,
  weightBefore: OrderWeight[] = [],
  status = StatusTypes.Default,
) => {
  if (!selectedItem || !order.value) {
    return;
  }

  updateOrderWeightItem(selectedItem, weightBefore, status);
  closeWeightBlock();
};

const onEditWeight = (item: OrderItem) => {
  if (!order.value) {
    return;
  }

  selectedId.value = item.id;
  selectedItem.value = item;
  visibleWeightPopup.value = true;
};

const closeWeightBlock = () => {
  visibleWeightPopup.value = false;
};
const onReject = (orderItem: OrderItem) => {
  const stagedItem =
    orderItem.status === OrderItemStatus.rejected
      ? order.value?.items.find((item) => {
          return (
            item.isRejected &&
            item.id === orderItem.id &&
            item.originalId === orderItem.originalId
          );
        })
      : orderItem;
  if (!stagedItem) {
    return;
  }

  focusOutInputHandler();
  rejectOrderItem(stagedItem, stagedItem.quantity, stagedItem.amount, true);
  emit('save');
};

const { confirm } = useDynamicDialog();

const onRestoreAgeVerification = async () => {
  if (!order.value || order.value.ageVerification?.status !== Status.Rejected) {
    return;
  }

  const confirmed = await confirm({
    title: $t('components.restore-age-verification-modal.title.text'),
    confirmText: $t('components.restore-age-verification-modal.continue.text'),
    cancelText: $t('components.restore-age-verification-modal.cancel.text'),
    component: {
      component: RestoreAgeVerificationContent,
    },
    isTitleCentered: false,
  });

  if (!confirmed) {
    return;
  }

  order.value.items
    .filter(
      (item) =>
        item.product.ageRestricted !== 0 &&
        item.status === OrderItemStatus.staged,
    )
    .forEach((item) => restoreItem(item));

  const params = router.get().currentRoute.value.params;
  await router.get().push({
    name: 'age-verification',
    params,
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(partitionedOrderItems), (filteredItems, sectionStatus) => {
        return (_openBlock(), _createBlock(_KeepAlive, { key: filteredItems }, [
          (
            (filteredItems.length && _unref(currentTab) === sectionStatus) ||
            (_unref(currentTab) === _unref(TabVariants).All &&
              filteredItems.length &&
              sectionStatus !== _unref(TabVariants).Rejected)
          )
            ? (_openBlock(), _createBlock(_unref(HandoverSection), {
                key: 0,
                "data-e2e": `handover-section-${_unref(handoverFilters)[sectionStatus]?.label}`,
                isTitleEnabled: _unref(currentTab) !== _unref(TabVariants).All,
                title: _unref(handoverFilters)[sectionStatus]?.label
              }, {
                default: _withCtx(() => [
                  _createVNode(HandoverItemList, {
                    "current-tab": _unref(currentTab),
                    items: filteredItems,
                    "section-status": sectionStatus,
                    onReject: onReject,
                    onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('save'))),
                    onEditWeight: onEditWeight,
                    onShowRestoreAgeVerificationModal: onRestoreAgeVerification
                  }, null, 8, ["current-tab", "items", "section-status"])
                ]),
                _: 2
              }, 1032, ["data-e2e", "isTitleEnabled", "title"]))
            : _createCommentVNode("", true)
        ], 1024))
      }), 128))
    ]),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (!getOrderItemsCountOnTab.value)
        ? (_openBlock(), _createBlock(_unref(EmptySection), { key: 0 }))
        : _createCommentVNode("", true)
    ], 1024)),
    (visibleWeightPopup.value && _unref(order))
      ? (_openBlock(), _createBlock(_unref(WeightPopup), {
          key: 0,
          id: selectedId.value,
          editable: false,
          orderItems: _unref(order).items,
          selectedItem: selectedItem.value,
          onClose: closeWeightBlock,
          onOnEditWeightBlock: onEditWeightBlock
        }, null, 8, ["id", "orderItems", "selectedItem"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})