import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-e2e"]


export default /*@__PURE__*/_defineComponent({
  __name: 'Chip',
  props: {
    text: {},
    variant: {},
    size: {},
    e2e: { default: 'chip' }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(['chip', `chip--${_ctx.variant}`, `chip--${_ctx.size}`]),
    "data-e2e": _ctx.e2e
  }, _toDisplayString(_ctx.text), 11, _hoisted_1))
}
}

})