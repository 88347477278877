
import type { Ref } from 'vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import Hammer from 'hammerjs';
import { useOrder } from '@/features/picking';
import QRcode from '@/features/ui/components/QRcode.vue';
import Button from '@/features/ui/components/Button.vue';
import { router } from '@/features/core/router';
import { AppHeader, BackButton } from '@/features/ui';
import { ArrowLeftIcon, ArrowRightIcon } from '@/features/ui/icons';
import { configurationServicePlugin } from '@/features/configuration';
import { loggerServicePlugin } from '@/features/core/logger';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';
import { useOrderLabels } from '../composables';

export default defineComponent({
  name: 'ZebraLabelQRCodePrinter',
  components: {
    Button,
    QRcode,
    BackButton,
    AppHeader,
    ArrowLeftIcon,
    ArrowRightIcon,
  },
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const maxLabelsInQRCode = ref(0);
    const { order, loadOrder } = useOrder();
    const { zplLabels, zplLabelQRCodes } = useOrderLabels(
      order,
      maxLabelsInQRCode,
    );
    const { startTracking, stopTracking } = usePerformanceTracker();

    const activeQRCodeIndex = ref(0);

    const getAmountOfLabelsInQRCode = async (): Promise<number> => {
      const isPrintingStorageLabelsActive = (
        await configurationServicePlugin
          .get()
          .isFeatureActive('printingStorageLabels')
      ).value;

      if (!isPrintingStorageLabelsActive) {
        return 0;
      }

      const maxLabelsInQRCode = await configurationServicePlugin
        .get()
        .getFeatureOption(
          'printingStorageLabels',
          'numberOfLabelsPerQRCode',
          'number',
        );

      return maxLabelsInQRCode || 0;
    };

    const paginationText = computed(() => {
      return `${activeQRCodeIndex.value + 1} of ${
        zplLabelQRCodes.value.length
      }`;
    });

    const isPaginationVisible = computed(
      () => zplLabelQRCodes.value.length > 1,
    );

    const isFooterVisible = computed(() => {
      return activeQRCodeIndex.value + 1 === zplLabelQRCodes.value.length;
    });

    const nextQRCode = () => {
      activeQRCodeIndex.value++;
    };

    const previousQRCode = () => {
      activeQRCodeIndex.value--;
    };

    const infoboxRef: Ref<HTMLElement | null> = ref(null);
    let isSwipeInProgress = false;

    // hammerjs swipeLeft will trigger 3 events: left left right, which will block swiping left behaviour.
    // swipeProgressDebounce was added to only accept first event and ignore 2 others.
    const swipeProgressDebounce = () => {
      isSwipeInProgress = true;
      setTimeout(() => {
        isSwipeInProgress = false;
      }, 100);
    };

    const initSwipeListener = (): void => {
      if (!infoboxRef.value || !isPaginationVisible.value) return;
      const hammerjs = new Hammer(infoboxRef.value);
      hammerjs.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
      hammerjs.on('swipe swiperight', () => {
        if (isSwipeInProgress) {
          return;
        }
        swipeProgressDebounce();
        if (activeQRCodeIndex.value !== 0) {
          previousQRCode();
        }
      });
      hammerjs.on('swipe swipeleft', () => {
        if (isSwipeInProgress) {
          return;
        }
        swipeProgressDebounce();
        if (activeQRCodeIndex.value + 1 !== zplLabelQRCodes.value.length) {
          nextQRCode();
        }
      });
    };

    onMounted(async () => {
      maxLabelsInQRCode.value = await getAmountOfLabelsInQRCode();
      await loadOrder(props.id);
      stopTracking(`assign-labels-to-printing-${props.id}`);
      initSwipeListener();
    });

    const goNext = async () => {
      loggerServicePlugin
        .get()
        .debug(
          'The user confirms that labels are printed. User will be navigated to complete-picking-order route',
        );

      startTracking(
        `printing-to-complete-picking-order-${props.id}`,
        PerformanceThresholdEnum.ROUTE_CHANGE,
      );
      await router.get().push({
        name: 'complete-picking-order',
        params: {
          id: props.id,
        },
      });
    };

    return {
      zplLabels,
      zplLabelQRCodes,
      activeQRCodeIndex,
      paginationText,
      isFooterVisible,
      order,
      previousQRCode,
      nextQRCode,
      goNext,
      infoboxRef,
      isPaginationVisible,
    };
  },
});
