/* eslint-disable  @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access,  @typescript-eslint/no-unsafe-return */

import { deepClone } from './deepClone';

const deleteFunctions = (obj: any): void => {
  if (obj === null) {
    return obj;
  }
  Object.keys(obj).forEach((k) => {
    if (typeof obj[k] === 'function' || obj[k] instanceof Event) delete obj[k];
    else if (typeof obj[k] === 'object') deleteFunctions(obj[k]);
  });
};
export function removeFunctionFromObj<T>(obj: T): T {
  const clonedObj = deepClone(obj);
  deleteFunctions(clonedObj);
  return clonedObj;
}
