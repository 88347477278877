
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import type { HandoverFilterRecord } from '@/features/ui/types';
import { handoverFilters } from '@/features/ui/types';
import type { MappedOrderItems } from '@/features/handover/constants';

export default defineComponent({
  props: {
    filters: {
      type: Object as PropType<HandoverFilterRecord>,
      default: handoverFilters,
    },
    filterQuantity: {
      type: Object as PropType<MappedOrderItems>,
      default: null,
    },
    currentTab: {
      type: String,
      default: 'all',
    },
  },
  emits: ['changeTab'],
  setup(props, { emit }) {
    const selectedTab = computed(() => {
      return props.currentTab;
    });

    const onSelectTab = (tab: string) => {
      emit('changeTab', tab);
    };

    return {
      onSelectTab,
      selectedTab,
    };
  },
});
