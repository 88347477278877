import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "accordion__content"
}
const _hoisted_2 = { key: 1 }

import { ref } from 'vue';
import ChevronRight from '@/features/ui/icons/ChevronRight.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SimpleAccordeon',
  setup(__props) {

const expanded = ref(false);

const toggle = () => {
  expanded.value = !expanded.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["accordion__header", { 'accordion__header--expanded': expanded.value }]),
      onClick: toggle
    }, [
      _renderSlot(_ctx.$slots, "headline"),
      _createElementVNode("div", {
        class: _normalizeClass(["chevron", { 'chevron--expanded': expanded.value }])
      }, [
        _createVNode(ChevronRight)
      ], 2)
    ], 2),
    (expanded.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "content")
        ]))
      : (_openBlock(), _createElementBlock("hr", _hoisted_2))
  ], 64))
}
}

})