
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import type { Tab } from '@/features/orders';
import PulsatingDot from '@/features/ui/components/PulsatingDot.vue';

const Events = {
  ChangeTab: 'change-tab',
} as const;

export default defineComponent({
  name: 'Tab',
  components: {
    PulsatingDot,
  },
  props: {
    activeTab: {
      type: Object as PropType<Tab>,
      required: true,
    },
    linkList: {
      type: Array as PropType<Tab[]>,
      required: true,
    },
  },
  emits: Object.values(Events),
  setup(props, { emit }) {
    return {
      emit,
      Events,
    };
  },
});
