import { ProviderPluginFactory } from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { productsServicePlugin } from '@/features/products/services';
import { loggerServicePlugin } from '@/features/core/logger';
import { ordersApiPlugin } from '../api';
import { OrdersService } from './orders-service';
import { orderMetadataServicePlugin } from '@/features/orderMetadata/services';
import { usePerformanceTracker } from '@/features/performance-tracker';

export { OrdersService } from './orders-service';

export const ordersServicePlugin = ProviderPluginFactory.create<OrdersService>({
  key: Symbol('OrdersService'),
  defaultFactory: {
    create: () => {
      return new OrdersService(
        storagePlugin.get(),
        ordersApiPlugin.get().order,
        productsServicePlugin.get(),
        loggerServicePlugin.get(),
        orderMetadataServicePlugin.get(),
        usePerformanceTracker(),
      );
    },
  },
});
