import { $t } from '@/i18n';
import type {
  CompletePipelineWith,
  PipelinePlugin,
} from '@ads/plugin-pipeline';
import CheckboxToggler from '@/features/ui/components/CheckboxToggler.vue';
import type { ConfirmOptions } from '@/features/ui/composables';
import { useDynamicDialog } from '@/features/ui/composables';
import type { OrderPluginDto } from '../types';
import type { Order } from '@/features/orders';
import { isAgeRestrictedOrder } from '@/utils/helpers/isAgeRestrictedOrder';

export class ShowPausePickingDialogPlugin
  implements PipelinePlugin<OrderPluginDto>
{
  public async execute(
    dataTransferObject: OrderPluginDto,
    completePipelineWith: CompletePipelineWith<OrderPluginDto>,
  ): Promise<OrderPluginDto> {
    const { confirm } = useDynamicDialog();
    const confirmed = await confirm(
      this.getConfirmOptions(dataTransferObject.order),
    );

    if (!confirmed) {
      return completePipelineWith(dataTransferObject);
    }

    return dataTransferObject;
  }

  private getConfirmOptions(order: Order) {
    const opts: ConfirmOptions = {
      title: $t('components.pause-dialog.title'),
      isTitleCentered: false,
      contentText: $t('components.pause-dialog.content'),
      confirmText: $t('components.pause-dialog.confirm'),
      cancelText: $t('components.pause-dialog.cancel'),
      e2e: `order-pause-dialog-popup-${order.id}`,
    };

    if (isAgeRestrictedOrder(order)) {
      opts.component = {
        component: CheckboxToggler,
        props: {
          label: $t('components.pause-dialog.legal-age'),
          big: true,
        },
      };
    }

    return opts;
  }
}
