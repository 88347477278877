import type { ScannableDeliveryUnit } from '../types';

export function groupScannedDeliveryUnitsByPosition(
  scannedDeliveryUnits: ScannableDeliveryUnit[],
): Array<{ position: string; count: number }> {
  return scannedDeliveryUnits.reduce((acc, item) => {
    const unitWithCurrentPositionIndex = acc.findIndex(
      (unit) => unit.position === item.position,
    );
    if (unitWithCurrentPositionIndex >= 0) {
      acc[unitWithCurrentPositionIndex].count =
        acc[unitWithCurrentPositionIndex].count + 1;
    } else {
      acc.push({
        position: item.position,
        count: 1,
      });
    }
    return acc;
  }, [] as Array<{ position: string; count: number }>);
}
