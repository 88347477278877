import { $t } from '@/i18n';
import { formatHours } from '@/composables/useDateFormat';
import type { Order } from '@/features/orders';
import { isOrderPicking, OrderLocalStatus } from '@/features/orders';

type StartConfirmationTexts = {
  confirmButtonText: string;
  confirmPickingContent: string;
  confirmHandoverContent: string;
  confirmedTitle: string;
};

export function startConfirmationTexts(order: Order): StartConfirmationTexts {
  const totalBags = order.bags.reduce((acc, bag) => {
    return (acc += bag.quantity);
  }, 0);
  const confirmButtonText =
    order.localStatus === OrderLocalStatus.PickingInProgress
      ? $t('components.start-confirmation-popup.continue-picking.text')
      : isOrderPicking(order)
      ? $t('components.start-confirmation-popup.start-picking.text')
      : $t('components.start-confirmation-popup.start-handover.text');
  const confirmPickingContent = `<div>
            ${$t('components.start-confirmation-popup.order-id.text', {
              orderReference: order.orderReference,
            })}</div><div>
            ${$t('components.start-confirmation-popup.articles.text', {
              itemsLength: order.items.length,
              itemsCountTotal: order.itemsCount.total,
            })}</div><div>
            ${$t('components.start-confirmation-popup.pickup-time.text', {
              startTime: formatHours(order.startTime),
            })}</div>
            <div class="red-text mt-24">
              ${$t('components.start-confirmation-popup.cannot-abort.text')}
            </div>`;
  const confirmHandoverContent = `<div>
          ${$t('components.start-confirmation-popup.pickup-code.text', {
            pickupCode: order.pickupCode,
          })}
          </div><div>
            ${$t('components.start-confirmation-popup.total-bags.text', {
              totalBags,
            })}
          </div>
          <div class="red-text mt-24">
            ${$t('components.start-confirmation-popup.cannot-abort.text')}
          </div>`;
  const confirmedTitle = isOrderPicking(order)
    ? $t('components.start-confirmation-popup.start-picking-confirmation.text')
    : $t(
        'components.start-confirmation-popup.start-handover-confirmation.text',
      );

  return {
    confirmButtonText,
    confirmPickingContent,
    confirmHandoverContent,
    confirmedTitle,
  };
}
