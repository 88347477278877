import type {
  Entity,
  EntityType,
  RepositoryReadOptions,
} from '@/features/core/entity-repository';
import type { EventBus } from '@/features/core/event-bus';
import type { Storage, StorageReadIdOptions } from '@/features/core/storage';
import { getEntityByName } from '@/utils/helpers/getEntityByName';
import { getEntityType } from '@/utils/helpers/getEntityType';
import { AppServiceWorkerNotifySyncEvent } from '@/features/service-worker';
import { DataFetchQueue } from '../entities';
import type { DataFetchQueueService } from '../types';

export class DataFetchQueueServiceImplementation
  implements DataFetchQueueService
{
  constructor(private storage: Storage, private eventBus: EventBus) {}

  async addToFetchQueue(
    id: string,
    entity: EntityType<Entity>,
    retries = 0,
    fcmMessageId?: string[],
  ): Promise<DataFetchQueue> {
    const entityType = getEntityType(entity);
    return await this.storage.save(
      DataFetchQueue.from({
        type: 'dataFetchQueue',
        id: `${id}-${entityType}`,
        originalId: id,
        entityType: entityType,
        timestamp: new Date().toISOString(),
        retries,
        fcmMessageId,
      }),
    );
  }

  async batchAddToFetchQueueIfNotIncluded(
    ids: string[] | undefined,
    entity: EntityType<Entity>,
    fcmMessageId?: string[],
  ): Promise<void> {
    if (!ids) {
      return;
    }
    await Promise.all(
      ids.map((id) =>
        this.addToFetchQueueIfNotIncluded(id, entity, fcmMessageId),
      ),
    );
  }

  async getStoredEntities(
    options: RepositoryReadOptions = {},
  ): Promise<string[]> {
    const dataFetchQueueEntries = await this.storage.getAll(
      DataFetchQueue,
      options,
    );
    const storedEntities = new Set<string>();
    dataFetchQueueEntries.forEach((entry) => {
      storedEntities.add(entry.entityType);
    });
    return Array.from(storedEntities);
  }

  async getAll(options: RepositoryReadOptions = {}): Promise<DataFetchQueue[]> {
    return await this.storage.getAll(DataFetchQueue, options);
  }

  async getById(
    options: StorageReadIdOptions,
  ): Promise<DataFetchQueue | undefined> {
    return await this.storage.getById(DataFetchQueue, options);
  }

  async updateAllRetriesOfEntity(entity: EntityType<Entity>): Promise<void> {
    const allEntriesOfEntity = await this.storage.getAll(DataFetchQueue, {
      filter: { entityType: { equals: getEntityType(entity) } },
    });
    await Promise.all(
      allEntriesOfEntity.map((entry) => this.updateEntryRetriesCount(entry)),
    );
  }

  async removeAllOfEntity(entity: EntityType<Entity>): Promise<void> {
    const allEntriesOfEntity = await this.storage.getAll(DataFetchQueue, {
      filter: { entityType: { equals: getEntityType(entity) } },
    });
    await Promise.all(
      allEntriesOfEntity.map((entry) => this.removeById(entry.id)),
    );
  }

  async removeById(id: string): Promise<void> {
    await this.storage.remove(
      DataFetchQueue.from({
        id,
        type: 'dataFetchQueue',
      }),
    );
  }

  async isEmpty(): Promise<boolean> {
    return !(await this.getAll()).length;
  }

  async triggerSyncForOutstandingEntities(): Promise<void> {
    const queueList = await this.getAll();
    queueList.forEach((queueItem) =>
      this.eventBus.emit(
        new AppServiceWorkerNotifySyncEvent(
          queueItem.entityType,
          queueItem.originalId,
        ),
      ),
    );
  }

  private async updateFetchQueueEntityMessageId(
    entry: DataFetchQueue,
    fcmMessageId: string[],
  ): Promise<DataFetchQueue> {
    const entryEntity = getEntityByName(entry.entityType);
    if (!entryEntity) {
      return entry;
    }

    const fcmMessageIds = [
      ...new Set([...entry.fcmMessageId, ...fcmMessageId]),
    ];

    return await this.addToFetchQueue(
      entry.originalId,
      entryEntity,
      entry.retries,
      fcmMessageIds,
    );
  }

  private async addToFetchQueueIfNotIncluded(
    id: string,
    entity: EntityType<Entity>,
    fcmMessageId?: string[],
  ) {
    const entityInFetchQueue = await this.getByIdAndEntity(id, entity);
    if (!entityInFetchQueue) {
      await this.addToFetchQueue(id, entity, 0, fcmMessageId);

      return;
    }

    if (!fcmMessageId || !fcmMessageId.length) {
      return;
    }

    await this.updateFetchQueueEntityMessageId(
      entityInFetchQueue,
      fcmMessageId,
    );
  }

  private async updateEntryRetriesCount(entry: DataFetchQueue): Promise<void> {
    const entryEntity = getEntityByName(entry.entityType);
    if (entryEntity) {
      await this.addToFetchQueue(
        entry.originalId,
        entryEntity,
        entry.retries + 1,
        entry.fcmMessageId,
      );
    }
  }

  private async getByIdAndEntity(
    id: string,
    entity: EntityType<Entity>,
  ): Promise<DataFetchQueue | undefined> {
    const entityType = getEntityType(entity);
    return await this.storage.getById(DataFetchQueue, {
      id: `${id}-${entityType}`,
    });
  }
}
