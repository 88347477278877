
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'RadioGroup',
  emits: ['selectedChange'],
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    options: {
      type: Array as () => {
        value: string | number | boolean | undefined;
        label: string;
        e2eLabel: string;
      }[],
      required: true,
    },
    isAllDisabled: {
      type: Boolean,
      default: false,
    },
    isPreSelected: {
      type: Boolean,
      default: true,
    },
    e2e: {
      type: String,
      required: false,
    },
  },
  setup: function (props, { emit }) {
    const defaultValue = props.isPreSelected
      ? props.value ?? props.options[0].value
      : null;
    const selected = ref(defaultValue);
    emit('selectedChange', selected.value);

    watch(
      () => props.value,
      () => {
        selected.value = props.value;
      },
    );
    return {
      props,
      selected,
    };
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateInput(event: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.$emit('selectedChange', event.target._value);
    },
  },
});
