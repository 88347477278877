import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ut-d-flex" }

import type { PropType } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { useRouter } from 'vue-router';
import { ArrowLeftIcon } from '@/features/ui/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'BackButton',
  props: {
  to: {
    type: [String, Object] as PropType<RouteLocationRaw>,
    default: '/',
  },
  beforeNavigate: {
    type: Function as PropType<(() => void | Promise<void>) | null>,
    default: null,
  },
},
  setup(__props) {

const props = __props;

const router = useRouter();

const handleClick = async (event: Event) => {
  if (props.beforeNavigate) {
    event.preventDefault();
    await props.beforeNavigate();
  }

  await router.push(props.to);
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: __props.to,
      "data-e2e": "header-back-link",
      onClick: _withModifiers(handleClick, ["prevent"])
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(ArrowLeftIcon), { width: "24" })
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}
}

})