import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "popup__title",
  "data-e2e": "dialog-title"
}
const _hoisted_2 = { class: "popup__subtitle" }

import { computed, ref, watch } from 'vue';
import { barcodeServicePlugin, isValidBarcode } from '@/features/barcode';
import { useWeightList } from '@/features/ui/composables';
import { MAX_BARCODE_CHARS, MIN_BARCODE_CHARS } from '../types';
import BarcodeInput from './BarcodeInput.vue';
import Button from './Button.vue';
import Popup from './Popup.vue';
import ScreenLoading from './ScreenLoading.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'WeightListBarcodePopup',
  props: {
    visible: { type: Boolean }
  },
  emits: ["submitBarcode", "close"],
  setup(__props: any, { emit: __emit }) {



const { isBarcodeValidating } = useWeightList();
const loading = ref(false);

const emit = __emit;

const isBarcodeValid = ref(true);
const barcodeValue = ref('');

const isBarcodeExistAndValid = async () => {
  loading.value = true;
  let barcode = barcodeValue.value;
  if (barcodeValue.value.startsWith('0')) {
    // To prevent check and save manual barcode started from 0
    barcode = barcodeValue.value.substring(1);
  }

  const isProductForBarcodeExist = await barcodeServicePlugin
    .get()
    .isProductForBarcodeExist(barcode);

  isBarcodeValid.value = isProductForBarcodeExist && isValidBarcode(barcode);

  if (isBarcodeValid.value) barcodeValue.value = barcode;
  loading.value = false;
  return isBarcodeValid.value;
};

watch(
  () => barcodeValue.value,
  () => {
    isBarcodeValid.value = true;
  },
  {
    immediate: true,
  },
);

const confirmBarcode = async () => {
  if (isBarcodeConfirmDisabled.value) {
    return;
  }
  isBarcodeValidating.value = true;
  const isBarcodeValid = await isBarcodeExistAndValid();
  isBarcodeValidating.value = false;

  if (!isBarcodeValid) return;
  emit('submitBarcode', barcodeValue.value);
  closePopup();
  barcodeValue.value = '';
};

const closePopup = () => {
  emit('close');
};

const isBarcodeConfirmDisabled = computed<boolean>(
  () =>
    barcodeValue.value.length > MAX_BARCODE_CHARS ||
    barcodeValue.value.length < MIN_BARCODE_CHARS,
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Popup, {
    isCloseButtonExist: false,
    isContentCentered: false,
    visible: _ctx.visible,
    isSpaceToContentExist: "",
    "data-e2e": "scan-barcode-popup"
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('pages.picking-order.scan-barcode.title')), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('pages.picking-order.scan-barcode.subtitle')), 1),
      _createVNode(BarcodeInput, {
        modelValue: barcodeValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((barcodeValue).value = $event)),
        isBarcodeValid: isBarcodeValid.value,
        class: "scan-barcode-popup__input",
        onSubmit: _withModifiers(confirmBarcode, ["prevent"])
      }, null, 8, ["modelValue", "isBarcodeValid"]),
      _createVNode(Button, {
        class: "scan-barcode-popup__submit",
        "data-e2e": "scan-barcode-popup__submit",
        disabled: isBarcodeConfirmDisabled.value,
        onClick: confirmBarcode
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('pages.picking-order.scan-barcode.confirm.text')), 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(Button, {
        btnWhite: "",
        class: "scan-barcode-popup__cancel",
        "data-e2e": "scan-barcode-popup__cancel",
        onClick: closePopup
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('pages.picking-order.scan-barcode.cancel.text')), 1)
        ]),
        _: 1
      }),
      (loading.value)
        ? (_openBlock(), _createBlock(ScreenLoading, {
            key: 0,
            title: _ctx.$t('components.card-order.button-loading.text'),
            blur: ""
          }, null, 8, ["title"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})