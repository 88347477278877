import type {
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
} from '@/features/core/router/types';

// NOTE: route.query is empty if the route is similar to '#picking?id=123'
export function getParamFromHashtagUrl(
  route: RouteLocationNormalizedLoaded | RouteLocationNormalized,
  parameter: string,
): string | undefined {
  const parametersWithoutHash = route.hash.substring(
    route.hash.lastIndexOf('?') + 1,
  );

  return parametersWithoutHash
    ? parametersWithoutHash
        .split('&')
        .find((param) => param.startsWith(parameter + '='))
        ?.split('=')[1]
    : undefined;
}
