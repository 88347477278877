import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = {
  key: 0,
  class: "section__title"
}
const _hoisted_3 = { class: "section__list" }


export default /*@__PURE__*/_defineComponent({
  __name: 'HandoverSection',
  props: {
    title: {},
    isTitleEnabled: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title && !_ctx.isTitleEnabled)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})