import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "popup__title",
  "data-e2e": "popup-title-failed-transmission"
}
const _hoisted_2 = { "data-e2e": "failed-transmission-headline" }
const _hoisted_3 = { "data-e2e": "failed-transmission-body-text" }
const _hoisted_4 = { class: "transmissions-button" }
const _hoisted_5 = {
  key: 3,
  class: "transmissions-button__hint-text"
}
const _hoisted_6 = { class: "accordeon-title accordeon-title--failed" }
const _hoisted_7 = { class: "accordeon-title accordeon-title--ongoing" }

import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { Button, Popup } from '@/features/ui';
import { InfoIcon, LoadingIcon } from '@/features/ui/icons';

import { eventBusServicePlugin } from '@/features/core/event-bus';
import { syncSchedulerServicePlugin } from '@/features/sync-scheduler';
import { AppServiceWorkerNotifySyncEvent } from '@/features/service-worker';
import { $t } from '@/i18n';
import TransmissionsAccordeon from './TransmissionsAccordeon.vue';
import { useTransmissions } from '../composables';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import { useOnlineStatus } from '@/features/orders';
import { loggerServicePlugin } from '@/features/core/logger';


export default /*@__PURE__*/_defineComponent({
  __name: 'Transmissions',
  setup(__props) {

const {
  failedTransmissions,
  pendingTransmissions,
  hasFailedTransmissions,
  hasPendingTransmissions,
  checkTransmissions,
  sendFailedTransmissionReport,
} = useTransmissions();

const isSupportInformed = ref(false);
const isSupportBeingInformed = ref(false);
const retryButtonDisabledTimeoutId: Ref<number | null> = ref(null);

const { isOnline } = useOnlineStatus();

const delayBetweenTransmissionRetryDisable =
  Number(process.env.VUE_APP_TRANSMISSION_RETRY_DELAY) || 60 * 1000;

const headlineText = computed(() => {
  switch (true) {
    case !isOnline.value:
      return $t('components.transmissions.headline.paused-transmission.text');
    case hasFailedTransmissions.value && isSupportInformed.value:
      return $t('components.transmissions.headline.support-informed.text');
    case hasFailedTransmissions.value:
      return $t('components.transmissions.headline.failed-transmission.text');
    default:
      return $t('components.transmissions.headline.ongoing-transmission.text');
  }
});

const bodyText = computed(() => {
  switch (true) {
    case !isOnline.value:
      return $t('components.transmissions.body.offline-device.text');
    case hasFailedTransmissions.value && isSupportInformed.value:
      return $t('components.transmissions.body.will-get-notified.text');
    case hasFailedTransmissions.value:
      return $t('components.transmissions.body.inform-support.text');
    case hasPendingTransmissions.value:
      return $t('components.transmissions.body.speed-up-hint.text');
    default:
      return $t('components.transmissions.body.everything-in-sync.text');
  }
});

const showInformSupportNotification = (result: boolean) => {
  let notificationType = NotificationType.Error;
  let notificationText = $t(
    'components.transmissions.inform-support-button.error-message',
  );
  if (result) {
    notificationType = NotificationType.Success;
    notificationText = $t(
      'components.transmissions.inform-support-button.success-message',
    );
  }
  notificationPlugin.get().show({
    text: notificationText,
    type: notificationType,
  });
};

const informSupport = async () => {
  isSupportBeingInformed.value = true;
  const result = await sendFailedTransmissionReport();
  isSupportBeingInformed.value = false;

  isSupportInformed.value = result;
  showInformSupportNotification(result);
};

const syncSchedulerService = syncSchedulerServicePlugin.get();
const isRetryLoadingDisabled = ref(false);

const resetRetriesOfFailed = async () => {
  await syncSchedulerService.resetRetriesOfFailed();
};

const resetDisabledButton = () => {
  clearTimeout(retryButtonDisabledTimeoutId.value);
  retryButtonDisabledTimeoutId.value = null;
  isRetryLoadingDisabled.value = false;
};

const onFailedRetry = async () => {
  loggerServicePlugin.get().info('Retry failed transmissions button clicked');

  await resetRetriesOfFailed();
  resetDisabledButton();
  checkTransmissions();
};

const retryLoading = () => {
  if (isRetryLoadingDisabled.value) {
    return;
  }

  loggerServicePlugin.get().info('Retry loading button clicked');

  const timestamp = String(new Date().getTime());

  eventBusServicePlugin
    .get()
    .emit(new AppServiceWorkerNotifySyncEvent('', '', timestamp));

  isRetryLoadingDisabled.value = true;

  // Enable RetryLoading button after 1 minute
  retryButtonDisabledTimeoutId.value = setTimeout(() => {
    isRetryLoadingDisabled.value = false;
  }, delayBetweenTransmissionRetryDisable) as number;
};

const isInformBtnVisible = computed(
  () => hasFailedTransmissions.value && !isSupportInformed.value,
);

const isRetryFailedBtnVisible = computed(
  () => hasFailedTransmissions.value && isSupportInformed.value,
);

const isRetryPendingBtnVisible = computed(
  () => !hasFailedTransmissions.value && hasPendingTransmissions.value,
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Popup), {
    visible: true,
    bottom: "",
    fixedHeader: "",
    fullPageSize: ""
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_unref($t)('components.transmissions.title.text')), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(headlineText.value), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(bodyText.value), 1),
      _createElementVNode("div", _hoisted_4, [
        (isRetryFailedBtnVisible.value)
          ? (_openBlock(), _createBlock(_unref(Button), {
              key: 0,
              disabled: !_unref(isOnline),
              btnOutline: "",
              class: "confirm",
              "data-e2e": "failed-transmission-button-retry-loading",
              onClick: onFailedRetry
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref($t)('components.transmissions.retry-button.text')), 1)
              ]),
              _: 1
            }, 8, ["disabled"]))
          : _createCommentVNode("", true),
        (isRetryPendingBtnVisible.value)
          ? (_openBlock(), _createBlock(_unref(Button), {
              key: 1,
              btnOutline: isRetryLoadingDisabled.value || !_unref(isOnline),
              disabled: isRetryLoadingDisabled.value || !_unref(isOnline),
              class: "confirm",
              "data-e2e": "failed-transmission-button-retry-loading",
              onClick: retryLoading
            }, {
              default: _withCtx(() => [
                (isRetryLoadingDisabled.value)
                  ? (_openBlock(), _createBlock(_unref(LoadingIcon), {
                      key: 0,
                      class: "loading-icon-animation",
                      fill: "rgb(var(--aldi-gray-light))",
                      width: "24"
                    }))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_unref($t)('components.transmissions.retry-button.text')), 1)
              ]),
              _: 1
            }, 8, ["btnOutline", "disabled"]))
          : _createCommentVNode("", true),
        (isInformBtnVisible.value)
          ? (_openBlock(), _createBlock(_unref(Button), {
              key: 2,
              "btn-outline": !_unref(isOnline),
              disabled: isSupportBeingInformed.value || !_unref(isOnline),
              loading: isSupportBeingInformed.value,
              class: "confirm",
              "data-e2e": "failed-transmission-button-inform-support",
              onClick: informSupport
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref($t)('components.transmissions.inform-support-button.text')), 1)
              ]),
              _: 1
            }, 8, ["btn-outline", "disabled", "loading"]))
          : _createCommentVNode("", true),
        (isRetryPendingBtnVisible.value && isRetryLoadingDisabled.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_unref(InfoIcon), {
                fill: "rgb(var(--aldi-gray-mid))",
                width: "16"
              }),
              _createTextVNode(" " + _toDisplayString(_unref($t)('components.transmissions.button-locked-hint.text')), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      (_unref(hasFailedTransmissions))
        ? (_openBlock(), _createBlock(TransmissionsAccordeon, {
            key: 0,
            transmissionsList: _unref(failedTransmissions),
            e2ePrefix: "failed-transmissions"
          }, {
            headline: _withCtx(() => [
              _createVNode(_unref(InfoIcon), { fill: "rgb(var(--aldi-red-100))" }),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", null, _toDisplayString(_unref($t)('components.transmissions.failed-transmissions.text')), 1),
                _createTextVNode(" (" + _toDisplayString(_unref(failedTransmissions).length) + ") ", 1)
              ])
            ]),
            _: 1
          }, 8, ["transmissionsList"]))
        : _createCommentVNode("", true),
      (_unref(hasPendingTransmissions))
        ? (_openBlock(), _createBlock(TransmissionsAccordeon, {
            key: 1,
            transmissionsList: _unref(pendingTransmissions),
            e2ePrefix: "ongoing-transmissions"
          }, {
            headline: _withCtx(() => [
              _createVNode(_unref(LoadingIcon), {
                class: "loading-icon-animation",
                width: "24"
              }),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", null, _toDisplayString(_unref($t)('components.transmissions.ongoing-transmissions.text')), 1),
                _createTextVNode(" (" + _toDisplayString(_unref(pendingTransmissions).length) + ") ", 1)
              ])
            ]),
            _: 1
          }, 8, ["transmissionsList"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})