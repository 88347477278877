import type { Ref } from 'vue';
import { onMounted, ref } from 'vue';
import type { OrderItem, OrderWeight } from '@/features/orders';
import { cloneItem } from '@/utils/helpers/clone';
import type { WeightPopupComposableValue } from '../types';

export function useWeightPopup(
  orderItems: Ref<OrderItem[] | undefined>,
  id: string | null | undefined,
  status?: string,
): WeightPopupComposableValue {
  const order = ref<OrderItem>();
  const visible = ref(false);
  const initialWeight = ref<number>(0);
  const weightBefore = ref<OrderWeight[]>([]);

  onMounted(() => {
    if (!orderItems.value) {
      return;
    }
    const orders = cloneItem<OrderItem>(orderItems.value);
    const currentOrder = orders.find(
      (order: OrderItem) =>
        order.id === id && (!status || order.status === status),
    );
    if (currentOrder?.weights && currentOrder?.weights.length) {
      weightBefore.value = [...currentOrder.weights];
    }
    initialWeight.value = weightBefore.value.reduce(
      (acc, item) => item.weight + acc,
      0,
    );
    order.value = currentOrder;
    visible.value = true;
  });

  return {
    order,
    visible,
    weightBefore,
    initialWeight,
  };
}
