import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/start_page@2x.png'


const _hoisted_1 = {
  key: 1,
  class: "picking-start-page"
}
const _hoisted_2 = { class: "picking-start-page__header" }
const _hoisted_3 = { class: "picking-start-page__title" }
const _hoisted_4 = {
  class: "picking-start-page__id",
  "data-e2e": "order-reference"
}
const _hoisted_5 = {
  class: "picking-start-page__message",
  "data-e2e": "notice"
}
const _hoisted_6 = { class: "picking-start-page__main" }
const _hoisted_7 = { class: "picking-start-page__note" }
const _hoisted_8 = {
  class: "title",
  "data-e2e": "sub-title"
}
const _hoisted_9 = { class: "picking-start-page__row" }
const _hoisted_10 = { "data-e2e": "timeslot" }
const _hoisted_11 = { class: "picking-start-page__total" }
const _hoisted_12 = { "data-e2e": "total-count" }
const _hoisted_13 = {
  key: 0,
  class: "picking-start-page__note"
}
const _hoisted_14 = {
  class: "title",
  "data-e2e": "customer-note-title"
}
const _hoisted_15 = { "data-e2e": "customer-note" }
const _hoisted_16 = {
  key: 1,
  class: "picking-start-page__note picking-start-page__toggle"
}
const _hoisted_17 = {
  class: "fixed-footer-content__footer",
  "data-e2e": "picking-footer"
}
const _hoisted_18 = { class: "fixed-footer-content__footer-button" }
const _hoisted_19 = {
  class: "popup__title",
  "data-e2e": "dialog-title"
}
const _hoisted_20 = { class: "popup__content-container" }

import { computed, onMounted, ref, watch } from 'vue';
import { useOrder } from '@/features/picking';
import {
  AppHeader,
  BackButton,
  Button,
  CheckboxToggler,
  Popup,
  ScreenLoading,
} from '@/features/ui/components';
import { formatHours } from '@/composables/useDateFormat';
import { CartIcon } from '@/features/ui';
import { useRouter } from 'vue-router';
import {
  Order,
  OrderLocalStatus,
  startPickingProcess,
  useOnlineStatus,
} from '@/features/orders';
import { ordersServicePlugin } from '@/features/orders/services';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import { EntityUpdatedEvent } from '@/features/core/entity-repository';
import { $t } from '@/i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'PickingStartedPage',
  props: {
  id: { type: String, required: true },
},
  setup(__props) {

const props = __props;

const { order, loadOrder, redirectIfOrderNotPicking } = useOrder();
const { isOnline } = useOnlineStatus();
const processing = ref(false);

const isUserTokenRefreshing = ref(false);
const isOrderAlreadyStarted = ref(false);
const isPickingCancelledOrCompleted = ref(false);
const isPageLoadingVisible = computed(
  () => isUserTokenRefreshing.value || processing.value,
);
const screenLoadingTitle = computed(
  () =>
    /* eslint-disable @typescript-eslint/no-unsafe-call */
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    isUserTokenRefreshing.value
      ? $t('pages.order-overview.refreshing-user-token.text')
      : $t('pages.bags-collection.screen-loading-label.success'),
  /* eslint-enable @typescript-eslint/no-unsafe-call */
);

onMounted(async () => {
  processing.value = true;
  await loadOrder(props.id);
  processing.value = false;
  await redirectIfOrderNotPicking(order.value);
});

const popupData = computed(() => {
  const getTitle = () => {
    if (isOrderAlreadyStarted.value) {
      return $t('pages.picking-start-page.is-already-started-popup-title.text');
    }

    if (isPickingCancelledOrCompleted.value) {
      return $t(
        'pages.picking-start-page.is-picking-cancelled-or-completed-popup-title.text',
      );
    }

    return $t('pages.picking-start-page.generic-error-popup-title.text');
  };

  const getBody = () => {
    if (isOrderAlreadyStarted.value) {
      return $t('pages.picking-start-page.is-already-started-popup-body.text');
    }

    if (isPickingCancelledOrCompleted.value) {
      return $t(
        'pages.picking-start-page.is-picking-cancelled-or-completed-popup-body.text',
      );
    }

    return $t('pages.picking-start-page.generic-error-popup-body.text');
  };

  const getButtonText = () => {
    if (isOrderAlreadyStarted.value) {
      return $t(
        'pages.picking-start-page.is-already-started-popup-button.text',
      );
    }

    if (isPickingCancelledOrCompleted.value) {
      return $t(
        'pages.picking-start-page.is-picking-cancelled-or-completed-popup-button.text',
      );
    }

    return $t('pages.picking-start-page.generic-error-popup-button.text');
  };

  return {
    title: getTitle(),
    body: getBody(),
    buttonText: getButtonText(),
    isVisible:
      isOrderAlreadyStarted.value || isPickingCancelledOrCompleted.value,
  };
});

const isAgeRestricted = computed(() => {
  if (order.value?.ageVerification) {
    return order.value.ageVerification?.requiredAge > 0;
  }
  return false;
});

const disabledStartPicking = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return isAgeRestricted.value && !isLegalAge.value;
});

const buttonLabel = computed(() => {
  if (
    order.value?.localStatus === OrderLocalStatus.Paused ||
    order.value?.localStatus === OrderLocalStatus.PickingInProgress
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return $t('components.card-order.button-continue-picking.text');
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
  return $t('pages.picking-start-page.start-picking.text');
});

const startPicking = async () => {
  if (order.value) {
    await startPickingProcess(order.value, {
      isProcessing: processing,
      isUserTokenRefreshing,
      isOrderAlreadyStarted,
      isPickingCancelledOrCompleted,
    });
  }
};

const formattedTime = computed(() => {
  return order.value
    ? formatHours(order.value.startTime) +
        ' - ' +
        formatHours(order.value.endTime)
    : '';
});

const isLegalAge = ref(false);

const itemsCount = computed(
  () =>
    order.value?.items.reduce((acc, item) => {
      acc += item.quantityOriginal;
      return acc;
    }, 0) || 0,
);

const router = useRouter();
const returnToOrderList = () => {
  void router.push({
    name: 'default',
  });
};

const goToOverviewPage = async () => {
  if (order.value) {
    await ordersServicePlugin.get().fetchOrdersByIds([order.value.id], false);
    eventBusServicePlugin.get().emit(
      new EntityUpdatedEvent([
        {
          entity: Order,
          updated: true,
          ids: [order.value.id],
        },
      ]),
    );
    returnToOrderList();
  }
};

watch(isOnline, (newValue) => {
  if (!newValue && !processing.value) {
    returnToOrderList();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isPageLoadingVisible.value)
      ? (_openBlock(), _createBlock(_unref(ScreenLoading), {
          key: 0,
          title: screenLoadingTitle.value,
          blur: "",
          fixed: "",
          inFront: ""
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    (_unref(order))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_unref(AppHeader), { class: "header" }, {
            default: _withCtx(() => [
              _createVNode(_unref(BackButton), { to: "/" })
            ]),
            _: 1
          }),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "picking-start-page__image" }, [
            _createElementVNode("img", {
              alt: "",
              class: "ut-img-contain",
              "data-e2e": "picking-start-img",
              src: _imports_0
            })
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_unref($t)('pages.picking-start-page.title.text')), 1),
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_unref(order).orderReference), 1)
          ]),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_unref($t)('pages.picking-start-page.notice.text')), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_unref($t)('pages.picking-start-page.details.text')), 1),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("p", _hoisted_10, _toDisplayString(formattedTime.value), 1),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, _toDisplayString(itemsCount.value), 1),
                  _createVNode(_unref(CartIcon), {
                    fill: "#0d3a93",
                    width: "18px"
                  })
                ])
              ])
            ]),
            (_unref(order).cartNote)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_unref($t)('pages.picking-start-page.customer-note.text')), 1),
                  _createElementVNode("p", _hoisted_15, _toDisplayString(_unref(order).cartNote), 1)
                ]))
              : _createCommentVNode("", true),
            (isAgeRestricted.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createVNode(_unref(CheckboxToggler), {
                    modelValue: isLegalAge.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isLegalAge).value = $event)),
                    big: "",
                    "data-e2e": "is-legal-age-toggle"
                  }, null, 8, ["modelValue"]),
                  _createTextVNode(" " + _toDisplayString(_unref($t)('pages.picking-start-page.legalAge.text')), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_unref(Button), {
          disabled: disabledStartPicking.value,
          "data-e2e": "start-picking-button",
          onClick: startPicking
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(buttonLabel.value), 1)
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(_unref(Button), {
          disabled: processing.value,
          btnOutline: "",
          "data-e2e": "cancel-picking-button",
          plain: "",
          onClick: returnToOrderList
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref($t)('pages.picking-start-page.cancel.text')), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ]),
    _createVNode(_unref(Popup), {
      isCloseButtonExist: false,
      visible: popupData.value.isVisible,
      "data-e2e": "is-order-already-started-popup",
      isContentCentered: "",
      isSpaceToContentExist: "",
      withoutHeader: ""
    }, {
      footer: _withCtx(() => [
        _createVNode(_unref(Button), {
          "data-e2e": "cancel-picking-button",
          onClick: goToOverviewPage
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(popupData.value.buttonText), 1)
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("h3", _hoisted_19, _toDisplayString(popupData.value.title), 1),
        _createElementVNode("div", _hoisted_20, _toDisplayString(popupData.value.body), 1)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})