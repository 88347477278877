import type { Ref, ComputedRef } from 'vue';
import type { Order, OrderBag } from '@/features/orders';
import type { DeliveryUnit } from '@/features/delivery-unit';

export interface UseBagsCollection {
  order: Ref<Order | undefined>;
  loadOrder: (id: string) => Promise<void>;
  deliveryUnits: Ref<DeliveryUnit[] | undefined>;
}

export interface UseBagsOrder {
  order: Ref<Order | undefined>;
  loadOrder: (id: string) => Promise<void>;
  bags: ComputedRef<Record<string, OrderBag>>;
}

export interface UseBagsStorage {
  saveBagsQuantityToStorage: (bagsArray: OrderBag[] | undefined) => void;
  getBagsQuantityFromStorage: () => OrderBag[] | null;
}

export enum BagQrVariables {
  PickupCode = 'pickupCode',
  LabelNumber = 'labelNumber',
}

export const LABEL_FOR_MANUALLY_SCAN = 'NO-LOCATION';
export const DEFAULT_MANUALLY_SCAN = 'default barcode';
