
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import { BaseButton, AppHeader, BackButton } from '@/features/ui';
import ScreenLoading from '@/features/ui/components/ScreenLoading.vue'; // NOTE:  Failed to resolve component, when importing via barrel
import DeliveryUnitsSection from '../components/DeliveryUnitsSection.vue';
import { startHandoverProcess, useBagsCollection } from '../composables';
import BagsEmptySection from '../components/BagsEmptySection.vue';
import ChipTime from '@/features/ui/components/ChipTime.vue';

export default defineComponent({
  components: {
    ChipTime,
    BagsEmptySection,
    DeliveryUnitsSection,
    BaseButton,
    AppHeader,
    BackButton,
    ScreenLoading,
  },
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const { order, loadOrder, deliveryUnits } = useBagsCollection();
    const processing = ref(false);

    onBeforeMount(async () => {
      await loadOrder(props.id);
    });

    const submitHandover = async () => {
      if (order.value) {
        await startHandoverProcess(order.value, processing);
      }
    };

    const allItemsPicked = ref(false);
    const onAllItemsPicked = (picked: boolean) => {
      allItemsPicked.value = picked;
    };

    const isSubmitAllowed = computed(
      (): boolean => !(allItemsPicked.value && !processing.value),
    );

    return {
      deliveryUnits,
      submitHandover,
      order,
      processing,
      isSubmitAllowed,
      onAllItemsPicked,
    };
  },
});
