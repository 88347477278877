import type { ApiClient, Response } from '@/features/core/api';
import type { UserResponse } from '@/features/user/types';
import { User } from '../entities';
import { GetUserByIdRequestError } from '../errors';

export class UserApiClient {
  constructor(private api: ApiClient) {}

  private parseUser(user: UserResponse) {
    return User.from({
      id: user.id,
      email: user.attributes.email,
    });
  }

  async getById(userId: string): Promise<User> {
    const userResponse = await this.api.client.get<Response<UserResponse>>(
      `users/${userId}`,
      {
        innerErrorCode: GetUserByIdRequestError.Code,
      },
    );
    const user = this.parseUser(userResponse.data.data);

    return user;
  }
}
