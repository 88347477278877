<template>
  <div class="tab">
    <router-link
      class="tab__link"
      v-for="link in linkList"
      :key="link.linkTo"
      :to="link.linkTo"
      :class="{
        tab__active: link.linkTo === activeTab.linkTo,
      }"
      :data-e2e="`tab-order-link-${link.linkTo}`"
      @click="emit(Events.ChangeTab, link)"
    >
      {{ link.label }}
      <slot name="badge" v-bind="link"></slot>
      <pulsating-dot v-if="link.hasReminder" />
    </router-link>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import type { Tab } from '@/features/orders';
import PulsatingDot from '@/features/ui/components/PulsatingDot.vue';

const Events = {
  ChangeTab: 'change-tab',
} as const;

export default defineComponent({
  name: 'Tab',
  components: {
    PulsatingDot,
  },
  props: {
    activeTab: {
      type: Object as PropType<Tab>,
      required: true,
    },
    linkList: {
      type: Array as PropType<Tab[]>,
      required: true,
    },
  },
  emits: Object.values(Events),
  setup(props, { emit }) {
    return {
      emit,
      Events,
    };
  },
});
</script>
<style lang="scss" scoped>
.tab {
  display: flex;
  padding: 4px;
  margin: 0 24px;

  /* stylelint-disable-next-line color-function-notation --  Linter takes this as one value. */
  border: 1px solid rgba(var(--aldi-light-blue-borders));
  border-radius: 10px;
  box-sizing: border-box;

  &__link {
    display: flex;
    gap: 0 4px;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    text-decoration: none;
    color: rgba(var(--aldi-mid-blue-100), 1);
    border-radius: 8px;
    transition: all 0.2s;
    flex: 1 1 0;

    &.tab__active {
      color: #fff;
      background: rgba(var(--aldi-mid-blue-100), 1);
    }
  }
}
</style>
