import type { OrderItem } from '@/features/orders';
import type { Product } from '@/features/products';
import { createBarcodeLookupResult } from './createBarcodeLookupResult';
import type {
  BarcodeLookupResult,
  BarcodeType,
  IBarcodeFoundService,
  FindConcreteItemByBarcodePrefixMatchResult,
} from '../types';
import { calculateWeight } from './calculateWeight';

interface FindEntityWithEmbeddedBarcodeOptions {
  barcodeFoundService: IBarcodeFoundService;
  barcode: string;
  dataset: OrderItem[] | Product[];
  barcodeType: BarcodeType;
  findConcreteItemResult:
    | FindConcreteItemByBarcodePrefixMatchResult
    | undefined;
}

const getPrefixes = (
  service: IBarcodeFoundService,
  type: BarcodeType,
): Promise<string[]> => {
  switch (type) {
    case 'priceEmbedded':
      return service.getPriceEmbeddedBarcodePrefixes();
    case 'weightEmbedded':
      return service.getWeightEmbeddedBarcodePrefixes();
    default:
      return Promise.resolve([]);
  }
};

export const findEntityWithEmbeddedBarcode = async ({
  barcodeFoundService,
  barcode,
  dataset,
  barcodeType,
  findConcreteItemResult,
}: FindEntityWithEmbeddedBarcodeOptions): Promise<BarcodeLookupResult | null> => {
  if (findConcreteItemResult) {
    const barcodePrefixes = await getPrefixes(barcodeFoundService, barcodeType);
    const barcodeWithMatchingPrefix = barcodePrefixes.find((prefix) =>
      barcode.startsWith(prefix),
    );
    if (barcodeWithMatchingPrefix && dataset.length > 0) {
      const extractResult = barcodeFoundService.extractEmbeddedValueFromBarcode(
        findConcreteItemResult,
        barcodeType,
      );
      if (extractResult) {
        const productOrItem = extractResult.concreteItem;
        if (productOrItem !== undefined) {
          return createBarcodeLookupResult(productOrItem, {
            weight: calculateWeight(productOrItem, extractResult),
            barcodeType: extractResult.barcodeType,
          });
        }
      }
    }
  }

  return null;
};
