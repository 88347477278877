import type { RestageOrderPluginDto } from '../types';
import { Order } from '@/features/orders';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { Storage } from '@/features/core/storage';
import type { EventBus } from '@/features/core/event-bus';
import type { EntityType } from '@/features/core/entity-repository';
import { EntityUpdatedEvent } from '@/features/core/entity-repository';
import type { EntityUpdate } from '@/features/service-worker';
import { EmitHandoverAbortedBusEventPlugin } from './emit-handover-aborted-bus-event';
import { TrackEventRestagingCompletedPlugin } from './track-event-restaging-completed';
import { UpdateLocalStatusPlugin } from './update-local-status';
import { AppCrashPlugin } from './app-crash';

export class SaveOrderToStoragePlugin
  implements PipelinePlugin<RestageOrderPluginDto>
{
  public readonly requiredPlugins = [
    EmitHandoverAbortedBusEventPlugin,
    TrackEventRestagingCompletedPlugin,
    UpdateLocalStatusPlugin,
    AppCrashPlugin,
  ];

  constructor(private storage: Storage, private eventBus: EventBus) {}

  public async execute(
    dataTransferObject: RestageOrderPluginDto,
  ): Promise<RestageOrderPluginDto> {
    await this.storage.save(Order.from(dataTransferObject.order));
    const entityUpdateData: EntityUpdate<EntityType<Order>>[] = [
      {
        entity: Order,
        ids: [dataTransferObject.order.id],
        updated: true,
      },
    ];

    this.eventBus.emit(new EntityUpdatedEvent(entityUpdateData));

    return dataTransferObject;
  }
}
