import type {
  BarcodeCallback,
  BarcodeServiceConfig,
  IBarcodeService,
} from '../interfaces';
import type { Cancellable } from '@/utils/types';

import { onBeforeUnmount, onMounted } from 'vue';
import { BarcodePatterns } from '../constants';

export class BarcodeService implements IBarcodeService {
  private subscribers = new Set<BarcodeCallback>();
  private readonly barcodeRegex: RegExp;
  private readonly timeout = 100;
  private capturedKeys: string[] = [];
  private timer: number | undefined = 0;
  private readonly allowedCharacterRegex = /^[a-zA-Z0-9.;:=|-]$/;
  private eventHandler: ((event: KeyboardEvent) => void) | null = null;
  private readonly defaultRegex = BarcodePatterns.ProductBarcode;

  constructor(config: BarcodeServiceConfig) {
    this.barcodeRegex = config?.patterns?.ProductBarcode ?? this.defaultRegex;
  }

  onBarcode(callback: BarcodeCallback): Cancellable {
    return this.addSubscriber(callback);
  }

  private subscribe(): void {
    this.enableEventHandler();
  }

  private unsubscribe(): void {
    this.disableEventhandler();
  }

  private enableEventHandler(): void {
    if (this.shouldSubscribe()) {
      this.eventHandler = this.createEventHandler.bind(this);
      window.addEventListener(
        'keydown',
        this.eventHandler as EventListenerOrEventListenerObject,
      );
    }
  }

  private disableEventhandler(): void {
    if (this.shouldUnsubscribe()) {
      window.removeEventListener(
        'keydown',
        this.eventHandler as EventListenerOrEventListenerObject,
      );
      this.eventHandler = null;
    }
  }

  private shouldSubscribe(): boolean {
    return this.eventHandler === null && this.subscribers.size > 0;
  }

  private shouldUnsubscribe(): boolean {
    return this.eventHandler !== null && !this.subscribers.size;
  }

  private addSubscriber(callback: BarcodeCallback): Cancellable {
    this.subscribers.add(callback);
    const response = {
      cancel: this.removeSubscriber.bind(this, callback),
    };
    this.subscribe();
    return response;
  }

  private removeSubscriber(callback: BarcodeCallback): void {
    this.subscribers.delete(callback);
    this.unsubscribe();
  }

  private notifySubscribers(barcode: string): void {
    for (const callback of this.subscribers) {
      if (typeof callback === 'function') {
        if (this.validate(barcode)) {
          callback(barcode);
        }
      } else {
        if (
          this.validate(
            barcode,
            callback.pattern
              ? BarcodePatterns[callback.pattern] ||
                  BarcodePatterns.ProductBarcode
              : undefined,
          )
        ) {
          callback.next(barcode);
        } else if (typeof callback.validationError === 'function') {
          callback.validationError(barcode);
        }
      }
    }
  }

  private createEventHandler(event: KeyboardEvent): void {
    if (
      this.allowedCharacterRegex.test(event.key) &&
      ((event.target as HTMLInputElement).tagName || '').toLowerCase() !==
        'input'
    ) {
      this.capturedKeys.push(event.key);
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        const allKeys = this.capturedKeys.join('');
        this.notifySubscribers(allKeys);
        this.capturedKeys = [];
      }, this.timeout) as unknown as number;
    }
  }

  validate(data: string, pattern?: RegExp): boolean {
    const barcodeRegex = pattern || this.barcodeRegex;
    return barcodeRegex.test(data);
  }
}

export class VueBarcodeService implements IBarcodeService {
  constructor(private barcodeService: BarcodeService) {}

  onBarcode(callback: BarcodeCallback): Cancellable {
    let _cancellable: Cancellable;
    let _cancelled = false;

    const cancellable: Cancellable = {
      cancel() {
        if (!_cancelled) {
          _cancelled = true;
          _cancellable?.cancel();
        }
      },
    };

    onMounted(() => {
      if (!_cancelled) {
        _cancellable = this.barcodeService.onBarcode(callback);
      }
    });

    onBeforeUnmount(() => {
      if (!_cancelled) {
        cancellable.cancel();
      }
    });

    return cancellable;
  }
}
