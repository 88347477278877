import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { OrderLocalStatus, OrderPluginDto } from '../types';

export class SetOrderStatusPlugin implements PipelinePlugin<OrderPluginDto> {
  constructor(private orderStatus: OrderLocalStatus) {}
  public execute(dataTransferObject: OrderPluginDto): OrderPluginDto {
    dataTransferObject.order.localStatus = this.orderStatus;
    return dataTransferObject;
  }
}
