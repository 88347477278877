import { isApiError } from '@/features/core/api/helper/is-api-client-error';
import type { ApiClientError } from '@/features/core/api/types';

/* eslint-disable @typescript-eslint/no-explicit-any */
function transformObject(obj: Record<string, any>): Record<string, any> {
  const apiClientError = reduceApiClientError(obj);
  if (apiClientError) return apiClientError;

  const result: Record<string, any> = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const value = obj[key];

      if (typeof value === 'object' && value !== null) {
        //Recursive call
        result[key] = transformObject(value);
      } else if (typeof value !== 'function') {
        //Only store which is not function
        Object.defineProperty(result, key, {
          enumerable: true,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          value,
        });
      }
    }
  }

  return result;
}

export function transformErrorToObject(
  error: Error | ApiClientError,
): Record<string, any> {
  const apiClientError = reduceApiClientError(error);
  if (apiClientError) return apiClientError;

  const transformedError: Record<string, any> = {};
  const properties = Object.getOwnPropertyNames(error);

  for (const property of properties) {
    const value = error[property as keyof typeof error];

    if (typeof value === 'object' && value !== null) {
      transformedError[property] = transformObject(value);
    } else if (typeof value !== 'function') {
      transformedError[property] = value;
    }
  }

  return transformedError;
}

function reduceApiClientError<T>(
  error: Error | ApiClientError | Record<string, any>,
): Record<string, any> | null {
  if (!isApiError(error)) {
    return null;
  }

  const apiClientError = error;
  return {
    message: apiClientError.message,
    stack: apiClientError.stack,
    response: {
      data: apiClientError.response?.data as T,
      status: apiClientError.response?.status,
      statusText: apiClientError.response?.statusText,
    },
    isAxiosError: true,
  };
}
