import type {
  Entity,
  NewEntity,
} from '@/features/core/entity-repository/entity';
import { RestorableEntity } from '@/features/core/entity-repository/entity';
import type {
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';

import type { ConfigurationOption } from '../types';
import { DBVersions } from '@/features/core/storage/dexie/versions';

class NewConfiguration extends RestorableEntity implements NewEntity {
  type = 'configuration';
}

export class Configuration extends NewConfiguration implements Entity {
  id!: string;
  active!: boolean;
  options!: ConfigurationOption[];
}

export class ConfigurationStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.Configuration_1).stores({
      [storeName]: '&id,active,options',
    });

    return Promise.resolve();
  }
}
