// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Hammer from 'hammerjs';
import type { Ref } from 'vue';
import { ref } from 'vue';
import type { UseScalableImage } from '../types';
import { useCheckInOrders } from '@/features/orders';

export function useScalableImage(): UseScalableImage {
  const { checkInBanner } = useCheckInOrders();
  const isImagePopupVisible = ref(false);
  const hammerJs: Ref<HammerManager | null> = ref(null);

  const togglePageOverflow = () => {
    if (isImagePopupVisible.value) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = '';
      resetHammerJs();
    }
  };

  const toggleImagePopup = () => {
    checkInBanner.value = isImagePopupVisible.value;
    isImagePopupVisible.value = !isImagePopupVisible.value;
    togglePageOverflow();
  };

  const closeImagePopup = () => {
    isImagePopupVisible.value = false;
    togglePageOverflow();
  };

  const setImageScale = (
    imageElement: HTMLElement,
    scalableProduct: HTMLElement,
    maxScalableWidth: number,
  ): void => {
    const minScale = 1;
    let maxScale = 2;
    let containerWidth: number;
    let containerHeight: number;
    let displayImageX = 0;
    let displayImageY = 0;
    let displayImageScale = 1;

    let displayDefaultWidth: number;
    let displayDefaultHeight: number;

    let rangeX = 0;
    let rangeMaxX = 0;
    let rangeMinX = 0;

    let rangeY = 0;
    let rangeMaxY = 0;
    let rangeMinY = 0;

    let displayImageCurrentX = 0;
    let displayImageCurrentY = 0;
    let displayImageCurrentScale = 1;
    let disablePan = false;

    function resizeContainer(): void {
      containerWidth = scalableProduct.offsetWidth;
      containerHeight = scalableProduct.offsetHeight;
      if (
        displayDefaultWidth !== undefined &&
        displayDefaultHeight !== undefined
      ) {
        displayDefaultWidth = imageElement.offsetWidth;
        displayDefaultHeight = imageElement.offsetHeight;
        updateRange();
        displayImageCurrentX = clamp(displayImageX, rangeMinX, rangeMaxX);
        displayImageCurrentY = clamp(displayImageY, rangeMinY, rangeMaxY);
        updateDisplayImage(
          displayImageCurrentX,
          displayImageCurrentY,
          displayImageCurrentScale,
        );
      }
    }

    resizeContainer();

    function clamp(value: number, min: number, max: number): number {
      return Math.min(Math.max(min, value), max);
    }

    function clampScale(newScale: number): number {
      return clamp(newScale, minScale, maxScale);
    }

    window.addEventListener('resize', resizeContainer, true);

    function setupImageVariables() {
      displayDefaultWidth = imageElement.offsetWidth;
      displayDefaultHeight = imageElement.offsetHeight;
      maxScale = maxScalableWidth / displayDefaultWidth;
      rangeX = Math.max(0, displayDefaultWidth - containerWidth);
      rangeY = Math.max(0, displayDefaultHeight - containerHeight);
    }

    setupImageVariables();

    function updateDisplayImage(x: number, y: number, scale: number): void {
      imageElement.style.transform = `translateX(${x}px) translateY(${y}px) translateZ(0px) scale(${scale},${scale})`;
    }

    function updateRange(): void {
      rangeX = Math.max(
        0,
        Math.round(displayDefaultWidth * displayImageCurrentScale) -
          containerWidth,
      );
      rangeY = Math.max(
        0,
        Math.round(displayDefaultHeight * displayImageCurrentScale) -
          containerHeight,
      );

      rangeMaxX = Math.round(rangeX / 2);
      rangeMinX = 0 - rangeMaxX;

      rangeMaxY = Math.round(rangeY / 2);
      rangeMinY = 0 - rangeMaxY;
    }

    hammerJs.value = new Hammer(scalableProduct);

    hammerJs.value?.get('pinch').set({ enable: true });
    hammerJs.value?.get('pan').set({ direction: Hammer.DIRECTION_ALL });
    hammerJs.value?.get('swipe').set({ direction: Hammer.DIRECTION_ALL });

    hammerJs.value?.on('pan', (ev: HammerInput) => {
      if (disablePan) {
        return;
      }
      displayImageCurrentX = clamp(
        displayImageX + ev.deltaX,
        rangeMinX,
        rangeMaxX,
      );
      displayImageCurrentY = clamp(
        displayImageY + ev.deltaY,
        rangeMinY,
        rangeMaxY,
      );
      updateDisplayImage(
        displayImageCurrentX,
        displayImageCurrentY,
        displayImageScale,
      );
    });

    hammerJs.value?.on('pinch', (ev: HammerInput) => {
      disablePan = true;
      displayImageCurrentScale = clampScale(ev.scale * displayImageScale);
      updateRange();
      displayImageCurrentX = clamp(displayImageX, rangeMinX, rangeMaxX);
      displayImageCurrentY = clamp(displayImageY, rangeMinY, rangeMaxY);
      updateDisplayImage(
        displayImageCurrentX,
        displayImageCurrentY,
        displayImageCurrentScale,
      );
    });

    hammerJs.value?.on('doubletap', () => {
      displayImageScale = displayImageCurrentScale =
        displayImageScale === minScale ? maxScale : minScale;
      updateRange();
      displayImageX = displayImageY = 0;
      updateDisplayImage(displayImageX, displayImageY, displayImageScale);
    });

    hammerJs.value?.on('pinchend pinchcancel', () => {
      displayImageScale = displayImageCurrentScale;
      setTimeout(() => {
        disablePan = false;
      }, 100);
    });

    hammerJs.value?.on('panend pancancel', () => {
      displayImageX = displayImageCurrentX;
      displayImageY = displayImageCurrentY;
    });

    hammerJs.value?.on('swipeup swipedown', () => {
      if (displayImageCurrentScale === 1) {
        closeImagePopup();
      }
    });
  };

  const resetHammerJs = (): void => {
    hammerJs.value?.off('pinch');
    hammerJs.value?.off('swipe');
    hammerJs.value?.get('pinch').set({ enable: false });
  };

  return {
    setImageScale,
    isImagePopupVisible,
    toggleImagePopup,
    resetHammerJs,
  };
}
