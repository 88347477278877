import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }

import 'vue';
import EditableQuantity from '@/features/ui/components/EditableQuantity.vue';
import { $t } from '@/i18n';
import Chip from '@/features/ui/components/Chip.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HandoverFixedWeightFooter',
  props: {
    staged: {},
    total: {},
    rejected: {},
    disabled: { type: Boolean, default: false },
    isRejectedCategory: { type: Boolean },
    showRejectedChip: { type: Boolean }
  },
  emits: ["update"],
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(EditableQuantity, {
      max: _ctx.staged + _ctx.rejected,
      "unit-text-callback": 
        (count) => _unref($t)('components.handover-item.items.text', { count })
      ,
      "shown-limit": _ctx.total,
      "separator-text": _unref($t)('components.handover-item.of.text'),
      disabled: _ctx.disabled,
      "model-value": _ctx.isRejectedCategory ? _ctx.rejected : _ctx.staged,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => _ctx.$emit('update', value))
    }, null, 8, ["max", "unit-text-callback", "shown-limit", "separator-text", "disabled", "model-value"]),
    (_ctx.rejected !== 0 && _ctx.showRejectedChip)
      ? (_openBlock(), _createBlock(Chip, {
          key: 0,
          text: 
        _ctx.staged === 0
          ? _unref($t)('components.handover-item.all-rejected.text')
          : _unref($t)('components.handover-item.count-rejected.text', {
              count: _ctx.rejected,
            })
      ,
          variant: "danger",
          size: "medium"
        }, null, 8, ["text"]))
      : _createCommentVNode("", true)
  ]))
}
}

})