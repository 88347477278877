import { Product } from '@/features/products';
import type { OrderItem } from '@/features/orders';
import { ItemUnit } from '@/features/orders';
import type { NotificationService } from '@/features/core/notifications';
import { NotificationType } from '@/features/core/notifications';
import { sortByBarcodesPriority } from '@/features/products/helpers';
import type { LoggerService } from '@/features/core/logger';
import { trackExecutionTime } from '@/features/core/logger/helpers/trackExecutionTime';
import type { EntityRepository } from '@/features/core/entity-repository';
import type { ConfigurationService } from '@/features/configuration';
import type {
  BarcodeLookupResultForItems,
  BarcodeLookupResultForProducts,
  BarcodeType,
  IBarcodeFoundService,
  FindConcreteItemByBarcodePrefixMatchResult,
} from '../types';
import type { BarcodeWithEmbeddedValue } from '../types';
import {
  getNormalizedWeightUnit,
  roundInputWithExponentToDigits,
  skuLeadingDigitsMatchAnyBarcodes,
} from '../helpers';
import { barcodeLookup } from '../composables';

export class BarcodeFoundService implements IBarcodeFoundService {
  constructor(
    private entityRepository: EntityRepository,
    private configurationService: ConfigurationService,
    private notificationService: NotificationService,
    private loggerService: LoggerService,
  ) {}

  extractEmbeddedValueFromBarcode(
    findConcreteItemResult: FindConcreteItemByBarcodePrefixMatchResult,
    barcodeType: BarcodeType,
  ): BarcodeWithEmbeddedValue | undefined {
    const { concreteItem, rawEmbeddedValue } = findConcreteItemResult;
    if (!concreteItem || !rawEmbeddedValue) return;
    const productWeightUnit = getNormalizedWeightUnit(concreteItem);
    switch (productWeightUnit) {
      case ItemUnit.Pound:
      case ItemUnit.Kilogram: {
        // Price is in cents while weight is in grams
        const fractionalDigits = barcodeType === 'priceEmbedded' ? 2 : 3;
        return {
          concreteItem,
          barcodeType,
          embeddedValue: roundInputWithExponentToDigits(
            rawEmbeddedValue,
            fractionalDigits,
            0,
          ),
        };
      }
      default:
        this.notificationService.show({
          text: 'Weight Unit not supported. Please make sure to enter the weight with needed decimals',
          type: NotificationType.Error,
        });
        return {
          concreteItem,
          barcodeType,
          embeddedValue: null,
        };
    }
  }

  async getWeightEmbeddedBarcodePrefixes(): Promise<string[]> {
    return await this.configurationService.getFeatureOption(
      'randomWeight',
      'weightEmbeddedBarcodePrefixes',
      'array',
    );
  }

  async getPriceEmbeddedBarcodePrefixes(): Promise<string[]> {
    return await this.configurationService.getFeatureOption(
      'randomWeight',
      'priceEmbeddedBarcodePrefixes',
      'array',
    );
  }

  /**
   * Checks for a matching item in the list for the provided barcode
   *
   * For details on how this method works check the documentation linked below
   * (look for the "Product/item lookup" section)
   *
   * @see https://aldi-sued.atlassian.net/l/cp/0HUfAQ2s
   */
  async findItemByBarcode(
    items: OrderItem[],
    barcode: string,
  ): Promise<BarcodeLookupResultForItems> {
    return await barcodeLookup(this, barcode, items);
  }

  async getSortedProductsList(): Promise<Product[]> {
    const productList = await this.entityRepository.getAll(Product);
    return trackExecutionTime(
      () => sortByBarcodesPriority(productList.value),
      ({ durationMs }) =>
        this.loggerService.info(
          `Sorting products during barcode scanning takes ${durationMs} milliseconds`,
        ),
    );
  }

  findScannedProductInList(
    barcode: string,
    sortedProductList: Product[],
  ): Product | undefined {
    return trackExecutionTime(
      () =>
        sortedProductList.find((product) => product.barcodes.includes(barcode)),
      ({ durationMs }) =>
        this.loggerService.info(
          `Finding product during barcode scanning takes ${durationMs} milliseconds`,
        ),
    );
  }

  /**
   * Attempts to find a product by barcode among the locally known entities
   *
   * For details on how this method works check the documentation linked below
   * (look for the "Product/item lookup" section)
   *
   * @see https://aldi-sued.atlassian.net/l/cp/0HUfAQ2s
   */
  async searchForProductByBarcode(
    barcode: string,
  ): Promise<BarcodeLookupResultForProducts> {
    return await barcodeLookup(this, barcode);
  }

  async isProductForBarcodeExist(barcode: string): Promise<boolean> {
    const productList = await this.entityRepository.getAll(Product);
    return productList.value.some((product) =>
      skuLeadingDigitsMatchAnyBarcodes(product.barcodes, barcode),
    );
  }
}
