
import { defineComponent, watch } from 'vue';
import { useOnlineStatus } from '@/features/orders';
import NotifySuccessIcon from '@/features/ui/icons/NotifySuccessIcon.vue';
import NotifyErrorIcon from '@/features/ui/icons/NotifyErrorIcon.vue';

export default defineComponent({
  name: 'ConnectionDialog',
  components: { NotifyErrorIcon, NotifySuccessIcon },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { isOnline } = useOnlineStatus();

    watch(
      isOnline,
      (isOnlineValue) => {
        if (isOnlineValue) {
          setTimeout(() => {
            emit('close');
          }, 1200);
        }
      },
      { deep: true },
    );
    return {
      isOnline,
    };
  },
});
