import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "popup__wrap" }
const _hoisted_2 = { class: "popup__footer" }

import { watch, ref } from 'vue';
import { ClearIcon } from '@/features/ui/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'Popup',
  props: {
    title: { default: '' },
    visible: { type: Boolean, default: false },
    bottom: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: true },
    fullPageSize: { type: Boolean, default: false },
    withoutHeader: { type: Boolean, default: false },
    isCloseButtonExist: { type: Boolean, default: true },
    isSpaceToContentExist: { type: Boolean, default: false },
    isContentCentered: { type: Boolean, default: false },
    fixedHeader: { type: Boolean, default: false }
  },
  emits: ["close"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const dialog = ref<HTMLDialogElement>(null);

const handleClose = () => {
  if (!props.isCloseButtonExist) {
    // Do not allow simply closing the modal if the close button is hidden
    return;
  }

  emit('close');
};

watch(
  () => [props.visible, dialog],
  (value) => {
    if (!dialog.value) return;

    if (value && !dialog.value.open) {
      dialog.value.showModal();
    }
  },
);

__expose({ visible: props.visible });

return (_ctx: any,_cache: any) => {
  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("dialog", {
        key: 0,
        ref_key: "dialog",
        ref: dialog,
        class: _normalizeClass([{
      'bottom-position': _ctx.bottom,
      'full-width': _ctx.fullWidth,
      'full-page-size': _ctx.fullPageSize,
      margin: _ctx.isSpaceToContentExist,
      center: _ctx.isContentCentered,
    }, "popup"]),
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (emit('close')), ["self"])),
        onClose: _withModifiers(handleClose, ["prevent"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (!_ctx.withoutHeader)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([{ 'popup__header-fixed': _ctx.fixedHeader }, "popup__header"])
              }, [
                _renderSlot(_ctx.$slots, "header")
              ], 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass([{
          'popup__body-fixed': _ctx.fixedHeader,
          'popup__body-without-mt': _ctx.withoutHeader,
        }, "popup__body"])
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "footer")
          ]),
          (_ctx.isCloseButtonExist)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "close-btn",
                "data-e2e": "popup-close-button",
                tabindex: "-1",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
              }, [
                _createVNode(_unref(ClearIcon))
              ]))
            : _createCommentVNode("", true)
        ])
      ], 34))
    : _createCommentVNode("", true)
}
}

})