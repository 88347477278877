import type { Order, OrderItem } from '@/features/orders';
import { isOrderItemReplacement, OrderItemStatus } from '@/features/orders';
import type { OverpickingThresholds } from '../composables/useOverpickingThresholds';
import { isOrderItemChanged } from './isOrderItemChanged';

export interface PartitionedPickingItems {
  notPicked: OrderItem[];
  changed: OrderItem[];
  picked: OrderItem[];
}

export const partitionPickingItems = (
  order: Order | null,
  overpickingThreshold?: OverpickingThresholds,
): PartitionedPickingItems => {
  const initialValue: PartitionedPickingItems = {
    picked: [],
    changed: [],
    notPicked: [],
  };
  return order
    ? order.items.reduce((result: PartitionedPickingItems, item) => {
        if (item.status !== OrderItemStatus.picked) {
          return { ...result, notPicked: [...result.notPicked, item] };
        }
        if (
          isOrderItemReplacement(item) ||
          isOrderItemChanged(item, overpickingThreshold)
        ) {
          return { ...result, changed: [...result.changed, item] };
        }
        return { ...result, picked: [...result.picked, item] };
      }, initialValue)
    : initialValue;
};
