import { configurationServicePlugin } from '@/features/configuration/services';
import { useFeatureToggle } from '@/features/feature-toggle';
import { ref } from 'vue';
import type { UseItemManualVerification } from '../types';

export const useItemManualVerification = (): UseItemManualVerification => {
  const isManualVerificationDisabled = ref<boolean>(false);
  const isFeatureToggleManualVerificationDisabled = ref<boolean>(false);
  const fallbackVerificationPin = ref<string>('');
  const manualPickItemsSkus = ref<string[]>([]);
  const { featureList } = useFeatureToggle();

  const loadItemPickingConfiguration = async (): Promise<void> => {
    const isItemPickingConfigurationEnabled = await configurationServicePlugin
      .get()
      .isFeatureActive('itemPicking');

    if (!isItemPickingConfigurationEnabled) {
      return;
    }

    isManualVerificationDisabled.value =
      await getIsManualVerificationDisabled();

    isFeatureToggleManualVerificationDisabled.value =
      featureList.value.disableManualVerification.active;

    fallbackVerificationPin.value = await getVerificationPin();

    manualPickItemsSkus.value = await getManualPickItemsSkus();
  };

  const isItemManualVerificationEnabled = (sku: string): boolean => {
    if (isFeatureToggleManualVerificationDisabled.value) {
      return false;
    }
    if (isManualVerificationDisabled.value) {
      return false;
    }

    return !manualPickItemsSkus.value.includes(sku);
  };

  const getIsManualVerificationDisabled = async (): Promise<boolean> => {
    const isManualVerificationDisabled = await configurationServicePlugin
      .get()
      .getFeatureOption(
        'itemPicking',
        'alwaysShowQuantityIncreaseOption',
        'boolean',
      );

    return isManualVerificationDisabled;
  };

  const getVerificationPin = async (): Promise<string> => {
    const fallbackVerificationPin = await configurationServicePlugin
      .get()
      .getFeatureOption('itemPicking', 'fallbackVerificationPin', 'string');

    return fallbackVerificationPin;
  };

  const getManualPickItemsSkus = async (): Promise<string[]> => {
    const manualPickItemsSkus = await configurationServicePlugin
      .get()
      .getFeatureOption('itemPicking', 'itemsManualPick', 'array');

    return manualPickItemsSkus;
  };

  return {
    loadItemPickingConfiguration,
    isItemManualVerificationEnabled,
    fallbackVerificationPin,
  };
};
