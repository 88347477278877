/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment */
import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';
import type { ProviderPlugin } from '@/features/core/plugin';
import { ProviderPluginFactory } from '@/features/core/plugin';
import type { InferType, Type } from '@/utils/types';
import { $t } from '@/i18n';
import type { StorageWriteOptions } from '../storage';
import { getStorableType } from '../storage';
import type { AnyEntity, Entity, EntityType } from './entity';
import type {
  EntityRepository,
  RepositoryReadByIdOptions,
  RepositoryReadByIdsOptions,
  RepositoryReadOptions,
  RepositoryWriteOptions,
  RepositoryWriteResult,
} from './entity-repository';

export interface RegistryEntityRepositoryOptions {
  registry: RegistryEntityRepositoryRegistry;
}

export abstract class RegistryEntityRepository implements EntityRepository {
  private registry: Map<Type<AnyEntity>, RegistryEntityApi<AnyEntity>>;

  constructor(private options: RegistryEntityRepositoryOptions) {
    this.registry = this.options.registry.getMap();
  }

  async getAll<T extends EntityType<any>>(
    entity: T,
    options?: RepositoryReadOptions,
  ): Promise<ComputedRef<InferType<T>[]>> {
    const api = this.getApi(entity);

    if (!api.getAll) {
      throw new Error(
        $t('errors.registry-entity-repository.get-all.message', {
          constructorName: this.constructor.name,
          entityName: entity.name,
        }),
      );
    }

    const data = await api.getAll(options);

    return computed(() => data);
  }

  async getById<T extends EntityType<any>>(
    entity: T,
    options: RepositoryReadByIdOptions,
  ): Promise<ComputedRef<InferType<T> | undefined>> {
    const api = this.getApi(entity);

    if (!api.getById) {
      throw new Error(
        $t('errors.registry-entity-repository.get-by-id.message', {
          constructorName: this.constructor.name,
          entityName: entity.name,
        }),
      );
    }

    const data = await api.getById(options);

    return computed(() => data);
  }

  async getByIds<T extends EntityType<any>>(
    entity: T,
    options: RepositoryReadByIdsOptions,
  ): Promise<ComputedRef<InferType<T>[]>> {
    const api = this.getApi(entity);

    if (!api.getByIds) {
      throw new Error(
        $t('errors.registry-entity-repository.get-by-ids.message', {
          constructorName: this.constructor.name,
          entityName: entity.name,
        }),
      );
    }

    const data = await api.getByIds(options);

    return computed(() => data);
  }

  save<T extends Entity>(
    entity: T,
    options?: RepositoryWriteOptions,
  ): RepositoryWriteResult<ComputedRef<T>> {
    const type = getStorableType(entity);
    const api = this.getApi(type);

    if (!api.save) {
      throw new Error(
        $t('errors.registry-entity-repository.save.message', {
          constructorName: this.constructor.name,
          typeName: type.name,
        }),
      );
    }

    const data = ref(entity) as Ref<T>;

    return this.execWrite(
      computed(() => data.value),
      () =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        api.save!(entity, options).then((d) => {
          data.value = d;
          return computed(() => data.value);
        }),
    );
  }

  remove(
    entity: Entity,
    options?: RepositoryWriteOptions,
  ): RepositoryWriteResult<void> {
    const entityType = entity.constructor as Type<Entity>;
    const api = this.getApi(entityType);

    if (!api.remove) {
      throw new Error(
        $t('errors.registry-entity-repository.remove.message', {
          constructorName: this.constructor.name,
          entityTypeName: entityType.name,
        }),
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.execWrite(undefined, () => api.remove!(entity, options));
  }

  removeAll(
    entity: EntityType<any>,
    options?: StorageWriteOptions,
  ): RepositoryWriteResult<void> {
    const api = this.getApi(entity);

    if (!api.removeAll) {
      throw new Error(
        $t('errors.registry-entity-repository.remove-all.message', {
          constructorName: this.constructor.name,
          entityName: entity.name,
        }),
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.execWrite(undefined, () => api.removeAll!(options));
  }

  private getApi<T extends AnyEntity>(entity: Type<T>): RegistryEntityApi<T> {
    const api = this.registry.get(entity);

    if (!api) {
      throw new Error(
        $t('errors.registry-entity-repository.get-api.message', {
          constructorName: this.constructor.name,
          entityName: entity.name,
        }),
      );
    }

    return api as RegistryEntityApi<T>;
  }

  private execWrite<T>(
    entity: T,
    writeFn: () => Promise<T>,
  ): RepositoryWriteResult<T> {
    const promise = new Promise<T>(
      (resolve, reject) => void writeFn().then(resolve).catch(reject),
    );

    return {
      scheduled: Promise.resolve(entity),
      completed: promise,
    };
  }
}

interface RegistryEntityApi<T extends AnyEntity> {
  getAll?(options?: RepositoryReadOptions): Promise<T[]>;

  getById?(options: RepositoryReadByIdOptions): Promise<T | undefined>;

  getByIds?(options: RepositoryReadByIdsOptions): Promise<T[]>;

  save?(entity: T, options?: RepositoryWriteOptions): Promise<T>;

  remove?(entity: T, options?: RepositoryWriteOptions): Promise<void>;

  removeAll?(options?: RepositoryWriteOptions): Promise<void>;
}

export class RegistryEntityRepositoryRegistry {
  private map: Map<Type<AnyEntity>, RegistryEntityApi<AnyEntity>> = new Map();

  register<T extends AnyEntity>(
    entity: Type<T>,
    api: RegistryEntityApi<T>,
  ): this {
    this.map.set(entity, api);
    return this;
  }

  extend<T extends AnyEntity>(
    entity: Type<T>,
    newApi: Partial<RegistryEntityApi<T>>,
  ): this {
    const existingApi = this.map.get(entity);
    return this.register(entity, { ...existingApi, ...newApi });
  }

  getMap(): Map<Type<AnyEntity>, RegistryEntityApi<AnyEntity>> {
    return this.map;
  }
}

export const createRegistryEntityRepositoryRegistryPlugin = (
  key: symbol,
): ProviderPlugin<RegistryEntityRepositoryRegistry, unknown> =>
  ProviderPluginFactory.create<RegistryEntityRepositoryRegistry>({
    key,
    defaultFactory: { create: () => new RegistryEntityRepositoryRegistry() },
  });
