import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, isRef as _isRef, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx } from "vue"

import { Dialog } from '@/features/ui/components';
import { useDynamicDialog } from '@/features/ui/composables/useDynamicDialog';


export default /*@__PURE__*/_defineComponent({
  __name: 'DynamicDialog',
  setup(__props) {

const {
  titleText,
  isTitleCentered,
  contentText,
  showDialog,
  confirmText,
  cancelText,
  showOnlyConfirm,
  e2e,
  onConfirm,
  close,
  component,
  secretToggle,
  hasToggleConfirm,
  isToggleConfirmChecked,
} = useDynamicDialog();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Dialog), {
    "cancel-text": _unref(cancelText),
    "confirm-text": _unref(confirmText),
    "content-text": _unref(contentText),
    "data-e2e": _unref(e2e),
    "is-confirm-button-disabled": _unref(hasToggleConfirm) && !_unref(isToggleConfirmChecked),
    "is-title-centered": _unref(isTitleCentered),
    "show-only-confirm": _unref(showOnlyConfirm),
    "title-text": _unref(titleText),
    visible: _unref(showDialog),
    onCanceled: _unref(close),
    onConfirmed: _unref(onConfirm),
    onSecretToggleClicked: _cache[1] || (_cache[1] = ($event: any) => (secretToggle.value = true))
  }, {
    default: _withCtx(() => [
      (_unref(component))
        ? _renderSlot(_ctx.$slots, "default", { key: 0 }, () => [
            (_unref(hasToggleConfirm))
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(component).component), _mergeProps({
                  key: 0,
                  modelValue: _unref(isToggleConfirmChecked),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(isToggleConfirmChecked) ? (isToggleConfirmChecked).value = $event : null))
                }, _unref(component).props), null, 16, ["modelValue"]))
              : (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(component).component), _normalizeProps(_mergeProps({ key: 1 }, _unref(component).props)), null, 16))
          ])
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["cancel-text", "confirm-text", "content-text", "data-e2e", "is-confirm-button-disabled", "is-title-centered", "show-only-confirm", "title-text", "visible", "onCanceled", "onConfirmed"]))
}
}

})