import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "completed-overlay",
  "data-e2e": "picking-completed-overlay"
}

import { CircleCheckIcon } from '@/features/ui/icons';

export default /*@__PURE__*/_defineComponent({
  __name: 'CompletedOverlay',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(CircleCheckIcon), { "data-e2e": "picking-completed-img" })
  ]))
}
}

})