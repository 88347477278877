import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "current-value"
}
const _hoisted_2 = { class: "separator" }
const _hoisted_3 = { class: "upper-limit" }
const _hoisted_4 = {
  key: 3,
  class: "unit-text"
}

import { computed, ref, toRefs } from 'vue';
import Input from '@/features/ui/components/Input.vue';
import {
  focusInInputHandler,
  focusOutInputHandler,
} from '@/utils/helpers/hideOnFocus';


export default /*@__PURE__*/_defineComponent({
  __name: 'EditableQuantity',
  props: {
    modelValue: {},
    min: { default: 0 },
    max: {},
    unitTextCallback: {},
    shownLimit: {},
    separatorText: {},
    disabled: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { disabled } = toRefs(props);

const isBeingEdited = ref(false);
const inputtedValue = ref(props.modelValue.toString());
const inputRef = ref<HTMLInputElement | null>(null);

const onClick = () => {
  if (disabled.value || isBeingEdited.value) {
    return;
  }

  isBeingEdited.value = true;
  inputtedValue.value = props.modelValue.toString();
};

const updateValue = () => {
  if (props.disabled || isInputIncorrect.value) {
    return;
  }

  focusOutInputHandler();
  isBeingEdited.value = false;

  if (parseInt(inputtedValue.value) === props.modelValue) {
    return;
  }

  emit('update:modelValue', parseInt(inputtedValue.value));
};

const isInputIncorrect = computed(
  () =>
    /^\d+$/.exec(inputtedValue.value) === null ||
    parseInt(inputtedValue.value) < props.min ||
    parseInt(inputtedValue.value) > props.max,
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ container: true, disabled: _unref(disabled), 'being-edited': isBeingEdited.value }),
    onClick: onClick
  }, [
    (!isBeingEdited.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.modelValue), 1))
      : (_openBlock(), _createBlock(Input, {
          key: 1,
          modelValue: inputtedValue.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputtedValue).value = $event)),
          onFocusOut: updateValue,
          onFocusIn: _unref(focusInInputHandler),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(() => inputRef.value?.blur(), ["enter"])),
          type: "number",
          inputmode: "numeric",
          class: "input",
          valid: !isInputIncorrect.value,
          "text-clearable": false,
          autofocus: "",
          autoselect: "",
          sm: "",
          ref_key: "inputRef",
          ref: inputRef
        }, null, 8, ["modelValue", "onFocusIn", "valid"])),
    (_ctx.shownLimit)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.separatorText), 1),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.shownLimit), 1)
        ], 64))
      : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.unitTextCallback(_ctx.modelValue)), 1))
  ], 2))
}
}

})