<template>
  <div class="default-layout">
    <div class="container">
      <RouterView v-if="withRouter" />
      <slot></slot>
    </div>
  </div>
  <ConnectionDialog
    :visible="visibleConnectionDialog"
    @close="closeConnectionDialog"
  />
  <VerificationDialog :visible="visibleVerificationDialog" />
  <DynamicDialog />

  <Teleport to="body">
    <KeepAlive>
      <Transmissions
        v-if="isTransmissionsWindowVisible"
        @close="() => toggleTransmissionsWindowVisibility(false)"
      />
    </KeepAlive>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent, onUnmounted, ref, watch } from 'vue';
import { RouterView } from 'vue-router';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import { authServicePlugin } from '@/features/core/auth';
import VerificationDialog from '@/features/ui/components/VerificationDialog.vue';
import ConnectionDialog from '@/features/ui/components/ConnectionDialog.vue';
import { VerificationDialogShow } from '@/features/login/events';
import DynamicDialog from '../components/DynamicDialog.vue';
import { Transmissions, useTransmissions } from '@/features/transmissions';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    VerificationDialog,
    ConnectionDialog,
    RouterView,
    DynamicDialog,
    Transmissions,
  },
  props: {
    withRouter: {
      default: true,
      type: Boolean,
    },
  },
  setup() {
    const visibleConnectionDialog = ref(false);
    const visibleVerificationDialog = ref(false);

    const { showOfflineDialog } = authServicePlugin.get();

    const {
      toggleTransmissionsWindowVisibility,
      isTransmissionsWindowVisible,
    } = useTransmissions();

    watch(showOfflineDialog, () => {
      if (showOfflineDialog.value) {
        visibleConnectionDialog.value = true;
      }
    });

    const subscription = eventBusServicePlugin
      .get()
      .on(VerificationDialogShow, () => {
        visibleVerificationDialog.value = true;
      });

    onUnmounted(() => {
      subscription.cancel();
    });

    const closeConnectionDialog = () => {
      visibleConnectionDialog.value = false;
    };

    return {
      visibleConnectionDialog,
      closeConnectionDialog,
      visibleVerificationDialog,
      isTransmissionsWindowVisible,
      toggleTransmissionsWindowVisibility,
    };
  },
});
</script>

<style lang="scss" scoped>
.default-layout {
  display: flex;
  justify-content: center;
  min-height: 100vh;

  .container {
    display: flex;
    width: 100%;
    max-width: 600px;
    flex-direction: column;
  }

  &.layout__with_banner {
    padding-bottom: 95px;
  }
}
</style>
