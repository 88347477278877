import type { FirebaseError } from '@firebase/app';
import { initializeApp } from '@firebase/app';
import { deleteToken, getMessaging, getToken } from '@firebase/messaging';
import type { PushProvider } from '../types';
import { PushProviderError, PushProviderPermissionError } from '../types';
import type { FirebaseProviderConfig } from './types';
import { permissionErrorCode } from './types';

export class FirebaseService implements PushProvider {
  constructor(private config: FirebaseProviderConfig) {}

  init(): void {
    try {
      initializeApp({
        apiKey: this.config.apiKey,
        authDomain: this.config.authDomain,
        projectId: this.config.projectId,
        storageBucket: this.config.storageBucket,
        messagingSenderId: this.config.messagingSenderId,
        appId: this.config.appId,
        measurementId: this.config.measurementId,
      });
    } catch (e) {
      throw new PushProviderError((e as FirebaseError).message);
    }
  }

  async getToken(): Promise<string> {
    const messaging = getMessaging();
    const registration = await this.getRegistration();
    try {
      return await getToken(messaging, {
        serviceWorkerRegistration: registration,
        vapidKey: this.config.vapidKey,
      });
    } catch (e) {
      const error = e as FirebaseError;
      if (error.code === permissionErrorCode) {
        throw new PushProviderPermissionError(error.message);
      }

      throw new PushProviderError(error.message);
    }
  }

  async deleteToken(): Promise<boolean> {
    const messaging = getMessaging();
    return await deleteToken(messaging);
  }

  private async getRegistration(): Promise<ServiceWorkerRegistration> {
    let registration: ServiceWorkerRegistration | undefined;
    if (navigator.serviceWorker) {
      // needed to reuse existing service worker. otherwise firebase sdk will register a second service worker
      registration = await navigator.serviceWorker.getRegistration();
      await navigator.serviceWorker.ready;
    }
    if (!registration) {
      throw new Error('Worker registration missing in FirebaseService');
    }
    return registration;
  }
}
