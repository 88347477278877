import { RestorableEntity } from '@/features/core/entity-repository/entity';
import type {
  Storable,
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { DBVersions } from '@/features/core/storage/dexie/versions';

export class AuthData extends RestorableEntity implements Storable {
  type = 'auth-data' as const;
  id = 'auth-data' as const;
  userToken?: string;
  deviceToken?: string;
  refreshToken?: string;
}

export class AuthDataStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.AuthData_1).stores({ [`${storeName}`]: '&id' });

    return Promise.resolve();
  }
}
