import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { $t } from '@/i18n';
import { MAX_BARCODE_CHARS, MIN_BARCODE_CHARS } from '../types';
import CustomInput from './CustomInput.vue';

enum Events {
  Update = 'update:modelValue',
  Focus = 'focus',
  Blur = 'blur',
}
interface Emits {
  (e: Events, value?: string): void;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'BarcodeInput',
  props: {
  modelValue: {
    type: String,
    required: true,
  },
  isBarcodeValid: {
    type: Boolean,
    default: false,
  },
},
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const barcode = computed({
  get() {
    return props.modelValue;
  },
  set(value: string) {
    emit(Events.Update, value);
  },
});
const isValid = computed(
  () => props.isBarcodeValid && barcode.value.length <= 13,
);
const hintText = computed(() => {
  if (isValid.value)
    return $t('pages.picking-order.scan-barcode.min.text', {
      min: `${MIN_BARCODE_CHARS}-${MAX_BARCODE_CHARS}`,
    });

  return $t('pages.picking-order.scan-barcode.max.text', {
    max: `${MIN_BARCODE_CHARS}-${MAX_BARCODE_CHARS}`,
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CustomInput, {
    modelValue: barcode.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((barcode).value = $event)),
    isValid: isValid.value,
    label: "Barcode*",
    type: "number",
    inputmode: "number",
    hint: hintText.value,
    onFocus: _cache[1] || (_cache[1] = ($event: any) => (emit(Events.Focus))),
    onBlur: _cache[2] || (_cache[2] = ($event: any) => (emit(Events.Blur)))
  }, null, 8, ["modelValue", "isValid", "hint"]))
}
}

})