import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-e2e"]
const _hoisted_2 = ["data-e2e"]

import { computed } from 'vue';
import type { OrderItem } from '@/features/orders/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'CounterBox',
  props: {
    orderItem: {},
    dataE2E: { default: 'picking' },
    left: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const sku = computed<string>(() => {
  return props.orderItem.product
    ? props.orderItem.product.sku
    : props.orderItem.id;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["counter ut-font_fix", { 'counter__left-position': _ctx.left }])
  }, [
    _createElementVNode("span", {
      class: "counter__item",
      "data-e2e": `${_ctx.dataE2E}-quantity-label-${sku.value}`
    }, _toDisplayString(_ctx.orderItem.quantity), 9, _hoisted_1),
    _cache[0] || (_cache[0] = _createElementVNode("span", null, "of", -1)),
    _createElementVNode("span", {
      class: "counter__item",
      "data-e2e": `${_ctx.dataE2E}-quantityOriginal-label-${sku.value}`
    }, _toDisplayString(_ctx.orderItem.quantityOriginal), 9, _hoisted_2)
  ], 2))
}
}

})