import { Order } from '../entities';
import type { RepositoryReadByIdsOptions } from '@/features/core/entity-repository';
import type { RegistryEntityRepositoryRegistry } from '@/features/core/entity-repository/registry-entity-repository';
import type { IOrderApiClient } from '../types';

export const registerOrderApi = (
  onlineEntityRepositoryRegistry: RegistryEntityRepositoryRegistry,
  order: IOrderApiClient,
): void => {
  onlineEntityRepositoryRegistry.register(Order, {
    getAll: async () => order.parseOrders(await order.getAll()),
    getByIds: async (options: RepositoryReadByIdsOptions) => {
      return order.parseOrders(await order.getByIds(options.ids));
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    save: async (entity: Order) => order.save(entity as any) as Promise<any>,
  });
};
