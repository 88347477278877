import type { Type } from '@/utils/types';

export interface Storable {
  id: string;
  type: string;
}

export interface Restorable<T extends Storable> {
  from(data: unknown): T;
  FKs?: RestorableFKs;
}

export type RestorableFKs = Record<string, RestorableFK>;

export type RestorableFK = RestorableType<Storable> | RestorableFKMeta;

export interface RestorableFKMeta {
  type: RestorableType<Storable>;
  key: string;
}

export interface RestorableType<T extends Storable>
  extends Restorable<T>,
    Type<T> {}

export function getStorableType<T extends Storable>(
  entity: T | T[],
): RestorableType<T> {
  const entityArray = Array.isArray(entity) ? entity : [entity];
  const entityType = entityArray[0].constructor as RestorableType<T>;

  if (!entityArray.every((item) => item.constructor === entityType)) {
    throw new Error('All items in the array must have the same type');
  }
  return entityType;
}
