import type { ApiClientError } from '@/features/core/api/types';

export const isApiError = (obj: unknown): obj is ApiClientError => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'isAxiosError' in obj &&
    obj.isAxiosError === true
  );
};
