import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "amount" }

import 'vue';
import { $t } from '@/i18n';
import { unitToDisplay } from '@/utils/helpers/unitPluralHandler';
import { roundValue } from '@/utils/helpers/roundValue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HandoverPackagedRandomWeightHeader',
  props: {
    amount: {},
    totalAmount: {},
    unit: {},
    showTotalAmount: { type: Boolean, default: true }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createTextVNode(_toDisplayString(_unref(roundValue)(_ctx.amount)) + " ", 1),
    (_ctx.showTotalAmount)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_unref($t)('components.handover-item.of.text')) + " " + _toDisplayString(_unref(roundValue)(_ctx.totalAmount)), 1)
        ], 64))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_unref(unitToDisplay)(_ctx.unit, _unref(roundValue)(_ctx.totalAmount))), 1)
  ]))
}
}

})