import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-e2e"]
const _hoisted_2 = { class: "replacement_card__header" }
const _hoisted_3 = ["data-e2e"]
const _hoisted_4 = { class: "replacement_card__body" }
const _hoisted_5 = { class: "product-img" }
const _hoisted_6 = { class: "replacement_card__footer" }
const _hoisted_7 = {
  key: 0,
  class: "counter ut-font_fix"
}
const _hoisted_8 = ["data-e2e"]
const _hoisted_9 = { class: "body__footer footer" }

import type { PropType } from 'vue';
import type { OrderItem } from '@/features/orders/types';
import { WeightBlock } from '@/features/ui';
import { ProductImage } from '@/features/products';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReplacementCard',
  props: {
  orderItem: {
    type: Object as PropType<OrderItem>,
    default: () => {
      return {};
    },
  },
  isPicked: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      picked: __props.isPicked,
    }, "replacement_card"]),
    "data-e2e": `picking-box-${__props.orderItem.product?.sku}`
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        "data-e2e": `picking-name-label-${__props.orderItem.product?.sku}`,
        class: "replacement_card__title"
      }, _toDisplayString(__props.orderItem.product?.productName), 9, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (__props.orderItem.product)
          ? (_openBlock(), _createBlock(_unref(ProductImage), {
              key: 0,
              product: __props.orderItem.product,
              scalable: true,
              "e2e-tag": "picking"
            }, null, 8, ["product"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (!__props.orderItem.product.rwpType)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("span", {
              "data-e2e": `replacement-quantity-label-${__props.orderItem.product.sku}`,
              class: "counter__item"
            }, _toDisplayString(__props.orderItem.quantity), 9, _hoisted_8),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('components.replacement-card.items.text', {
            count: __props.orderItem.quantity,
          })), 1)
          ]))
        : (_openBlock(), _createBlock(_unref(WeightBlock), {
            key: 1,
            amount: __props.orderItem.amount,
            "amount-original": __props.orderItem.amountOriginal,
            "count-weight": __props.orderItem.product?.rwpType === 1,
            quantity: __props.orderItem.quantity,
            "quantity-original": __props.orderItem.quantityOriginal,
            showOriginal: false,
            sku: __props.orderItem.product?.sku,
            unit: __props.orderItem.unit
          }, null, 8, ["amount", "amount-original", "count-weight", "quantity", "quantity-original", "sku", "unit"]))
    ]),
    _createElementVNode("div", _hoisted_9, [
      _renderSlot(_ctx.$slots, "footer")
    ])
  ], 10, _hoisted_1))
}
}

})