import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/images/age-verification-hero.svg'


const _hoisted_1 = {
  key: 0,
  class: "chip-time"
}
const _hoisted_2 = { "data-e2e": "content-section" }
const _hoisted_3 = { class: "content-header" }
const _hoisted_4 = {
  class: "title",
  "data-e2e": "age-verification-title"
}
const _hoisted_5 = {
  class: "subtitle",
  "data-e2e": "age-verification-subtitle"
}
const _hoisted_6 = {
  key: 0,
  class: "content-info"
}
const _hoisted_7 = {
  class: "content-info__row-title",
  "data-e2e": "customer-name-label"
}
const _hoisted_8 = {
  class: "title",
  "data-e2e": "customer-name-value"
}
const _hoisted_9 = { "data-e2e": "age-verification-value" }
const _hoisted_10 = { "data-e2e": "age-verification-last-name-value" }
const _hoisted_11 = {
  key: 1,
  id: "born-before",
  class: "content-info"
}
const _hoisted_12 = {
  class: "content-info__row-title",
  "data-e2e": "born-before-label"
}
const _hoisted_13 = {
  class: "title",
  "data-e2e": "born-before-value"
}
const _hoisted_14 = {
  key: 2,
  class: "input-margin"
}
const _hoisted_15 = {
  key: 3,
  class: "input-margin"
}
const _hoisted_16 = {
  class: "actions",
  "data-e2e": "footer-section"
}
const _hoisted_17 = {
  class: "rejection-modal__label",
  "data-e2e": "age-verification-rejection-modal-title"
}
const _hoisted_18 = { class: "rejection-modal__actions" }

import {
  DateInput,
  Popup,
  RadioGroup,
  ThreeSectionLayout,
} from '@/features/ui';
import { configurationServicePlugin } from '@/features/configuration';
import type { Ref } from 'vue';
import { computed, onMounted, ref } from 'vue';
import { useOrders } from '@/common/composables/orders/useOrders';
import type { Customer } from '@/features/orders';
import { OrderItemStatus } from '@/features/orders';
import { useRoute, useRouter } from 'vue-router';
import ArrowLeftIcon from '@/features/ui/icons/ArrowLeftIcon.vue';
import { addDate, dateAmountType } from '@/utils/helpers/DateFormatter';
import BaseButton from '@/features/ui/components/BaseButton.vue';
import type { AgeVerification, RejectionReason } from '../types';
import { Status } from '../types';
import { FormGroup } from '../components';
import { errorPlugin } from '@/features/core/errors';
import { useDynamicDialog } from '@/features/ui/composables';
import { $t } from '@/i18n';
import { useCurrentLocale } from '@/i18n/composables';
import { ageVerificationPluginRegister } from '..';
import AppHeader from '@/features/ui/components/AppHeader.vue';
import StatusBar from '@/features/ui/components/StatusBar.vue';
import ChipTime from '@/features/ui/components/ChipTime.vue';

interface ListOption {
  label: string;
  e2eLabel: string;
  value: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AgeVerification',
  props: {
    id: {}
  },
  setup(__props: any) {



const { order, loadOrder } = useOrders();

const route = useRoute();
const router = useRouter();
const orderUser = ref<Customer>();
const displayDateOfBirthThresholdConfig = ref<boolean>(false);
const displayCustomerNameConfig = ref<boolean>(false);
const collectDateOfBirthConfig = ref<boolean>(false);
const displayFailureReasonConfig = ref<boolean>(false);
const collectIdentificationTypeConfig = ref<boolean>();
const customerIdentificationTypes = ref<string[]>();
const customerIdentificationFailureReasons = ref<string[]>();
const processing = ref(false);

const idTypeList: Ref<ListOption[]> = ref([]);
const identificationFailureReasonList: Ref<ListOption[]> = ref([]);
const rejectionModalDialog = ref<boolean>(false);
const numberOfAgeRestrictedItems = ref<number | undefined>(0);
const ageVerification = ref<Omit<AgeVerification, 'requiredAge' | 'status'>>({
  dateOfBirth: null,
  userIdType: null,
  rejectionReason: null,
});

const isDateValid = computed(
  () =>
    ageVerification.value.dateOfBirth !== null &&
    ageVerification.value.dateOfBirth < new Date(),
);

const isAgeValid = computed(() =>
  bornBeforeDate.value && ageVerification.value.dateOfBirth
    ? ageVerification.value.dateOfBirth <= bornBeforeDate.value
    : undefined,
);

const isIdValid = computed(
  () =>
    ageVerification.value.userIdType || !collectIdentificationTypeConfig.value,
);

const isFormValid = computed(
  () =>
    (isAgeValid.value || !collectDateOfBirthConfig.value) &&
    (isIdValid.value || !collectIdentificationTypeConfig.value),
);
const handleRejection = async () => {
  if (
    !displayFailureReasonConfig.value ||
    !customerIdentificationFailureReasons.value?.length
  ) {
    const { confirm } = useDynamicDialog();
    const confirmed = await confirm({
      title: $t('pages.age-verification.confirm-rejection-modal.title'),
      isTitleCentered: true,
      contentText: '',
      confirmText: $t(
        'pages.age-verification.confirm-rejection-modal.apply-button.text',
      ),
    });

    if (confirmed) {
      await reject();
    }

    return;
  }

  rejectionModalDialog.value = true;
};
const handleDate = (value: Date | null) => {
  if (!order.value) {
    return;
  }
  ageVerification.value.dateOfBirth = value;
};
const handleId = (value: string) => {
  if (!order.value) {
    return;
  }
  ageVerification.value.userIdType = value;
};
const handleRejectionReason = (value: RejectionReason) => {
  if (!order.value) {
    return;
  }
  ageVerification.value.rejectionReason = value;
};

const prepareConfigurationData = async () => {
  numberOfAgeRestrictedItems.value =
    order.value &&
    order.value.items?.reduce((totalQuantity, item) => {
      if (
        item.product?.ageRestricted > 0 &&
        item.status !== OrderItemStatus.cancelled
      ) {
        return totalQuantity + (item.quantity || 0);
      }
      return totalQuantity;
    }, 0);

  orderUser.value = order.value?.customer;
  displayDateOfBirthThresholdConfig.value = await configurationServicePlugin
    .get()
    .getFeatureOption(
      'ageVerification',
      'displayDateOfBirthThreshold',
      'boolean',
    );
  displayCustomerNameConfig.value = await configurationServicePlugin
    .get()
    .getFeatureOption('ageVerification', 'displayCustomerName', 'boolean');
  collectDateOfBirthConfig.value = await configurationServicePlugin
    .get()
    .getFeatureOption(
      'ageVerification',
      'displayDateOfBirthInputField',
      'boolean',
    );
  displayFailureReasonConfig.value = await configurationServicePlugin
    .get()
    .getFeatureOption('ageVerification', 'displayFailureReason', 'boolean');
  collectIdentificationTypeConfig.value = await configurationServicePlugin
    .get()
    .getFeatureOption('ageVerification', 'displayIdType', 'boolean');
  customerIdentificationTypes.value = await configurationServicePlugin
    .get()
    .getFeatureOption(
      'ageVerification',
      'customerIdentificationTypes',
      'array',
    );
  idTypeList.value = customerIdentificationTypes.value.map(
    (idType: string) => ({
      label: idType,
      e2eLabel: idType.toLowerCase(),
      value: idType.toLowerCase(),
    }),
  );
  customerIdentificationFailureReasons.value = await configurationServicePlugin
    .get()
    .getFeatureOption(
      'ageVerification',
      'customerIdentificationFailureReasons',
      'array',
    );
  identificationFailureReasonList.value =
    customerIdentificationFailureReasons.value.map((reason: string) => ({
      label: reason,
      e2eLabel: reason.toLowerCase(),
      value: reason.toLowerCase(),
    }));
};

onMounted(async () => {
  await loadOrder(route.params.id);
  await prepareConfigurationData();
});
const reject = async () => {
  if (!order.value) {
    return;
  }

  processing.value = true;
  if (order.value?.ageVerification) {
    if (customerIdentificationFailureReasons.value?.length) {
      order.value.ageVerification.rejectionReason =
        ageVerification.value.rejectionReason;
    }

    order.value.ageVerification.status = Status.Rejected;
  }

  const handleAgeVerificationPlugin = ageVerificationPluginRegister();
  await handleAgeVerificationPlugin.execute({
    order: order.value,
    isVerified: false,
    ageVerification: ageVerification.value,
    itemRejectionReasonRequired: false,
  });

  const { confirm } = useDynamicDialog();
  const confirmed = await confirm({
    title: $t('pages.age-verification.finalise-rejection.title', {
      count: numberOfAgeRestrictedItems.value,
    }),
    isTitleCentered: false,
    showOnlyConfirm: true,
    contentText: $t('pages.age-verification.finalise-rejection.subtitle', {
      count: numberOfAgeRestrictedItems.value,
    }),
    confirmText: $t('pages.age-verification.finalise-rejection.confirm-text'),
  });

  if (confirmed) {
    await redirectHandover();
  }

  processing.value = false;
  return;
};

const redirectHandover = async () => {
  if (!order.value) {
    return;
  }

  await router.push({
    name: 'handover-order',
    params: {
      id: order.value.id,
    },
  });
};

const verifyAge = async () => {
  processing.value = true;
  if (order.value?.ageVerification) {
    order.value.ageVerification.status = Status.Verified;
    order.value.ageVerification.userIdType = ageVerification.value.userIdType;
    order.value.ageVerification.dateOfBirth = ageVerification.value.dateOfBirth;
  }
  if (!order.value) {
    return;
  }
  try {
    const handleAgeVerificationPlugin = ageVerificationPluginRegister();
    await handleAgeVerificationPlugin.execute({
      order: order.value,
      isVerified: true,
      ageVerification: ageVerification.value,
      itemRejectionReasonRequired: false,
    });
    await redirectHandover();
  } catch (error) {
    errorPlugin.get().handle(error);
  }
  processing.value = false;
};

const firstName = computed(() => order.value?.customer?.firstName ?? '');
const lastName = computed(() => order.value?.customer?.lastName ?? '');

const bornBeforeDate = computed(() => {
  if (!order.value?.ageVerification?.requiredAge) {
    return null;
  }
  const date = addDate(
    new Date(),
    -order.value?.ageVerification?.requiredAge,
    dateAmountType.YEARS,
  );
  if (!date) {
    return null;
  }
  return date;
});

const locale = useCurrentLocale();

const displayedBornBeforeDate = computed(() => {
  if (!bornBeforeDate.value) {
    return '-';
  }

  return bornBeforeDate.value.toLocaleDateString(locale.value, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(ThreeSectionLayout), null, {
      header: _withCtx(() => [
        _createVNode(AppHeader, null, {
          default: _withCtx(() => [
            (_unref(order))
              ? (_openBlock(), _createBlock(StatusBar, {
                  key: 0,
                  customerNote: _unref(order)?.cartNote,
                  order: _unref(order),
                  title: _unref(order).pickupCode,
                  to: { name: 'handover-customer-information', params: { id: _ctx.id } }
                }, null, 8, ["customerNote", "order", "title", "to"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_unref(order)?.checkIn?.timestamp)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(ChipTime, {
                time: _unref(order)?.checkIn?.timestamp
              }, null, 8, ["time"])
            ]))
          : _createCommentVNode("", true)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_16, [
          _createVNode(BaseButton, {
            disabled: !isFormValid.value || processing.value,
            label: _unref($t)('pages.age-verification.verify-button.text'),
            loading: processing.value,
            "data-e2e": "age-verification-verify-button",
            type: "primary",
            onClick: verifyAge
          }, null, 8, ["disabled", "label", "loading"]),
          _createVNode(BaseButton, {
            id: "reject-button",
            disabled: processing.value,
            label: _unref($t)('pages.age-verification.reject-button.text'),
            class: "m-t-md",
            "data-e2e": "age-verification-reject-button",
            type: "secondary",
            onClick: handleRejection
          }, null, 8, ["disabled", "label"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "ut-d-flex navigation__back-button",
            "data-e2e": "header-back-link",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).back()))
          }, [
            _createVNode(ArrowLeftIcon, { "data-e2e": "back-icon" })
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            alt: "illustration for age verification",
            class: "hero",
            "data-e2e": "age-verification-image",
            src: _imports_0
          }, null, -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_unref($t)('pages.age-verification.title.text')), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_unref($t)('pages.age-verification.subtitle.text')), 1),
            (displayCustomerNameConfig.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_unref($t)('pages.age-verification.name.text')), 1),
                  _createElementVNode("h1", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, _toDisplayString(firstName.value), 1),
                    _cache[3] || (_cache[3] = _createTextVNode(" ")),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(lastName.value), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (displayDateOfBirthThresholdConfig.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("p", _hoisted_12, _toDisplayString(_unref($t)('pages.age-verification.born-before.text')), 1),
                  _createElementVNode("h1", _hoisted_13, _toDisplayString(displayedBornBeforeDate.value), 1)
                ]))
              : _createCommentVNode("", true),
            (collectDateOfBirthConfig.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createVNode(_unref(DateInput), {
                    autocomplete: false,
                    "error-validation-instruction": 
                _unref($t)(
                  'pages.age-verification.form.date.error.validationInstruction',
                )
              ,
                    "error-validation-text": 
                _unref($t)('pages.age-verification.form.date.error.validationText')
              ,
                    format: _unref($t)('pages.age-verification.form.date.format'),
                    "is-valid": isAgeValid.value,
                    label: _unref($t)('pages.age-verification.form.date.label'),
                    placeholder: _unref($t)('pages.age-verification.form.date.format'),
                    value: ageVerification.value.dateOfBirth,
                    "e2e-description": "age-verification",
                    required: "",
                    "onUpdate:date": handleDate
                  }, null, 8, ["error-validation-instruction", "error-validation-text", "format", "is-valid", "label", "placeholder", "value"])
                ]))
              : _createCommentVNode("", true),
            (collectIdentificationTypeConfig.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  (idTypeList.value.length && (isAgeValid.value || !isDateValid.value))
                    ? (_openBlock(), _createBlock(_unref(FormGroup), {
                        key: 0,
                        id: "collect-identification",
                        legend: _unref($t)('pages.age-verification.form.id-type.label'),
                        e2e: "age-verification-id-type"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(RadioGroup), {
                            id: "id-type",
                            isPreSelected: false,
                            options: idTypeList.value,
                            onSelectedChange: handleId
                          }, null, 8, ["options"])
                        ]),
                        _: 1
                      }, 8, ["legend"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_unref(Popup), {
      isCloseButtonExist: false,
      visible: rejectionModalDialog.value,
      isContentCentered: "",
      isSpaceToContentExist: "",
      onClose: _cache[2] || (_cache[2] = ($event: any) => (rejectionModalDialog.value = false))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_17, _toDisplayString(_unref($t)('components.age-verification-rejection-modal.title')), 1),
        _createVNode(_unref(RadioGroup), {
          id: "rejectionGroup",
          options: identificationFailureReasonList.value,
          e2e: "age-verification-reject-radio",
          onSelectedChange: handleRejectionReason
        }, null, 8, ["options"]),
        _createElementVNode("div", _hoisted_18, [
          _createVNode(BaseButton, {
            label: 
            _unref($t)('components.age-verification-rejection-modal.apply-button.text')
          ,
            "data-e2e": "age-verification-rejection-modal-apply",
            type: "primary",
            onClick: reject
          }, null, 8, ["label"]),
          _createVNode(BaseButton, {
            label: 
            _unref($t)('components.age-verification-rejection-modal.cancel-button.text')
          ,
            class: "m-t-md",
            "data-e2e": "age-verification-rejection-modal-cancel",
            type: "tertiary",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (rejectionModalDialog.value = false))
          }, null, 8, ["label"])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})