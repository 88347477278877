import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  class: "no-orders-fallback__title",
  "data-e2e": "no-orders-fallback-title"
}

import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import Button from '@/features/ui/components/Button.vue';
import { FallbackType } from '../types';
import { $t } from '@/i18n';
import emptyImage from '@/assets/images/empty.svg';
import swimmingWomanImage from '@/assets/images/swimming-woman.svg';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoOrdersFallback',
  props: {
    fallbackType: {}
  },
  emits: ['buttonClick'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;
const props = __props;

const buttonClick = () => emit('buttonClick');
let isButtonVisible: Ref<boolean> = ref(false);
let fallbackTitle: Ref<string> = ref('');

watch(
  () => props.fallbackType,
  () => {
    switch (props.fallbackType) {
      case FallbackType.noPickingOrders:
        fallbackTitle.value = $t('pages.order-overview.screen-no-order.text');
        break;
      case FallbackType.noFilteredPickingOrders:
        fallbackTitle.value = $t(
          'pages.order-overview.screen-no-picking-orders.text',
        );
        isButtonVisible.value = true;
        break;
      case FallbackType.noHandoverOrders:
        fallbackTitle.value = $t(
          'pages.order-overview.screen-no-handover.text',
        );
        break;
    }
  },
  {
    immediate: true,
  },
);
const isHandoverImage = computed(
  () => props.fallbackType === FallbackType.noHandoverOrders,
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["no-orders-fallback", { 'handover-image': isHandoverImage.value }])
  }, [
    (isHandoverImage.value)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "handover-image",
          width: "223",
          height: "195",
          src: _unref(emptyImage),
          alt: _unref(fallbackTitle),
          "data-e2e": "empty-order-list"
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _unref(swimmingWomanImage),
          "data-e2e": "empty-order-list"
        }, null, 8, _hoisted_2)),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(fallbackTitle)), 1),
    (_unref(isButtonVisible))
      ? (_openBlock(), _createBlock(Button, {
          key: 2,
          "btn-outline": "",
          onClick: buttonClick,
          "data-e2e": "no-orders-reset-filter"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref($t)('components.no-orders-fallback.reset-button.text')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}
}

})