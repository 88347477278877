import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "restore-age-verification-popup__content" }
const _hoisted_2 = { class: "restore-age-verification-popup__instructions-top" }
const _hoisted_3 = { class: "restore-age-verification-popup__instructions-bottom" }

import { $t } from '@/i18n';

export default /*@__PURE__*/_defineComponent({
  __name: 'RestoreAgeVerificationContent',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_unref($t)('components.restore-age-verification-modal.instructions-top.text')), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_unref($t)('components.restore-age-verification-modal.instructions-bottom.text')), 1)
  ]))
}
}

})