import type { EventBusEvent } from '@/features/core/event-bus';

export class CheckinNotifyEvent implements EventBusEvent {
  name = 'CheckinNotifyEvent';
}

export class CustomerCheckInAcknowledgedEvent implements EventBusEvent {
  name = 'CustomerCheckInAcknowledgedEvent';

  constructor(public readonly orderId: string) {}
}

export class RedirectToCheckinNotifyEvent implements EventBusEvent {
  name = 'RedirectToCheckinNotifyEvent';
}

export class BarcodeScanEvent implements EventBusEvent {
  name = 'BarcodeScanEvent';
}
