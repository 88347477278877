import { ProviderPluginFactory } from '@/features/core/plugin';
import { SyncSchedulerService } from './sync-scheduler-service';
import { storagePlugin } from '@/features/core/storage';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import type { SyncSchedulerExecutor } from './sync-scheduler-executor';
import { SyncSchedulerExecutorService } from './sync-scheduler-executor';
import { MultiPluginFactory } from '@/features/core/plugin';
import { onlineEntityRepositoryPlugin } from '@/features/core/entity-repository';
import { SyncSchedulerExecutorAdapter } from './sync-scheduler-executor-adapter';
import type { Options } from '../types';
import { loggerServicePlugin } from '@/features/core/logger';

export * from './sync-scheduler';
export * from './sync-scheduler-service';
export * from './sync-scheduler-executor';
export * from './sync-scheduler-executor-adapter';

export const syncSchedulerServicePlugin =
  ProviderPluginFactory.create<SyncSchedulerService>({
    key: Symbol('SyncSchedulerService'),
    defaultFactory: {
      create: () =>
        new SyncSchedulerService(
          storagePlugin.get(),
          eventBusServicePlugin.get(),
          loggerServicePlugin.get(),
        ),
    },
  });

export const syncSchedulerExecutorPlugin =
  ProviderPluginFactory.create<SyncSchedulerExecutor>({
    key: Symbol('SyncSchedulerExecutor'),
    defaultFactory: {
      create: () =>
        new SyncSchedulerExecutorService(
          syncSchedulerServicePlugin.get(),
          storagePlugin.get(),
          onlineEntityRepositoryPlugin.get(),
          eventBusServicePlugin.get(),
          loggerServicePlugin.get(),
        ),
    },
  });

export const syncSchedulerExecutorAdapterPlugin =
  ProviderPluginFactory.create<SyncSchedulerExecutorAdapter>({
    key: Symbol('SyncSchedulerExecutorAdapter'),
    defaultFactory: {
      create: (app, options: Options) =>
        new SyncSchedulerExecutorAdapter(
          syncSchedulerExecutorPlugin.get(),
          options.skipSW,
        ),
    },
  });

export const syncSchedulerServicesPlugin = MultiPluginFactory.with({
  syncSchedulerServicePlugin,
  syncSchedulerExecutorPlugin,
  syncSchedulerExecutorAdapterPlugin,
});
