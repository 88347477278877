import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["checked", "id", "data-e2e"]
const _hoisted_2 = ["for", "data-e2e"]


export default /*@__PURE__*/_defineComponent({
  __name: 'Checkbox',
  props: {
  color: {
    type: String,
    default: 'transparent',
  },
  label: String,
  fieldId: {
    type: String,
    required: true,
  },
  checked: {
    type: Boolean,
  },
  e2eDescription: String,
},
  emits: ['update:checked'],
  setup(__props, { emit: __emit }) {

const emit = __emit;



const updateInput = (event: Event) => {
  emit('update:checked', event.target.checked);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-checkbox", {
      'base-checkbox__off': !__props.checked,
    }])
  }, [
    _createElementVNode("input", {
      class: "base-checkbox__input",
      type: "checkbox",
      checked: __props.checked,
      id: __props.fieldId,
      onInput: updateInput,
      "data-e2e": __props.e2eDescription
    }, null, 40, _hoisted_1),
    _createElementVNode("label", {
      class: "base-checkbox__label",
      style: _normalizeStyle({ 'border-color': __props.color }),
      for: __props.fieldId,
      "data-e2e": `${__props.e2eDescription}-label`
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 12, _hoisted_2)
  ], 2))
}
}

})