import axios from 'axios';
import { HandledError } from '@/features/core/errors';
import type { AxiosError, AxiosInstance } from 'axios';
import type { ErrorHandler } from '../errors';
import { HttpGeneratedError } from '../errors';
import type { OauthConfig } from '@/features/oauth/types';

export interface BaseApiClientOptions {
  baseURL: string;
  withCredentials?: boolean;
  config?: OauthConfig;
  timeout?: number;
  headers?: { [key: string]: string };
}
export interface Response<T> {
  data: T;
}

export class BaseApiClient {
  client: AxiosInstance;

  constructor(
    public options: BaseApiClientOptions,
    protected errorHandler: ErrorHandler,
    private adapter?: 'xhr' | 'http' | 'fetch',
  ) {
    this.client = axios.create({
      baseURL: this.options.baseURL,
      timeout: this.options.timeout,
      withCredentials: this.options.withCredentials || false,
      headers: {
        'Content-Type': 'application/json',
        ...this.options.headers,
      },
      maxRedirects: 0,
      adapter: this.adapter,
    });

    this.client.interceptors.response.use(
      undefined,
      this.onResponseError.bind(this),
    );
  }

  private onResponseError(err: AxiosError): Promise<void> {
    // const error: Omit<AxiosError, 'config'> & {
    //   config: AxiosRequestConfig;
    // } = {
    //   ...err,
    //   config: {
    //     innerErrorCode: err.config?.innerErrorCode,
    //     disableErrorHandling: err.config?.disableErrorHandling,
    //     url: err.config?.url,
    //     method: err.config?.method,
    //     data: err.config?.data as T,
    //     baseURL: err.config?.baseURL,
    //   },
    //   isAxiosError: true,
    // };
    if (err.config?.disableErrorHandling) return Promise.reject(err);
    this.errorHandler.handle(
      new HttpGeneratedError(err.config?.innerErrorCode, err),
    );
    return Promise.reject(new HandledError(err));
  }
}
