import type { OrderItem } from '@/features/orders';
import { isOrderItemReplacement, OrderItemStatus } from '@/features/orders';
import type { OverpickingThresholds } from '@/features/picking';
import { isOrderItemChanged } from '@/features/picking';
import type { PartitionedHandoverItems } from '../constants';
import { isFullyPickedItemRejected } from './isFullyPickedItemRejected';

export const partitionHandoverItems = (
  orderItems: OrderItem[] | null | undefined,
  overpickingThreshold: OverpickingThresholds | undefined,
): PartitionedHandoverItems => {
  if (!orderItems) {
    return {
      changed: [],
      staged: [],
      rejected: [],
    };
  }

  const stagedItemIdsSet = new Set(
    orderItems
      .filter((item) => item.status === OrderItemStatus.staged)
      .map((item) => item.id),
  );

  const isItemRejected = (item: OrderItem) =>
    item.status === OrderItemStatus.rejected ||
    (item.isRejected && isOrderItemReplacement(item));
  const isItemStaged = (item: OrderItem) =>
    !isItemRejected(item) &&
    item.status === OrderItemStatus.staged &&
    !isOrderItemChanged(item, overpickingThreshold);

  const rejectedItems = orderItems.filter((item) => isItemRejected(item));
  const stagedItems = orderItems.filter(
    (item) =>
      isItemStaged(item) ||
      isFullyPickedItemRejected(item, orderItems, overpickingThreshold),
  );
  const changedItems = orderItems.filter(
    (item) =>
      !isItemRejected(item) &&
      !isItemStaged(item) &&
      !isFullyPickedItemRejected(item, orderItems, overpickingThreshold) &&
      // If we have a matching staged item already, ignore
      // cancelled items with the same ID (CICS-50505)
      !(
        item.status === OrderItemStatus.cancelled &&
        stagedItemIdsSet.has(item.id)
      ),
  );

  return {
    rejected: rejectedItems,
    staged: stagedItems,
    changed: changedItems,
  };
};
