import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card__image"
}

import { useProductImage } from '../composables';
import type { Product } from '../entities';
import ProductImage from './ProductImage.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductCardImage',
  props: {
    product: {},
    e2eTag: { default: 'pickup' },
    e2eValue: { default: null },
    scalable: { type: Boolean, default: false },
    grayscale: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const { noImage, imageSrc, isBag } = useProductImage(props.product);

return (_ctx: any,_cache: any) => {
  return (_unref(isBag) || _unref(noImage) || _unref(imageSrc))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(ProductImage, _normalizeProps(_guardReactiveProps(props)), null, 16)
      ]))
    : _createCommentVNode("", true)
}
}

})