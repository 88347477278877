import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/complete-picking-bags@2.png'


const _hoisted_1 = { class: "hero" }
const _hoisted_2 = { class: "hero__title" }
const _hoisted_3 = { "data-e2e": "order-code-label" }
const _hoisted_4 = {
  class: "hero__code",
  "data-e2e": "order-code-value"
}
const _hoisted_5 = {
  class: "error",
  "data-e2e": "order-empty-error"
}
const _hoisted_6 = { class: "error__title" }
const _hoisted_7 = {
  class: "ut-font_fix",
  "data-e2e": "order-empty-error-title"
}
const _hoisted_8 = { "data-e2e": "order-empty-error-desc" }

import { $t } from '@/i18n';
import { onMounted } from 'vue';
import { InfoIcon } from '@/features/ui';


export default /*@__PURE__*/_defineComponent({
  __name: 'BagsEmptySection',
  props: {
  order: { required: true },
},
  emits: ['onAllItemsPicked'],
  setup(__props, { emit: __emit }) {


const emit = __emit;
const bagsSectionTitle = $t(
  'pages.bags-collection.title-order-code-label.text',
);

onMounted(() => {
  emit('onAllItemsPicked', true);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "hero__image" }, [
        _createElementVNode("picture", null, [
          _createElementVNode("img", {
            alt: "",
            class: "ut-img-contain",
            "data-e2e": "order-image",
            src: _imports_0
          })
        ])
      ], -1)),
      _createElementVNode("h1", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(bagsSectionTitle)), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(__props.order?.pickupCode), 1)
      ])
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("hr", { class: "separator" }, null, -1)),
    _createElementVNode("section", _hoisted_5, [
      _createElementVNode("h2", _hoisted_6, [
        _createVNode(_unref(InfoIcon), { fill: "#D70000" }),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_unref($t)('errors.bags.failed-to-load-information.text')), 1)
      ]),
      _createElementVNode("p", _hoisted_8, _toDisplayString(_unref($t)('errors.bags.order-empty.text')), 1)
    ])
  ], 64))
}
}

})