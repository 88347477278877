import { authServicePlugin } from '@/features/core/auth';
import { loggerServicePlugin } from '@/features/core/logger';
import {
  ExecPluginFactory,
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { syncSchedulerServicePlugin } from '@/features/sync-scheduler';
import { userServicePlugin } from '@/features/user';
import { OauthService } from './oauth';
import type { OauthConfig } from './types';
import { sessionStoragePlugin } from '@/features/session-storage';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import { LogoutEvent } from './events';

export const oauthServicePlugin = ProviderPluginFactory.create<
  OauthService,
  OauthConfig
>({
  key: Symbol('OAuthService'),
  defaultFactory: {
    create: (app, options) =>
      new OauthService(
        options,
        authServicePlugin.get(),
        loggerServicePlugin.get(),
        sessionStoragePlugin.get(),
        userServicePlugin.get(),
      ),
  },
});

export const oauthExecutePlugin = ExecPluginFactory.fn(async () => {
  const isForceLogoutRequired = await userServicePlugin
    .get()
    .isForceLogoutRequired();
  if (isForceLogoutRequired) {
    await notifyAboutForcedLogout();
    await oauthServicePlugin.get().logout('empowerId');
  }
  eventBusServicePlugin.get().on(LogoutEvent, () => {
    void oauthServicePlugin.get().logout('empowerId');
  });
});

export const oauthPlugin = MultiPluginFactory.with({
  oauthServicePlugin,
  oauthExecutePlugin,
});

const notifyAboutForcedLogout = async () => {
  const userLastActivity = await userServicePlugin.get().getLastActivity();
  const pendingTransmissions = await syncSchedulerServicePlugin
    .get()
    .getPendingSyncs();
  const failedTransmissions = await syncSchedulerServicePlugin
    .get()
    .getFailedSyncs();

  loggerServicePlugin.get().warn('User was logged out due to inactivity', {
    userLastActivity,
    pendingTransmissions: pendingTransmissions.length,
    failedTransmissions: failedTransmissions.length,
  });
};
