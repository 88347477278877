import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { Order } from '../entities/order';
import type { OrdersService } from '../services';

/**
 * Save order
 */

export class SaveOrderPlugin<T extends { order: Order }>
  implements PipelinePlugin<T>
{
  constructor(private ordersService: OrdersService) {}

  public async execute(dto: T): Promise<T> {
    await this.ordersService.saveOrder(dto.order);
    return dto;
  }
}
