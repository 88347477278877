import type { ItemWithOriginalId } from './getItemAndReplacementIds';

export type MappingCallback<T> = (params: {
  id: string;
  originalId: string | null;
}) => T;

export function mapItemsToEntries<T>(
  items: ItemWithOriginalId[],
  callback: MappingCallback<T>,
): { [p: string]: T } {
  return Object.fromEntries(
    items.map(({ id, originalId }) => [id, callback({ id, originalId })]),
  );
}
