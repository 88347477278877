import type { AuthService } from '@/features/core/auth';
import type { ErrorHandler } from '@/features/core/errors';
import type { ResolverService } from '@/features/core/resolver/service';
import type { BaseApiClientOptions } from './base-api-client';
import { BaseApiClient } from './base-api-client';
import type { OauthProviderConfig } from '@/features/oauth/types';
import { loggerServicePlugin } from '../logger';
import type { ApiClientRequestConfig } from './types';

export class ApiClient extends BaseApiClient {
  constructor(
    options: BaseApiClientOptions,
    private authService: AuthService,
    private resolverService: ResolverService,
    errorHandler: ErrorHandler,
    adapter?: 'xhr' | 'http' | 'fetch',
  ) {
    super(
      {
        ...options,
        withCredentials: true,
      },
      errorHandler,
      adapter,
    );

    const resolver = this.resolverService.getResolver();

    this.init()
      .then(() => {
        return resolver.resolve();
      })
      .catch((error) => {
        resolver.reject(error);
      });

    this.client.interceptors.request.use(
      this.beforeResponse.bind(this),
      undefined,
    );
  }

  private async init() {
    await this.updateAuthHeaders();
    this.authService.onUserTokenChange(() => void this.updateAuthHeaders());
    this.authService.onDeviceTokenChange(() => void this.updateAuthHeaders());
  }

  private async updateAuthHeaders<T>() {
    const authHeaders = await this.getAuthHeaders();

    if (Object.keys(authHeaders).length) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.client.defaults.headers.common = {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        ...(this.client.defaults.headers.common as T),
        ...authHeaders,
      };
    }
  }

  private async getAuthHeaders() {
    const token =
      (await this.authService.getUserToken()) ??
      (await this.authService.getDeviceToken());
    return token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {};
  }

  public createDeleteAuthHeaderInterceptor = (): ((
    config: ApiClientRequestConfig,
  ) => Promise<ApiClientRequestConfig>) => {
    // eslint-disable-next-line @typescript-eslint/require-await
    return async (config: ApiClientRequestConfig) => {
      if (config.headers) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        delete config.headers.Authorization;
      }
      return config;
    };
  };

  private async beforeResponse(
    config: ApiClientRequestConfig,
  ): Promise<ApiClientRequestConfig> {
    const oauthConfig = {
      ...this.options.config?.providers['empowerId'],
      useDeviceToken: config.useDeviceToken,
    } as OauthProviderConfig;
    const token = await this.authService.checkToken(config.method, oauthConfig);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      //Cancel request if token expired
      loggerServicePlugin
        .get()
        .info(
          'The request was canceled because there is no valid token (user/device) available.',
        );
      return Promise.reject(new Error('no valid token'));
    }
    return config;
  }
}
