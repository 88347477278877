import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "labels__item" }
const _hoisted_2 = ["data-e2e"]
const _hoisted_3 = { class: "labels-quantity__block" }
const _hoisted_4 = ["data-e2e"]

import { computed } from 'vue';
import BaseButton from '@/features/ui/components/Button.vue';
import MinusIcon from '@/features/ui/icons/MinusIcon.vue';
import PlusIcon from '@/features/ui/icons/PlusIcon.vue';
import { LabelsLayoutEnum } from '@/features/ui/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'Label',
  props: {
    title: {},
    dataE2eId: { default: 'Label' },
    quantity: { default: 0 },
    quantityOriginal: { default: 0 },
    minimumBagCount: { default: 0 },
    layout: { default: LabelsLayoutEnum.Default }
  },
  emits: ["update:quantity"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const updateQuantity = (quantity: number) => {
  quantity = Math.max(0, quantity);
  quantity = Math.min(quantity, props.quantityOriginal);
  emit('update:quantity', quantity);
};

const quantityLabel = computed(() => {
  if (
    props.quantityOriginal > 0 &&
    props.layout === LabelsLayoutEnum.ShowOriginalQuantity
  ) {
    return `${props.quantity} / ${props.quantityOriginal}`;
  }

  return props.quantity;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", {
      "data-e2e": `labels-name-label-${_ctx.dataE2eId}`,
      class: "labels__item__title"
    }, [
      _createElementVNode("p", null, _toDisplayString(_ctx.title), 1)
    ], 8, _hoisted_2),
    _createVNode(BaseButton, {
      "data-e2e": `labels-decrease-button-${_ctx.dataE2eId}`,
      disabled: _ctx.quantity <= _ctx.minimumBagCount,
      icon: "",
      btnOutline: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (updateQuantity(_ctx.quantity - 1)))
    }, {
      default: _withCtx(() => [
        _createVNode(MinusIcon, {
          fill: "currentColor",
          stroke: "currentColor"
        })
      ]),
      _: 1
    }, 8, ["data-e2e", "disabled"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", {
        "data-e2e": `labels-${_ctx.dataE2eId}-quantity-text`,
        class: "labels-quantity__value text-bold"
      }, _toDisplayString(quantityLabel.value), 9, _hoisted_4)
    ]),
    _createVNode(BaseButton, {
      "data-e2e": `labels-increase-button-${_ctx.dataE2eId}`,
      disabled: _ctx.quantity >= _ctx.quantityOriginal,
      btnOutline: "",
      icon: "",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (updateQuantity(_ctx.quantity + 1)))
    }, {
      default: _withCtx(() => [
        _createVNode(PlusIcon, {
          fill: "currentColor",
          stroke: "currentColor"
        })
      ]),
      _: 1
    }, 8, ["data-e2e", "disabled"])
  ]))
}
}

})