import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/loading.gif'



export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingSpinner',
  props: {
    isInline: { type: Boolean, default: false }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["loading", { 'loading--inline': _ctx.isInline }]),
    "data-e2e": "loading"
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("img", {
      src: _imports_0,
      alt: "loading"
    }, null, -1)
  ]), 2))
}
}

})