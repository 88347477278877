import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { onMounted } from 'vue';
import {
  CheckInBanner,
  CheckInBannerContextEnum,
} from '@/features/check-in-banner';
import { useCheckInOrders } from '@/features/orders';


export default /*@__PURE__*/_defineComponent({
  __name: 'PickingLayout',
  setup(__props) {

const {
  checkCheckedInOrders,
  firstCheckedInOrder,
  showBanner,
  checkinOrdersLength,
} = useCheckInOrders();

onMounted(() => {
  void checkCheckedInOrders();
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RouterView),
    (_unref(firstCheckedInOrder))
      ? (_openBlock(), _createBlock(_unref(CheckInBanner), {
          key: 0,
          checkinOrdersLength: _unref(checkinOrdersLength),
          context: _unref(CheckInBannerContextEnum).PICKING_FLOW,
          order: _unref(firstCheckedInOrder),
          "show-banner": _unref(showBanner)
        }, null, 8, ["checkinOrdersLength", "context", "order", "show-banner"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})