
import type { PropType } from 'vue';
import { defineComponent, ref } from 'vue';
import type { OrderItem } from '@/features/orders/types';
import type { TemperatureClass } from '../types';
import { basicTemperatureClasses } from '../types';
import ProductIcons from './ProductIcons.vue';
import ProductItem from './ProductItem.vue';
import { cloneItem } from '@/utils/helpers/clone';

export default defineComponent({
  name: 'ProductBox',
  components: { ProductItem, ProductIcons },
  props: {
    id: String,
    products: {
      type: Array as PropType<OrderItem[]>,
      default: () => {
        return [];
      },
    },
    isSpaceBetween: {
      type: Boolean,
      default: false,
    },
  },
  setup: function (props) {
    const temperatureClassList = ref<TemperatureClass[]>(
      cloneItem(basicTemperatureClasses),
    );

    const countOfProduct = (temperatureClassName: string | string[]) => {
      let typeCountArray = props.products.filter((item) => {
        if (typeof temperatureClassName === 'string') {
          return item.product && typeof item.product !== 'string'
            ? item.product.temperatureClass === temperatureClassName
            : false;
        }

        return item.product && typeof item.product !== 'string'
          ? temperatureClassName.includes(item.product.temperatureClass)
          : false;
      });

      return typeCountArray.length;
    };

    return {
      countOfProduct,
      temperatureClassList,
      ...props,
    };
  },
});
