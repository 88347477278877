import type { OverpickingThresholds } from '@/features/picking';
import { isOrderItemChanged } from '@/features/picking';
import type { OrderItem } from '@/features/orders';
import { OrderItemStatus } from '@/features/orders';
import { isOrderItemReplaced } from './isOrderItemReplaced';

export interface PartitionedReviewItems {
  picked: OrderItem[];
  changed: OrderItem[];
}

export const partitionReviewItems = (
  orderItems: OrderItem[] | null | undefined,
  overpickingThreshold: OverpickingThresholds | undefined,
): PartitionedReviewItems => {
  const initialValue: PartitionedReviewItems = {
    picked: [],
    changed: [],
  };
  if (!orderItems) return initialValue;

  return orderItems.reduce((acc, item) => {
    if (
      item.status === OrderItemStatus.picked &&
      !isOrderItemReplaced(item) &&
      !isOrderItemChanged(item, overpickingThreshold)
    ) {
      return { ...acc, picked: [...acc.picked, item] };
    }
    return { ...acc, changed: [...acc.changed, item] };
  }, initialValue);
};
