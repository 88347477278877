import type {
  CompletePipelineWith,
  PipelinePlugin,
} from '@ads/plugin-pipeline';
import { loggerServicePlugin } from '@/features/core/logger';
import { ordersServicePlugin } from '@/features/orders';
import type { OrderPluginDto } from '../types';

export class SaveOrderAndSyncScheduledPlugin
  implements PipelinePlugin<OrderPluginDto>
{
  public async execute(
    dataTransferObject: OrderPluginDto,
    complete: CompletePipelineWith<OrderPluginDto>,
  ): Promise<OrderPluginDto> {
    try {
      await ordersServicePlugin
        .get()
        .saveOrder(dataTransferObject.order, true, false, false);
    } catch (error) {
      loggerServicePlugin
        .get()
        .error(
          `Plugin failed to save and schedule sync for order ${dataTransferObject.order.id}`,
          {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            error,
            orderId: dataTransferObject.order.id,
            orderReference: dataTransferObject.order.orderReference,
          },
        );

      return complete(dataTransferObject);
    }

    return dataTransferObject;
  }
}
