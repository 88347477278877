import type { ObjectComparator } from '@/utils/types';

/**
 * The isArrayOfObjectsEqualBy function compares two arrays of objects element by element using a custom comparison function (compareFn),
 * considering both order and equality. It returns true if both arrays have the same length and all elements match in order.
 */
export function isArrayOfObjectsEqualBy<T extends Record<string, unknown>>(
  array1: T[],
  array2: T[],
  compareFn: ObjectComparator<T>,
): boolean {
  if (array1.length !== array2.length) {
    return false;
  }

  return array1.every((item, index) => compareFn(item, array2[index]));
}
