// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import qs from 'qs';
import type { ApiClient, BaseApiClient } from '@/features/core/api';
import { oauthServicePlugin } from '@/features/oauth';
import { sessionStoragePlugin } from '@/features/session-storage';
import { $t } from '@/i18n';
import type { OauthLoginAuthResponse, OauthTokenAuthResponse } from '../types';
import { GetDeviceTokenError } from '../errors';

export class OauthLoginApiClient {
  constructor(private api: ApiClient, private baseApi: BaseApiClient) {
    this.api.createDeleteAuthHeaderInterceptor();
  }

  async getEmpowerIDToken(
    params: OauthLoginAuthResponse,
  ): Promise<OauthTokenAuthResponse> {
    const authService = oauthServicePlugin.get();
    const config = authService.getSalteConfig('empowerId');
    params.client_id = config.clientID;
    params.code_verifier = sessionStoragePlugin.get().getItem('code_verifier');
    sessionStoragePlugin.get().removeItem('code_verifier');
    const token: { data: OauthTokenAuthResponse } =
      await this.baseApi.client.post(config.tokenUri, qs.stringify(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    return token.data;
  }

  async getDeviceToken(): Promise<string> {
    const token: { data: OauthTokenAuthResponse } = await this.api.client.get(
      '/device-token',
      {
        disableErrorHandling: true,
        innerErrorCode: GetDeviceTokenError.Code,
      },
    );

    if (!token.data.accessToken) {
      throw new Error($t('errors.login.no-device-token.message'));
    }

    return token.data.accessToken;
  }
}
