import { Product } from '../entities';
import type { RepositoryReadByIdsOptions } from '@/features/core/entity-repository';
import type { RegistryEntityRepositoryRegistry } from '@/features/core/entity-repository/registry-entity-repository';
import type { ProductApiClient } from './product';

export const registerProductsApi = (
  onlineEntityRepositoryRegistry: RegistryEntityRepositoryRegistry,
  product: ProductApiClient,
): void => {
  onlineEntityRepositoryRegistry.register(Product, {
    getAll: product.getAll.bind(product),
    getByIds(options: RepositoryReadByIdsOptions): Promise<Product[]> {
      return product.getByIds(options.ids);
    },
  });
};
