import { Order } from '@/features/orders/entities';
import { Product } from '@/features/products/entities';
import { DataSync } from '@/features/data-sync/entities';
import type {
  Entity,
  EntityType,
} from '@/features/core/entity-repository/entity';
import { Sync } from '@/features/sync-scheduler/entities';
import { AuthData } from '@/features/core/auth/entity';

export function getEntityByName(
  entityName: string,
): EntityType<Entity> | undefined {
  const entityMap: Record<string, EntityType<Entity>> = {
    [Order.from({}).type]: Order,
    [Product.from({}).type]: Product,
    [Sync.from({}).type]: Sync,
    [AuthData.from({}).type]: AuthData,
    [DataSync.from({}).type]: DataSync,
  };
  return entityMap[entityName];
}
