import type { ChangeLog, UseChangeLog } from '../types';
import type { Ref } from 'vue';
import { ref } from 'vue';

export function useChangeLog(): UseChangeLog {
  const changeLog: Ref<ChangeLog> = ref({});
  const version = process.env.VUE_APP_VERSION ?? '';
  const hasContent: Ref<boolean | undefined> = ref();
  const content: Ref<string[]> = ref([]);

  const fetchChangeLog = async () => {
    const changeLogUrl = 'changelog.json';
    const response = await fetch(changeLogUrl, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (
      response.ok === false ||
      response.headers.get('Content-Type')?.includes('json') === false
    ) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    changeLog.value = await response.json();

    hasContent.value =
      Boolean(changeLog.value[version]) &&
      (changeLog.value[version] as string[]).length > 0;
    content.value = hasContent.value
      ? (changeLog.value[version] as string[])
      : [];
  };

  void fetchChangeLog();

  return {
    version,
    content,
    hasContent,
  };
}
