import type { App } from 'vue';

export interface VuePlugin<O = unknown> {
  install(app: App, options: VuePluginOptions<O>): void;
}

export type VuePluginOptions<O = unknown> = O | (() => O);

export function resolvePluginOptions<O>(options?: VuePluginOptions<O>): O {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-explicit-any
  return typeof options === 'function' ? (options as any)() : options;
}
