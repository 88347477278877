import scanSuccessSound from '@/assets/sounds/scanner/scan_success.mp3';
import actionRequiredSound from '@/assets/sounds/scanner/action_required.mp3';
import scanSuccessThenActionRequiredSound from '@/assets/sounds/scanner/scan_success_then_action_required.mp3';
import type { DeviceFeedback } from './index';

//https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_Web_Audio_API
export class SoundFeedback implements DeviceFeedback {
  constructor(private audio: HTMLAudioElement) {}

  trigger(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.audio.onended = () => {
          resolve();
        };
        this.audio.onerror = (error) => {
          reject(error);
        };
        void this.audio.play();
      } catch (error) {
        reject(error);
      }
    });
  }
}

export class ScanSuccessSound extends SoundFeedback {
  constructor() {
    super(new Audio(scanSuccessSound));
  }
}

export class ActionRequiredSound extends SoundFeedback {
  constructor() {
    super(new Audio(actionRequiredSound));
  }
}

//TODO it might be unnecessary, just play ScanSuccessSound and ActionRequiredSound
export class ScanSuccessThenActionRequiredSound extends SoundFeedback {
  constructor() {
    super(new Audio(scanSuccessThenActionRequiredSound));
  }
}
