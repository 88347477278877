import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-e2e"]
const _hoisted_2 = ["data-e2e"]
const _hoisted_3 = { class: "body__content" }
const _hoisted_4 = ["data-e2e"]
const _hoisted_5 = { class: "body__action" }
const _hoisted_6 = { class: "body__footer footer" }

import type { Ref } from 'vue';
import { ref } from 'vue';
import type { OrderItem } from '@/features/orders';
import { CloseButton } from '@/features/handover/components';
import { ProductCardImage } from '@/features/products';


export default /*@__PURE__*/_defineComponent({
  __name: 'CardRowFloating',
  props: {
    orderItem: {},
    isControlsDisabled: { type: Boolean, default: false },
    isDeleted: { type: Boolean, default: false },
    isRestorable: { type: Boolean, default: true },
    canDelete: { type: Boolean, default: false },
    quantityOriginal: { default: 0 },
    currentTab: {},
    disabledImg: { type: Boolean, default: false },
    noImage: { type: Boolean, default: false },
    e2eTag: { default: 'pickup' },
    e2eValue: { default: null }
  },
  emits: [
  'remove',
  'restore',
  'reject',
  'apply',
  'focusOut',
  'focusIn',
],
  setup(__props: any, { expose: __expose, emit: __emit }) {



const emit = __emit;

const handleRejectButton = () => {
  emit('reject');
};

const highlighted: Ref<boolean> = ref(false);

const highlightCard = (): void => {
  highlighted.value = true;
  setTimeout(() => {
    highlighted.value = false;
  }, 350);
};

__expose({
  highlightCard,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['card', { highlighted: highlighted.value }]),
    "data-e2e": `${_ctx.e2eTag}-box-${_ctx.e2eValue ?? _ctx.orderItem?.product?.sku}`
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['card__body body', { 'card--disabled-image': _ctx.disabledImg }]),
      "data-e2e": `${_ctx.e2eTag}-img-wrapper-${_ctx.e2eValue ?? _ctx.orderItem?.product?.sku}`
    }, [
      (_ctx.orderItem.product)
        ? (_openBlock(), _createBlock(_unref(ProductCardImage), {
            key: 0,
            product: _ctx.orderItem.product,
            e2eTag: _ctx.e2eTag,
            e2eValue: _ctx.e2eValue
          }, null, 8, ["product", "e2eTag", "e2eValue"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "body__title",
          "data-e2e": `${_ctx.e2eTag}-name-label-${
            _ctx.e2eValue ?? _ctx.orderItem?.product?.sku
          }`
        }, _toDisplayString(_ctx.orderItem?.product?.productName), 9, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.canDelete)
          ? _withDirectives((_openBlock(), _createBlock(_unref(CloseButton), {
              key: 0,
              onClick: handleRejectButton,
              "data-e2e": `delete-${_ctx.e2eTag}-item-${
              _ctx.e2eValue ?? _ctx.orderItem?.product?.sku
            }`
            }, null, 8, ["data-e2e"])), [
              [_vShow, !_ctx.isDeleted]
            ])
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "footer")
      ])
    ], 10, _hoisted_2)
  ], 10, _hoisted_1))
}
}

})