import type { Ref } from 'vue';
import { errorPlugin } from '@/features/core/errors';
import router from '@/features/core/router';
import type { Order } from '@/features/orders';
import { usePerformanceTracker } from '@/features/performance-tracker';
import { PipelineExecutionError } from '@ads/plugin-pipeline/build/pipeline-execution-error';
import type { UseStartHandoverProcess } from '../types';
import { CheckInBannerContextEnum } from '../types';
import { getStartHandoverPluginPipeline } from '../helpers/get-start-handover-plugin-pipeline';

const handlePipelineError = (error: Error): void => {
  if (error instanceof PipelineExecutionError) {
    errorPlugin.get().handle(error.originalError);

    return;
  }

  errorPlugin.get().handle(error);
};

const handleRedirect = async (
  order: Order,
  context: CheckInBannerContextEnum,
): Promise<void> => {
  if (context === CheckInBannerContextEnum.PICKING_FLOW) {
    await router.get().push({
      name: 'picking-order',
      params: {
        id: router.get().currentRoute.value.params.id,
      },
    });

    return;
  }

  await router.get().push('/');
};

export const useStartHandoverProcess = (
  context: CheckInBannerContextEnum,
): UseStartHandoverProcess => {
  const startHandoverProcess = async (
    order: Order,
    switcher: Ref<boolean>,
  ): Promise<void> => {
    const performanceTrackerComposable = usePerformanceTracker();
    try {
      const routeId = router.get().currentRoute.value.params.id;
      const pickingOrder = Array.isArray(routeId) ? routeId[0] : routeId;

      const startHandoverPlugin = getStartHandoverPluginPipeline(
        order,
        switcher,
        context,
        performanceTrackerComposable,
        pickingOrder,
      );

      await startHandoverPlugin.execute({
        order,
      });
    } catch (error: unknown) {
      switcher.value = false;
      if (error instanceof Error) {
        handlePipelineError(error);
      }
      await handleRedirect(order, context);
      performanceTrackerComposable.cancelTracking(
        `checkin-banner-clicked-${order.id}`,
      );
    } finally {
      performanceTrackerComposable.stopTracking(
        `checkin-banner-clicked-${order.id}`,
      );
    }
  };

  return {
    startHandoverProcess,
  };
};
