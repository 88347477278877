import { PluginPipeline } from '@ads/plugin-pipeline';
import { PipelineExecutionError } from '@ads/plugin-pipeline/build/pipeline-execution-error';
import { errorPlugin } from '@/features/core/errors';
import type { OrderItem } from '@/features/orders';
import {
  FindEmbeddedBarcodeEntityPlugin,
  FindExactMatchEntityPlugin,
  SearchProductByBarcodePlugin,
} from '../plugins';
import type {
  BarcodeLookupPluginDto,
  BarcodeLookupResultForItems,
  BarcodeLookupResultForProducts,
  IBarcodeFoundService,
} from '../types';

export async function barcodeLookup(
  barcodeFoundService: IBarcodeFoundService,
  barcode: string,
  items: OrderItem[],
): Promise<BarcodeLookupResultForItems>;

export async function barcodeLookup(
  barcodeFoundService: IBarcodeFoundService,
  barcode: string,
  items?: undefined,
): Promise<BarcodeLookupResultForProducts>;

/**
 * Executes a plugin pipeline which handles the lookup of a provided barcode
 *
 * Optionally `items` can be provided to perform lookup in a known set of items,
 * otherwise the list of locally known `Product` entities is retrieved & searched
 *
 * For details on how this pipeline works check the documentation linked below
 * (look for the "Product/item lookup" section)
 *
 * @see https://aldi-sued.atlassian.net/l/cp/0HUfAQ2s
 */
export async function barcodeLookup(
  barcodeFoundService: IBarcodeFoundService,
  barcode: string,
  items?: OrderItem[],
): Promise<BarcodeLookupPluginDto['result']> {
  const scanBarcodePlugin = new PluginPipeline<BarcodeLookupPluginDto>();

  scanBarcodePlugin.registerPlugins(
    [new SearchProductByBarcodePlugin(barcodeFoundService)],
    () => !items,
  );
  scanBarcodePlugin.registerPlugins(
    [new FindExactMatchEntityPlugin()],
    () => !!items,
  );
  scanBarcodePlugin.registerPlugins([
    new FindEmbeddedBarcodeEntityPlugin(barcodeFoundService),
  ]);

  const fallbackResult: BarcodeLookupPluginDto['result'] = {
    item: null,
    product: null,
    barcodeType: null,
    weight: null,
  };

  try {
    const { result } = await scanBarcodePlugin.execute({
      barcode,
      dataset: items ?? [],
      result: fallbackResult,
    });

    return result;
  } catch (error) {
    if (error instanceof PipelineExecutionError) {
      errorPlugin.get().handle(error.originalError);
    } else {
      errorPlugin.get().handle(error);
    }
  }

  return fallbackResult;
}
