import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/complete_handover@2x.png'


const _hoisted_1 = { class: "picking" }
const _hoisted_2 = { class: "picking__content" }
const _hoisted_3 = { class: "picking__content-image" }
const _hoisted_4 = ["alt"]
const _hoisted_5 = { class: "picking__content-note" }
const _hoisted_6 = { class: "picking__content-note__title" }
const _hoisted_7 = { class: "picking__footer" }

import { getCustomerNote } from '@/features/orders';
import { AppHeader, BaseButton, StatusBar } from '@/features/ui';
import type { ComputedRef } from 'vue';
import { computed, onMounted } from 'vue';
import { useHandoverOrder } from '@/features/handover/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'HandoverComplete',
  props: {
  id: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { order, loadOrder, processing, completeHandover } = useHandoverOrder();

onMounted(async () => {
  await loadOrder(props.id);
});

const customerNote: ComputedRef<string | undefined> = computed<
  string | undefined
>(() => getCustomerNote(order.value));

const customerFullName = computed(() => {
  const firstName = order.value?.customer?.firstName ?? '';
  const lastName = order.value?.customer?.lastName ?? '';

  if (order.value) {
    return `${firstName} ${lastName}`;
  }

  return '';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(order))
      ? (_openBlock(), _createBlock(_unref(AppHeader), { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_unref(StatusBar), {
              customerFullName: customerFullName.value,
              customerNote: customerNote.value,
              order: _unref(order),
              title: _unref(order).pickupCode,
              to: { name: 'handover-order', params: { id: __props.id } }
            }, null, 8, ["customerFullName", "customerNote", "order", "title", "to"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            alt: _ctx.$t('pages.handover-complete.content-note-title.text'),
            class: "responsive",
            "data-e2e": "picking-complete-img",
            height: "265",
            src: _imports_0,
            width: "210"
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.$t('pages.handover-complete.content-note-title.text')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", null, _toDisplayString(_unref(processing)), 1),
        _createVNode(_unref(BaseButton), {
          disabled: _unref(processing),
          label: 
          _ctx.$t('pages.handover-complete.footer-finish-handover-button.label')
        ,
          "data-e2e": "handover-complete-button",
          variant: "secondary",
          onClick: _unref(completeHandover)
        }, null, 8, ["disabled", "label", "onClick"])
      ])
    ])
  ], 64))
}
}

})