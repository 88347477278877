import { apiPlugin } from '@/features/core/api';
import {
  ExecPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { storagePlugin, storageSetupPlugin } from '@/features/core/storage';
import { DeviceId, DeviceIdStorageSetup } from '../entities';
import { DeviceIdService } from '../services';

export const deviceIdServicePlugin =
  ProviderPluginFactory.create<DeviceIdService>({
    key: Symbol('DeviceIdService'),
    defaultFactory: {
      create: () => {
        return new DeviceIdService(storagePlugin.get(), apiPlugin.get());
      },
    },
  });

export const deviceIdEntityPlugin = ExecPluginFactory.fn(() => {
  storageSetupPlugin.get().add(DeviceId, new DeviceIdStorageSetup());
});
