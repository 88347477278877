import type OrderCard from '../components/OrderCard.vue';

export function scrollToOrder(orderCard: typeof OrderCard): Promise<void> {
  const beforeScrollDelay = 500;
  const afterScrollDelay = 1000;

  return new Promise<void>((resolve) => {
    setTimeout(() => {
      (orderCard.$el as Element).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      (orderCard.$el as Element).classList.add('focus');

      setTimeout(() => {
        (orderCard.$el as Element).classList.remove('focus');
        resolve();
      }, afterScrollDelay);
    }, beforeScrollDelay);
  });
}
