import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-e2e"]
const _hoisted_2 = { class: "note__header header" }
const _hoisted_3 = ["data-e2e"]
const _hoisted_4 = ["data-e2e"]
const _hoisted_5 = { class: "note__content" }
const _hoisted_6 = {
  key: 0,
  class: "note__customer-name",
  "data-e2e": "cart-note-customer-name"
}
const _hoisted_7 = ["data-e2e"]
const _hoisted_8 = { class: "note__footer" }

import { isOrderPicking } from '@/features/orders';
import { computed } from 'vue';
import { Button, Popup } from '@/features/ui/components';
import type { Order } from '@/features/orders/entities';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerNotePopup',
  props: {
    isVisible: { type: Boolean, default: false },
    order: {},
    closeButtonText: { default: 'Close' },
    customerNote: { default: undefined },
    customerFullName: { default: undefined }
  },
  setup(__props: any) {

const props = __props;

const isPicking = computed<boolean>(
  () => !!props.order && isOrderPicking(props.order),
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Popup), {
    isCloseButtonExist: false,
    visible: _ctx.isVisible,
    fullWidth: "",
    isContentCentered: "",
    isSpaceToContentExist: "",
    withoutHeader: ""
  }, {
    default: _withCtx(() => [
      (_ctx.order)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            "data-e2e": !isPicking.value ? 'customer-note-popup' : '',
            class: "note"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h1", {
                "data-e2e": !isPicking.value ? 'customer-note-title' : '',
                class: "note__title header__item"
              }, _toDisplayString(isPicking.value
              ? _ctx.$t('components.customer-note-popup.title.text')
              : _ctx.$t('components.customer-note-popup.order-title.text')), 9, _hoisted_3),
              _createElementVNode("h2", {
                "data-e2e": 
            isPicking.value ? 'order-pickupCode-label' : 'cart-note-order-label'
          ,
                class: "note__id header__item"
              }, _toDisplayString(_ctx.order.orderReference), 9, _hoisted_4)
            ]),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.customerFullName)
                ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.customerFullName), 1))
                : _createCommentVNode("", true),
              _createElementVNode("p", {
                class: _normalizeClass({
            note__message: true,
            'note__message--no-message': !_ctx.customerNote,
          }),
                "data-e2e": 
            !isPicking.value ? 'cart-note-comment-label' : 'order-cartNote-label'
          
              }, _toDisplayString(_ctx.customerNote ?? _ctx.$t('components.customer-note-popup.no-note.text')), 11, _hoisted_7),
              _renderSlot(_ctx.$slots, "additionalInfo")
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_unref(Button), {
                "data-e2e": "confirm-note-button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('confirm')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.closeButtonText), 1)
                ]),
                _: 1
              })
            ])
          ], 8, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["visible"]))
}
}

})