import type { Order } from '../entities';
import type { OrderItem } from '../types';
import { OrderItemStatus, OrderLocalStatus } from '../types';
import { hasOrderLocalStatus } from './order-status';

export function parseOrderBeforeSave(order: Order): Order {
  order.items = order.items.filter(
    (item) => item.status !== OrderItemStatus.cancelled,
  );

  const parsedItems = Array<OrderItem>();

  // #region type 0
  const nonWeightItems = order.items.filter(
    (item) =>
      item.product.rwpType === 0 && item.status !== OrderItemStatus.rejected,
  );
  parsedItems.push(...nonWeightItems);

  // NOTE: When the item was previously rejected, we didn't make any changes to the quantity, so we have to change it here and add this item to the order
  const rejectedNonWeightItems = order.items.filter(
    (item) =>
      item.product.rwpType === 0 && item.status === OrderItemStatus.rejected,
  );

  const preRejectedItems = rejectedNonWeightItems.reduce((acc, orderItem) => {
    const originalItem = nonWeightItems.find(
      (item) => item.id === orderItem.id,
    );
    if (!originalItem) {
      orderItem.quantity = 0;
      acc.push(orderItem);
    }
    return acc;
  }, Array<OrderItem>());
  if (preRejectedItems.length) {
    parsedItems.push(...preRejectedItems);
  }
  // #endregion

  // #region type 1: calculation of the quantity of items
  const weightItems = order.items
    .filter((item) => item.product.rwpType === 1)
    .reduce((acc, weightItem) => {
      //#region type 1: separating
      // NOTE: we have to create a separate item with weight for each weight in our item.
      const weightList = weightItem.weights?.filter(
        (orderWeight) => orderWeight.weight > 0,
      );

      const separatedByWeightItems = weightList?.map((orderWeight) => {
        const newWeightItem = {
          ...weightItem,
          quantity: 1,
          scannedBarcodes: <string[]>[],
        };

        if (newWeightItem.status === OrderItemStatus.rejected) {
          newWeightItem.amount = 0;
        } else {
          newWeightItem.amount = orderWeight.weight;
        }
        if (orderWeight.relatedBarcode.length)
          newWeightItem.scannedBarcodes.push(orderWeight.relatedBarcode);

        return newWeightItem;
      });

      if (separatedByWeightItems) {
        acc.push(...separatedByWeightItems);
      }

      //#endregion
      //#region type 1: balance
      // NOTE: If you want to send a less "quantity" of the pieces than the "quantityOriginal", you must send the balance with zero "quantity
      if (
        weightList &&
        weightList.length < weightItem.quantityOriginal &&
        !hasOrderLocalStatus(order, OrderLocalStatus.HandoverCompleted) &&
        !weightItem.originalId
      ) {
        const newWeightItems = Array<OrderItem>(
          weightItem.quantityOriginal - weightList.length,
        ).fill({
          ...weightItem,
          quantity: 1,
          amount: 0,
        });
        acc.push(...newWeightItems);
      }
      //#endregion
      return acc;
    }, Array<OrderItem>());

  parsedItems.push(...weightItems);
  //#endregion

  // #region type 2 & 3
  const productTypes = [2, 3];
  const otherItems = order.items.filter(
    (item) =>
      productTypes.includes(item.product.rwpType) &&
      item.status !== OrderItemStatus.rejected,
  );
  parsedItems.push(...otherItems);
  // #endregion

  order.items = parsedItems;

  // Removing bags with zero quantity
  order.bags = order.bags.filter((bag) => bag.quantity > 0);

  return order;
}
