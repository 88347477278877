import type { AgeVerification } from '@/features/age-verification/types';
import type {
  Entity,
  NewEntity,
} from '@/features/core/entity-repository/entity';
import { RestorableEntity } from '@/features/core/entity-repository/entity';
import type {
  RestorableFKs,
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { Product } from '@/features/products/entities';
import { OrderMetadata } from '@/features/orderMetadata';
import type {
  Customer,
  EventList,
  OrderAction,
  OrderBag,
  OrderCheckIn,
  OrderItem,
  OrderItemCount,
  OrderLocalStatus,
} from '../types';
import { DBVersions } from '@/features/core/storage/dexie/versions';
import type { DeliveryUnit } from '@/features/delivery-unit';

export class NewOrder extends RestorableEntity implements NewEntity {
  type = 'order';
}

export class Order extends NewOrder implements Entity {
  static FKs: RestorableFKs = {
    'items.product': { type: Product, key: 'sku' },
    orderMetadata: { type: OrderMetadata, key: 'id' },
  };

  id!: string;
  status!: string;
  startTime!: Date;
  endTime!: Date;
  orderReference!: string;
  pickupCode!: string;
  cartNote?: string;
  checkIn!: OrderCheckIn;
  items!: OrderItem[];
  actions!: OrderAction[];
  bags!: OrderBag[];
  ageVerification!: AgeVerification | null;
  customer!: Customer;
  orderMetadata!: OrderMetadata;

  // Aggregated
  temperatureClasses!: string[];
  actionStatuses!: string[];
  itemsCount!: OrderItemCount;
  productSkus!: string[];
  restrictedAges!: number[];
  creationTime?: string;

  localStatus!: OrderLocalStatus;
  lastLocalStatusChange!: Date | null;
  events!: EventList[];
  deliveryUnits: DeliveryUnit[] = [];
  numberOfLabels = 0;
}

export class OrderStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.Orders_1).stores({
      [`${storeName}`]:
        '&id' +
        ',orderReference' +
        ',status' +
        ',pickupCode' +
        ',startTime' +
        ',endTime' +
        ',itemsQuantity' +
        ',localStatus' +
        ',*productTypes' +
        ',*actionStatuses' +
        ',*productSkus',
    });
    version(DBVersions.Orders_2).stores({
      [`${storeName}`]:
        '&id' +
        ',orderReference' +
        ',status' +
        ',pickupCode' +
        ',startTime' +
        ',endTime' +
        ',localStatus' +
        ',*productTypes' +
        ',*actionStatuses' +
        ',itemsCount.total' +
        ',itemsCount.chiller' +
        ',itemsCount.fresh' +
        ',itemsCount.ambient' +
        ',itemsCount.freezer',
    });
    version(DBVersions.Orders_3).stores({
      [`${storeName}`]:
        '&id' +
        ',orderReference' +
        ',status' +
        ',pickupCode' +
        ',startTime' +
        ',endTime' +
        ',localStatus' +
        ',*productTypes' +
        ',*actionStatuses' +
        ',*productSkus' +
        ',itemsCount.total' +
        ',itemsCount.chiller' +
        ',itemsCount.fresh' +
        ',itemsCount.ambient' +
        ',itemsCount.freezer',
    });
    version(DBVersions.Orders_4).stores({
      [`${storeName}`]:
        '&id' +
        ',orderReference' +
        ',status' +
        ',pickupCode' +
        ',startTime' +
        ',endTime' +
        ',localStatus' +
        ',*productTypes' +
        ',*actionStatuses' +
        ',*productSkus' +
        ',itemsCount.total' +
        ',itemsCount.chiller' +
        ',itemsCount.fresh' +
        ',itemsCount.ambient' +
        ',itemsCount.freezer' +
        ',checkIn.isCheckedIn' +
        ',checkIn.timestamp',
    });
    version(DBVersions.Orders_5)
      .stores({
        [`${storeName}`]:
          '&id' +
          ',orderReference' +
          ',status' +
          ',pickupCode' +
          ',startTime' +
          ',endTime' +
          ',localStatus' +
          ',*temperatureClasses' +
          ',*actionStatuses' +
          ',*productSkus' +
          ',itemsCount.total' +
          ',itemsCount.chiller' +
          ',itemsCount.fresh' +
          ',itemsCount.ambient' +
          ',itemsCount.freezer' +
          ',checkIn.isCheckedIn' +
          ',checkIn.timestamp',
      })
      .upgrade((trans) => {
        const table = trans.table(storeName);
        return table.toCollection().modify((order) => {
          // The following errors are ignored to avoid adding a deprecated property to the class
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
          order.temperatureClasses = order.productTypes;

          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          delete order.productTypes;
        });
      });
    version(DBVersions.Orders_6).stores({
      [`${storeName}`]:
        '&id' +
        ',orderReference' +
        ',status' +
        ',pickupCode' +
        ',startTime' +
        ',endTime' +
        ',localStatus' +
        ',*temperatureClasses' +
        ',*actionStatuses' +
        ',*productSkus' +
        ',itemsCount.total' +
        ',itemsCount.chiller' +
        ',itemsCount.fresh' +
        ',itemsCount.ambient' +
        ',itemsCount.freezer' +
        ',checkIn.isCheckedIn' +
        ',checkIn.timestamp' +
        ',lastLocalStatusChange',
    });
    version(DBVersions.Orders_7).stores({
      [`${storeName}`]:
        '&id' +
        ',orderReference' +
        ',status' +
        ',pickupCode' +
        ',startTime' +
        ',endTime' +
        ',localStatus' +
        ',*temperatureClasses' +
        ',*actionStatuses' +
        ',*productSkus' +
        ',itemsCount.total' +
        ',itemsCount.chiller' +
        ',itemsCount.fresh' +
        ',itemsCount.ambient' +
        ',itemsCount.freezer' +
        ',checkIn.isCheckedIn' +
        ',checkIn.timestamp' +
        ',lastLocalStatusChange' +
        ',creationTime',
    });
    version(DBVersions.Orders_8).stores({
      [`${storeName}`]:
        '&id' +
        ',orderReference' +
        ',status' +
        ',pickupCode' +
        ',startTime' +
        ',endTime' +
        ',localStatus' +
        ',*temperatureClasses' +
        ',*actionStatuses' +
        ',*productSkus' +
        ',itemsCount.total' +
        ',itemsCount.chiller' +
        ',itemsCount.fresh' +
        ',itemsCount.ambient' +
        ',itemsCount.freezer' +
        ',checkIn.isCheckedIn' +
        ',checkIn.timestamp' +
        ',lastLocalStatusChange' +
        ',creationTime' +
        ',orderMetadata',
    });
    version(DBVersions.Orders_9)
      .stores({
        [`${storeName}`]:
          '&id' +
          ',orderReference' +
          ',status' +
          ',pickupCode' +
          ',startTime' +
          ',endTime' +
          ',localStatus' +
          ',*temperatureClasses' +
          ',*actionStatuses' +
          ',*productSkus' +
          ',itemsCount.total' +
          ',itemsCount.chiller' +
          ',itemsCount.fresh' +
          ',itemsCount.ambient' +
          ',itemsCount.freezer' +
          ',checkIn.isCheckedIn' +
          ',checkIn.timestamp' +
          ',lastLocalStatusChange' +
          ',creationTime' +
          ',orderMetadata',
      })
      .upgrade((trans) => {
        const table = trans.table(storeName);
        return table.toCollection().modify((order: Order) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          order.items.map((item) => {
            item.weights = item.weight
              ? item.weight.map((weight) => ({
                  weight,
                  relatedBarcode: '',
                }))
              : [];
            delete item.weight;
          });
        });
      });

    return Promise.resolve();
  }
}
