import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-e2e", "src", "title", "alt"]
const _hoisted_2 = ["src", "alt", "data-e2e"]

import { ref } from 'vue';
import { imageCachingQueueServicePlugin } from '@/features/imageCachingQueue';
import placeholderImage from '@/assets/images/placeholder-image.svg';
import { loggerServicePlugin } from '@/features/core/logger';


export default /*@__PURE__*/_defineComponent({
  __name: 'Image',
  props: {
    e2e: {},
    title: {},
    image: {},
    blendedImageBackground: { type: Boolean, default: false },
    grayscale: { type: Boolean, default: false }
  },
  emits: ['click'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const imageLoadingFailed = ref(false);

const replaceWidth = (url: string) =>
  imageCachingQueueServicePlugin.get().replaceUrlWidth(url);

const handleBrokenImage = (e: ErrorEvent) => {
  // Log images that failed to load
  // Because image should be null if no valid URL is found this will
  // only fire when an actual image we expect to exist could not load
  loggerServicePlugin.get().warn(e.error);
  imageLoadingFailed.value = true;
};

const emitClick = () => {
  if (imageLoadingFailed.value !== true) {
    emit('click');
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["img", {
      'blended-image-background': _ctx.blendedImageBackground,
      'grayscale-image': _ctx.grayscale,
    }])
  }, [
    (_ctx.image && !imageLoadingFailed.value)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          "data-e2e": _ctx.e2e,
          src: replaceWidth(_ctx.image),
          title: _ctx.title,
          alt: _ctx.title,
          class: "ut-img-contain",
          onClick: emitClick,
          onError: handleBrokenImage
        }, null, 40, _hoisted_1))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _unref(placeholderImage),
          alt: _ctx.title,
          "data-e2e": _ctx.e2e,
          class: "ut-img-contain"
        }, null, 8, _hoisted_2))
  ], 2))
}
}

})