
import type { PropType } from 'vue';
import { computed, defineComponent, ref } from 'vue';
import { Button, WeightBlock } from '@/features/ui';
import type { OrderItem } from '@/features/orders/types';
import { ClearIcon, EditIcon, ReplacementIcon } from '@/features/ui/icons';
import { roundValue } from '@/utils/helpers/roundValue';
import { unitToDisplay } from '@/utils/helpers/unitPluralHandler';
import { $t } from '@/i18n';

export default defineComponent({
  name: 'ProductCardFooterTypeWithWeight',
  methods: { $t, unitToDisplay },
  props: {
    orderItem: {
      type: Object as PropType<OrderItem>,
      default: () => ({}),
    },
    currentTab: String,
    isCancelBtnExist: {
      type: Boolean,
      default: false,
    },
    isReplaceBtnExist: {
      type: Boolean,
      default: false,
    },
    hasReplacement: {
      type: Boolean,
      default: false,
    },
    showCustomerNote: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['edit', 'moveToNotFound', 'return', 'replace', 'handle-replace'],
  components: {
    WeightBlock,
    ClearIcon,
    Button,
    EditIcon,
    ReplacementIcon,
  },
  setup(props, { emit }) {
    const isAlertDisplay = ref(false);

    const setAlertDisplayState = (value: boolean) => {
      isAlertDisplay.value = value;
    };

    const onEdit = () => {
      emit('edit', props.orderItem.id);
    };

    const onHandleReplace = () => {
      emit('handle-replace', props.orderItem);
    };

    const onMoveToNotFound = () => {
      emit('moveToNotFound', props.orderItem.id);
    };

    const sku = computed<string>(() => {
      return props.orderItem.product
        ? props.orderItem.product.sku
        : props.orderItem.id;
    });

    const quantity = computed<number>(() => {
      return props.orderItem.quantity;
    });

    const amount = computed<number>(() => {
      return props.orderItem.amount;
    });

    const onReplace = () => {
      emit('replace', props.orderItem);
    };

    return {
      onEdit,
      onHandleReplace,
      onMoveToNotFound,
      sku,
      quantity,
      amount,
      isAlertDisplay,
      setAlertDisplayState,
      onReplace,
      roundValue,
    };
  },
});
