import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-e2e"]


export default /*@__PURE__*/_defineComponent({
  __name: 'Badge',
  props: {
    text: {},
    e2e: { default: 'default' },
    variant: { default: 'default' }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["badge", `badge-variant-${_ctx.variant}`]),
    "data-e2e": `${_ctx.e2e}-tab-badge`
  }, _toDisplayString(_ctx.text.length > 3 ? `${_ctx.text.slice(0, 3)}+` : _ctx.text), 11, _hoisted_1))
}
}

})