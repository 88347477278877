
import { computed, defineComponent } from 'vue';
import { $t } from '@/i18n';

export default defineComponent({
  name: 'SearchResultFeedback',

  props: {
    ordersAmount: {
      type: Number,
      default: 0,
    },
    isSearching: {
      type: Boolean,
      default: false,
    },
    searchHintText: {
      type: String,
      default: $t('components.search-result-feedback.search-hint-text.text'),
    },
  },
  setup(props) {
    const searchResultsAmount = computed(() => {
      return props.isSearching ? props.ordersAmount : 0;
    });
    const noResults = computed(
      () => props.isSearching && searchResultsAmount.value === 0,
    );

    return {
      noResults,
      searchResultsAmount,
    };
  },
});
