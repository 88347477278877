import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type {
  UsePerformanceTracker,
  PerformanceThresholdEnum,
} from '@/features/performance-tracker';
import type { Order } from '../entities/order';

/**
 * Start Performance Tracker
 */

export class StartPerformanceTrackingPlugin<T extends { order: Order }>
  implements PipelinePlugin<T>
{
  constructor(
    private usePerformanceTracker: UsePerformanceTracker,
    private key: string,
    private threshold: PerformanceThresholdEnum,
  ) {}

  public execute(dto: T): T {
    this.usePerformanceTracker.startTracking(this.key, this.threshold);

    return dto;
  }
}
