import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { TabVariants } from '@/features/ui';
import HandoverItem from '@/features/handover/components/HandoverItem.vue';
import type { OrderItem } from '@/features/orders';
import { OrderItemStatus } from '@/features/orders';
import { useHandoverOrder } from '@/features/handover/composables';
import { Status } from '@/features/age-verification';
import { focusOutInputHandler } from '@/utils/helpers/hideOnFocus';


export default /*@__PURE__*/_defineComponent({
  __name: 'HandoverItemList',
  props: {
    items: {},
    sectionStatus: {},
    currentTab: {}
  },
  emits: ["save", "editWeight", "reject", "showRestoreAgeVerificationModal"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const { order, currentTab, restoreItem, applyOrderItem } = useHandoverOrder();

const getQuantityFilteredByStatus = (id: string, status: string): number => {
  return (
    order.value?.items
      .filter((item) => item.id === id && item.status == status)
      .reduce((sum, item) => sum + item.quantity, 0) ?? 0
  );
};

const onApply = (orderItem: OrderItem, quantity: number) => {
  if (
    !orderItem.originalId &&
    quantity ===
      orderItem.quantityOriginal -
        getQuantityFilteredByStatus(orderItem.id, OrderItemStatus.cancelled)
  ) {
    restoreItem(orderItem);
    emit('save');
    return;
  }

  applyOrderItem(orderItem, quantity);
  emit('save');
};

const onApplyRejected = (rejectedItem: OrderItem, quantity: number) => {
  if (quantity === 0) {
    restoreItem(rejectedItem);
    emit('save');
    return;
  }

  const stagedItem = order.value?.items.find((item) => {
    return item.isRejected && item.id === rejectedItem.id;
  });
  if (!stagedItem) {
    return;
  }

  applyOrderItem(
    stagedItem,
    stagedItem.quantity + (rejectedItem.quantity - quantity),
  );
};

const onRestore = (orderItem: OrderItem) => {
  if (
    order.value?.ageVerification?.status === Status.Rejected &&
    orderItem.product.ageRestricted !== 0
  ) {
    emit('showRestoreAgeVerificationModal');
    return;
  }

  focusOutInputHandler();
  restoreItem(orderItem);
  emit('save');
};

return (_ctx: any,_cache: any) => {
  return (_unref(order))
    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(HandoverItem, {
          key: item.id + item.quantity,
          "current-tab": _unref(currentTab),
          item: item,
          order: _unref(order),
          "section-status": _ctx.sectionStatus,
          onApply: _cache[0] || (_cache[0] = 
        (orderItem, quantity) =>
          _ctx.sectionStatus === _unref(TabVariants).Rejected
            ? onApplyRejected(orderItem, quantity)
            : onApply(orderItem, quantity)
      ),
          onReject: _cache[1] || (_cache[1] = (orderItem) => emit('reject', orderItem)),
          onRestore: onRestore,
          onEditWeight: _cache[2] || (_cache[2] = (orderItem) => emit('editWeight', orderItem))
        }, null, 8, ["current-tab", "item", "order", "section-status"]))
      }), 128))
    : _createCommentVNode("", true)
}
}

})