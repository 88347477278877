import type {
  Entity,
  NewEntity,
} from '@/features/core/entity-repository/entity';
import { RestorableEntity } from '@/features/core/entity-repository/entity';
import type {
  StorageSetupMeta,
  StorageSetupStrategy,
} from '@/features/core/storage';
import { DBVersions } from '@/features/core/storage/dexie/versions';

class NewProcessedOrder extends RestorableEntity implements NewEntity {
  type = 'processedOrder';
}

export class ProcessedOrder extends NewProcessedOrder implements Entity {
  id!: string;
  lastRoute!: string;
  processedBy!: string;
}

export class ProcessedOrderStorageSetup implements StorageSetupStrategy {
  setup({ version, storeName }: StorageSetupMeta): Promise<void> {
    version(DBVersions.ProcessedOrder_1).stores({
      [storeName]: '&id,lastRoute,processedBy',
    });
    return Promise.resolve();
  }
}
