import type { EventBus } from '@/features/core/event-bus';
import type { Storage } from '@/features/core/storage';
import { BooleanNumber, StorageSortDirection } from '@/features/core/storage';
import type { LoggerService } from '@/features/core/logger';
import { AppServiceWorkerNotifySyncEvent } from '@/features/service-worker/events';
import type { AnyEntity } from '@/features/core/entity-repository';
import type { Cancellable } from '@/utils/types';
import {
  SyncSchedulerEvent,
  SyncStatusChangedEvent,
  SyncTableUpdateEvent,
  UpdateSyncStatusEvent,
} from '../events';
import { Sync } from '../entities';
import type {
  EntitySyncOperation,
  SyncStatus,
  ISyncSchedulerExecutorFilterDto,
} from '../types';
import type { SyncScheduler } from './sync-scheduler';

export class SyncSchedulerService implements SyncScheduler {
  private cancelMap = new Map<string, Set<Cancellable>>();
  private onScheduleDoneList = new Set<
    (entity: AnyEntity | undefined) => void
  >();

  constructor(
    private storage: Storage,
    private eventBus: EventBus,
    private loggerService: LoggerService,
  ) {
    this.loggerService.info('Sync Scheduler initialization');
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.eventBus.on(SyncSchedulerEvent, async (event) => {
      const syncStatus = await this.schedule({ data: event.data });
      syncStatus && this.eventBus.emit(new UpdateSyncStatusEvent(syncStatus));
    });
  }

  public static convertSyncStatusToSync(syncStatus: SyncStatus | Sync): Sync {
    return Sync.from({
      id: syncStatus.id,
      prevId: syncStatus.prevId,
      data: syncStatus.data,
      scheduledAt: syncStatus.scheduledAt,
      completedAt: syncStatus.completedAt,
      isCompleted: syncStatus.isCompleted,
      retries: syncStatus.retries,
      failReason: syncStatus.failReason,
      reportSent: syncStatus.reportSent,
    });
  }

  onScheduleDone(callback: (entity: AnyEntity | undefined) => void): void {
    this.onScheduleDoneList.add(callback);
  }

  async getPendingSyncs(
    syncFilterDto?: ISyncSchedulerExecutorFilterDto,
  ): Promise<Sync[]> {
    const pendingOperations: Sync[] = await this.storage.getAll(Sync, {
      filter: {
        isCompleted: {
          equals: BooleanNumber.False,
        },
      },
      sortBy: 'scheduledAt',
      sortDir: StorageSortDirection.ASC,
    });
    let blockedOperationIds: string[] = [];
    pendingOperations?.forEach((pendingOperation) => {
      if (
        pendingOperation.retries >= 3 ||
        (pendingOperation.prevId &&
          blockedOperationIds.includes(pendingOperation.prevId))
      ) {
        blockedOperationIds = [...blockedOperationIds, pendingOperation.id];
      }
    });

    return pendingOperations
      ?.filter((sync) => !blockedOperationIds.includes(sync.id))
      .filter(
        (sync) =>
          !syncFilterDto ||
          (sync.data &&
            sync.data.entityId &&
            sync.data.entityId === syncFilterDto.entityId &&
            sync.data.entity === syncFilterDto.entityType),
      );
  }
  async getPending(
    syncFilterDto?: ISyncSchedulerExecutorFilterDto,
  ): Promise<SyncStatus[]> {
    const syncs = (await this.getPendingSyncs(syncFilterDto)).map((sync) =>
      this.constructSyncStatus(sync),
    );

    return syncs;
  }

  async getFailedSyncs(filterReportedTransmissions = false): Promise<Sync[]> {
    const filter = {
      isCompleted: {
        equals: BooleanNumber.False,
      },
      ...(filterReportedTransmissions && {
        reportSent: {
          equals: BooleanNumber.False,
        },
      }),
    };

    const pendingOperations: Sync[] = await this.storage.getAll(Sync, {
      filter,
      sortBy: 'scheduledAt',
      sortDir: StorageSortDirection.ASC,
    });

    const failedOperations: Sync[] = pendingOperations.filter(
      (pendingOperation) => pendingOperation.retries >= 3,
    );

    return failedOperations;
  }

  async getFailed(filterReportedTransmissions = false): Promise<SyncStatus[]> {
    const syncs = (await this.getFailedSyncs(filterReportedTransmissions)).map(
      (sync) => this.constructSyncStatus(sync),
    );

    return syncs;
  }

  private async getBlockedSyncs(): Promise<Sync[]> {
    const pendingOperations: Sync[] = await this.storage.getAll(Sync, {
      filter: {
        isCompleted: {
          equals: BooleanNumber.False,
        },
      },
      sortBy: 'scheduledAt',
      sortDir: StorageSortDirection.ASC,
    });

    const failedOperationsIds: string[] = pendingOperations
      .filter((pendingOperation) => pendingOperation.retries >= 3)
      .map((s) => s.id);

    const blockedOperations = pendingOperations.filter(
      (pendingOperation) =>
        pendingOperation.prevId &&
        failedOperationsIds.includes(pendingOperation.prevId),
    );
    return blockedOperations;
  }

  async removeFailedTransmissions(): Promise<void> {
    const failedTransmissions = await this.getFailedSyncs();
    const failedTransmissionIds = failedTransmissions.map((s) => s.id);
    const blockedTransmissionIds = (await this.getBlockedSyncs()).map(
      (s) => s.id,
    );

    await this.storage.removeSeveral(Sync, {
      ids: [...failedTransmissionIds, ...blockedTransmissionIds],
    });
  }

  async setTransmissionsAsReported(
    reportedStatuses: SyncStatus[] | Sync[],
  ): Promise<void> {
    const reportedSyncs: Sync[] = [];
    reportedStatuses.forEach((reportedStatus: Sync | SyncStatus) => {
      const sync = SyncSchedulerService.convertSyncStatusToSync(reportedStatus);
      sync.reportSent = BooleanNumber.True;
      reportedSyncs.push(sync);
    });

    await this.storage.bulkSave(reportedSyncs);
  }

  async getAllSyncByEntity(
    syncFilterDto: ISyncSchedulerExecutorFilterDto,
  ): Promise<SyncStatus[]> {
    const pendingOperations: Sync[] = await this.storage.getAll(Sync);

    return pendingOperations
      .filter(
        (sync) =>
          sync.data &&
          sync.data.entityId &&
          sync.data.entityId === syncFilterDto.entityId &&
          sync.data.entity === syncFilterDto.entityType,
      )
      .map((sync) => this.constructSyncStatus(sync));
  }

  async resetRetriesOfFailed(): Promise<void> {
    const syncStatuses = await this.getFailed();
    for (const syncStatus of syncStatuses) {
      syncStatus.retries = 0;
      await this.storage.save(
        SyncSchedulerService.convertSyncStatusToSync(syncStatus),
      );
      this.eventBus.emit(
        new AppServiceWorkerNotifySyncEvent(
          syncStatus.data.entity,
          syncStatus.data.entityId,
        ),
      );
    }
  }

  async getStatus(id: string): Promise<SyncStatus | undefined> {
    const sync = await this.storage.getById(Sync, {
      id,
    });

    if (!sync) {
      return;
    }

    return this.constructSyncStatus(sync);
  }

  onStatusChange(
    syncId: string,
    callback: (syncStatus: Sync) => void,
  ): Cancellable {
    const cancelSync = this.eventBus.on(SyncStatusChangedEvent, (event) => {
      if (event.sync.id === syncId) {
        callback(event.sync);
      }
    });

    if (!this.cancelMap.has(syncId)) {
      this.cancelMap.set(syncId, new Set());
    }
    this.cancelMap.get(syncId)?.add(cancelSync);

    return {
      cancel: () => {
        cancelSync?.cancel();
        this.cancelMap.get(syncId)?.delete(cancelSync);
      },
    };
  }

  async schedule(operation: EntitySyncOperation): Promise<SyncStatus | void> {
    try {
      this.loggerService.debug(`Sync schedule has been started.
      Entity: ${operation.data.entity}
      EntityId: ${String(operation.data.entityId)}
      API Method: ${operation.data.api}
    `);

      const [prevSync] = await this.storage.getAll(Sync, {
        filter: {
          'data.entity': { equals: operation.data.entity },
          'data.entityId': { equals: operation.data.entityId },
        },
        limit: 1,
        sortBy: 'scheduledAt',
        sortDir: StorageSortDirection.DESC,
      });
      const [lastSync] = await this.storage.getAll(Sync, {
        limit: 1,
        sortBy: 'scheduledAt',
        sortDir: StorageSortDirection.DESC,
      });

      const newSync = Sync.from({
        id: String(Number(lastSync?.id || 0) + 1),
        data: operation.data,
        scheduledAt: new Date(),
        retries: 0,
        isCompleted: BooleanNumber.False,
        reportSent: BooleanNumber.False,
      });

      if (prevSync) {
        newSync.prevId = prevSync.id;
      }

      const sync = await this.storage.save(newSync);

      this.eventBus.emit(
        new AppServiceWorkerNotifySyncEvent(
          operation.data.entity,
          operation.data.entityId,
        ),
      );
      this.eventBus.emit(new SyncTableUpdateEvent());

      this.loggerService.debug(`Sync has been scheduled.
      Entity: ${operation.data.entity}
      EntityId: ${String(operation.data.entityId)}
      API Method: ${operation.data.api}
    `);

      const syncStatus = this.constructSyncStatus(sync);

      try {
        this.onScheduleDoneList.forEach((callback) =>
          callback(operation.data.entitySnapshot),
        );
      } catch (callbackError) {
        this.loggerService.error(
          'Error: SyncScheduler.schedule, error during callback execution',
          callbackError,
        );
      }
      return syncStatus;
    } catch (error) {
      this.loggerService.error('Error: SyncScheduler.schedule', error);
    }
  }

  private async cancel(sync: Sync) {
    this.cancelMap.delete(sync.id);
    await this.storage.remove(Sync.from(sync));
    this.eventBus.emit(new SyncTableUpdateEvent());
  }

  private constructSyncStatus(sync: Sync): SyncStatus {
    let statusChange: Cancellable;
    return {
      ...sync,
      data: sync.data,
      cancel: async () => {
        await this.cancel(sync);
        statusChange.cancel();
      },
      completed: new Promise((resolve, reject) => {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        statusChange = this.onStatusChange(sync.id, async (sync) => {
          if (sync.isCompleted === BooleanNumber.True) {
            resolve();
          } else {
            reject(sync.failReason);
          }
          await this.cancel(sync);
          statusChange.cancel();
        });
      }),
    };
  }
}
