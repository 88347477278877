import type { OrderItem } from '@/features/orders';

export type ItemWithOriginalId =
  | { id: string; originalId: null }
  | { id: string; originalId: string };

export function getItemAndReplacementIds(
  item: OrderItem,
): ItemWithOriginalId[] {
  return [
    { id: item.id, originalId: item.originalId ?? null },
    ...(item.replacements?.map((replacement) => ({
      id: replacement.id,
      originalId: item.id,
    })) ?? []),
  ];
}
