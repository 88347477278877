import type { ConfigurationValueReturnType } from '@/features/configuration/types';

export function transformStringToType(
  value: string,
  transformedType: ConfigurationValueReturnType,
): string | number | boolean | string[] {
  let transformedValue;
  switch (transformedType) {
    case 'number': {
      const transformedToNumberValue = parseFloat(value);
      transformedValue = isNaN(transformedToNumberValue)
        ? 0
        : transformedToNumberValue;
      break;
    }
    case 'boolean':
      transformedValue = value === 'true';
      break;
    case 'array':
      if (value === '') {
        transformedValue = [];
      } else {
        transformedValue = value.split(',');
      }
      break;
    default:
      transformedValue = value;
      break;
  }
  return transformedValue;
}
