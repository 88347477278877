import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "slideunlock-handler__inner" }

import ArrowRightIcon from '@/features/ui/icons/ArrowRightIcon.vue';
import CheckIcon from '@/features/ui/icons/CheckIcon.vue';
import { computed, reactive, ref } from 'vue';

// Due to impossibility to slide the button programmatically in e2e tests - global function is needed
const BUTTON_HEIGHT = 64;
const PROGRESS_TO_CONFIRM = 70;


export default /*@__PURE__*/_defineComponent({
  __name: 'SlideButton',
  emits: ['completed'],
  setup(__props, { emit: __emit }) {

window.slideButtonConfirm = (): void => {
  complete();
};

const emit = __emit;

const Slider = reactive({
  CanMove: false,
  IsComplete: false,
  StartPositionWindow: 0,
  HandlerPosition: 0,
  ProgressWidth: 0,
});

const progressBarStyle = computed(() => {
  return {
    transform: `translateX(calc(-100% + ${Slider.HandlerPosition}px))`,
  };
});

const handlerStyle = computed(() => {
  return {
    transform: `translateX(${Slider.HandlerPosition}px)`,
  };
});

const slideunlockEl = ref<HTMLElement | null>(null);

const sliderWidth = computed(() =>
  slideunlockEl.value ? slideunlockEl.value.clientWidth : 0,
);

const slideStart = (e: MouseEvent | TouchEvent) => {
  if (Slider.IsComplete) {
    return;
  }

  Slider.CanMove = true;

  if (window.TouchEvent && e instanceof TouchEvent && e.touches) {
    Slider.StartPositionWindow = e.touches[0].pageX - Slider.HandlerPosition;
  } else if (e instanceof MouseEvent) {
    Slider.StartPositionWindow = e.pageX - Slider.HandlerPosition;
  }

  document.onmousemove = (ev) => {
    const el = ev;
    slideMoving(el);
    return false;
  };
  document.onmouseup = (ev) => {
    const el = ev;
    slideFinish(el);
    document.onmousemove = null;
    return false;
  };
};

const slideMoving = (e: unknown) => {
  if (Slider.IsComplete || !Slider.CanMove) {
    return;
  }

  let pageX = Slider.StartPositionWindow;
  if (window.TouchEvent && e instanceof TouchEvent && e.touches)
    pageX = e.touches[0].pageX;
  else if (e instanceof MouseEvent) pageX = e.pageX;

  if (pageX < Slider.StartPositionWindow) return;
  else Slider.HandlerPosition = pageX - Slider.StartPositionWindow;

  if (
    Slider.HandlerPosition > 0 &&
    Slider.HandlerPosition <= sliderWidth.value - BUTTON_HEIGHT
  ) {
    Slider.ProgressWidth = Slider.HandlerPosition + BUTTON_HEIGHT / 2;
  } else if (Slider.HandlerPosition > sliderWidth.value - BUTTON_HEIGHT) {
    Slider.HandlerPosition = sliderWidth.value - BUTTON_HEIGHT;
    Slider.ProgressWidth = sliderWidth.value;
    complete();
  }
};

const sliderProgressPercent = computed(() => {
  return (Slider.ProgressWidth / sliderWidth.value) * 100;
});

const slideFinish = (e: unknown) => {
  if (sliderProgressPercent.value > PROGRESS_TO_CONFIRM) {
    complete();
  }

  if (!Slider.CanMove || Slider.IsComplete) {
    return;
  }

  let pageX = Slider.StartPositionWindow;
  if (window.TouchEvent && e instanceof TouchEvent && e.touches) {
    pageX = e.changedTouches[0].pageX;
  } else if (e instanceof MouseEvent) {
    pageX = e.pageX;
  }

  if (pageX < Slider.StartPositionWindow) {
    return;
  }

  if (Slider.HandlerPosition < sliderWidth.value - BUTTON_HEIGHT) {
    reset();
  }
  Slider.CanMove = false;
};

const isHandlerActive = computed(() => {
  return !Slider.IsComplete && Slider.HandlerPosition !== 0;
});

const reset = () => {
  Slider.CanMove = false;
  Slider.IsComplete = false;
  Slider.StartPositionWindow = 0;
  Slider.HandlerPosition = 0;
  Slider.ProgressWidth = 0;
};

const complete = () => {
  if (Slider.IsComplete) {
    return;
  }
  Slider.CanMove = false;
  Slider.IsComplete = true;
  Slider.ProgressWidth = sliderWidth.value;
  Slider.HandlerPosition = sliderWidth.value - BUTTON_HEIGHT;
  emit('completed');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "slideunlockEl",
    ref: slideunlockEl,
    class: _normalizeClass(["slideunlock", {
      'is-active': Slider.CanMove,
      'is-complete': Slider.IsComplete,
    }]),
    onMousemove: slideMoving,
    onMouseup: slideFinish,
    onTouchmove: slideMoving,
    onTouchend: slideFinish
  }, [
    _createElementVNode("div", {
      class: "slideunlock-progressbar",
      style: _normalizeStyle(progressBarStyle.value)
    }, [
      _createVNode(_Transition, null, {
        default: _withCtx(() => [
          (Slider.IsComplete)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Confirmed!"))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_Transition, null, {
        default: _withCtx(() => [
          (!Slider.IsComplete)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "slideunlock-release-text",
                style: _normalizeStyle({
            opacity: sliderProgressPercent.value / 100,
            transform: `translateX(${
              25 - (25 * sliderProgressPercent.value) / 100
            }%)`,
          })
              }, "Release", 4))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        class: "slideunlock-text",
        style: _normalizeStyle({
          opacity: (100 - sliderProgressPercent.value * 1.3) / 100,
          width: `${100 - sliderProgressPercent.value / 1.5}%`,
        })
      }, " Slide to confirm ", 4)
    ], 4),
    _createElementVNode("div", {
      class: _normalizeClass([
        'slideunlock-handler',
        { 'slideunlock-handler--active': isHandlerActive.value },
      ]),
      style: _normalizeStyle(handlerStyle.value),
      onMousedown: slideStart,
      onTouchstart: slideStart
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_Transition, null, {
          default: _withCtx(() => [
            (Slider.IsComplete)
              ? (_openBlock(), _createBlock(CheckIcon, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_Transition, null, {
          default: _withCtx(() => [
            (!Slider.IsComplete)
              ? (_openBlock(), _createBlock(ArrowRightIcon, {
                  key: 0,
                  fillColor: "#fff"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ], 38)
  ], 34))
}
}

})