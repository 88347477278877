import { urlBuilderServicePlugin } from '@/features/url';
import type { PrintedLabel } from '../types';

export const openLabelPrintPage = (id: string): void => {
  window.open(
    `${urlBuilderServicePlugin.get().getHostUrl()}/print/${id}`,
    '_blank',
  );
};

export const getQrData = (label: PrintedLabel): string => {
  if (label.code) {
    return label.code;
  }
  return '';
};
