/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { errorPlugin } from '@/features/core/errors';
import { LoadUserError } from '../errors';
import { ref } from 'vue';
import { User } from '../entities';
import { authServicePlugin } from '@/features/core/auth';
import type { UseUser, UseUserLoadParams } from '../types';
import { isApiError } from '@/features/core/api/helper/is-api-client-error';

export function useUser(): UseUser {
  const user = ref<User | undefined>();
  const loading = ref(false);
  const error = ref({
    name: '',
    message: '',
  });

  const loadUser = async (params?: UseUserLoadParams): Promise<void> => {
    loading.value = true;

    try {
      const id =
        params?.id || (await authServicePlugin.get().getMerchantReference());

      if (!id) {
        throw new Error('useUser: No User Id to load the user!');
      }

      const userRef = await entityRepositoryPlugin.get().getById(User, {
        id,
      });

      user.value = userRef.value;
    } catch (e: unknown) {
      errorPlugin.get().handle(new LoadUserError());

      let message;

      if (isApiError(e) && e.response && e.response.data) {
        const { error } = e.response.data as Record<string, unknown>;
        message = typeof error === 'string' ? error : 'unknown';
      } else if (e instanceof Error) {
        message = e.message;
      } else {
        message = 'unknown';
      }

      error.value = {
        name: 'error',
        message,
      };
    } finally {
      loading.value = false;
    }
  };

  return {
    user,
    loading,
    error,
    loadUser,
  };
}
