import type { OrderItem } from '@/features/orders';

export const MAX_PRODUCT_QUANTITY = 99;

export interface PartitionedHandoverItems {
  changed: OrderItem[];
  staged: OrderItem[];
  rejected: OrderItem[];
}

export interface MappedOrderItems {
  all: number;
  changed: number;
  staged: number;
  [key: string]: number;
}
