/* eslint-disable @typescript-eslint/no-empty-function */

import type { PushProvider, PushService } from '../types';

export class NoopPushService implements PushService {
  init(): void {}
  unSubscribe(): boolean {
    return true;
  }
  getProvider(): PushProvider | null {
    return null;
  }
}
