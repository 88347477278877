import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "weight-list" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  class: "weight-list-remaining error",
  "data-e2e": "maximum-quantity-exceeded-notice"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "weight-list-remaining",
  "data-e2e": "remaining-weight"
}
const _hoisted_6 = {
  key: 1,
  class: "weight-list-remaining error",
  "data-e2e": "target-quantity-exceeded-notice"
}
const _hoisted_7 = {
  key: 2,
  class: "weight-list-remaining",
  "data-e2e": "total-weight-reached-notice"
}

import { computed, ref, toRefs, watch } from 'vue';
import type { OrderWeight } from '@/features/orders';
import { ExclamationCircle } from '@/features/ui';
import {
  WeightListBarcodePopup,
  WeightListItem,
} from '@/features/ui/components';
import { unitToDisplay } from '@/utils/helpers/unitPluralHandler';
import { $t } from '@/i18n';
import { roundValue } from '@/utils/helpers/roundValue';


export default /*@__PURE__*/_defineComponent({
  __name: 'WeightList',
  props: {
    totalOrderWeight: {},
    barcode: {},
    barcodeWeights: {},
    editable: { type: Boolean, default: false },
    showRemainingWeight: { type: Boolean, default: false },
    unit: {},
    totalWeightReached: { type: Boolean, default: false },
    additionalWeightAllowed: { type: Boolean, default: false },
    barcodeRelatedProduct: { type: Boolean, default: false },
    isOverpickingThresholdReached: { type: Boolean, default: false },
    isReplacement: { type: Boolean, default: false },
    isReplacementThresholdReached: { type: Boolean, default: false }
  },
  emits: ["onchangeWeight"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const isScanBarcodePopupVisible = ref(false);
const toggleScanBarcodePopup = (isVisible: boolean): void => {
  isScanBarcodePopupVisible.value = isVisible;
};
const { totalOrderWeight, editable } = toRefs(props);
const weights = ref<OrderWeight[]>([
  {
    weight: 0,
    relatedBarcode: '',
  },
]);

watch(
  () => props.barcodeWeights,
  () => {
    toggleScanBarcodePopup(false);
    weights.value = props.barcodeWeights.length
      ? [...props.barcodeWeights]
      : [
          {
            weight: 0,
            relatedBarcode: '',
          },
        ];
  },
  {
    immediate: true,
  },
);

const updateAmount = (weight: number) => {
  if (weight <= 0) {
    return;
  }

  if (isWeightReached.value) {
    addNewRowPossible.value = false;
    return;
  }

  weights.value.push({
    weight: 0,
    relatedBarcode: '',
  });
  emit('onchangeWeight', weights.value);
};
const removeWeight = (index: number) => {
  weights.value.splice(index, 1);
  if (weights.value[weights.value.length - 1]?.weight && props.editable) {
    weights.value.push({
      weight: 0,
      relatedBarcode: '',
    });
  }
  addNewRowPossible.value = true;
  emit('onchangeWeight', weights.value);
};

const updateLastWeightBarcode = (barcode: string): void => {
  weights.value[weights.value.length - 1].relatedBarcode = barcode;
};

const changeWeight = (
  index: number,
  weight: number,
  relatedBarcode: string,
) => {
  weights.value[index] = isNaN(weight)
    ? {
        weight: 0,
        relatedBarcode: '',
      }
    : {
        weight,
        relatedBarcode,
      };
  emit('onchangeWeight', weights.value);
};

const totalWeight = computed<number>(() =>
  weights.value.reduce(
    (acc: number, item: OrderWeight) => item.weight + acc,
    0,
  ),
);

const remainingWeight = computed<number>(() => {
  if (!totalOrderWeight.value) {
    return 0;
  }
  return roundValue(totalOrderWeight.value - totalWeight.value);
});

const isWeightReached = computed<boolean>(
  () => !!totalOrderWeight.value && remainingWeight.value <= 0,
);
const addNewRowPossible = ref(!isWeightReached.value);

const isListItemEditable = (itemIndex: number) => {
  return (
    editable.value &&
    itemIndex + 1 === weights.value.length &&
    addNewRowPossible.value
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(weights.value, (weight, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createVNode(_unref(WeightListItem), {
            barcodeRelatedProduct: _ctx.barcodeRelatedProduct,
            class: _normalizeClass({ 'weight-list--no-top-border': index > 0 }),
            editable: isListItemEditable(index),
            index: index,
            relatedBarcode: weight.relatedBarcode,
            unit: _ctx.unit,
            weight: weight.weight,
            isReplacement: _ctx.isReplacement,
            onChange: changeWeight,
            onInsert: updateAmount,
            onRemove: ($event: any) => (removeWeight(index)),
            onScanBarcode: _cache[0] || (_cache[0] = ($event: any) => (toggleScanBarcodePopup(true)))
          }, null, 8, ["barcodeRelatedProduct", "class", "editable", "index", "relatedBarcode", "unit", "weight", "isReplacement", "onRemove"])
        ]))
      }), 128)),
      (_ctx.isReplacementThresholdReached)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, [
              _createVNode(_unref(ExclamationCircle)),
              _createTextVNode(" " + _toDisplayString(_unref($t)('pages.weight-list.maximum-quantity-exceeded-notice.text')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.showRemainingWeight)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (remainingWeight.value > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref($t)('pages.weight-list.remaining-weight.text', {
            remainingWeight: remainingWeight.value,
            unit: _unref(unitToDisplay)(_ctx.unit, remainingWeight.value),
          })), 1))
              : _createCommentVNode("", true),
            (_ctx.isOverpickingThresholdReached)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                  _createVNode(_unref(ExclamationCircle)),
                  _createTextVNode(" " + _toDisplayString(_unref($t)('pages.weight-list.target-quantity-exceeded-notice.text')), 1)
                ]))
              : (isWeightReached.value || remainingWeight.value <= 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref($t)('pages.weight-list.total-weight-reached-notice.text')), 1))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_unref(WeightListBarcodePopup), {
      visible: isScanBarcodePopupVisible.value,
      onSubmitBarcode: _cache[1] || (_cache[1] = ($event: any) => (updateLastWeightBarcode($event))),
      onClose: _cache[2] || (_cache[2] = ($event: any) => (toggleScanBarcodePopup(false)))
    }, null, 8, ["visible"])
  ], 64))
}
}

})