import type { LogLevel, MessageTransferObject, Transporter } from '../types';
import { deviceIdServicePlugin } from '@/features/device-id';

export class NewRelicTransporter implements Transporter {
  constructor() {
    void this.init();
  }

  private deviceId = 'Unknown';

  defaultLogLevel: LogLevel = 50;
  private currentLogLevel: LogLevel = this.defaultLogLevel;

  write(logLevel: LogLevel, msgObject: MessageTransferObject): void {
    if (logLevel < this.currentLogLevel) {
      return;
    }
    this.noticeError(msgObject.err);
  }

  setLogLevel(logLevel: LogLevel): void {
    this.currentLogLevel = logLevel;
  }

  noticeError(error: Error | string): void {
    if (!error) return;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    window?.newrelic?.noticeError(error, {
      deviceId: this.deviceId,
    });
  }

  private async init(): Promise<void> {
    try {
      const script = document.createElement('script');
      script.src = `/EXTscripts/NewRelicInitScript.${String(
        process.env.VUE_APP_ENV_FOR_ISSUE_LOGGING_SCRIPT,
      )}.min.js`;
      script.async = true;
      document.head.appendChild(script);
      this.deviceId =
        (await deviceIdServicePlugin.get().getDeviceId()) || 'Unknown';
    } catch (e) {
      console.log('Error:', e);
    }
  }
}
