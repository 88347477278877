import type {
  CompletePipelineWith,
  PipelinePlugin,
} from '@ads/plugin-pipeline';
import type { Order } from '../entities/order';
import type { OrdersService } from '../services';

/**
 * Load order by id and store it to the DTO
 */

export class LoadOrderPlugin<T extends { order: Order }>
  implements PipelinePlugin<T>
{
  constructor(private ordersService: OrdersService, private orderId: string) {}

  public async execute(
    dto: T,
    completePipelineWith: CompletePipelineWith<T>,
  ): Promise<T> {
    const orderRef = await this.ordersService.getOrderById(this.orderId);

    if (orderRef === null) {
      return completePipelineWith(dto);
    }

    dto.order = orderRef.value;

    return dto;
  }
}
