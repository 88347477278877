import { PluginPipeline } from '@ads/plugin-pipeline';
import { loggerServicePlugin } from '@/features/core/logger';
import { ordersServicePlugin, OrderLocalStatus } from '@/features/orders';
import { errorPlugin } from '@/features/core/errors';
import router from '@/features/core/router';
import { storagePlugin } from '@/features/core/storage';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import { appCrashServicePlugin } from '@/features/app-crash';
import type { RestageOrderPluginDto } from '../types';
import {
  AppCrashPlugin,
  EmitHandoverAbortedBusEventPlugin,
  SaveOrderToStoragePlugin,
  SaveRestagedOrderPlugin,
  TrackEventRestagingCompletedPlugin,
  UpdateLocalStatusPlugin,
} from '../plugins';

export function restageOrderPluginRegister(): PluginPipeline<RestageOrderPluginDto> {
  const restageOrderPluginDtoPluginPipeline =
    new PluginPipeline<RestageOrderPluginDto>();

  restageOrderPluginDtoPluginPipeline.registerPlugins([
    new EmitHandoverAbortedBusEventPlugin(eventBusServicePlugin.get()),
    new TrackEventRestagingCompletedPlugin(
      ordersServicePlugin.get(),
      loggerServicePlugin.get(),
    ),
    new UpdateLocalStatusPlugin(
      loggerServicePlugin.get(),
      OrderLocalStatus.HandoverAborted,
    ),
    new AppCrashPlugin(appCrashServicePlugin.get(), loggerServicePlugin.get()),
    new SaveRestagedOrderPlugin(
      ordersServicePlugin.get(),
      loggerServicePlugin.get(),
      errorPlugin.get(),
      router.get(),
    ),
  ]);

  restageOrderPluginDtoPluginPipeline.registerPlugins(
    [
      new UpdateLocalStatusPlugin(
        loggerServicePlugin.get(),
        OrderLocalStatus.HandoverReady,
      ),
      new SaveOrderToStoragePlugin(
        storagePlugin.get(),
        eventBusServicePlugin.get(),
      ),
    ],
    (dto: RestageOrderPluginDto) =>
      dto.order.localStatus === OrderLocalStatus.HandoverAborted,
  );

  return restageOrderPluginDtoPluginPipeline;
}
