import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "base-input__wrapper" }
const _hoisted_2 = ["id", "type", "value", "disabled", "data-e2e", "placeholder", "autocomplete", "inputmode"]
const _hoisted_3 = ["data-e2e", "for"]
const _hoisted_4 = ["disabled", "data-e2e"]

import BaseError from '@/features/ui/components/Error.vue';
import type { Ref } from 'vue';
import { computed, nextTick, onMounted, ref } from 'vue';
import { ClearIcon } from '@/features/ui/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'Input',
  props: {
  id: {
    type: String,
  },
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  textClearable: {
    type: Boolean,
    default: true,
  },
  valid: {
    type: Boolean,
    default: true,
  },
  errorMessage: {
    type: String,
    default: '',
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  autoselect: {
    type: Boolean,
    default: false,
  },
  sm: {
    type: Boolean,
    default: false,
  },
  md: {
    type: Boolean,
    default: false,
  },
  e2eDescription: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  keydownCallback: {
    type: [Function, null],
    default: null,
  },
  autocomplete: {
    type: String,
  },
  inputmode: {
    type: String,
  },
},
  emits: ['update:modelValue', 'focusIn', 'focusOut', 'click'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const inputRef: Ref<HTMLElement | null> = ref(null);
const highlighted = ref(false);
const hasValue = computed(() =>
  Boolean(typeof props.modelValue === 'string' && props.modelValue?.length),
);

const blur = () => inputRef.value?.blur();

__expose({ blur });

if (props.autofocus) {
  onMounted(() => {
    void nextTick(() => {
      inputRef.value?.focus();
      if (props.autoselect) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        inputRef.value?.select();
      }
    });
  });
}

const onClick = () => {
  highlighted.value = true;
  emit('click');
};
const onFocusIn = () => {
  highlighted.value = true;
  emit('focusIn');
};
const onFocusOut = () => {
  highlighted.value = false;
  emit('focusOut');
};
const updateInput = (event: Event) => {
  const target = event.target as HTMLInputElement;

  emit('update:modelValue', target.value);
};

const clearInput = () => {
  emit('update:modelValue', null);
};

const onKeydown = (event: KeyboardEvent) => {
  if (props.keydownCallback === null) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  props.keydownCallback(event);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-input", {
      'has-text': Boolean(__props.modelValue),
      'focused-input': highlighted.value,
      invalid: !__props.valid,
      'base-input__sm': __props.sm,
      'base-input__md': __props.md,
      'disabled-input': __props.disabled,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        ref_key: "inputRef",
        ref: inputRef,
        id: __props.id,
        type: __props.type,
        value: __props.modelValue,
        disabled: __props.disabled,
        "data-e2e": __props.e2eDescription + '-input',
        onInput: _cache[0] || (_cache[0] = ($event: any) => (updateInput($event))),
        onClick: onClick,
        onFocusin: onFocusIn,
        onFocusout: onFocusOut,
        placeholder: __props.placeholder,
        class: _normalizeClass(["base-input__input", {
          'with-text': Boolean(__props.modelValue),
        }]),
        onKeydown: onKeydown,
        autocomplete: __props.autocomplete,
        inputmode: __props.inputmode
      }, null, 42, _hoisted_2),
      (__props.label)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            "data-e2e": __props.e2eDescription + '-label',
            for: __props.id,
            class: "base-input__label"
          }, _toDisplayString(__props.label), 9, _hoisted_3))
        : _createCommentVNode("", true),
      _createVNode(_Transition, {
        name: "md-input-action",
        appear: ""
      }, {
        default: _withCtx(() => [
          (hasValue.value && __props.textClearable)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                tabindex: "-1",
                class: "clear-btn",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (clearInput($event))),
                disabled: __props.disabled,
                "data-e2e": `clear-${__props.e2eDescription}`
              }, [
                _createVNode(_unref(ClearIcon), { width: "14" })
              ], 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    (__props.errorMessage)
      ? (_openBlock(), _createBlock(BaseError, {
          key: 0,
          "error-message": __props.errorMessage
        }, null, 8, ["error-message"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})