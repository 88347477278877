import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "weight" }
const _hoisted_3 = { class: "amount" }
const _hoisted_4 = {
  key: 0,
  class: "total-amount"
}

import 'vue';
import { $t } from '@/i18n';
import Chip from '@/features/ui/components/Chip.vue';
import { unitToDisplay } from '@/utils/helpers/unitPluralHandler';
import { roundValue } from '@/utils/helpers/roundValue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HandoverLooseRandomWeightFooter',
  props: {
    amount: {},
    totalAmount: {},
    unit: {},
    showRejectedChip: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createTextVNode(_toDisplayString(_unref(roundValue)(_ctx.amount)) + " ", 1),
        (_ctx.totalAmount === undefined)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_unref(unitToDisplay)(_ctx.unit, _unref(roundValue)(_ctx.amount))), 1)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      (_ctx.totalAmount !== undefined)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref($t)('components.handover-item.of.text')) + " " + _toDisplayString(_unref(roundValue)(_ctx.totalAmount)) + " " + _toDisplayString(_unref(unitToDisplay)(_ctx.unit, _unref(roundValue)(_ctx.totalAmount))), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showRejectedChip)
      ? (_openBlock(), _createBlock(Chip, {
          key: 0,
          text: _unref($t)('components.handover-item.all-rejected.text'),
          variant: "danger",
          size: "medium"
        }, null, 8, ["text"]))
      : _createCommentVNode("", true)
  ]))
}
}

})