import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-e2e"]
const _hoisted_2 = { class: "card__header" }
const _hoisted_3 = ["data-e2e"]
const _hoisted_4 = { class: "card__body mt-8" }
const _hoisted_5 = { class: "product-img" }

import type { PropType, Ref } from 'vue';
import { computed, ref } from 'vue';
import type { OrderItem } from '@/features/orders/types';
import { OrderItemStatus } from '@/features/orders/types';
import { TabVariants, CompletedOverlay } from '@/features/ui';
import { ProductImage } from '@/features/products';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductCard',
  props: {
  orderItem: {
    type: Object as PropType<OrderItem>,
    default: () => {
      return {};
    },
  },
  currentTab: String,
},
  setup(__props, { expose: __expose }) {

const props = __props;

const highlighted: Ref<boolean> = ref(false);
const completedOverlayVisible: Ref<boolean> = ref(false);

const highlightCard = (): void => {
  highlighted.value = true;
  setTimeout(() => {
    highlighted.value = false;
  }, 350);
};

const showCompletedOverlay = (onCompleted: () => void): void => {
  completedOverlayVisible.value = true;

  setTimeout(() => {
    completedOverlayVisible.value = false;
    onCompleted();
  }, 900);
};

__expose({
  highlightCard,
  showCompletedOverlay,
});

const isProductFullyReplaced = computed(() => {
  return Boolean(
    props.orderItem.quantity === 0 &&
      props.orderItem.status === OrderItemStatus.picked,
  );
});

const isCardVisible = computed<number | boolean>(() => {
  if (
    props.currentTab !== TabVariants.Changed &&
    props.currentTab !== TabVariants.Picked
  ) {
    return true;
  }

  if (props.currentTab === TabVariants.Changed) {
    if (props.orderItem.product.rwpType) {
      return props.orderItem.amount < props.orderItem.amountOriginal;
    } else {
      return props.orderItem.quantity !== props.orderItem.quantityOriginal;
    }
  } else {
    return props.orderItem.quantity;
  }
});

return (_ctx: any,_cache: any) => {
  return (isCardVisible.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["card", {
      picked: __props.orderItem.status === _unref(OrderItemStatus).picked,
      highlighted: highlighted.value,
    }]),
        "data-e2e": `picking-box-${__props.orderItem.product?.sku}`
      }, [
        (completedOverlayVisible.value)
          ? (_openBlock(), _createBlock(_unref(CompletedOverlay), {
              key: 0,
              class: "card__completed-overlay",
              "data-e2e": "picking-completed-overlay"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "card__title",
            "data-e2e": `picking-name-label-${__props.orderItem.product?.sku}`
          }, _toDisplayString(__props.orderItem.product?.productName), 9, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (__props.orderItem.product)
              ? (_openBlock(), _createBlock(_unref(ProductImage), {
                  key: 0,
                  product: __props.orderItem.product,
                  "e2e-tag": "picking",
                  grayscale: isProductFullyReplaced.value,
                  scalable: true
                }, null, 8, ["product", "grayscale"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _renderSlot(_ctx.$slots, "footer")
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})