import { createBarcodeLookupResult, isItemEntityArray } from '../helpers';
import type {
  CompletePipelineWith,
  PipelinePlugin,
} from '@ads/plugin-pipeline';
import type { BarcodeLookupPluginDto } from '../types';

/**
 * Looks for exact barcode matches in the dataset
 *
 * For details on how this plugin works check the documentation linked below
 * (look for the "Product/item lookup" section)
 *
 * @see https://aldi-sued.atlassian.net/l/cp/0HUfAQ2s
 */
export class FindExactMatchEntityPlugin
  implements PipelinePlugin<BarcodeLookupPluginDto>
{
  public execute(
    dataTransferObject: BarcodeLookupPluginDto,
    completePipelineWith: CompletePipelineWith<BarcodeLookupPluginDto>,
  ): BarcodeLookupPluginDto {
    if (dataTransferObject.dataset.length > 0) {
      const item = isItemEntityArray(dataTransferObject.dataset)
        ? dataTransferObject.dataset.find((item) =>
            item.product.barcodes.includes(dataTransferObject.barcode),
          )
        : dataTransferObject.dataset.find((item) =>
            item.barcodes.includes(dataTransferObject.barcode),
          );

      if (item) {
        return completePipelineWith({
          ...dataTransferObject,
          result: createBarcodeLookupResult(item, {
            barcodeType: 'normal',
            weight: null,
          }),
        });
      }
    }

    return dataTransferObject;
  }
}
