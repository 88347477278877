export enum PerformanceThresholdEnum {
  API_CALL = 500,
  INDEXED_DB_OPERATION = 250,
  PAGE_LOAD = 750,
  ROUTE_CHANGE = 750,
  SCREEN_TRANSITION = 750,

  /* Generic Values */
  SMALL_OPERATION = 100,
  LARGE_OPERATION = 1000,
}
