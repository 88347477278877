import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/Error.svg'


const _hoisted_1 = { class: "error-content" }
const _hoisted_2 = { class: "error-title" }
const _hoisted_3 = { class: "error-subtitle" }
const _hoisted_4 = { class: "error-device-info" }
const _hoisted_5 = { class: "device-info-title" }
const _hoisted_6 = { class: "device-info-id" }

import type { PropType, Ref } from 'vue';
import { onMounted, ref } from 'vue';
import { useSecretToggle } from '@/features/ui/composables/useSecretToggle';
import { useDeviceInfo } from '@/features/device-id';
import ErrorPreviewFooter from './ErrorPreviewFooter.vue';
import ThreeSectionLayout from '@/features/ui/layouts/ThreeSectionLayout.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorPreview',
  props: {
    error: {}
  },
  emits: ['open'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;



const imageRef: Ref<HTMLElement | undefined> = ref();
const { deviceId } = useDeviceInfo();

onMounted(() => {
  useSecretToggle(
    imageRef.value as HTMLElement,
    'click',
    () => emit('open'),
    5,
    5000,
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ThreeSectionLayout, { "with-router": false }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          ref_key: "imageRef",
          ref: imageRef,
          class: "error-image"
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            src: _imports_0,
            class: "hero error-image",
            alt: "",
            "data-e2e": "error-image"
          }, null, -1)
        ]), 512),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('pages.error.error-preview.title.text')), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('pages.error.error-preview.subtitle.text')), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('pages.error.error-preview.device-id.text')), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(deviceId)), 1)
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(ErrorPreviewFooter, { error: _ctx.error }, null, 8, ["error"])
    ]),
    _: 1
  }))
}
}

})