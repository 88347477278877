
import { defineComponent } from 'vue';
import { NotificationType } from '@/features/core/notifications';
import BaseIcon from '@/features/ui/icons/BaseIcon.vue';
import NotifyCancelIcon from '@/features/ui/icons/NotifyCancelIcon.vue';
import NotifyErrorIcon from '@/features/ui/icons/NotifyErrorIcon.vue';
import NotifyInfoIcon from '@/features/ui/icons/NotifyInfoIcon.vue';
import NotifySuccessIcon from '@/features/ui/icons/NotifySuccessIcon.vue';

export default defineComponent({
  name: 'Notification',
  components: {
    BaseIcon,
    NotifyCancelIcon,
    NotifyErrorIcon,
    NotifyInfoIcon,
    NotifySuccessIcon,
  },
  setup() {
    const getNotifyIconName = (notifyType: NotificationType): string => {
      switch (notifyType) {
        case NotificationType.Info:
          return 'NotifyInfoIcon';
        case NotificationType.Success:
          return 'NotifySuccessIcon';
        default:
          return 'NotifyErrorIcon';
      }
    };

    const getNotifyIconColor = (notifyType: NotificationType): string => {
      switch (notifyType) {
        case NotificationType.Info:
          return '#0D3A93';
        case NotificationType.Success:
          return '#258900';
        default:
          return '#E00016';
      }
    };

    return { getNotifyIconName, getNotifyIconColor };
  },
});
