import {
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import type { loggerServiceOptions } from './types';
import { LoggerService } from './services/logger-service';

export const loggerServicePlugin = ProviderPluginFactory.create<
  LoggerService,
  loggerServiceOptions
>({
  key: Symbol('loggerService'),
  defaultFactory: {
    create: (app, options) => {
      return new LoggerService(options);
    },
  },
});

export const loggerPlugin = MultiPluginFactory.with({
  loggerServicePlugin,
});
