<template>
  <AppHeader v-if="order" class="header">
    <BackButton :to="{ name: 'review-order', params: { id } }" />
  </AppHeader>
  <ScreenLoading
    v-if="loading || processing"
    :title="$t('pages.review-assigning-bags.loading-title')"
    blur
  />
  <div
    :class="[
      'note fixed-footer-content fixed-footer-content--extra-padding',
      { minified: bags?.length === 1 },
    ]"
  >
    <div class="note__body">
      <div class="note__header">
        <div class="note__image">
          <img
            alt=""
            class="ut-responsive-img"
            data-e2e="bags-screen-image"
            height="176"
            src="@/assets/images/complete-picking-bags@2.png"
            width="156"
          />
        </div>
        <h1 ref="titleRef" class="note__title" data-e2e="bags-screen-title">
          {{ $t('pages.review-assigning-bags.title.text') }}
        </h1>
      </div>

      <div v-if="bags" ref="scrollTo" class="bags-list">
        <Bags
          v-for="item in bags"
          :key="item.sku"
          :name="item.name"
          :quantity="item.quantity"
          :sku="item.sku"
          @focusIn="focusInHandler"
          @focusOut="focusOutHandler"
          @update:quantity="setBagQuantity(item, $event)"
        />
      </div>
    </div>
  </div>

  <footer v-show="!isBagFocused" class="footer fixed-footer-content__footer">
    <Button
      :disabled="processing"
      btnOutline
      class="column--full"
      data-e2e="confirm-button"
      @click="goNext"
    >
      <span class="ut-font_fix">{{
        $t('pages.review-assigning-bags.confirm-amount.text')
      }}</span>
    </Button>
  </footer>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { OrderEventNames, ordersServicePlugin } from '@/features/orders';
import { useBagsStorage } from '@/features/bags/composables';
import { AppHeader, BackButton, Bags, Button } from '@/features/ui';
import ScreenLoading from '@/features/ui/components/ScreenLoading.vue'; // NOTE:  Failed to resolve component, when importing via barrel
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';
import { useOrder as usePickingOrder } from '@/features/picking';
import { useOrder } from '..';

export default defineComponent({
  components: {
    Bags,
    Button,
    AppHeader,
    BackButton,
    ScreenLoading,
  },
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const scrollTo = ref<null | HTMLElement>(null);
    const { bags, loading, order, loadOrder, setBagQuantity } = useOrder();

    const { redirectIfOrderNotPicking } = usePickingOrder();

    const { saveBagsQuantityToStorage, getBagsQuantityFromStorage } =
      useBagsStorage();
    const processing = ref(false);
    const titleRef = ref(Element);
    const router = useRouter();
    const { startTracking, stopTracking } = usePerformanceTracker();

    onMounted(async () => {
      processing.value = true;
      await getBags();
      processing.value = false;
      stopTracking(`review-order-to-order-bags-${props.id}`);
      await redirectIfOrderNotPicking(order.value);
      if (order.value) {
        await ordersServicePlugin
          .get()
          .trackEvent(order.value, OrderEventNames.staging_started);
      }
    });

    const setOrderBagsQuantityFromStorage = () => {
      const storedBags = getBagsQuantityFromStorage();
      if (storedBags && Array.isArray(storedBags)) {
        storedBags.forEach((storedBag) => {
          const bag = order.value?.bags.find(
            (bagItem) => bagItem.sku === storedBag.sku,
          );

          if (bag) {
            setBagQuantity(bag, storedBag.quantity);
          }
        });
      }
    };

    const initWatcher = () =>
      watch(
        bags,
        () => {
          if (order.value) saveBagsQuantityToStorage(bags.value);
        },
        { deep: true },
      );

    const getBags = async () => {
      await loadOrder(props.id);
      setOrderBagsQuantityFromStorage();
      initWatcher();
    };

    const goNext = async () => {
      processing.value = true;

      if (order.value) {
        await ordersServicePlugin.get().saveOrder(order.value);
      }

      startTracking(
        `review-orders-to-picking-labels-${props.id}`,
        PerformanceThresholdEnum.ROUTE_CHANGE,
      );

      await router.push({
        name: 'picking-labels',
        params: {
          id: props.id,
        },
      });
    };

    const isBagFocused = ref(false);
    const focusInHandler = () => {
      isBagFocused.value = true;
      scrollTo.value?.scrollIntoView();
    };
    const focusOutHandler = () => {
      isBagFocused.value = false;
    };

    return {
      bags,
      goNext,
      order,
      setBagQuantity,
      isBagFocused,
      focusInHandler,
      focusOutHandler,
      processing,
      titleRef,
      scrollTo,
      loading,
    };
  },
});
</script>
<style lang="scss" scoped>
.header {
  padding: 16px;
}

.note {
  justify-content: center;

  &__title {
    margin: 24px auto 0;
    font-size: 21px;
    line-height: 1.42em;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 1.625em;
    font-weight: normal;
  }
}

.minified {
  .bags-list {
    border-top: 0.5px solid rgba(var(--aldi-mid-blue-40), 1);
  }

  .note__image {
    width: 156px;
  }
}

.note__image {
  display: inline-block;
  margin: 0 auto;
  width: 135px;
}

.bags-list {
  display: grid;
  margin-top: 32px;
}

.footer {
  &__info {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-items: left;
    margin-bottom: 8px;

    .bags-error-text {
      display: flex;
      align-items: center;
      font-size: 14px;
      text-align: left;
      color: #e00016;
      line-height: 24px;
      letter-spacing: 0.2px;

      svg {
        margin-right: 4px;
      }
    }
  }
}

.row__value,
.row__label {
  line-height: 1em;
}

.row__value {
  justify-self: end;
}

.row__value--accent {
  font-size: 18px;
}

.row--accent {
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
}

.column--full {
  grid-column: 1/-1;
}

.ut-responsive-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
