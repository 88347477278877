import type { AuthService } from '@/features/core/auth';
import type { ApiClient, Response } from '@/features/core/api';
import type {
  ConfigurationOption,
  ConfigurationResponse,
  ConfigurationResponseOption,
} from '../types';
import { Configuration } from '../entities';
import { GetConfigurationRequestError } from '../errors';

export class ConfigurationApiClient {
  constructor(private api: ApiClient, private authService: AuthService) {}

  private parseConfigurationOptions(
    options: ConfigurationResponseOption[],
  ): ConfigurationOption[] {
    return options.map((option) => {
      return {
        id: option.identifier,
        values: option.values,
      };
    });
  }

  async getAll(): Promise<Configuration[]> {
    const merchantReference = await this.authService.getMerchantReference();

    const configurationResponse = await this.api.client.get<
      Response<ConfigurationResponse[]>
    >(`/configurations/${String(merchantReference)}`, {
      innerErrorCode: GetConfigurationRequestError.Code,
    });

    return configurationResponse.data.data.map((configuration) =>
      Configuration.from({
        id: configuration.identifier,
        active: configuration.isActive,
        options: this.parseConfigurationOptions(configuration.options),
      }),
    );
  }
}
