import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["type", "variant", "disabled", "loading"]
const _hoisted_2 = {
  key: 0,
  class: "button__content"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { "data-e2e": "button-loading-label" }
const _hoisted_5 = ["data-e2e"]
const _hoisted_6 = {
  key: 1,
  class: "button__content"
}
const _hoisted_7 = ["data-e2e"]

import BaseIcon from '@/features/ui/icons/BaseIcon.vue';
import { TransitionSpinner } from '@/features/ui/icons';


const __default__ = { name: 'BaseButton' };

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    label: { default: '' },
    type: { default: undefined },
    variant: { default: undefined },
    icon: {},
    disabled: { type: Boolean, default: false },
    iconRight: { type: Boolean },
    loading: { type: Boolean },
    iconWidth: {},
    iconHeight: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: _ctx.type,
    variant: _ctx.variant,
    class: _normalizeClass([
      'button',
      {
        button__primary: (_ctx.type || _ctx.variant) === 'primary',
      },
      {
        button__secondary: (_ctx.type || _ctx.variant) === 'secondary',
      },
      {
        button__tertiary: (_ctx.type || _ctx.variant) === 'tertiary',
      },
    ]),
    disabled: _ctx.disabled,
    loading: _ctx.loading
  }, [
    (_ctx.label && !_ctx.icon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_unref(TransitionSpinner), {
                  class: "spinner",
                  fill: "#fff"
                }),
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('components.card-order.button-loading.text')), 1)
              ]))
            : (_openBlock(), _createElementBlock("span", {
                key: 1,
                "data-e2e": `button-label-${_ctx.label}`
              }, _toDisplayString(_ctx.label), 9, _hoisted_5))
        ]))
      : _createCommentVNode("", true),
    (_ctx.label && _ctx.icon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (!_ctx.iconRight)
            ? (_openBlock(), _createBlock(BaseIcon, {
                key: 0,
                class: "button__icon",
                width: _ctx.iconWidth,
                height: _ctx.iconHeight
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
              }, 8, ["width", "height"]))
            : _createCommentVNode("", true),
          _createElementVNode("span", {
            "data-e2e": `button-label-${_ctx.label}`
          }, _toDisplayString(_ctx.label), 9, _hoisted_7),
          (_ctx.iconRight)
            ? (_openBlock(), _createBlock(BaseIcon, {
                key: 1,
                class: "button__icon--right",
                width: _ctx.iconWidth,
                height: _ctx.iconHeight
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
              }, 8, ["width", "height"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(BaseIcon, {
                key: 0,
                width: _ctx.iconWidth,
                height: _ctx.iconHeight
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
              }, 8, ["width", "height"]))
            : _createCommentVNode("", true)
        ], 64))
  ], 10, _hoisted_1))
}
}

})