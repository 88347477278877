
import { defineComponent, onMounted } from 'vue';
import { CheckIcon, LoadingIcon } from '@/features/ui/icons';
import { retrySynchronisationsPlugin } from '@/features/retry-synchronisation';
import Dialog from '@/features/ui/components/Dialog.vue';
import { oauthServicePlugin } from '@/features/oauth';
import router from '@/features/core/router';
import { useStart } from '../composables';
import { LoadingProcess } from '../types';

export default defineComponent({
  name: 'StartPage',
  components: {
    Dialog,
    LoadingIcon,
    CheckIcon,
  },
  setup() {
    const { loadData, loadingData } = useStart();
    const { showSynchronisationFailPopup } = retrySynchronisationsPlugin.get();

    onMounted(async () => {
      const url = await loadData();
      if (url) {
        await router.get().push(url);
      }
    });

    const isLoading = (loadingProcess: LoadingProcess) => {
      return loadingProcess === LoadingProcess.Loading;
    };

    const isLoaded = (loadingProcess: LoadingProcess) => {
      return loadingProcess === LoadingProcess.Loaded;
    };

    const handleLogout = async () => {
      await oauthServicePlugin.get().logout('empowerId', false, false);
    };

    return {
      loadingData,
      isLoading,
      isLoaded,
      showSynchronisationFailPopup,
      handleLogout,
    };
  },
});
