
import { defineComponent } from 'vue';
import CartIcon from '@/features/ui/icons/CartIcon.vue';
import { formatCount } from '@/utils/helpers/ItemsCountFormatter';
import { $t } from '@/i18n';

export default defineComponent({
  components: {
    CartIcon,
  },
  props: {
    quantity: {
      type: Number,
      default: 0,
    },
    quantityOriginal: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const formatCountToSting = (item: number) => {
      return formatCount(item);
    };

    const getTranslatedText = (identifier: string, data = {}) => {
      return $t(identifier, data);
    };

    return {
      formatCountToSting,
      getTranslatedText,
    };
  },
});
