import type { Subscription } from 'rxjs';
import { bufferCount, filter, fromEvent, switchMap, windowTime } from 'rxjs';
import type { secretToggleEvent } from '../types';
import { SecretToggleEvent } from '../types';

export const useSecretToggle = (
  element: HTMLElement,
  event: secretToggleEvent,
  callback: () => void,
  clickCount = 5,
  timeToResetClickCounter = 5000,
): Subscription => {
  const click$ = fromEvent(element, SecretToggleEvent[event]);
  return click$
    .pipe(
      windowTime(timeToResetClickCounter),
      switchMap((window) => window.pipe(bufferCount(clickCount))),
      filter((clicks) => clicks.length >= clickCount),
    )
    .subscribe(() => callback());
};
