import { SomethingWrongError } from '@/features/core/errors/error-classes';
import type { LogLevel } from '@/features/core/logger';

declare module '@/features/core/errors/error-code' {
  interface ErrorCodes {
    [ClearNotificationsByTagError.Code]: 'ClearNotificationsByTagError';
    [ClearAllNotificationsError.Code]: 'ClearAllNotificationsError';
  }
}

export class ClearNotificationsByTagError extends SomethingWrongError {
  static Code = 40 as const;
  static Message = `Failed to clear notifications with tag "{tag}"`;
  static LogLevel: LogLevel = 40;

  constructor(
    public readonly tag: string,
    public readonly originalError: unknown,
  ) {
    super({ tag });
  }
}

export class ClearAllNotificationsError extends SomethingWrongError {
  static Code = 41 as const;
  static Message = `Failed to clear all notifications`;
  static LogLevel: LogLevel = 40;

  constructor(public readonly originalError: unknown) {
    super();
  }
}
