<template>
  <h2 class="page-title">ALDI Picker App</h2>
  <div class="data-loading-screen">
    <div class="data-loading-screen__content">
      <div class="logo">
        <img
          alt="ALDI logo"
          data-e2e="aldi-logo"
          src="@/assets/images/ALDI-logo.svg"
        />
      </div>
      <h1 class="data-loading-screen__title" data-e2e="start-page-screen-title">
        {{ $t('pages.start-page.screen-title.text') }}
      </h1>
      <ul class="list">
        <li
          :class="{ loading: isLoading(loadingData.configuration) }"
          class="list-item"
          data-e2e="list-item-configuration"
        >
          <LoadingIcon
            v-if="isLoading(loadingData.configuration)"
            class="list-icon spinner"
          />
          <CheckIcon
            v-if="isLoaded(loadingData.configuration)"
            class="list-icon"
            width="25"
          />

          {{ $t('pages.start-page.configuration.text') }}
        </li>
        <li
          :class="{ loading: isLoading(loadingData.data) }"
          class="list-item"
          data-e2e="list-item-master-data"
        >
          <LoadingIcon
            v-if="isLoading(loadingData.data)"
            class="list-icon spinner"
          />
          <CheckIcon
            v-if="isLoaded(loadingData.data)"
            class="list-icon"
            width="25"
          />

          {{ $t('pages.start-page.master-data.text') }}
        </li>
        <li
          :class="{ loading: isLoading(loadingData.sync) }"
          class="list-item"
          data-e2e="list-item-live-sync"
        >
          <LoadingIcon
            v-if="isLoading(loadingData.sync)"
            class="list-icon spinner"
          />
          <CheckIcon
            v-if="isLoaded(loadingData.sync)"
            class="list-icon"
            width="25"
          />
          {{ $t('pages.start-page.live-sync.text') }}
        </li>
        <li
          :class="{ loading: isLoading(loadingData.order) }"
          class="list-item"
          data-e2e="list-item-orders"
        >
          <LoadingIcon
            v-if="isLoading(loadingData.order)"
            class="list-icon spinner"
          />
          <CheckIcon
            v-if="isLoaded(loadingData.order)"
            class="list-icon"
            width="25"
          />
          {{ $t('pages.start-page.orders.text') }}
        </li>
      </ul>
    </div>
  </div>
  <Dialog
    :confirmText="$t('pages.start-page.app-updated-dialog.confirm.text')"
    :contentText="$t('pages.start-page.app-updated-dialog.content.text')"
    :showOnlyConfirm="true"
    :titleText="$t('pages.start-page.app-updated-dialog.title.text')"
    :visible="showSynchronisationFailPopup"
    @confirmed="handleLogout"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { CheckIcon, LoadingIcon } from '@/features/ui/icons';
import { retrySynchronisationsPlugin } from '@/features/retry-synchronisation';
import Dialog from '@/features/ui/components/Dialog.vue';
import { oauthServicePlugin } from '@/features/oauth';
import router from '@/features/core/router';
import { useStart } from '../composables';
import { LoadingProcess } from '../types';

export default defineComponent({
  name: 'StartPage',
  components: {
    Dialog,
    LoadingIcon,
    CheckIcon,
  },
  setup() {
    const { loadData, loadingData } = useStart();
    const { showSynchronisationFailPopup } = retrySynchronisationsPlugin.get();

    onMounted(async () => {
      const url = await loadData();
      if (url) {
        await router.get().push(url);
      }
    });

    const isLoading = (loadingProcess: LoadingProcess) => {
      return loadingProcess === LoadingProcess.Loading;
    };

    const isLoaded = (loadingProcess: LoadingProcess) => {
      return loadingProcess === LoadingProcess.Loaded;
    };

    const handleLogout = async () => {
      await oauthServicePlugin.get().logout('empowerId', false, false);
    };

    return {
      loadingData,
      isLoading,
      isLoaded,
      showSynchronisationFailPopup,
      handleLogout,
    };
  },
});
</script>

<style lang="scss" scoped>
.data-loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  flex-direction: column;

  :deep(.loading) {
    img {
      height: 24px;
    }
  }

  &__title {
    margin: 56px auto 16px;
    font-size: 21px;
    color: $default-text-color;
    letter-spacing: 0.05rem;
    line-height: 1.4;
  }
}

ul.list {
  margin: auto;
  width: fit-content;

  .list-item {
    position: relative;
    padding: 8px 0 8px 30px;
    text-align: left;

    &.loading {
      color: $primary-color;
    }

    .list-icon {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -15px;
      fill: $primary-color;
    }
  }
}

.page-title {
  position: fixed;
  top: 14px;
  right: 0;
  left: 0;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: $default-text-color;
  letter-spacing: 0.015em;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
