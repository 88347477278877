import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/parking-lot.svg'


const _hoisted_1 = {
  key: 0,
  class: "customer-information"
}
const _hoisted_2 = {
  key: 0,
  class: "customer-information__chip"
}
const _hoisted_3 = { class: "customer-information__image" }
const _hoisted_4 = ["alt"]
const _hoisted_5 = { class: "note" }
const _hoisted_6 = { class: "note__title" }
const _hoisted_7 = { class: "note__list" }
const _hoisted_8 = { class: "item" }
const _hoisted_9 = {
  class: "title",
  "data-e2e": "order-checkin-location-title"
}
const _hoisted_10 = { class: "item" }
const _hoisted_11 = { class: "title" }
const _hoisted_12 = {
  class: "description",
  "data-e2e": "order-pickup-code"
}
const _hoisted_13 = { class: "item" }
const _hoisted_14 = { class: "title" }
const _hoisted_15 = {
  class: "description",
  "data-e2e": "order-customer-name"
}

import type { ComputedRef } from 'vue';
import { computed, onBeforeMount, ref } from 'vue';
import { useHandoverOrder } from '@/features/handover/composables';
import { HandoverFooter } from '@/features/handover/components';
import { AppHeader, BaseButton, StatusBar } from '@/features/ui/components/';
import { getCustomerNote } from '@/features/orders';
import { usePerformanceTracker } from '@/features/performance-tracker';
import ChipTime from '@/features/ui/components/ChipTime.vue';
import { configurationServicePlugin } from '@/features/configuration';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerInformation',
  props: {
  id: { type: String, required: true },
},
  setup(__props) {

const props = __props;

const { order, loadOrder, continueToHandover, goRestaging } =
  useHandoverOrder();
const { stopTracking } = usePerformanceTracker();
const isRestagingActive = ref<boolean>(false);

onBeforeMount(async () => {
  await loadOrder(props.id);
  stopTracking('start-handover-process');
  isRestagingActive.value = (
    await configurationServicePlugin.get().isFeatureActive('restaging')
  ).value;
});

const customerFullName = computed(() => {
  const firstName = order.value?.customer?.firstName ?? '';
  const lastName = order.value?.customer?.lastName ?? '';

  if (order.value) {
    return `${firstName} ${lastName}`;
  }

  return '';
});

const isLocationTextLong = computed(() => {
  const locationLength = order.value?.checkIn?.location?.length;
  return locationLength && locationLength > 74;
});

const customerNote: ComputedRef<string | undefined> = computed<
  string | undefined
>(() => getCustomerNote(order.value));

return (_ctx: any,_cache: any) => {
  return (_unref(order))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(AppHeader), { fixed: "" }, {
          default: _withCtx(() => [
            _createVNode(_unref(StatusBar), {
              customerFullName: customerFullName.value,
              customerNote: customerNote.value,
              hasBackButton: false,
              order: _unref(order),
              title: _unref(order).pickupCode,
              class: "search"
            }, null, 8, ["customerFullName", "customerNote", "order", "title"])
          ]),
          _: 1
        }),
        (_unref(order).checkIn?.timestamp)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(ChipTime, {
                time: _unref(order).checkIn?.timestamp
              }, null, 8, ["time"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            alt: _ctx.$t('pages.handover-customer-information.note-title.text'),
            "data-e2e": "customer-information-image",
            src: _imports_0
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.$t('pages.handover-customer-information.note-title.text')), 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('pages.handover-customer-information.location-title.text')), 1),
              _createElementVNode("div", {
                class: _normalizeClass([{ 'description--long-text': isLocationTextLong.value }, "description"]),
                "data-e2e": "order-checkin-location"
              }, _toDisplayString(_unref(order).checkIn?.location), 3)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t(
                'pages.handover-customer-information.note-list-item-title.text',
              )), 1),
              _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(order).pickupCode), 1)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('pages.handover-customer-information.note-list-item-name.text')), 1),
              _createElementVNode("div", _hoisted_15, _toDisplayString(customerFullName.value), 1)
            ])
          ])
        ]),
        _createVNode(_unref(HandoverFooter), null, {
          default: _withCtx(() => [
            _createVNode(_unref(BaseButton), {
              label: 
          _ctx.$t(
            'pages.handover-customer-information.handover-footer-continue-button.label',
          )
        ,
              "data-e2e": "continue-to-handover-button",
              variant: "primary",
              onClick: _unref(continueToHandover)
            }, null, 8, ["label", "onClick"]),
            (isRestagingActive.value)
              ? (_openBlock(), _createBlock(_unref(BaseButton), {
                  key: 0,
                  label: 
          _ctx.$t(
            'pages.handover-customer-information.handover-footer-customer-not-present-button.label',
          )
        ,
                  class: "handover-footer-customer-not-present-button",
                  "data-e2e": "customer-not-present-button",
                  variant: "secondary",
                  onClick: _unref(goRestaging)
                }, null, 8, ["label", "onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})