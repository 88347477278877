import { appCrashServicePlugin, appCrashExecutePlugin } from './plugin';
import { MultiPluginFactory } from '@/features/core/plugin';

export * from './types';
export * from './services';
export * from './plugin';

export const appCrashPlugin = MultiPluginFactory.with({
  appCrashServicePlugin,
  appCrashExecutePlugin,
});
