
import { defineComponent } from 'vue';
import SimpleAccordeon from '@/features/ui/components/SimpleAccordeon.vue';

export default defineComponent({
  name: 'TransmissionsAccordeon',
  components: {
    SimpleAccordeon,
  },
  props: {
    transmissionsList: {
      type: Array,
      required: true,
    },
    e2ePrefix: {
      type: String,
      required: true,
    },
  },
});
