import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "popup__title",
  "data-e2e": "dialog-title"
}
const _hoisted_2 = { class: "popup__content-container" }
const _hoisted_3 = { class: "radio-group" }
const _hoisted_4 = {
  key: 0,
  class: "input-wrapper"
}

import { ref, computed, toRefs } from 'vue';
import { Popup, RadioGroup, Input, BaseButton } from '@/features/ui/components';
import { $t } from '@/i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'RejectionReason',
  props: {
  reasons: {
    type: Array,
    default: () => [],
    required: true,
  },
  visible: {
    type: Boolean,
    required: true,
  },
},
  emits: ['submit', 'close'],
  setup(__props, { emit: __emit }) {

const props = __props;

const { reasons } = toRefs(props);

const emit = __emit;

const rejectionNotice = ref<null | string>(null);
const rejectionNoticeError = computed<string | undefined>(() => {
  if (rejectionNotice.value === '') {
    return $t(
      'components.handover-rejection-reason.notice.field-must-not-be-empty.text',
    );
  } else if (
    rejectionNotice.value !== null &&
    rejectionNotice.value.length >= 200
  ) {
    return $t(
      'components.handover-rejection-reason.notice.maximum-characters-reached.text',
    );
  }
  return undefined;
});
const selectedReason = ref<string | null>(null);

const hasError = computed(
  () =>
    selectedReason.value === 'other' &&
    (rejectionNotice.value === '' ||
      rejectionNotice.value === null ||
      rejectionNotice.value.length >= 200),
);

const rejectionReason = (reason: string) => {
  rejectionNotice.value = null;
  selectedReason.value = reason;
};

const submit = () => {
  emit(
    'submit',
    selectedReason.value === 'other'
      ? rejectionNotice.value
      : selectedReason.value,
  );
  selectedReason.value = null;
  rejectionNotice.value = null;
};

const close = () => {
  emit('close');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Popup), {
    visible: __props.visible,
    isContentCentered: "",
    isSpaceToContentExist: "",
    isCloseButtonExist: false,
    "data-e2e": "rejection-reason-popup"
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_unref($t)('components.handover-rejection-reason.popup-title.text')), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(RadioGroup), {
            "data-e2e": "rejection-reason-popup__radio-group",
            id: "rejectionReason",
            options: _unref(reasons),
            isPreSelected: false,
            onSelectedChange: rejectionReason
          }, null, 8, ["options"])
        ]),
        (selectedReason.value === 'other')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_unref(Input), {
                required: "",
                type: "text",
                autofocus: "",
                placeholder: 
            _unref($t)(
              'components.handover-rejection-reason.other-reason-placeholder.text',
            )
          ,
                e2eDescription: "other-reason",
                modelValue: rejectionNotice.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((rejectionNotice).value = $event)),
                valid: !rejectionNoticeError.value,
                "error-message": rejectionNoticeError.value
              }, null, 8, ["placeholder", "modelValue", "valid", "error-message"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_unref(BaseButton), {
          onClick: submit,
          variant: "primary",
          label: 
          _unref($t)('components.handover-rejection-reason.popup-confirm-button.text')
        ,
          disabled: hasError.value || selectedReason.value === null,
          "data-e2e": "rejection-reason-popup__submit"
        }, null, 8, ["label", "disabled"]),
        _createVNode(_unref(BaseButton), {
          onClick: close,
          variant: "tertiary",
          label: 
          _unref($t)('components.handover-rejection-reason.popup-cancel-button.text')
        ,
          "data-e2e": "rejection-reason-popup__cancel"
        }, null, 8, ["label"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})