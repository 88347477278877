import type { AgeVerification } from '@/features/age-verification/types';
import { Status } from '@/features/age-verification/types';
import type { Response } from '@/features/core/api';
import type { ApiClient } from '@/features/core/api';
import type { AuthService } from '@/features/core/auth';
import type { BaseError, ErrorHandler } from '@/features/core/errors';
import { UnknownError } from '@/features/core/errors';
import type { Storage } from '@/features/core/storage';
import { BooleanNumber } from '@/features/core/storage';
import { TemperatureClassList } from '@/features/products/types';
import { formatDate, formatDateFromYear } from '@/utils/helpers/DateFormatter';
import { Order } from '../entities';
import {
  GetAllOrdersRequestError,
  getErrorCode,
  getErrorDetail,
  GetOrderByIdRequestError,
  HandoverAlreadyStartedError,
  isAlreadyCompletedOrCancelledOMSState,
  isAlreadyInExistedOMSState,
  isHandoverAlreadyStartedError,
  isPickingAlreadyStartedError,
  PickingAlreadyStartedError,
  PickingCancelOrCompletedError,
  SaveOrderError,
  SaveOrderRequestError,
} from '../errors';
import { hasOrderAction } from '../helpers/has-order-action';
import { hasOrderLocalStatus } from '../helpers/order-status';
import type {
  IOrderApiClient,
  OrderCheckin,
  OrderItem,
  OrderItemCount,
  OrderItemRaw,
  OrderItemResponse,
  OrderPatchAttributes,
  OrderRaw,
  OrderResponse,
  PickingCompletedAgeVerificationComplete,
  PickingCompletedAgeVerificationFailed,
  PickingCompletedItem,
} from '../types';
import {
  PickupCancelledOptions,
  OrderActionStatus,
  OrderItemStatus,
  OrderLocalStatus,
  OrderPickingPatch,
  PickingCancelledOptions,
  PickingCompletedOptions,
  PickingStartedOptions,
  PickupCompletedOptions,
  PickupStartedOptions,
  PickupAbortedOptions,
} from '../types';

import type { ConfigurationService } from '@/features/configuration';
import type { LoggerService } from '@/features/core/logger';
import type { ImageCachingQueueServiceImplementation } from '@/features/imageCachingQueue/services';
import type { SyncSchedulerService } from '@/features/sync-scheduler';
import { PipelineExecutionError } from '@ads/plugin-pipeline/build/pipeline-execution-error';
import { getParseOrderPipeline } from '../composables/parseOrder';
import { isApiError } from '@/features/core/api/helper/is-api-client-error';
import type { ApiClientError } from '@/features/core/api/types';
export class OrderApiClient implements IOrderApiClient {
  constructor(
    private api: ApiClient,
    private storage: Storage,
    private errorHandler: ErrorHandler,
    private authService: AuthService,
    private imageCachingService: ImageCachingQueueServiceImplementation,
    private syncSchedulerService: SyncSchedulerService,
    private loggerService: LoggerService,
    private configurationService: ConfigurationService,
  ) {}

  getOrderItemSkus(order: OrderResponse): string[] {
    return order.attributes.items.map((item) => item.sku);
  }

  async parseOrders(orders: OrderResponse[]): Promise<Order[]> {
    const ordersList = await Promise.all(
      orders.map((order) => this.parseOrder(order)),
    );
    await this.imageCachingService.setCachingQueue();
    await this.imageCachingService.fetchAll();

    return ordersList;
  }

  public quantityCount(
    temperatureClass: string,
    itemsQuantity: OrderItemCount,
    item: OrderItemRaw | OrderItem | OrderItemResponse,
  ): void {
    itemsQuantity.total += item.quantityOriginal;
    if (temperatureClass === TemperatureClassList.freezer) {
      itemsQuantity.freezer += item.quantityOriginal;
    } else if (temperatureClass === TemperatureClassList.fresh) {
      itemsQuantity.fresh += item.quantityOriginal;
    } else if (temperatureClass === TemperatureClassList.chiller) {
      itemsQuantity.chiller += item.quantityOriginal;
    } else if (temperatureClass === TemperatureClassList.ambient) {
      itemsQuantity.ambient += item.quantityOriginal;
    }
  }

  async orderCheckedInJustNow(order: Order): Promise<OrderCheckin> {
    const checkIn = order.checkIn;
    const existingOrder: Order | undefined = await this.storage.getById(Order, {
      id: order.id,
    });
    const isCheckedIn = checkIn?.isCheckedIn === BooleanNumber.True;

    return {
      id: order.id,
      checkin: Boolean(
        isCheckedIn &&
          (!existingOrder ||
            existingOrder?.checkIn?.isCheckedIn === BooleanNumber.False),
      ),
    };
  }

  orderSameDateId(order: Order): string | undefined {
    const creationDayTime = order.creationTime;
    const statuses = order.actionStatuses;

    if (
      !statuses.includes('picking_started') ||
      !creationDayTime ||
      order.localStatus !== OrderLocalStatus.PickingReady
    ) {
      return;
    }

    const creationDay = formatDate(new Date(creationDayTime));

    const currentDay = formatDate(new Date());

    if (creationDay !== currentDay) return;

    return order.id;
  }

  async parseOrder(orderRaw: OrderResponse | OrderRaw): Promise<Order> {
    const parseOrderPlugin = getParseOrderPipeline(
      this.storage,
      this.imageCachingService,
      this.syncSchedulerService,
      this.loggerService,
      this.configurationService,
    );
    try {
      const { order } = await parseOrderPlugin.execute({
        orderRaw,
        order: Order.from({}),
        rawItems: [],
      });
      return order;
    } catch (error) {
      if (error instanceof PipelineExecutionError) {
        this.errorHandler.handle(error.originalError);
      } else {
        this.errorHandler.handle(error);
      }
      throw new Error();
    }
  }

  async getAll(): Promise<OrderResponse[]> {
    const merchantReference = await this.authService.getMerchantReference();
    const ordersResponse = await this.api.client.get<Response<OrderResponse[]>>(
      `/merchants/${String(merchantReference)}/picking-orders`,
      {
        innerErrorCode: GetAllOrdersRequestError.Code,
      },
    );
    return ordersResponse.data.data ?? [];
  }

  async getByIds(orderIds: string[]): Promise<OrderResponse[]> {
    const merchantReference = await this.authService.getMerchantReference();
    const idsList = orderIds.join(',');
    const ordersResponse = await this.api.client.get<Response<OrderResponse[]>>(
      `/merchants/${String(
        merchantReference,
      )}/picking-orders?filter[order.ids]=${idsList}`,
      {
        innerErrorCode: GetOrderByIdRequestError.Code,
      },
    );
    return ordersResponse.data.data ?? [];
  }

  ageVerificationData(
    ageVerification: AgeVerification,
  ):
    | PickingCompletedAgeVerificationFailed
    | PickingCompletedAgeVerificationComplete
    | null {
    if (ageVerification.status === Status.Rejected) {
      return {
        failureReason: ageVerification.rejectionReason,
      };
    }

    if (ageVerification.userIdType) {
      return {
        documentId: ageVerification.userIdType,
        dateOfBirth: ageVerification.dateOfBirth
          ? formatDateFromYear(ageVerification.dateOfBirth)
          : null,
      };
    }

    return null;
  }

  async save(order: OrderRaw): Promise<OrderRaw> {
    const merchantReference = await this.authService.getMerchantReference();
    if (!merchantReference) {
      throw new Error('No merchant reference available');
    }

    const timestamp = order.lastLocalStatusChange || new Date();

    let options: OrderPatchAttributes;

    const parsedOrderItems = (items: OrderItemRaw[]) => {
      return items.reduce((acc: PickingCompletedItem[], item: OrderItemRaw) => {
        const arr = [...acc];
        if (item.status !== OrderItemStatus.cancelled) {
          const pickingOrderItem: PickingCompletedItem = {
            id: item.id,
            sku: item.product,
            quantity: item.quantity,
            amount: item.amount,
            unit: item.unit,
            scannedBarcodes: item.scannedBarcodes,
          };
          if (item.originalId) {
            pickingOrderItem.originalId = item.originalId;
          }
          arr.push(pickingOrderItem);
        }
        return arr;
      }, []);
    };

    const events = order.events.filter((event) => !event.isPatchedToBackEnd);

    const deliveryUnits = order.deliveryUnits;

    if (
      hasOrderAction(order, OrderActionStatus.picking) &&
      hasOrderLocalStatus(order, OrderLocalStatus.PickingReady)
    ) {
      options = new PickingStartedOptions(timestamp, events);
    } else if (hasOrderLocalStatus(order, OrderLocalStatus.PickingCancelled)) {
      options = new PickingCancelledOptions(timestamp, events);
    } else if (hasOrderLocalStatus(order, OrderLocalStatus.HandoverCancelled)) {
      options = new PickupCancelledOptions(timestamp, events);
    } else if (hasOrderLocalStatus(order, OrderLocalStatus.PickingCompleted)) {
      options = new PickingCompletedOptions(
        timestamp,
        [
          ...parsedOrderItems(order.items),
          ...order.bags.map((bag) => ({
            id: `bags_${bag.sku}_${bag.sku}_${String(merchantReference)}`,
            sku: bag.sku,
            quantity: bag.quantity,
          })),
        ],
        events,
        deliveryUnits,
      );
    } else if (
      hasOrderAction(order, OrderActionStatus.handover) &&
      hasOrderLocalStatus(order, OrderLocalStatus.HandoverReady)
    ) {
      options = new PickupStartedOptions(timestamp, events);
    } else if (hasOrderLocalStatus(order, OrderLocalStatus.HandoverCompleted)) {
      options = new PickupCompletedOptions(
        timestamp,
        parsedOrderItems(order.items),
        order.ageVerification
          ? this.ageVerificationData(order.ageVerification)
          : null,
        events,
      );
    } else if (hasOrderLocalStatus(order, OrderLocalStatus.HandoverAborted)) {
      options = new PickupAbortedOptions(timestamp, events, deliveryUnits);
    } else {
      return order;
    }

    const requestOption = new OrderPickingPatch(options);
    try {
      await this.api.client.patch(
        `/merchants/${String(merchantReference)}/picking-orders/${order.id}`,
        {
          data: requestOption,
        },
        {
          disableErrorHandling: true,
          innerErrorCode: SaveOrderRequestError.Code,
        },
      );
    } catch (error: unknown) {
      if (!isApiError(error)) {
        throw new UnknownError({
          ErrorReason: 'unknown',
        });
      }

      const errorObject = await this.assessErrorObjectInstance(error, order.id);

      if (errorObject === null) {
        return order;
      }

      this.loggerService.error('PATCH request error', errorObject);
      if (!isPickingAlreadyStartedError(error)) {
        this.errorHandler.handle(errorObject);
        errorObject.handled = true;
      }

      return Promise.reject(errorObject);
    }

    return order;
  }

  private cleanUpCompletedOrCancelledOrder = async (
    orderId: string,
  ): Promise<void> => {
    const ordersScheduledForSync =
      await this.syncSchedulerService.getAllSyncByEntity({
        entityId: orderId,
        entityType: 'order',
      });

    ordersScheduledForSync.forEach((syncItem) => syncItem.cancel());

    await this.storage.remove(
      Order.from({
        id: orderId,
      }),
    );

    this.loggerService.info(new PickingCancelOrCompletedError(), {
      orderId,
    });
  };

  private assessErrorObjectInstance = async (
    error: ApiClientError,
    orderId: string,
  ): Promise<null | BaseError> => {
    if (
      isAlreadyInExistedOMSState(error) &&
      !isPickingAlreadyStartedError(error)
    ) {
      return null;
    }

    if (isAlreadyCompletedOrCancelledOMSState(error)) {
      await this.cleanUpCompletedOrCancelledOrder(orderId);
      return new PickingCancelOrCompletedError();
    }

    if (isPickingAlreadyStartedError(error)) {
      return new PickingAlreadyStartedError();
    }

    if (isHandoverAlreadyStartedError(error)) {
      return new HandoverAlreadyStartedError();
    }
    if (isApiError(error)) {
      return new SaveOrderError(getErrorCode(error), getErrorDetail(error));
    }

    return new UnknownError({
      ErrorReason: 'unknown',
    });
  };
}
