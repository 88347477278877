import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { OrderParsePluginDto } from '../types';
import { OrderItemStatus } from '../types';

export class ManipulateOrderStatesPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  execute(
    dataTransferObject: OrderParsePluginDto,
  ): Promise<OrderParsePluginDto> | OrderParsePluginDto {
    dataTransferObject.order.items.forEach((item) => {
      item.quantity = item.quantityOriginal;
      item.status = OrderItemStatus.staged;
    });
    return dataTransferObject;
  }
}
