import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "banner__timestamp" }
const _hoisted_2 = {
  key: 0,
  class: "customer-information__chip"
}
const _hoisted_3 = {
  class: "banner__subtitle",
  "data-e2e": "banner-subtitle"
}
const _hoisted_4 = { "data-e2e": "checkin-customer-last-name" }

import { ref } from 'vue';
import { $t } from '@/i18n';
import { Banner, ChipTime } from '@/features/ui';
import type { Order } from '@/features/orders';
import { useCheckInBanner } from '@/features/check-in-banner/composables/useCheckInBanner';
import { CheckInBannerContextEnum } from '@/features/check-in-banner/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckInBanner',
  props: {
    context: { default: CheckInBannerContextEnum.ORDER_OVERVIEW },
    order: {},
    showBanner: { type: Boolean, default: false },
    checkinOrdersLength: {}
  },
  setup(__props: any) {

const props = __props;

const { goToCheckInOrder } = useCheckInBanner(props.context);

const turnOnPulse = ref(false);

const vibrate = () => {
  turnOnPulse.value = true;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Banner), {
    visible: _ctx.showBanner,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_unref(goToCheckInOrder)(_ctx.order))),
    class: _normalizeClass(["first_button", {
      pulsing: turnOnPulse.value,
      'banner--checkin-waiting-order': _ctx.checkinOrdersLength > 1,
      'banner--checkin-waiting-list': _ctx.checkinOrdersLength > 2,
    }]),
    "data-e2e": "checkin-banner"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.order.checkIn?.timestamp)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_unref(ChipTime), {
                time: _ctx.order.checkIn?.timestamp,
                e2e: "checkin-timestamp",
                timeAgo: "",
                smallView: "",
                onVibrate: vibrate
              }, null, 8, ["time"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_unref($t)('components.checkin-banner.title.text')), 1),
        _cache[1] || (_cache[1] = _createElementVNode("span", {
          class: "dot",
          "data-e2e": "dot"
        }, null, -1)),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.order?.customer.lastName), 1)
      ])
    ]),
    _: 1
  }, 8, ["visible", "class"]))
}
}

})