
import { computed, defineComponent, onMounted, ref } from 'vue';
import { router } from '@/features/core/router';
import { useOrder } from '@/features/picking';
import ChipTime from '@/features/ui/components/ChipTime.vue';
import Button from '@/features/ui/components/Button.vue';
import { isOrderPicking } from '@/features/orders';

export default defineComponent({
  components: {
    ChipTime,
    Button,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { order, loadOrder } = useOrder();
    const processing = ref(false);
    const isPicking = computed(() =>
      !order.value ? true : isOrderPicking(order.value),
    );

    onMounted(async () => {
      await loadOrder(props.id);
    });

    const goNext = async () => {
      if (!order.value) {
        return;
      }

      await router.get().push({
        name: 'bags-collection',
        params: {
          id: props.id,
        },
      });

      processing.value = false;
    };

    return {
      order,
      goNext,
      processing,
      isPicking,
    };
  },
});
