
import type { Ref } from 'vue';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue';
import type { LocationQueryValue } from 'vue-router';
import { useRoute } from 'vue-router';
import { Button, Dialog, Error, Popup } from '@/features/ui/components';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import {
  clickCount,
  FeatureToggleSection,
  timeToResetClickCounter,
} from '@/features/feature-toggle';
import { sessionStoragePlugin } from '@/features/session-storage';
import type { Cancellable } from '@/utils/types';
import { $t } from '@/i18n';
import { useChangeLog, useOauthLogin } from '../composables';
import type { UseOauthLoginConfig } from '../types';
import { SKIP_WAITING, UPDATE_SW } from '../types';
import { useSecretToggle } from '@/features/ui/composables';
import type { Subscription } from 'rxjs';

export default defineComponent({
  name: 'LoginPage',
  components: {
    Dialog,
    Button,
    Error,
    Popup,
    FeatureToggleSection,
  },
  setup: function () {
    const versionNumber = ref<null | HTMLElement>(null);
    const scrollTo = ref<null | HTMLElement>(null);
    const route = useRoute();
    const user = ref({
      username: '',
      password: '',
    });

    const validateQuery = (
      query: Record<string, string | LocationQueryValue[] | null>,
    ) => {
      let filteredQuery = {};
      for (let queryKey in query) {
        if (typeof query[queryKey] === 'string') {
          filteredQuery = {
            ...filteredQuery,
            [queryKey]: query[queryKey],
          };
        }
      }
      return filteredQuery;
    };
    const validQuery: UseOauthLoginConfig = validateQuery(route.query);

    const {
      loading,
      login,
      error,
      requestTokenSet,
      notify,
      notifyMessage,
      disableNotify,
    } = useOauthLogin();
    // add validate query

    const isPageRedirected = computed(() => {
      const state = sessionStoragePlugin.get().getItem('state');
      return validQuery?.state && state;
    });

    const isPageRedirectedFromLogout = computed(() => {
      return sessionStoragePlugin.get().getItem('logout');
    });

    const isPageHasSameState = computed(() => {
      const state = sessionStoragePlugin.get().getItem('state');
      return validQuery.state === state;
    });

    const {
      version: changeLogVersion,
      content: changeLogContent,
      hasContent: changeLogHasContent,
    } = useChangeLog();

    const showChangeLog = ref(false);
    const changeLogTitleText = $t('pages.login.change-log-dialog.title.text', {
      changeLogVersion,
    });
    const setChangeLogVisibility = (visible: boolean) => {
      showChangeLog.value = visible;
    };

    async function handleLogin() {
      const registration = await navigator?.serviceWorker?.getRegistration();
      if (registration) {
        registration?.active?.postMessage(UPDATE_SW);
      }

      await login();
    }

    const appVersion = process.env.VUE_APP_VERSION;

    const showReloadMessage = computed(
      () => notificationPlugin.get().isShowMessage.value,
    );

    let refreshPageTimeout: null | ReturnType<typeof setTimeout> = null;

    const refreshPageAfterUpdate = () => {
      // remove controllerchange listener in case of fired event during picking flow
      navigator.serviceWorker?.removeEventListener(
        'controllerchange',
        refreshPageAfterUpdate,
      );

      if (refreshPageTimeout) {
        clearTimeout(refreshPageTimeout);
      }
      window.location.href = window.location.href + '&version-updated=true';
    };

    const updateSW = async (): Promise<void> => {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration && registration.waiting) {
        registration.waiting.postMessage(SKIP_WAITING);
        // refresh page after SW skip waiting in case if controllerchange event wasn't fired
        refreshPageTimeout = setTimeout(() => {
          refreshPageAfterUpdate();
        }, 3000);
        // refresh page after SW skip waiting
        navigator.serviceWorker.addEventListener(
          'controllerchange',
          refreshPageAfterUpdate,
        );
      } else {
        //To prevent show update notification popup several times
        notificationPlugin.get().hideMessage();
      }
    };

    const isFeatureWindowVisible = ref(false);

    const closeFeatureWindow = () => {
      isFeatureWindowVisible.value = false;
    };

    let showNotification: Cancellable;
    const notificationData = {
      link: {
        text: 'Learn More',
        onClick: (event: Event) => {
          event.preventDefault();
          showNotification?.cancel();
          setChangeLogVisibility(true);
        },
      },
    };

    watch(changeLogHasContent, () => {
      const hasUpdated = route.query['version-updated'] === 'true';
      if (hasUpdated) {
        showNotification = notificationPlugin.get().show({
          text: $t('pages.login.app-success-updated.text'),
          type: NotificationType.Success,
          ...(changeLogHasContent.value && {
            data: notificationData,
          }),
        });
      }
    });

    const subscription: Ref<Subscription | null> = ref(null);

    onMounted(async () => {
      subscription.value = useSecretToggle(
        versionNumber.value as HTMLElement,
        'click',
        () => (isFeatureWindowVisible.value = true),
        clickCount,
        timeToResetClickCounter,
      );

      window.onbeforeunload = () => {
        if (isPageRedirectedFromLogout.value) {
          sessionStoragePlugin.get().removeItem('logout');
        }
      };
      if (isPageRedirected.value) {
        if (isPageHasSameState.value) {
          if (validQuery?.code) {
            await requestTokenSet({
              code: validQuery.code,
            });
          }
        } else {
          notificationPlugin.get().show({
            text: $t('pages.login.something-went-wrong.text'),
            type: NotificationType.Info,
            duration: -1,
          });
        }
      }
    });

    onBeforeUnmount(() => {
      if (subscription.value) {
        subscription.value.unsubscribe();
      }
    });

    return {
      user,
      error,
      handleLogin,
      loading,
      scrollTo,
      appVersion,
      showReloadMessage,
      updateSW,
      isPageRedirected,
      isPageHasSameState,
      isPageRedirectedFromLogout,
      notify,
      notifyMessage,
      disableNotify,
      isFeatureWindowVisible,
      closeFeatureWindow,
      showChangeLog,
      changeLogTitleText,
      changeLogContent,
      setChangeLogVisibility,
      versionNumber,
    };
  },
});
