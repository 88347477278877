import type { OrderItem, OrderWeight } from '@/features/orders/types';
import {
  Order,
  OrderEventNames,
  OrderItemStatus,
  OrderLocalStatus,
  ordersServicePlugin,
  parseOrderBeforeSave,
  useOnlineStatus,
} from '@/features/orders';
import { useCancelOrder } from '@/features/orders/composables/useCancelOrder';
import { emitActionChangeEvent } from '@/features/orders/plugins/emit-event-bus-status-change';
import type { Ref } from 'vue';
import { ref, unref } from 'vue';
import type { UseHandoverOrder, WeightToRestore } from '../types';
import { StatusTypes, TabVariants } from '@/features/ui/types';
import { Status } from '@/features/age-verification/types';
import { deepClone } from '@/utils/helpers/deepClone';
import { getArrayDiffWithDuplicates } from '@/utils/helpers/getArrayDiffWithDuplicates';
import { useRouter } from 'vue-router';
import { appCrashServicePlugin } from '@/features/app-crash/plugin';
import { loggerServicePlugin } from '@/features/core/logger/plugin';
import { errorPlugin } from '@/features/core/errors/plugin';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';

const order: Ref<Order | null> = ref(null);
const weightToRestore = ref<WeightToRestore>({});

export function useHandoverOrder(): UseHandoverOrder {
  const currentTab = ref<TabVariants>(TabVariants.All);
  const loading = ref<boolean>(true);
  const processing = ref<boolean>(false);
  const router = useRouter();

  const rejectOrderItem = (
    orderItem: Pick<OrderItem, 'id' | 'originalId'>,
    quantityRejected: number,
    amountRejected = 0,
    fullyRejected = false,
  ) => {
    const orderItemId = orderItem.id;
    const orderItemOriginalId = orderItem.originalId;
    if (!order.value) {
      throw new Error(
        `Order value missing while updating item with ID "${orderItemId}"`,
      );
    }
    const existingItems = order.value.items.filter(
      (item) =>
        item.id === orderItemId && item.originalId === orderItemOriginalId,
    );

    if (!existingItems.length) {
      throw new Error(
        `No existing order items with ID "${orderItemId}" found in order ${order.value.id}`,
      );
    }

    // Manually find item in order
    // This lets us not rely on `orderItem` being a direct reference
    const existingStagedItem = existingItems.find(
      (item) => item.status === OrderItemStatus.staged,
    );

    if (!existingStagedItem) {
      throw new Error(
        `Staged order item with ID "${orderItemId}" not found in order ${order.value.id}`,
      );
    }

    if (quantityRejected > 0) {
      const existingRejectedItem = existingItems?.find(
        (item) => item.status === OrderItemStatus.rejected,
      );

      handleQuantityRejectedItems(
        orderItemId,
        existingStagedItem,
        existingRejectedItem,
        quantityRejected,
        amountRejected,
        fullyRejected,
      );
    }

    handleStagedItemRejection(
      existingStagedItem,
      quantityRejected,
      amountRejected,
    );
  };

  const handleStagedItemRejection = (
    item: OrderItem,
    quantityRejected: number,
    amountRejected: number,
  ) => {
    delete item.rejectionReason;
    item.isRejected = true;
    // Prevent the quantity and amount from going negative
    item.quantity = Math.max(item.quantity - quantityRejected, 0);
    item.amount = Math.max(item.amount - amountRejected, 0);
  };

  const handleQuantityRejectedItems = (
    orderItemId: string,
    existingStagedItem: OrderItem,
    existingRejectedItem: OrderItem | undefined,
    quantityRejected: number,
    amountRejected = 0,
    fullyRejected: boolean,
  ) => {
    if (existingRejectedItem) {
      existingRejectedItem.quantity += quantityRejected;
      existingRejectedItem.amount += amountRejected;

      if (existingRejectedItem.id in weightToRestore.value) {
        const weightArray = [...weightToRestore.value[orderItemId]];
        existingRejectedItem.weights = getArrayDiffWithDuplicates(
          existingStagedItem.weights as OrderWeight[],
          weightArray,
        );
      }

      return;
    }

    const newRejectedItem = buildNewRejectedItem(
      existingStagedItem,
      quantityRejected,
      amountRejected,
      fullyRejected,
    );

    order.value?.items.push(newRejectedItem);
  };

  const buildNewRejectedItem = (
    existingStagedItem: OrderItem,
    quantityRejected: number,
    amountRejected: number,
    fullyRejected: boolean,
  ): OrderItem => {
    const newRejectedItem = deepClone(existingStagedItem);
    newRejectedItem.status = OrderItemStatus.rejected;
    newRejectedItem.quantity = quantityRejected;
    newRejectedItem.quantityOriginal = existingStagedItem.quantityOriginal;
    newRejectedItem.amount = amountRejected;
    newRejectedItem.amountOriginal = existingStagedItem.amountOriginal;
    newRejectedItem.isRejected = true;

    if (fullyRejected) {
      newRejectedItem.weights = existingStagedItem.weights
        ? [...existingStagedItem.weights]
        : [];
      existingStagedItem.weights = [];
    } else if (newRejectedItem.id in weightToRestore.value) {
      const itemWeightsToRestore = [
        ...weightToRestore.value[existingStagedItem.id],
      ];
      newRejectedItem.weights = getArrayDiffWithDuplicates(
        existingStagedItem.weights as OrderWeight[],
        itemWeightsToRestore,
      );
    }

    return newRejectedItem;
  };

  const updateOrderWeightItem = (
    orderItem: OrderItem,
    weightBefore = <OrderWeight[]>[],
    status = StatusTypes.Default,
  ) => {
    if (!order.value) {
      return;
    }
    const currentItemIndex = order.value.items.findIndex(
      (item) =>
        item.status === OrderItemStatus.staged && item.id === orderItem.id,
    );

    if (order.value && currentItemIndex >= 0) {
      const countRejected =
        order.value.items[currentItemIndex].quantity - orderItem.quantity;
      const countAmountRejected =
        order.value.items[currentItemIndex].amount - orderItem.amount;

      if (status === StatusTypes.Discard) {
        order.value.items[currentItemIndex].weights = [...weightBefore];
      } else {
        order.value.items[currentItemIndex].weights = orderItem.weights;
      }

      if (orderItem.rejectionReason) {
        order.value.items[currentItemIndex].rejectionReason =
          orderItem.rejectionReason;
      }

      if (countRejected > 0 || countAmountRejected > 0) {
        rejectOrderItem(
          order.value.items[currentItemIndex],
          countRejected,
          countAmountRejected,
        );
      }
    }
  };

  const applyOrderItem = (orderItem: OrderItem, quantity: number) => {
    const countRejected = orderItem.quantity - quantity;
    rejectOrderItem(orderItem, countRejected);
  };

  const loadOrder = async (id: string): Promise<void> => {
    const result = await ordersServicePlugin.get().getOrderById(id);

    if (result === null) {
      return;
    }

    order.value = result.value;

    if (order.value.ageVerification?.status === Status.Rejected) {
      currentTab.value = TabVariants.Rejected;
    }

    weightToRestore.value = order.value.items?.reduce(
      (acc: Record<string, OrderWeight[]>, item: OrderItem) => {
        if (
          (item.status === OrderItemStatus.staged ||
            item.status === OrderItemStatus.rejected) &&
          item.weights?.length
        ) {
          const savedWeight = Array.isArray(acc[item.id]) ? acc[item.id] : [];
          acc[item.id] = [...savedWeight, ...item.weights];
        }
        return acc;
      },
      {},
    );

    loading.value = false;
  };

  const restoreItem = (orderItem: OrderItem) => {
    if (!order.value) {
      return;
    }

    // This exists because `orderItem` may be a reference to an item in the Rejected or Changed tab
    const existingItem = order.value.items.find((item) => {
      return (
        item.isRejected &&
        item.status === OrderItemStatus.staged &&
        item.id === orderItem.id &&
        item.originalId === orderItem.originalId
      );
    });

    const index = order.value.items.findIndex((item) => {
      return (
        item.status === OrderItemStatus.rejected &&
        item.id === orderItem.id &&
        item.originalId === orderItem.originalId
      );
    });

    if (existingItem) {
      restoreExistingOrderItem(existingItem, order.value.items[index]);
      // @todo: Remove this part of code (else) if not happening, monitor on logs
    } else {
      loggerServicePlugin.get().info('Restore item not found', {
        orderId: order.value.id,
        orderItem,
      });

      order.value?.items.push({
        ...orderItem,
        status: OrderItemStatus.staged,
      });
    }

    if (index >= 0) {
      order.value?.items.splice(index, 1);
    }
  };

  const restoreExistingOrderItem = (
    orderItem: OrderItem,
    rejectedItem: OrderItem,
  ) => {
    orderItem.isRejected = false;

    delete orderItem.rejectionReason;

    orderItem.quantity += rejectedItem.quantity;
    orderItem.amount += rejectedItem.amount;
    if (orderItem.id in weightToRestore.value) {
      orderItem.amount = weightToRestore.value[orderItem.id].reduce(
        (acc, item) => acc + item.weight,
        0,
      );

      orderItem.weights = [...weightToRestore.value[orderItem.id]];
    }
  };

  const isHandoverOrderCancellable = (): boolean => {
    if (!order.value) {
      return false;
    }

    const totalQuantityByItem: { [key: string]: number } = {};

    order.value.items.forEach((item) => {
      const currentQuantity =
        totalQuantityByItem[item.id] ?? item.quantityOriginal;

      totalQuantityByItem[item.id] = isItemRemoved(item.status)
        ? currentQuantity - item.quantity
        : currentQuantity;
    });

    const totalQuantity = Object.values(totalQuantityByItem).reduce(
      (acc, quantity) => acc + quantity,
      0,
    );

    return totalQuantity === 0;
  };

  const isItemRemoved = (itemStatus: OrderItemStatus): boolean => {
    return (
      itemStatus === OrderItemStatus.rejected ||
      itemStatus === OrderItemStatus.cancelled
    );
  };

  const cancelHandover = async (): Promise<void> => {
    if (!order.value) {
      void router.push('/');
      return;
    }
    const { cancelOrder } = useCancelOrder();
    await cancelOrder(order.value);
  };

  const completeHandover = async (): Promise<void> => {
    if (!order.value) {
      void router.push('/');
      return;
    }
    let completedOrder = Object.assign({}, unref(order));

    const { startTracking } = usePerformanceTracker();
    const { isOnline } = useOnlineStatus();

    processing.value = true;
    emitActionChangeEvent('handover-completed');
    completedOrder = await ordersServicePlugin
      .get()
      .trackEvent(completedOrder, OrderEventNames.pickup_completed, {
        skipSaving: true,
      });

    completedOrder.localStatus = OrderLocalStatus.HandoverCompleted;

    try {
      loggerServicePlugin
        .get()
        .info(
          `Handover for Order ${completedOrder.id} completed ${
            isOnline.value ? '' : '(offline)'
          }`,
          {
            orderId: completedOrder.id,
            orderReference: completedOrder.orderReference,
          },
        );
      completedOrder = parseOrderBeforeSave(Order.from(completedOrder));

      await appCrashServicePlugin
        .get()
        .updateProcessedOrderData(completedOrder);

      await ordersServicePlugin
        .get()
        .saveOrder(completedOrder, true, false, false);
    } catch (error: unknown) {
      loggerServicePlugin
        .get()
        .error('Error during the completed handover operation.');
      errorPlugin.get().handle(error);
      return;
    }

    startTracking(
      `handover-order-to-order-list`,
      PerformanceThresholdEnum.ROUTE_CHANGE,
    );
    void router.push('/');
    processing.value = false;
  };

  const continueToHandover = async (): Promise<void> => {
    if (order.value === null) {
      return Promise.resolve();
    }

    const { startTracking } = usePerformanceTracker();

    startTracking(
      `customer-information-to-handover-order-${order.value.id}`,
      PerformanceThresholdEnum.ROUTE_CHANGE,
    );

    await router.push({
      name: 'handover-order',
      params: {
        id: order.value.id,
      },
    });
  };

  const goRestaging = async (): Promise<void> => {
    if (order.value === null) {
      return Promise.resolve();
    }

    const { startTracking } = usePerformanceTracker();

    await ordersServicePlugin
      .get()
      .trackEvent(order.value, OrderEventNames.restaging_started, {
        allowMultipleTracking: true,
      });

    startTracking(
      `customer-information-to-restaging-${order.value.id}`,
      PerformanceThresholdEnum.ROUTE_CHANGE,
    );

    await router.push({
      name: 'restaging',
      params: {
        id: order.value.id,
      },
    });
  };

  return {
    currentTab,
    order,
    loading,
    processing,
    applyOrderItem,
    completeHandover,
    loadOrder,
    rejectOrderItem,
    restoreItem,
    updateOrderWeightItem,
    buildNewRejectedItem,
    handleStagedItemRejection,
    isHandoverOrderCancellable,
    cancelHandover,
    continueToHandover,
    goRestaging,
  };
}
