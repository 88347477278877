import type { BaseApiClient } from '@/features/core/api';
import { SearchProductError, SearchProductWrongDataError } from '../errors';
import type {
  ProductSearchResponse,
  SearchedProduct,
  SearchProductsParams,
} from '../types';
import type { AuthService } from '@/features/core/auth';
import { errorPlugin } from '@/features/core/errors';

export class ProductsSearchApiClient {
  constructor(
    private productSearchApi: BaseApiClient,
    private authService: AuthService,
  ) {}

  async searchProducts(
    params: SearchProductsParams,
  ): Promise<SearchedProduct[] | void> {
    try {
      const merchantReference = await this.authService.getMerchantReference();

      const productsSearchResponse =
        await this.productSearchApi.client.get<ProductSearchResponse>(
          '/v3/product-search',
          {
            innerErrorCode: SearchProductError.Code,
            params: {
              servicePoint: merchantReference,
              ...params,
            },
          },
        );

      if (productsSearchResponse.data.data) {
        return productsSearchResponse.data.data;
      }

      errorPlugin.get().handle(new SearchProductWrongDataError());
    } catch (error) {
      errorPlugin.get().handle(error);
    }
  }
}
