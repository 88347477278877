import type { ApiClient } from '@/features/core/api';
import type { DeviceIdService } from '@/features/device-id';
import type { AuthService } from '@/features/core/auth';
import { LoggingMessagesRequestError } from '../errors';
import type { Logs } from '../entities';

export class LoggerApiClient {
  constructor(
    private api: ApiClient,
    private deviceIdService: DeviceIdService,
    private authService: AuthService,
  ) {
    void this.setDeviceId();
  }

  private deviceId = 'Unknown';

  async sendLogs(logs: Logs[]): Promise<void> {
    const isDeviceTokenExp = await this.authService.isDeviceTokenExpired();
    if (isDeviceTokenExp) return;
    await this.api.client.post<string>(
      '/logging-messages',
      {
        metadata: {
          appVersion: process.env.VUE_APP_VERSION,
          deviceId: this.deviceId,
        },
        messages: logs,
      },
      {
        disableErrorHandling: true,
        innerErrorCode: LoggingMessagesRequestError.Code,
        useDeviceToken: true,
        skipRequestLog: true,
      },
    );
  }

  private async setDeviceId() {
    this.deviceId = (await this.deviceIdService.getDeviceId()) || 'Unknown';
  }
}
