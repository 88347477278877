import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { OrdersService } from '@/features/orders';
import { OrderEventNames } from '@/features/orders';
import type { LoggerService } from '@/features/core/logger';
import type { RestageOrderPluginDto } from '../types';
import { EmitHandoverAbortedBusEventPlugin } from './emit-handover-aborted-bus-event';

/**
 * Track restaging_completed event
 **/
export class TrackEventRestagingCompletedPlugin
  implements PipelinePlugin<RestageOrderPluginDto>
{
  public readonly requiredPlugins = [EmitHandoverAbortedBusEventPlugin];

  constructor(
    private ordersService: OrdersService,
    private loggerService: LoggerService,
  ) {}

  public async execute(
    dataTransferObject: RestageOrderPluginDto,
  ): Promise<RestageOrderPluginDto> {
    dataTransferObject.order = await this.ordersService.trackEvent(
      dataTransferObject.order,
      OrderEventNames.restaging_completed,
      {
        allowMultipleTracking: true,
        skipSaving: true,
      },
    );

    this.loggerService.debug(
      `Events added to the order ${dataTransferObject.order.id}.`,
      {
        order: dataTransferObject.order,
      },
    );

    return dataTransferObject;
  }
}
