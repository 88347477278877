import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { Image, ScalableImage } from '@/features/ui';
import { computed } from 'vue';
import { useProductImage } from '../composables';
import type { Product } from '../entities';
import bagImage from '@/assets/images/bag.svg';
import placeholderImage from '@/assets/images/placeholder-image.svg';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductImage',
  props: {
    product: {},
    e2eTag: { default: 'pickup' },
    e2eValue: { default: null },
    scalable: { type: Boolean, default: false },
    grayscale: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const e2eAttribute = computed(
  () => `${props.e2eTag}-img-${props.e2eValue ?? props.product.sku}`,
);

const { noImage, imageSrc, isBag } = useProductImage(props.product);

return (_ctx: any,_cache: any) => {
  return (_unref(isBag))
    ? (_openBlock(), _createBlock(_unref(Image), {
        key: 0,
        image: _unref(bagImage),
        grayscale: _ctx.grayscale,
        e2e: e2eAttribute.value,
        blendedImageBackground: true
      }, null, 8, ["image", "grayscale", "e2e"]))
    : (_unref(noImage) || !_unref(imageSrc))
      ? (_openBlock(), _createBlock(_unref(Image), {
          key: 1,
          image: _unref(placeholderImage),
          grayscale: _ctx.grayscale,
          e2e: e2eAttribute.value,
          blendedImageBackground: true
        }, null, 8, ["image", "grayscale", "e2e"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.scalable)
            ? (_openBlock(), _createBlock(_unref(ScalableImage), {
                key: 0,
                imageSrc: _unref(imageSrc),
                title: _ctx.product.productName,
                imageAlt: _ctx.product.productName,
                grayscale: _ctx.grayscale,
                disableScale: false,
                e2e: `picking-img-${_ctx.product?.sku}`
              }, null, 8, ["imageSrc", "title", "imageAlt", "grayscale", "e2e"]))
            : (_openBlock(), _createBlock(_unref(Image), {
                key: 1,
                image: _unref(imageSrc),
                title: _ctx.product.productName,
                imageAlt: _ctx.product.productName,
                grayscale: _ctx.grayscale,
                e2e: e2eAttribute.value,
                blendedImageBackground: true
              }, null, 8, ["image", "title", "imageAlt", "grayscale", "e2e"]))
        ], 64))
}
}

})