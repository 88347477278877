import type { UseStart } from '../types';
import { LoadingProcess } from '../types';
import {
  pushNotificationServicePlugin,
  PushProviderPermissionError,
} from '@/features/push-notification';
import { Order, ordersServicePlugin } from '@/features/orders';
import { oauthServicePlugin } from '@/features/oauth';
import { userServicePlugin } from '@/features/user';
import { ref } from 'vue';
import { errorPlugin, HandledError } from '@/features/core/errors';
import {
  NoMerchantForUserError,
  PushPermissionError,
} from '@/features/login/errors';
import { authServicePlugin } from '@/features/core/auth';
import { configurationServicePlugin } from '@/features/configuration';
import { appServiceWorkerServicePlugin } from '@/features/service-worker';
import { BackgroundSyncTags } from '@/features/data-background-sync/types';
import { orderMetadataServicePlugin } from '@/features/orderMetadata';
import { retrySynchronisationsPlugin } from '@/features/retry-synchronisation';
import { loggerServicePlugin } from '@/features/core/logger';
import { deviceServicePlugin } from '@/features/device-info';
import { dataFetchQueueServicePlugin } from '@/features/data-fetch-queue';

export function useStart(): UseStart {
  const loadingData = ref({
    configuration: LoadingProcess.NotStarted,
    data: LoadingProcess.NotStarted,
    sync: LoadingProcess.NotStarted,
    order: LoadingProcess.NotStarted,
  });

  const configurationLoading = async () => {
    loadingData.value.configuration = LoadingProcess.Loading;
    errorPlugin.get().setVisibleErrorPopup(true);
    await configurationServicePlugin.get().fetchAllConfigurations();
    errorPlugin.get().setVisibleErrorPopup(false);
    loadingData.value.configuration = LoadingProcess.Loaded;
  };

  const pushNotificationSubscribe = async () => {
    const pushNotificationService = pushNotificationServicePlugin.get();
    pushNotificationService.init();
    await deviceServicePlugin.get().updateAndSync();
  };

  const orderLoading = async (withBackgroundFullSync = true) => {
    const orders = await ordersServicePlugin.get().fetchAllOrders();
    await orderMetadataServicePlugin.get().clearAll();
    await orderMetadataServicePlugin.get().checkAndSetOrdersMetadata(orders);

    if (withBackgroundFullSync) {
      await appServiceWorkerServicePlugin
        .get()
        .notifySync(BackgroundSyncTags.NormalBackgroundSync);

      await appServiceWorkerServicePlugin
        .get()
        .registerPeriodicSync(BackgroundSyncTags.PeriodicBackgroundSync);
    }
    return orders;
  };

  const loadPickerData = async (): Promise<string> => {
    errorPlugin.get().setVisibleErrorPopup(true);

    loadingData.value.sync = LoadingProcess.Loading;
    await pushNotificationSubscribe();
    loadingData.value.sync = LoadingProcess.Loaded;

    loadingData.value.order = LoadingProcess.Loading;
    await orderLoading();
    await dataFetchQueueServicePlugin.get().removeAllOfEntity(Order);
    errorPlugin.get().setVisibleErrorPopup(false);
    loadingData.value.order = LoadingProcess.Loaded;

    return '/';
  };

  const handleNoMerchantForUser = async (): Promise<void> => {
    await authServicePlugin.get().removeUserToken();
    errorPlugin.get().setVisibleErrorPopup(false);
    errorPlugin.get().handle(new NoMerchantForUserError());
  };

  const handlePushProviderPermissionError = async (): Promise<void> => {
    errorPlugin.get().setVisibleErrorPopup(false);
    errorPlugin.get().handle(new PushPermissionError());

    await oauthServicePlugin.get().logout('empowerId');
    await userServicePlugin.get().clearUser();
  };

  const handleDataLoadingError = async (error: Error): Promise<void> => {
    loggerServicePlugin.get().error(error);
    if (error instanceof PushProviderPermissionError) {
      await handlePushProviderPermissionError();
    } else {
      retrySynchronisationsPlugin.get().retry();
    }
  };

  const loadData = async () => {
    try {
      loadingData.value.data = LoadingProcess.Loading;
      await configurationLoading();
      const user = await userServicePlugin.get().setUserByMerchant();
      loadingData.value.data = LoadingProcess.Loaded;

      return user.merchant.reference
        ? await loadPickerData()
        : await handleNoMerchantForUser();
    } catch (error) {
      if (error instanceof HandledError) {
        return;
      }
      await handleDataLoadingError(error as Error);
    }
  };

  return {
    loadingData,
    loadData,
  };
}
