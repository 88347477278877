import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

import { toRefs } from 'vue';
import ProductIcons from '@/features/products/components/ProductIcons.vue';
import type { TemperatureClassList } from '@/features/products';


export default /*@__PURE__*/_defineComponent({
  __name: 'TemperatureClasses',
  props: {
    classes: { default: () => [] }
  },
  setup(__props: any) {

const props = __props;

const capitalize = (temperatureClass: string) =>
  temperatureClass.charAt(0).toUpperCase() + temperatureClass.slice(1);

const { classes } = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(classes), (temperatureClass, index) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "temperature-class",
      key: index
    }, [
      _createVNode(ProductIcons, {
        class: "temperature-class__image",
        name: capitalize(temperatureClass),
        "data-e2e": capitalize(temperatureClass),
        width: "26"
      }, null, 8, ["name", "data-e2e"])
    ]))
  }), 128))
}
}

})