import type { SalteConfig } from '@/features/oauth/types';
import { buildUrl } from '@/utils/helpers/buildUrl';
import { getUUID } from '@/utils/helpers/getUUID';
import { sessionStoragePlugin } from '@/features/session-storage';

export class OauthClient {
  public userToken: string | undefined = undefined;

  constructor(private config: SalteConfig) {}

  login(): void {
    const state = getUUID('empowerId-state');
    const nonce = getUUID('empowerId-nonce');
    sessionStoragePlugin.get().setItem('state', state);

    const url = buildUrl(`${this.config.url}`, {
      client_id: this.config.clientID,
      response_type: this.config.responseType,
      redirect_uri: this.config.redirectUrl,
      scope: this.config.scope as string,
      state,
      nonce,
      grant_type: this.config.grant_type,
      code_challenge_method: this.config.code_challenge_method as string,
      code_challenge: this.config.code_challenge as string,
    });

    this.navigate(url);
  }

  logout(): void {
    const url = buildUrl(`${this.config.logoutUri}`, {
      post_logout_redirect_uri: this.config.redirectUrl,
      global_logout: true,
      client_id: this.config.clientID,
      id_token_hint: this.userToken as string,
    });

    this.navigate(url);
  }

  private navigate(url: string): void {
    location.href = url;
  }
}
