import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "item"
}
const _hoisted_2 = ["data-e2e"]
const _hoisted_3 = ["data-e2e"]

import type { PropType } from 'vue';
import type { FeatureToggle } from '../types';
import { CheckboxToggler } from '@/features/ui';

enum Events {
  Change = 'update:modelValue',
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ToggleItem',
  props: {
  e2e: {
    type: String,
    default: 'feature',
  },
  feature: {
    type: Object as PropType<FeatureToggle>,
    required: true,
  },
},
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (__props.feature)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", {
          class: "title",
          "data-e2e": `feature-title-${__props.e2e}`
        }, _toDisplayString(__props.feature.title), 9, _hoisted_2),
        _createVNode(_unref(CheckboxToggler), {
          "model-value": __props.feature.active,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit(Events.Change, $event))),
          "data-e2e": `feature-active-${__props.e2e}`
        }, null, 8, ["model-value", "data-e2e"]),
        (__props.feature.description)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              "data-e2e": `feature-description-${__props.e2e}`
            }, _toDisplayString(__props.feature.description), 9, _hoisted_3))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})