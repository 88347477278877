import { ProviderPluginFactory } from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { DataSyncServiceImplementation } from './data-sync-service';
import type { DataSyncServiceConfig } from '../types';

export * from './data-sync-service';

export const dataSyncServicePlugin = ProviderPluginFactory.create<
  DataSyncServiceImplementation,
  DataSyncServiceConfig
>({
  key: Symbol('DataSyncService'),
  defaultFactory: {
    create: (app, options) => {
      return new DataSyncServiceImplementation(options, storagePlugin.get());
    },
  },
});
