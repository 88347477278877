import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "weight-list--item" }
const _hoisted_2 = {
  key: 0,
  class: "weight-list--item-barcode",
  "data-e2e": `weight-list--item-barcode`
}
const _hoisted_3 = {
  key: 1,
  class: "weight-list--item-input",
  "data-e2e": "weight-list--item-input"
}
const _hoisted_4 = ["data-e2e"]
const _hoisted_5 = ["data-e2e"]
const _hoisted_6 = {
  class: "weight-list--item-action",
  "data-e2e": "weight-list-add-item-barcode"
}

import { computed, toRefs } from 'vue';
import Input from './Input.vue';
import {
  BarcodeScannerIcon,
  DownArrowCurvingLeft,
  PlusIcon,
  TrashIcon,
} from '../icons';
import {
  isControlCharacter,
  isValidPositiveFloatNumber,
  parseFloatLikeInput,
} from '@/features/ui/helpers';
import { isOverReplacementThreshold } from '@/features/orders';

enum Events {
  Insert = 'insert',
  Remove = 'remove',
  Change = 'change',
  ScanBarcode = 'scanBarcode',
}

interface Emits {
  (eventName: Events.Insert, weight: number): void;
  (eventName: Events.Remove): void;
  (
    eventName: Events.Change,
    index: number,
    weight: number,
    relatedBarcode: string,
  ): void;
  (eventName: Events.ScanBarcode): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'WeightListItem',
  props: {
  weight: {
    type: Number,
    required: true,
  },
  relatedBarcode: {
    type: String,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  editable: {
    type: Boolean,
    default: false,
  },
  isFocused: {
    type: Boolean,
    required: false,
  },
  barcodeRelatedProduct: {
    type: Boolean,
    default: false,
  },
  isReplacement: {
    type: Boolean,
    default: false,
  },
  unit: String,
},
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { weight, relatedBarcode, barcodeRelatedProduct, isReplacement } =
  toRefs(props);

const addWeight = () => {
  emit(Events.Insert, weight.value);
};

const removeWeight = () => {
  emit(Events.Remove);
};

const scanBarcode = () => {
  emit(Events.ScanBarcode);
};

const onChangeWeight = (changedWeight: string) => {
  emit(
    Events.Change,
    props.index,
    parseFloatLikeInput(changedWeight),
    relatedBarcode.value,
  );
};

const weightFixed = computed(() =>
  Number.isInteger(weight.value) ? weight.value : weight.value.toFixed(2),
);
const isValid = computed(
  () => !isReplacement.value || !isOverReplacementThreshold(weight.value),
);

const fillColor = computed(() => (weight.value > 0 ? '#0D3A93' : '#D6D8DA'));

const onKeydown = (event: KeyboardEvent) => {
  if (isControlCharacter(event.key)) {
    return;
  }

  if (
    !isValidPositiveFloatNumber(
      `${(event.target as HTMLInputElement).value}${event.key}`,
    )
  ) {
    event.preventDefault();
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(_ctx.$t('pages.weight-list.split-label.text', { nth: __props.index + 1 })), 1),
    (!_unref(relatedBarcode).length && _unref(barcodeRelatedProduct))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", null, [
            _createVNode(_unref(BarcodeScannerIcon), { width: "24" }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('pages.weight-list.scan-item.text')), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(relatedBarcode).length || !_unref(barcodeRelatedProduct))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (__props.editable)
            ? (_openBlock(), _createBlock(Input, {
                key: 0,
                id: `${__props.index}`,
                md: true,
                "model-value": _unref(weight) || '',
                onInput: _cache[0] || (_cache[0] = (e) => onChangeWeight(e.target.value)),
                onChange: addWeight,
                textClearable: false,
                type: "text",
                "data-e2e": `weight-input-${__props.index + 1}`,
                autofocus: false,
                onKeydown: onKeydown,
                autocomplete: "off",
                inputmode: "numeric",
                valid: isValid.value
              }, null, 8, ["id", "model-value", "data-e2e", "valid"]))
            : (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: _normalizeClass(['weight-list--item-output', { invalid: !isValid.value }]),
                "data-e2e": `weight-output-${__props.index + 1}`
              }, _toDisplayString(weightFixed.value), 11, _hoisted_4)),
          _createElementVNode("span", {
            class: "weight-list--item-unit",
            "data-e2e": `weight-unit-${__props.index + 1}`
          }, _toDisplayString(__props.unit?.toLowerCase()), 9, _hoisted_5)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      (__props.editable && (_unref(relatedBarcode).length || !_unref(barcodeRelatedProduct)))
        ? (_openBlock(), _createBlock(_unref(DownArrowCurvingLeft), {
            key: 0,
            onClick: addWeight,
            "data-e2e": `add-weight-button-${__props.index + 1}`,
            fill: fillColor.value
          }, null, 8, ["data-e2e", "fill"]))
        : _createCommentVNode("", true),
      (!__props.editable)
        ? (_openBlock(), _createBlock(_unref(TrashIcon), {
            key: 1,
            "data-e2e": `remove-weight-button-${__props.index + 1}`,
            onClick: removeWeight
          }, null, 8, ["data-e2e"]))
        : _createCommentVNode("", true),
      (!_unref(relatedBarcode).length && _unref(barcodeRelatedProduct))
        ? (_openBlock(), _createBlock(_unref(PlusIcon), {
            key: 2,
            width: "24",
            "data-e2e": `scan-barcode-button-${__props.index + 1}`,
            onClick: scanBarcode
          }, null, 8, ["data-e2e"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})