import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/icons/CheckboxChecked.svg'
import _imports_1 from '@/assets/images/icons/CheckboxUnChecked.svg'


const _hoisted_1 = ["data-e2e", "aria-disabled"]
const _hoisted_2 = {
  class: "checkbox-input__icon",
  "data-e2e": `checkbox-icon`
}
const _hoisted_3 = {
  key: 0,
  src: _imports_0
}
const _hoisted_4 = {
  key: 1,
  src: _imports_1
}

import { toRefs } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'CheckboxInput',
  props: {
    label: {},
    checked: { type: Boolean },
    e2eDescription: {},
    disabled: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const { label, checked, e2eDescription, disabled } = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["checkbox-input", { disabled: _unref(disabled) }]),
    "data-e2e": _unref(e2eDescription),
    "aria-disabled": _unref(disabled)
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_unref(checked))
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : _createCommentVNode("", true),
      (!_unref(checked))
        ? (_openBlock(), _createElementBlock("img", _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, _toDisplayString(_unref(label)), 1)
  ], 10, _hoisted_1))
}
}

})