import type { DeviceFeedback, DeviceFeedbackServiceInterface } from '../types';

export class DeviceFeedbackService implements DeviceFeedbackServiceInterface {
  private isTriggering = false;

  async trigger(feedbacks: DeviceFeedback[]): Promise<void> {
    if (this.isTriggering) {
      return;
    }

    this.isTriggering = true;
    for (const feedback of feedbacks) {
      await feedback.trigger();
    }
    this.isTriggering = false;
  }
}
