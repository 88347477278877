import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "credits-content" }
const _hoisted_2 = { class: "credits-content-header" }
const _hoisted_3 = { class: "credits-content-body" }

import { ref } from 'vue';
import useCredits from '../composables/useCredits';

export default /*@__PURE__*/_defineComponent({
  __name: 'Credits',
  setup(__props) {

const { list } = useCredits();

const showCredits = ref(false);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _mergeProps(_ctx.$attrs, {
      class: "credits-button",
      "data-e2e": "credits-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (showCredits.value = !showCredits.value))
    }), _toDisplayString(_ctx.$t('components.feature-toggle.credits.title.text')), 17),
    (showCredits.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          "data-e2e": "credits-overlay",
          class: "credits-wrapper",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (showCredits.value = !showCredits.value))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('components.feature-toggle.credits.subtitle.text')), 1)
            ]),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (i) => {
                return (_openBlock(), _createElementBlock("span", { key: i }, _toDisplayString(i), 1))
              }), 128))
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "credits-content-footer" }, [
              _createElementVNode("svg", {
                width: "32",
                height: "32",
                viewBox: "0 0 24 24"
              }, [
                _createElementVNode("path", {
                  fill: "currentColor",
                  d: "M9 2H5v2H3v2H1v6h2v2h2v2h2v2h2v2h2v2h2v-2h2v-2h2v-2h2v-2h2v-2h2V6h-2V4h-2V2h-4v2h-2v2h-2V4H9V2zm0 2v2h2v2h2V6h2V4h4v2h2v6h-2v2h-2v2h-2v2h-2v2h-2v-2H9v-2H7v-2H5v-2H3V6h2V4h4z"
                })
              ])
            ], -1))
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "credits-background" }, null, -1))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})