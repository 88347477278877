import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "filters" }
const _hoisted_3 = ["data-e2e", "onClick"]
const _hoisted_4 = ["data-e2e"]

import type {
  FilterRecordCount,
  HandoverFilterRecord,
} from '@/features/ui/types';
import { TabVariants } from '@/features/ui/types';
import { watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'RowFilter',
  props: {
    filters: {},
    currentTab: { default: TabVariants.NotPicked },
    filterQuantity: {},
    hiddenFilters: {}
  },
  emits: ['changeTab'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

watch(
  () => props.hiddenFilters,
  () => {
    if (props.hiddenFilters?.includes(props.currentTab)) {
      emit('changeTab', Object.keys(props.filters)[0]);
    }
  },
);

const onSelectTab = (tab: string) => {
  emit('changeTab', tab);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (item, key) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
          (!_ctx.hiddenFilters?.includes(key))
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: _normalizeClass(["filters__item", {
            filters__item_active: item.value.toString() === _ctx.currentTab,
          }]),
                "data-e2e": `header-tab-${item?.value}`,
                onClick: ($event: any) => (onSelectTab(item.value))
              }, [
                _createTextVNode(_toDisplayString(item.label) + " ", 1),
                (_ctx.filterQuantity && _ctx.filterQuantity.hasOwnProperty(item.value))
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      "data-e2e": `tab-count-label-${item.value}`
                    }, " (" + _toDisplayString(_ctx.filterQuantity[item.value]) + ") ", 9, _hoisted_4))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_3))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ]))
}
}

})