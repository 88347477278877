
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    width: { type: [String, Number], default: 20 },
    height: { type: [String, Number], default: 20 },
    viewBox: { type: String, default: '0 0 20 20' },
    iconName: { type: String, default: 'box' },
    iconFill: { type: String, default: '#0d3a93' },
    iconStroke: { type: String, default: '#0d3a93' },
  },
});
