import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-e2e", "onUpdate:modelValue"]

import type { Ref } from 'vue';
import { onMounted, ref, watch } from 'vue';

type InputKeyboardEvent = KeyboardEvent & { target: HTMLInputElement };


export default /*@__PURE__*/_defineComponent({
  __name: 'MultipleInputsCode',
  props: {
    digitsAmount: { default: 4 }
  },
  emits: ["validate", "focus", "blur"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const inputRefs: Ref<HTMLElement[] | null[]> = ref([]);
const inputsValue: Ref<string[]> = ref([]);
const inputsState = ref('');

onMounted(() => {
  inputRefs.value[0]?.focus();
});

const onInputChange = (event: InputKeyboardEvent) => {
  const input = event.target;
  if (
    input.value &&
    input.nextElementSibling &&
    input.nextElementSibling instanceof HTMLInputElement
  ) {
    input.nextElementSibling.focus();
  }
};

const onInputDelete = (event: InputKeyboardEvent) => {
  const input = event.target;
  if (
    !input.value &&
    input.previousElementSibling &&
    input.previousElementSibling instanceof HTMLInputElement
  ) {
    input.previousElementSibling.focus();
  }
};

const clearInputs = () => {
  inputsValue.value.splice(0);
  inputRefs.value[0]?.focus();
};

const indicateValidation = (state: 'success' | 'error') => {
  inputsState.value = state;
  setTimeout(() => {
    inputsState.value = '';
    clearInputs();
  }, 300);
};

watch(inputsValue.value, () => {
  if (inputsValue.value.length === props.digitsAmount) {
    const inputsHasEmptyValues = inputsValue.value.includes('');
    if (!inputsHasEmptyValues) {
      emit('validate', inputsValue.value.join(''));
    }
  }
});

__expose({ indicateValidation, inputsState });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['multiple-inputs-form', inputsState.value])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.digitsAmount, (inputIndex) => {
      return _withDirectives((_openBlock(), _createElementBlock("input", {
        key: inputIndex,
        "data-e2e": `multiple-input-${inputIndex}`,
        class: "multiple-input",
        type: "text",
        maxlength: "1",
        inputmode: "numeric",
        ref_for: true,
        ref: (element) => inputRefs.value.push(element),
        "onUpdate:modelValue": ($event: any) => ((inputsValue.value[inputIndex - 1]) = $event),
        onInput: onInputChange,
        onKeydown: _withKeys(onInputDelete, ["delete"]),
        onFocus: _cache[0] || (_cache[0] = ($event: any) => (emit('focus'))),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (emit('blur')))
      }, null, 40, _hoisted_1)), [
        [_vModelText, inputsValue.value[inputIndex - 1]]
      ])
    }), 128))
  ], 2))
}
}

})