import type {
  Entity,
  EntityType,
  RepositoryReadOptions,
} from '@/features/core/entity-repository';
import type { StorageReadIdOptions } from '@/features/core/storage';
import type { DataFetchQueue } from '../entities';

export interface DataFetchQueueService {
  addToFetchQueue(
    id: string,
    entity: EntityType<Entity>,
    retries?: number,
    fcmMessageId?: string[],
  ): Promise<DataFetchQueue>;

  batchAddToFetchQueueIfNotIncluded(
    ids: string[] | undefined,
    entity: EntityType<Entity>,
    fcmMessageId?: string[],
  ): Promise<void>;

  getStoredEntities(options?: RepositoryReadOptions): Promise<string[]>;

  getAll(options?: RepositoryReadOptions): Promise<DataFetchQueue[]>;

  getById(options: StorageReadIdOptions): Promise<DataFetchQueue | undefined>;

  updateAllRetriesOfEntity(entity: EntityType<Entity>): Promise<void>;

  removeAllOfEntity(entity: EntityType<Entity>): Promise<void>;

  isEmpty(): Promise<boolean>;

  triggerSyncForOutstandingEntities(): Promise<void>;
}

export const dataFetchQueueFilters = {
  excludeExceededRetries: { retries: { anyOf: [0, 1, 2] } },
  includeExceededRetries: { retries: { noneOf: [0, 1, 2] } },
};
