
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import Button from '@/features/ui/components/Button.vue';
import type { Order } from '@/features/orders';
import { BackButton, AppHeader } from '@/features/ui';

export default defineComponent({
  name: 'PrintBlock',
  components: { Button, BackButton, AppHeader },
  emits: ['buttonClick', 'print'],
  props: {
    order: {
      type: Object as PropType<Order>,
      default: () => {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const buttonClick = () => emit('buttonClick');
    const print = () => emit('print');

    return {
      buttonClick,
      print,
    };
  },
});
