import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-e2e"]
const _hoisted_2 = ["data-e2e"]
const _hoisted_3 = { class: "bags-quantity__block" }
const _hoisted_4 = ["data-e2e"]

import type { PropType } from 'vue';
import { toRefs } from 'vue';
import type { DeliveryUnit } from '@/features/delivery-unit';
import CheckboxInput from './CheckboxInput.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'BagWithPosition',
  props: {
  position: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  deliveryUnit: {
    type: Object as PropType<DeliveryUnit>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  checked: {
    type: Boolean,
    default: false,
  },
  manuallyPlaced: {
    type: Boolean,
    default: false,
  },
},
  emits: ['toggleBagsPosition'],
  setup(__props, { emit: __emit }) {

const props = __props;
const { deliveryUnit, title, disabled, checked } = toRefs(props);

const emit = __emit;
const toggleBagsPosition = () => {
  emit('toggleBagsPosition', {
    deliveryUnit: deliveryUnit.value,
    checked: !checked.value,
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "bags__item",
    "data-e2e": `bags-item-${__props.position}`
  }, [
    _createVNode(CheckboxInput, {
      type: "checkbox",
      class: "frame__input",
      disabled: _unref(disabled),
      checked: _unref(checked),
      onClick: toggleBagsPosition,
      e2eDescription: `bags-position-checkbox-${__props.position}`
    }, null, 8, ["disabled", "checked", "e2eDescription"]),
    _createElementVNode("h3", {
      "data-e2e": `bags-name-label-${__props.position}`,
      class: _normalizeClass(["bags__item__title", { 'bags__item__title--text': __props.manuallyPlaced }])
    }, _toDisplayString(_unref(title)), 11, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", {
        "data-e2e": `bags-${__props.position}-quantity-text`,
        class: "bags-quantity__value text-bold"
      }, _toDisplayString(_unref(deliveryUnit).scannedBarcode?.length || 0) + " of " + _toDisplayString(_unref(deliveryUnit).totalCount), 9, _hoisted_4)
    ])
  ], 8, _hoisted_1))
}
}

})