import type { ApiClient } from '@/features/core/api';
import { retryFunction } from '@/utils/helpers/retryFunction';

export class AcknowledgeCheckInNotificationService {
  constructor(private api: ApiClient) {}

  async sendAcknowledgeRequest(orderId: string): Promise<void> {
    try {
      await this.apiRequest(orderId);
    } catch (error) {
      await retryFunction(() => this.apiRequest(orderId), 6, [10000]);
    }
  }

  private async apiRequest(orderId: string): Promise<void> {
    await this.api.client.delete(`/order-checkin-renotify/${orderId}`, {
      disableErrorHandling: true,
    });
  }
}
