import type { Ref } from 'vue';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { OrderPluginDto } from '../types';

export class BooleanSwitcherPlugin implements PipelinePlugin<OrderPluginDto> {
  constructor(private switcher: Ref<boolean>, private value?: boolean) {}
  public execute(dataTransferObject: OrderPluginDto): OrderPluginDto {
    this.switcher.value =
      this.value !== undefined ? this.value : !this.switcher.value;
    return dataTransferObject;
  }
}
