import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import router from '@/features/core/router';
import { oauthServicePlugin } from '@/features/oauth';
import { $t } from '@/i18n';
import { errorPlugin } from '@/features/core/errors';
import { authServicePlugin } from '@/features/core/auth';
import { loggerServicePlugin } from '@/features/core/logger';
import { oauthLoginApiClientPlugin } from '../api';
import type { OauthLoginAuthResponse, UseOauthLoginError } from '../types';

const isSameMerchant = ref(false);
const skipList = ['foldername'];

type UseOauthLogin = {
  loading: Ref<boolean>;
  error: Ref<UseOauthLoginError | null>;
  login: () => Promise<void>;
  notify: Ref<boolean>;
  notifyMessage: Ref<string>;
  requestTokenSet: (params: OauthLoginAuthResponse) => Promise<void>;
  disableNotify: () => Promise<void>;
  isSameMerchant: Ref<boolean>;
};

export function useOauthLogin(): UseOauthLogin {
  const _loading = ref(false);
  const _error = ref<UseOauthLoginError | null>(null);
  const _notify = ref(false);
  const _notifyMessage = ref(
    $t('errors.login.not-assigned-to-correct-roles.text'),
  );

  const login = async () => {
    try {
      document.cookie.split(';').forEach(function (c) {
        const cookie = c.replace(/^ +/, ''); // Trim leading spaces
        const cookieName = cookie.split('=')[0];

        // Check if the cookie is in the skip list
        if (!skipList.includes(cookieName)) {
          document.cookie = cookie.replace(
            /=.*/,
            '=;expires=' + new Date().toUTCString() + ';path=/',
          );
        }
      });
      const authService = oauthServicePlugin.get();
      await authService.login('empowerId');
    } catch (error) {
      errorPlugin.get().handle(error);
    }
  };

  const requestTokenSet = async (params: OauthLoginAuthResponse) => {
    try {
      _loading.value = true;
      const auth = oauthLoginApiClientPlugin.get();
      const merchantRef = await authServicePlugin.get().getMerchantReference();

      const token = await auth.getEmpowerIDToken({
        ...params,
        grant_type: 'authorization_code',
      });

      await oauthServicePlugin.get().setToken(token.id_token);
      await oauthServicePlugin.get().setRefreshToken(token.refresh_token);

      const roles = await authServicePlugin.get().getUserRoles();
      const merchantReference = await authServicePlugin
        .get()
        .getMerchantReference();
      isSameMerchant.value = merchantRef === merchantReference;

      if (
        roles &&
        roles.length &&
        merchantReference &&
        merchantReference.length
      ) {
        const deviceToken = await oauthLoginApiClientPlugin
          .get()
          .getDeviceToken();
        await oauthServicePlugin.get().setDeviceToken(deviceToken);
        const redirectUrl = sessionStorage.getItem('post_login_redirect_url');
        if (redirectUrl) {
          sessionStorage.removeItem('post_login_redirect_url');
          await router.get().push(redirectUrl);
        } else {
          await router.get().push({
            name: 'start',
          });
        }
        loggerServicePlugin.get().info(`User logged in`);
      } else {
        _notify.value = true;
        if (!merchantReference) {
          _notifyMessage.value = $t('errors.login.not-assigned-to-store.text');
        }
      }
    } catch (error: unknown) {
      errorPlugin.get().handle(error);
    }
  };

  const disableNotify = async () => {
    _notify.value = false;
    await oauthServicePlugin.get().logout('empowerId');
    _loading.value = false;
  };

  const loading = computed<boolean>(() => _loading.value);
  const error = computed<UseOauthLoginError | null>(() => _error.value);
  const notify = computed<boolean>(() => _notify.value);
  const notifyMessage = computed<string>(() => _notifyMessage.value);

  return {
    loading,
    error,
    login,
    notify,
    notifyMessage,
    requestTokenSet,
    disableNotify,
    isSameMerchant,
  };
}
