import { eventBusServicePlugin } from '@/features/core/event-bus';
import { loggerServicePlugin } from '@/features/core/logger';
import {
  ExecPluginFactory,
  MultiPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { notificationPlugin } from '../notifications';
import type { ErrorHandler } from './error-handler';
import { ErrorHandlerImpl } from './error-handler';

export const errorPlugin = ProviderPluginFactory.create<ErrorHandler>({
  key: Symbol('ErrorHandlerImpl'),
  defaultFactory: {
    create: () =>
      new ErrorHandlerImpl(
        notificationPlugin.get(),
        eventBusServicePlugin.get(),
      ),
  },
});

export const errorConfigPlugin = MultiPluginFactory.with({
  errorPlugin,
});

export const registerLoggerServicePlugin = ExecPluginFactory.fn(() => {
  errorPlugin.get().registerLoggerService(loggerServicePlugin.get());
});
