import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-e2e"]
const _hoisted_2 = { class: "product-card__image" }
const _hoisted_3 = { class: "product-card__body" }
const _hoisted_4 = ["data-e2e"]
const _hoisted_5 = { class: "product-card__action" }

import 'vue';
import { ChevronRight } from '@/features/ui/icons/';
import { Image } from '@/features/ui';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductSearchCard',
  props: {
  image: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  sku: {
    type: String,
    required: true,
  },
  barcodes: {
    type: Array,
    required: true,
  },
},
  emits: ['onAction'],
  setup(__props, { emit: __emit }) {



const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "card product-card",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('onAction', __props.sku))),
    "data-e2e": `product-card-${__props.sku}`
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Image), {
        image: __props.image,
        title: __props.name,
        e2e: `product-card-image-${__props.sku}`
      }, null, 8, ["image", "title", "e2e"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h1", {
        class: "product-card__name",
        "data-e2e": `product-card-name-${__props.sku}`
      }, _toDisplayString(__props.name), 9, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_unref(ChevronRight))
    ])
  ], 8, _hoisted_1))
}
}

})