import type { Product } from '../entities';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import { getSizedImageSrc } from '@/utils/helpers/getSizedImageSrc';

interface ProductImageData {
  imageSrc: ComputedRef<string>;
  isBag: ComputedRef<boolean>;
  noImage: ComputedRef<boolean>;
}

export const useProductImage = (product: Product): ProductImageData => {
  const imageSrc = computed(() => getSizedImageSrc(product.image));
  const isBag = computed(() => product.isBag);
  const noImage = computed(() => product.noImage);

  return {
    imageSrc,
    isBag,
    noImage,
  };
};
