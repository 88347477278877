import type { ConfigurationService } from '@/features/configuration';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type {
  OrderBag,
  OrderItemRaw,
  OrderItemResponse,
  OrderParsePluginDto,
} from '../types';

/**
 * Get available bags from configuration
 * Get items, which are bags
 * Store bagItems to order in 'bag' property if there are bag items
 * Store configuration bag ids to order in 'bag' property if there are no bag items
 **/
export class AddBagsToOrderPlugin
  implements PipelinePlugin<OrderParsePluginDto>
{
  constructor(private configurationService: ConfigurationService) {}

  public async execute(
    dataTransferObject: OrderParsePluginDto,
  ): Promise<OrderParsePluginDto> {
    const bagsIds = (
      await this.configurationService.getFeatureOption(
        'bags',
        'availableBags',
        'array',
      )
    ).map((i) => ({
      sku: i.split(':')[1],
      name: i.split(':')[0],
      quantity: 0,
    }));

    const bags = this.gettingBagsItem(dataTransferObject.rawItems, bagsIds);

    dataTransferObject.order.bags = bags.length ? bags : bagsIds;

    return dataTransferObject;
  }

  private gettingBagsItem(
    items: OrderItemRaw[] | OrderItemResponse[],
    bagsIds: OrderBag[],
  ): OrderBag[] {
    return (items as Array<OrderItemRaw | OrderItemResponse>)
      .filter((item) => item.id.startsWith('bags_'))
      .map((item) => {
        const sku = 'product' in item ? item.product : item.sku;
        return {
          sku,
          quantity: item.quantity,
          name: bagsIds.filter((bag) => bag.sku === sku)[0]?.name,
        };
      });
  }
}
