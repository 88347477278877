
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { $t } from '@/i18n';
import { useOrder } from '@/features/picking';
import { configurationServicePlugin } from '@/features/configuration';
import { formatHours, formatDate } from '@/composables/useDateFormat';
import { QRcode } from '@/features/ui/components';
import { getQrData } from '../helpers/printLabel';
import { useOrderLabels } from '../composables';
import { PrintBlock } from '../components';

export default defineComponent({
  name: 'LabelPrinter',
  components: { PrintBlock, QRcode },

  setup() {
    const { order, loadOrder } = useOrder();
    const { params } = useRoute();
    const { labels } = useOrderLabels(order);

    const printLabels = () => {
      if (process.env.VUE_APP_CI === undefined) {
        window.print();
      }
    };

    const closeWindow = () => window.close();

    onMounted(async () => {
      await loadOrder(params.id as string);
    });

    const orderTime = computed(() =>
      formatHours(new Date(String(order.value?.startTime))),
    );
    const orderDate = computed(() =>
      formatDate(new Date(String(order.value?.startTime))),
    );

    let qrsLoadedAmount = 0;

    const onQrLoad = () => {
      if (++qrsLoadedAmount === labels.value.length) {
        printLabels();
      }
    };

    const getTranslation = (key: string, named = {}): string => {
      return $t(key, named);
    };

    // #region Customer Last Name on label
    const isCustomerLastNameVisible = ref(false);
    const configurationService = configurationServicePlugin.get();
    onMounted(async () => {
      isCustomerLastNameVisible.value =
        await configurationService.getFeatureOption(
          'printingStorageLabels',
          'customerLastNameOnLabel',
          'boolean',
        );
    });
    // #endregion

    return {
      labels,
      order,
      getQrData,
      orderTime,
      orderDate,
      onQrLoad,
      printLabels,
      closeWindow,
      getTranslation,
      isCustomerLastNameVisible,
    };
  },
});
