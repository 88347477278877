import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["checked", "value"]

enum Events {
  Change = 'update:modelValue',
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CheckboxToggler',
  props: {
  modelValue: {
    type: Boolean,
    required: true,
  },
  big: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: false,
  },
},
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['checkbox-toggler', { 'checkbox-toggler--with-label': !!__props.label }])
  }, [
    _createElementVNode("label", {
      class: _normalizeClass(['wrapper', { big: __props.big }])
    }, [
      _createElementVNode("input", {
        checked: __props.modelValue,
        value: __props.modelValue ? 1 : 0,
        class: "checkbox",
        "data-e2e": "checkbox-input",
        hidden: "",
        type: "checkbox",
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit(Events.Change, !__props.modelValue)))
      }, null, 40, _hoisted_1),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "toggler-slider" }, null, -1)),
      _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "toggler-knob",
        "data-e2e": "checkbox-button"
      }, null, -1))
    ], 2),
    (__props.label)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(__props.label), 1)
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}
}

})