import type { DeviceFeedback } from './index';
import { sumDurations } from '../helpers';

//https://developer.mozilla.org/en-US/docs/Web/API/Vibration_API
//Note: Vibration is not supported on any browser on Mac
export class VibrationFeedback implements DeviceFeedback {
  constructor(private vibrationPattern: number[] | number) {}

  trigger(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        if ('vibrate' in navigator) {
          navigator.vibrate(this.vibrationPattern);

          //This setTimeout prevents to start a new vibration while the current is not finished.
          setTimeout(() => {
            resolve();
          }, sumDurations(this.vibrationPattern));
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}

export class VibrationOnce extends VibrationFeedback {
  constructor() {
    super(300);
  }
}
