import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/print-labels@x2.png'


const _hoisted_1 = {
  key: 2,
  class: "note fixed-footer-content fixed-footer-content--extra-padding"
}
const _hoisted_2 = { class: "note__body" }
const _hoisted_3 = { class: "note__header" }
const _hoisted_4 = {
  class: "note__title",
  "data-e2e": "labels-screen-title"
}
const _hoisted_5 = { class: "labels-list" }
const _hoisted_6 = {
  key: 3,
  class: "footer fixed-footer-content__footer"
}
const _hoisted_7 = { class: "footer__info" }
const _hoisted_8 = {
  class: "row__label row--accent",
  "data-e2e": "labels-label-needed-title"
}
const _hoisted_9 = {
  class: "row__value row--accent",
  "data-e2e": "labels-count-label"
}
const _hoisted_10 = { class: "footer__info" }
const _hoisted_11 = {
  class: "row__label row--sub-accent",
  "data-e2e": "labels-labels-needed-title"
}
const _hoisted_12 = {
  class: "row__value row--sub-accent",
  "data-e2e": "labels-count-bags"
}
const _hoisted_13 = { class: "ut-font_fix" }

import { onMounted, ref } from 'vue';
import { $t } from '@/i18n';
import {
  AppHeader,
  BackButton,
  Button,
  Label,
  ScreenLoading,
} from '@/features/ui';
import { configurationServicePlugin } from '@/features/configuration';
import { useOrderLabels } from '@/features/label-print/composables';
import { deliveryUnitServicePlugin } from '@/features/delivery-unit';
import { ordersServicePlugin } from '@/features/orders';
import { useDynamicDialog } from '@/features/ui/composables/useDynamicDialog';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';
import { useOrder as usePickingOrder } from '@/features/picking';
import { PrinterFeatureOptions } from '../types';
import { useOrder } from '..';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssigningLabels',
  props: {
  id: { type: String, required: true },
},
  setup(__props) {

const props = __props;

const { order, boxBagsNeeded, loadOrder, setNumberOfLabels, loading } =
  useOrder();
const { redirectToPrintPage } = useOrderLabels(order);
const { startTracking, stopTracking } = usePerformanceTracker();
const { redirectIfOrderNotPicking } = usePickingOrder();
const processing = ref(false);

let confirmationButtonLabel = $t(
  'pages.assign-labels.confirmation-button-label.print-label',
);

onMounted(async () => {
  await loadOrder(props.id);
  stopTracking(`review-orders-to-picking-labels-${props.id}`);
  await redirectIfOrderNotPicking(order.value);
  setNumberOfLabels(0);
  await setConfirmationButtonLabel();
});

const setConfirmationButtonLabel = async () => {
  const isPrintingFeatureActive = (
    await configurationServicePlugin
      .get()
      .isFeatureActive('printingStorageLabels')
  ).value;

  if (isPrintingFeatureActive) {
    const printerFeatureOption = await configurationServicePlugin
      .get()
      .getFeatureOption('printingStorageLabels', 'printerType', 'string');

    confirmationButtonLabel =
      printerFeatureOption === PrinterFeatureOptions.ZebraQrCodePrinter
        ? $t('pages.assign-labels.confirmation-button-label.generate-qr')
        : $t('pages.assign-labels.confirmation-button-label.print-label');
  }
};

const saveDeliveryUnits = async () => {
  if (order.value?.numberOfLabels) {
    const deliveryUnits = deliveryUnitServicePlugin
      .get()
      .bagDeliveryUnitsFactory(order.value?.numberOfLabels);
    await ordersServicePlugin.get().saveOrder({
      ...order.value,
      deliveryUnits: deliveryUnits,
      numberOfLabels: order.value?.numberOfLabels,
    });
  }
};

const { confirm } = useDynamicDialog();

const goNext = async () => {
  if (order.value !== undefined && order.value?.numberOfLabels < 1) {
    await confirm({
      title: $t('pages.assign-labels.not-enough-labels.title'),
      isTitleCentered: false,
      contentText: $t('pages.assign-labels.not-enough-labels.content'),
      confirmText: $t('pages.assign-labels.not-enough-labels.confirm'),
      showOnlyConfirm: true,
    });

    return;
  }

  try {
    processing.value = true;
    await saveDeliveryUnits();
    await redirectToPrintPage(props.id);
  } finally {
    processing.value = false;
    startTracking(
      `assign-labels-to-printing-${props.id}`,
      PerformanceThresholdEnum.ROUTE_CHANGE,
    );
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(order))
      ? (_openBlock(), _createBlock(_unref(AppHeader), {
          key: 0,
          class: "header"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(BackButton), {
              to: { name: 'order-bags', params: { id: __props.id } }
            }, null, 8, ["to"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(loading) || processing.value)
      ? (_openBlock(), _createBlock(_unref(ScreenLoading), {
          key: 1,
          title: _unref($t)('pages.assign-labels.loading-title'),
          blur: ""
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    (_unref(order))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "note__image" }, [
                _createElementVNode("img", {
                  alt: "",
                  class: "ut-responsive-img",
                  "data-e2e": "labels-screen-image",
                  src: _imports_0
                })
              ], -1)),
              _createElementVNode("h1", _hoisted_4, _toDisplayString(_unref($t)('pages.assign-labels.title')), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_unref(Label), {
                minimumBagCount: 0,
                quantity: _unref(order).numberOfLabels,
                quantityOriginal: 999,
                "data-e2e-id": "Labels",
                title: "Labels",
                "onUpdate:quantity": _cache[0] || (_cache[0] = ($event: any) => (_unref(setNumberOfLabels)($event)))
              }, null, 8, ["quantity"])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(order))
      ? (_openBlock(), _createElementBlock("footer", _hoisted_6, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_unref($t)('pages.assign-labels.labels')), 1),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(order).numberOfLabels), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("p", _hoisted_11, _toDisplayString(_unref($t)('pages.assign-labels.bags')), 1),
              _createElementVNode("p", _hoisted_12, _toDisplayString(_unref(boxBagsNeeded)), 1)
            ])
          ]),
          _createVNode(_unref(Button), {
            disabled: processing.value,
            btnOutline: "",
            class: "footer__button column--full",
            "data-e2e": "confirm-button",
            onClick: goNext
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(confirmationButtonLabel)), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})