import type { Ref } from 'vue';
import { computed, readonly, ref } from 'vue';
import type { Sync, SyncScheduler } from '@/features/sync-scheduler';
import {
  SyncFailureEvent,
  syncSchedulerServicePlugin,
  SyncTableUpdateEvent,
} from '@/features/sync-scheduler';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import { authServicePlugin } from '@/features/core/auth';
import { Order } from '@/features/orders';
import type { Transmission, UseTransmissionsInterface } from '../types';
import { failedTransmissionsServicePlugin } from '../services';
import { useDeviceInfo } from '@/features/device-id';
import { loggerServicePlugin } from '@/features/core/logger';
import type { Cancellable } from '@/utils/types';

const hasPendingTransmissions: Ref<boolean> = ref(false);
const hasFailedTransmissions: Ref<boolean> = ref(false);

const failedTransmissions: Ref<Transmission[]> = ref([]);
const pendingTransmissions: Ref<Transmission[]> = ref([]);
const failedSyncs: Ref<Sync[]> = ref([]);

let syncFailureEventSubscription: undefined | Cancellable;
let syncTableUpdateEventSubscription: undefined | Cancellable;

const _isTransmissionsModalVisible: Ref<boolean> = ref(true);
const isTransmissionsModalVisible = computed(
  () => hasFailedTransmissions.value && _isTransmissionsModalVisible.value,
);
const _isTransmissionsWindowVisible = ref(false);
const checkPendingTransmissions = async (
  syncSchedulerService: SyncScheduler,
) => {
  const pendingSyncs = await syncSchedulerService.getPendingSyncs();
  pendingTransmissions.value = pendingSyncs
    ? await parseTransmissions(pendingSyncs)
    : [];
  hasPendingTransmissions.value = Boolean(pendingTransmissions.value?.length);
};

const checkFailedTransmissions = async (
  syncSchedulerService: SyncScheduler,
  filterReportedTransmissions = false,
) => {
  failedSyncs.value =
    (await syncSchedulerService.getFailedSyncs(filterReportedTransmissions)) ??
    [];

  failedTransmissions.value = failedSyncs.value
    ? await parseTransmissions(failedSyncs.value)
    : [];

  hasFailedTransmissions.value = Boolean(failedTransmissions.value?.length);
};

const formatKebabText = (kebabString: string) => {
  if (!kebabString) return '';
  return kebabString.replace(/-/g, ' ');
};

const parseTransmissions = async (
  transmissions: Sync[],
): Promise<Transmission[]> => {
  const { deviceId } = useDeviceInfo();
  const userId = await authServicePlugin.get().getUserEmail();
  return transmissions.map((transmission) => {
    const commonTransmissionFields = {
      timestamps: {
        scheduledAt: transmission.scheduledAt,
        retries: transmission.retries,
        failReason: transmission.failReason,
      },
      deviceId: deviceId.value,
      userId: userId,
      syncStatusId: transmission.id,
      reportSent: Boolean(transmission.reportSent),
    };

    const entitySnapshot = Order.from({ ...transmission.data.entitySnapshot });
    return {
      id: entitySnapshot.id,
      title: formatKebabText(entitySnapshot.localStatus),
      orderReference: entitySnapshot.orderReference,
      ...commonTransmissionFields,
    };
  });
};

export function useTransmissions(): UseTransmissionsInterface {
  let syncFailureTimeout: number;

  const syncSchedulerService = syncSchedulerServicePlugin.get();
  const eventBusService = eventBusServicePlugin.get();
  const failedTransmissionsService = failedTransmissionsServicePlugin.get();

  const skipTransmission = () => {
    _isTransmissionsModalVisible.value = false;

    clearTimeout(syncFailureTimeout);
    if (!window) return;
    syncFailureTimeout = window.setTimeout(
      () => eventBusService.emit(new SyncFailureEvent()),
      180_000,
    );
  };

  const checkTransmissions = () => {
    void checkPendingTransmissions(syncSchedulerService);
    void checkFailedTransmissions(syncSchedulerService);
  };

  const initTransmissionsCheck = (): void => {
    checkTransmissions();

    if (!syncFailureEventSubscription) {
      syncFailureEventSubscription = eventBusService.on(
        SyncFailureEvent,
        () => {
          void checkFailedTransmissions(syncSchedulerService);
          void checkPendingTransmissions(syncSchedulerService);
        },
      );
    }
    if (!syncTableUpdateEventSubscription) {
      syncTableUpdateEventSubscription = eventBusService.on(
        SyncTableUpdateEvent,
        () => {
          void checkFailedTransmissions(syncSchedulerService);
          void checkPendingTransmissions(syncSchedulerService);
        },
      );
    }
  };

  const toggleTransmissionsWindowVisibility = (state = true) => {
    _isTransmissionsWindowVisible.value = state;
    if (state) {
      _isTransmissionsModalVisible.value = false;
    }
  };

  const isTransmissionsWindowVisible = readonly(_isTransmissionsWindowVisible);

  const setTransmissionsAsReported = async (
    transmissions: Transmission[],
  ): Promise<void> => {
    const reportedSyncs = failedSyncs.value.filter((syncStatus) =>
      transmissions.some(
        (transmission) => transmission.syncStatusId === syncStatus.id,
      ),
    );

    await syncSchedulerService.setTransmissionsAsReported(reportedSyncs);
  };

  const sendFailedTransmissionReport = async (): Promise<boolean> => {
    try {
      await checkFailedTransmissions(syncSchedulerService);
      const reportedTransmissions =
        await failedTransmissionsService.sendFailedTransmissionReports(
          failedTransmissions.value,
        );

      if (reportedTransmissions.length > 0) {
        await setTransmissionsAsReported(reportedTransmissions);
      }

      return true;
    } catch (e) {
      loggerServicePlugin.get().error(e as Error);
      return false;
    }
  };

  return {
    hasPendingTransmissions,
    hasFailedTransmissions,
    failedTransmissions,
    pendingTransmissions,
    skipTransmission,
    toggleTransmissionsWindowVisibility,
    isTransmissionsWindowVisible,
    isTransmissionsModalVisible,
    initTransmissionsCheck,
    checkTransmissions,
    sendFailedTransmissionReport,
  };
}
