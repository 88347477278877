import type { Product } from '@/features/products/entities';
import { $t } from '@/i18n';

export interface TemperatureClass {
  name: string | string[];
  label: string;
  active: boolean;
  color: string;
}

export interface BasicTemperatureClass {
  name: string;
  label: string;
  active: boolean;
  color: string;
}

export interface ProductResponse extends Omit<Product, 'ageRestricted'> {
  ageRestriction: number;
}

export interface ProductResponseItem {
  attributes: ProductResponse;
}

export enum TemperatureClassList {
  fresh = 'fresh',
  chiller = 'chiller',
  freezer = 'freezer',
  ambient = 'ambient',
}

export const basicTemperatureClasses = [
  {
    name: TemperatureClassList.fresh,
    label: $t('components.products-types.temperature-classes.fresh.text'),
    active: false,
    color: '#8EB826',
  },
  {
    name: TemperatureClassList.chiller,
    label: $t('components.products-types.temperature-classes.chiller.text'),
    active: false,
    color: '#FF7800',
  },
  {
    name: TemperatureClassList.freezer,
    label: $t('components.products-types.temperature-classes.freezer.text'),
    active: false,
    color: '#55C3F0',
  },
  {
    name: TemperatureClassList.ambient,
    label: $t('components.products-types.temperature-classes.ambient.text'),
    active: false,
    color: '#FFC800',
  },
].map((i) => Object.freeze(i));
