import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { LoggerService } from '@/features/core/logger';
import { updateOrderTimestamp } from '@/utils/helpers/updateOrderTimestamp';
import type { RestageOrderPluginDto } from '../types';
import type { OrderLocalStatus } from '@/features/orders';
import { EmitHandoverAbortedBusEventPlugin } from './emit-handover-aborted-bus-event';
import { TrackEventRestagingCompletedPlugin } from './track-event-restaging-completed';

/**
 * Update local status
 **/
export class UpdateLocalStatusPlugin
  implements PipelinePlugin<RestageOrderPluginDto>
{
  public readonly requiredPlugins = [
    EmitHandoverAbortedBusEventPlugin,
    TrackEventRestagingCompletedPlugin,
  ];

  constructor(
    private loggerService: LoggerService,
    private localStatus: OrderLocalStatus,
  ) {}

  public execute(
    dataTransferObject: RestageOrderPluginDto,
  ): RestageOrderPluginDto {
    dataTransferObject.order.localStatus = this.localStatus;

    this.loggerService.debug(
      `Order status changed to ${dataTransferObject.order.localStatus} for order ${dataTransferObject.order.id}`,
      { order: dataTransferObject.order },
    );

    updateOrderTimestamp(dataTransferObject.order);

    this.loggerService.debug(
      `Order timestamp changed for order ${dataTransferObject.order.id}`,
      {
        order: dataTransferObject.order,
      },
    );

    return dataTransferObject;
  }
}
